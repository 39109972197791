import {handleValidationChange} from '../CommonValidation';

export const validateCashAdvance = (data, employees, setIsError) => {
    var isValid = true;
    console.log(data)
    console.log(employees)

    if(data.cash_advance_date === "") {
        handleValidationChange("cash_advance_date", true, setIsError);
        console.log("cash_advance_date")
        isValid=false;
    } else {
        handleValidationChange("cash_advance_date", false, setIsError);
    }

    if(data.disbursement_date === "") {
        handleValidationChange("disbursement_date", true, setIsError);
        console.log("disbursement_date")
        isValid=false;
    } else {
        handleValidationChange("disbursement_date", false, setIsError);
    }

    if(data.avail_date === "") {
        handleValidationChange("avail_date", true, setIsError);
        console.log("avail_date")
        isValid=false;
    } else {
        handleValidationChange("avail_date", false, setIsError);
    }

    if (employees.length < 1) {
        handleValidationChange("list", true, setIsError);
        console.log("list")
        isValid = false;
    } else {
        handleValidationChange("list", false, setIsError);
    }

    if (employees.length >= 1) {
        employees.map((data) => {
            if(data.employee_id === "" || data.employee_id === undefined) {
                handleValidationChange("listInfo", true, setIsError);
                console.log("employee_id")
                isValid=false;
            } else {
                handleValidationChange("listInfo", false, setIsError);
            }
    
            if(data.amount === "" || data.amount === undefined) {
                handleValidationChange("listInfo", true, setIsError);
                console.log("amount")
                isValid=false;
            } else {
                handleValidationChange("listInfo", false, setIsError);
            }
            
            if(data.disbursement_method === "" || data.disbursement_method === undefined) {
                handleValidationChange("listInfo", true, setIsError);
                console.log("disbursement_method")
                isValid=false;
            } else {
                handleValidationChange("listInfo", false, setIsError);
            }

        })
    }


    return isValid
  
}