import moment from "moment";
import React, { useState } from "react";
import { Col, Container, Row, Table, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import cleanLogo from "../../../Assets/Images/Login/logo.png";
import grabfood from "../../../Assets/Images/Login/grabfood-logo.png";
import signature from "../../../Assets/Images/signature.png";
import Navbar from "../../../Components/Navbar/Navbar";
import { getSupplier } from "../../../Helpers/apiCalls/suppliersApi";
import NoDataImg from "../../../Assets/Images/no-data-img.png"
import {
    dateFormat,
    formatDate,
    getName,
    getTodayDateISO,
    isAdmin,
    numberFormat,
    refreshPage,
    toastStyle,
    TokenExpiry,
} from "../../../Helpers/Utils/Common";
import Moment from "moment";
import { getExpenses, searchExpenses } from "../../../Helpers/apiCalls/Reports/DailyExpensesApi";
import { getGrabfoodReport, getGrabfoodInvoice } from './../../../Helpers/apiCalls/Reports/GrabfoodReportApi';
import "../OfficeReport.css"

export default function PrintGrabfoodReport() {
    const { id } = useParams(); // id
    const { state } = useLocation();
    let navigate = useNavigate();

    const [inactive, setInactive] = useState(true);
    const [allDetails, setAllDetails] = useState([]);
    const date = state.date;


    async function fetchData() {
        setAllDetails([])

        const response = await getGrabfoodInvoice(id, date);
        console.log(response)
        if (response.error) {
        } else {
            var allData = response.data.data.map((data) => {
                var info = data;
                return info;
            });
            setAllDetails(allData[0])
        }
    }
    console.log(allDetails)

    async function handlePrint() {
        toast.loading("Printing invoice...", { style: toastStyle() });
        setTimeout(() => {
            toast.dismiss();
            Print();
        }, 1000);
    }

    function renderTable() {
        return (
            <Table className="align-middle">
                <thead>
                    <tr>
                        <th colSpan={3}></th>
                        <th>Normal Orders</th>
                        <th>Dine-In Orders</th>
                        <th>Pandabox Orders</th>
                        <th>Total</th>
                        <th colSpan={1}></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan={3} className="text-start">Number of successful orders</td>
                        <td>{numberFormat(allDetails.normal_orders)}</td>
                        <td>{numberFormat(allDetails.dinein_orders)}</td>
                        <td>{numberFormat(allDetails.pandabox_orders)}</td>
                        <td>{numberFormat(allDetails.total_orders)}</td>
                    </tr>
                    <tr>
                        <td colSpan={3} className="text-start">Your total revenue after discounts funded by you</td>
                        <td colSpan={4}></td>
                        <td>{numberFormat(allDetails.sales_revenue)}</td>
                    </tr>
                    <tr>
                        <td colSpan={8}><hr/></td>
                    </tr>
                    <tr>
                        <td colSpan={3} className="text-start">Outstanding amount</td>
                        <td colSpan={4}></td>
                        <td>{numberFormat(allDetails.outstanding_amount)}</td>
                    </tr>
                </tbody>
            </Table>
        );
    }

    function renderTable3() {
        return (
            <Table className="align-middle">
                <thead>
                    <tr>
                        <th colSpan={3}></th>
                        <th>Tax rate</th>
                        <th colSpan={1}></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan={3} className="text-start">Other services (Appendix B)</td>
                        <td>{numberFormat(allDetails.other_services_tax_rate)}</td>
                        <td>{numberFormat(allDetails.other_services_tax_rate)}</td>
                    </tr>
                    <tr>
                        <td colSpan={5}><hr/></td>
                    </tr>
                    <tr>
                        <td colSpan={3} className="text-start">Total Fees & Adjustments</td>
                        <td colSpan={1}></td>
                        <td>{numberFormat(allDetails.total_fees_and_adjustments)}</td>
                    </tr>
                </tbody>
            </Table>
        );
    }

    function renderTable4() {
        return (
            <Table className="align-middle">
                <thead>
                    <tr>
                        <th colSpan={3}></th>
                        <th>Tax rate</th>
                        <th>Tax base</th>
                        <th colSpan={1}></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan={3} className="text-start">Total Deductions</td>
                        <td colSpan={2} ></td>
                        <td>{numberFormat(allDetails.vat_and_deductions)}</td>
                    </tr>
                    <tr>
                        <td colSpan={3} className="text-start">VAT on Total Deductions</td>
                        <td>{numberFormat(allDetails.vat_on_total_deductions_tax_rate)}</td>
                        <td>{numberFormat(allDetails.vat_on_total_deductions_tax_base)}</td>
                        <td>{numberFormat(allDetails.total_vat_on_deductions)}</td>
                    </tr>
                    <tr>
                        <td colSpan={6}><hr/></td>
                    </tr>
                    <tr>
                        <td colSpan={3} className="text-start">Total VAT + Deductions</td>
                        <td colSpan={2}></td>
                        <td>{numberFormat(allDetails.vat_and_deductions)}</td>
                    </tr>
                </tbody>
            </Table>
        );
    }

    function Print() {
        let printContents = document.getElementById("printablediv").innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print(printContents);
        document.body.innerHTML = originalContents;
        refreshPage();
    }
    //console.log(allDetails)
    React.useEffect(() => {
        fetchData();
    }, []);

    console.log(allDetails?.attachment)

    return (
        <div>
            <div className="page">
                <Navbar
                    onCollapse={(inactive) => {
                        setInactive(inactive);
                    }}
                    active={"STORE REPORTS"}
                />
            </div>
            <div className={`container ${inactive ? "inactive" : "active"}`}>
                <div className=" px-3 py-2" id="printablediv">
                    <div
                        className="text-end print-header d-flex flex-column"
                    >
                        <span>BILLING STATEMENT</span>
                        <span className="text-uppercase">
                            {moment(getTodayDateISO()).format("MMMM DD, yyyy")}
                        </span>
                    </div>
                    <div className="d-flex justify-content-center py-1">
                        <img src={grabfood} className="print-logo" />
                    </div>

                    {/* content */}
                    <div className="print-body mt-5 justify-content-start">
                        <Row className="d-flex align-items-start">
                            <Col xs={8}>
                                <div className="d-flex my-2 align-items-center">
                                    <Col xs={4} className="print-label">
                                        Partner Name:
                                    </Col>
                                    <Col xs={7} className="print-data">
                                        {allDetails.partner_name}
                                    </Col>
                                </div>
                                <div className="d-flex my-2 align-items-center">
                                    <Col xs={4} className="print-label">
                                        Company Name:
                                    </Col>
                                    <Col xs={7} className="print-data">
                                        {allDetails.company_name}
                                    </Col>
                                </div>
                                <div className="d-flex my-2 align-items-center">
                                    <Col xs={4} className="print-label">
                                        Address:
                                    </Col>
                                    <Col xs={7} className="print-data">
                                        {allDetails.address}
                                    </Col>
                                </div>
                            </Col>
                            <Col xs={4}>
                                <div className="d-flex my-2 align-items-center">
                                    <Col xs={4} className="print-label">
                                        Statement Date:
                                    </Col>
                                    <Col xs={7} className="print-data">
                                        {dateFormat(allDetails.statement_date)}
                                    </Col>
                                </div>
                            </Col>
                        </Row>
                        <div className="d-flex mt-3 mb-2 justify-content-evenly">
                            {/* table */}
                            <div className="print-table mt-3 mx-2">
                                {renderTable()}
                            </div>
                        </div>
                        <Row className="d-flex mb-2 mt-2 justify-content-evenly p-3">
                            <Row className="print-table mx-2">
                                <Row className="">
                                    <Col xs={6}><span className="cash-breakdown-subheader f-small left">Commission On Normal Orders (Appendix A)</span></Col>
                                    <Col xs={6} ><span className="cash-breakdown-subheader f-small right d-flex">-{numberFormat(allDetails.commission_on_normal_orders)}</span></Col>
                                </Row>
                                <Row>
                                    <hr/>
                                </Row>
                                <Row className=" ">
                                    <Col xs={6}><span className="cash-breakdown-subheader f-small left">Total Commission</span></Col>
                                    <Col xs={6} ><span className="cash-breakdown-subheader f-small right d-flex">-{numberFormat(allDetails.commission)}</span></Col>
                                </Row>
                            </Row>
                        </Row>
                        <div className="d-flex mt-2 mb-2 justify-content-evenly">
                            {/* table */}
                            <div className="print-table mt-3 mx-2">
                                {renderTable3()}
                            </div>
                        </div>
                        <div className="d-flex mt-2 mb-2 justify-content-evenly">
                            {/* table */}
                            <div className="print-table mt-3 mx-2">
                                {renderTable4()}
                            </div>
                        </div>

                        <Row className="d-flex mt-2 mb-2 justify-content-evenly p-3">
                            <Row className="print-table mx-2">
                                <Col xs={6}><span className="cash-breakdown-subheader f-small ">Total amount paid out </span></Col>
                                <Col xs={6} ><span className="cash-breakdown-subheader right d-flex green f-small ">{numberFormat(allDetails.total_amount_paid_out)}</span></Col>
                            </Row>
                        </Row>

                    </div>
                </div>

                {/* footer */}
                <div className="d-flex justify-content-end my-4 pb-5">
                    <button
                        className="button-secondary me-3"
                        onClick={() => navigate(-1)}
                    >
                        Close
                    </button>
                    <button className="button-primary" onClick={handlePrint}>
                        Print
                    </button>
                </div>
            </div>
        </div>
    );
}
