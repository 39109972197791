import React, { useState } from "react";
import { Col, Form, Row, Tab, Tabs, Modal, DropdownButton, ButtonGroup, Dropdown } from "react-bootstrap";
import toast from "react-hot-toast";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import Delete from "../../../Components/Modals/DeleteModal";
import Navbar from "../../../Components/Navbar/Navbar";
import Table from "../../../Components/TableTemplate/Table";
import AdjustmentTable from "./../../Inventory/Adjustment/AdjustmentTable";
import NoDataImg from "../../../Assets/Images/no-data-img.png"
import {
    dateFormat,
    formatDateNoTime,
    formatDate,
    numberFormat,
    refreshPage,
    toastStyle,
    getTodayDate,
    getType,
    TokenExpiry,
    getTodayDateISO
} from "../../../Helpers/Utils/Common"; 
import DatePicker from "react-datepicker";
import Moment from "moment";

import { getAllBanks } from "../../../Helpers/apiCalls/banksAPi";
import { getAllFranchisee } from "../../../Helpers/apiCalls/franchiseeApi";
import { searchStoreDeposit, updateStatusStoreDeposit, deleteStoreDeposit } from "../../../Helpers/apiCalls/Reports/StoreDepositApi";
import { getCashCountReport } from "../../../Helpers/apiCalls/Reports/DailyCashCountApi";
// import { getAllBranches } from "../../../Helpers/apiCalls/Manage/Branches";
import { getAllEmployees } from "../../../Helpers/apiCalls/employeesApi";
import { CSVLink, CSVDownload } from "react-csv";
import downloadIcon from "../../../Assets/Images/download_icon.png";
import { Fragment } from "react";
import { getAllDiscounts, getAllDiscountsPotato, deleteDiscounts, deleteDiscountsPotato } from './../../../Helpers/apiCalls/Manage/DiscountsApi';
import { searchBranch } from './../../../Helpers/apiCalls/Manage/Branches';
import { searchBranchPotato } from './../../../Helpers/apiCalls/PotatoCorner/Manage/Branches';


export default function Payroll() {
    let navigate = useNavigate();
    var dateToday = getTodayDate();
    const accountType = getType();
    const [inactive, setInactive] = useState(true);
    const [filterConfig, setFilterConfig] = useState({
        tab: "valid",
        shop: "",
        validity: "valid",
        branch_id: "",
        code: "",
        date_from: "",
        date_to: "",
    })
    const [employees, setEmployees] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [discounts, setDiscounts] = useState([]);
    const [totalSales, setTotalSales] = useState([]);
    const [checkedPayments, setCheckedPayments] = useState([]);
    const [selectedTab, setSelectedTab] = useState("submitted");
    const [dailySales, setDailySales] = useState([]);
    const [branches, setBranches] = useState([]);

     /* delete modal handler */
     const [showDeleteModal, setShowDeleteModal] = useState(false);
     const handleShowDeleteModal = () => setShowDeleteModal(true);
     const handleCloseDeleteModal = () => setShowDeleteModal(false); 

     const [selectedRow, setSelectedRow] = useState([]);
     const [selectedEmployee, setSelectedEmployee] = useState([]);
     const today = Moment().format("MM/DD/YYYY");

    const excelHeaders = [
        // { label: "Sales Date", key: "sales_date" },
        { label: "Total Sales", key: "total_sales" },
        { label: "Branch Name", key: "branch_name" },
        { label: "Bank", key: "bank" },
        // { label: "DSR No.", key: "dsr_no" },
        { label: "Deposit Date", key: "deposited_on" },
        { label: "Transaction Type", key: "transaction_type" },
        // { label: "Deposited By", key: "deposited_by" },
    ];

    const dummy = [
        {
            id: "1",
            type: "mango",
            branch_name: "SM CITY CEBU",
            date: "December 2022",
            amount: "1000",
            added_by: "JOSE RIZAL",
        },
        {
            id: "2",
            type: "potato",
            branch_name: "SM CONSOLACION",
            date: "December 2022",
            amount: "1000",
            added_by: "JOSE RIZAL",
        },
    ]

    const handleTabSelect = (key) => {
        setFilterConfig((prev) => {
            return {
                ...prev,
                tab: key,
                validity: key,
            };
        });
    };

    // SEARCH USER
    function handleOnSearch(e) {
        const { name, value } = e.target;
        setFilterConfig((prev) => {
            return { ...prev, [name]: value };
        });
    }

    function handleSelectChange(e, row) {
        setSelectedRow(row);
        console.log(row)

        if (e.target.value === "view") {
            navigate("/discounts/view/" + row.shop + "/" + row.id)
        } else if (e.target.value === "edit") {
            navigate("/discounts/edit/" + row.shop + "/" + row.id)
        } else if (e.target.value === "delete") {
            handleShowDeleteModal();
        }
    }


    function ActionBtn(row, type) {
        return (
            <Form.Select
                name="action"
                className="PO-select-action"
                onChange={(e) => handleSelectChange(e, row)}
            >
                <option value="" hidden selected>
                    Select
                </option>
                <option value="view" className="color-options">
                    View
                </option>
                <option value="edit" className="color-options">
                    Edit
                </option>
                <option value="delete" className="color-red">
                    Delete
                </option>
            </Form.Select>
        );
    }

    function ViewBtn(row) {
        return (
            <button
                name="action"
                className="btn btn-sm view-btn-table"
                id={row.id}
                onClick={() => navigate("/discounts/view/" + row.shop + "/" + row.id)}
            >
                View
            </button>
        );
    }

    async function fetchEmployees() {

        const response = await getAllEmployees();
        if (response.error) {
            setEmployees([])
        } else {
            let result = response.data.data.map((data) => {
                var employee = data;
                employee.label = data.first_name + " " + data.last_name;
                employee.value = data.id;
                return employee;
            });
            setEmployees([{label: "All Employees", value:""}, ...result]);
        }
    }

    async function fetchBranches() {
        setBranches([]);
        //Mango
        if (filterConfig.shop === "mango") {
            const response = await searchBranch({is_franchise: 0});
            if (response.data) {
                var result = response.data.data.map((data) => {
                    var branch = data;
                    branch.label = data.name;
                    branch.value = data.id;
                    return branch;
                });
                setBranches(result);
            } else if (response.error) {
                setBranches([]);
            }
        } else if (filterConfig.shop === "potato") {
            const response2 = await searchBranchPotato({is_franchise: 0});

            if (response2.data) {
                var result = response2.data.data.map((data) => {
                    var branch = data;
                    branch.label = data.name;
                    branch.value = data.id;
                    return branch;
                });
                setBranches(result);
            } else if (response2.error) {
                setBranches([]);
            }
        } else if(filterConfig.shop === ""){
            const response = await searchBranch({is_franchise: 0});
            if (response.data) {
                var result = response.data.data.map((data) => {
                    var branch = data;
                    branch.label = data.name;
                    branch.value = data.id;
                    setBranches((prev) => [...prev, branch]);
                });
            }

            const response2 = await searchBranchPotato({is_franchise: 0});
            if (response2.data) {
                var result = response2.data.data.map((data) => {
                    var branch = data;
                    branch.label = data.name;
                    branch.value = data.id;
                    setBranches((prev) => [...prev, branch]);
                });
            }
        }
    }

    async function fetchData() {
        setShowLoader(true);
        setDiscounts([])

        if(filterConfig.shop === "mango") {
            const response = await getAllDiscounts(filterConfig);
            console.log("response", response)
            if (response.error) {
                setDiscounts([])
            } else {
                var allData = response.data.data.map((data) => {
                    var days = ""
                    if(data.sundays === "1") {
                        days = "Sunday "
                    }
                    if(data.mondays === "1") {
                        days = days + "Monday "
                    }
                    if(data.tuesdays === "1") {
                        days = days + "Tuesday "
                    }
                    if(data.wednesdays === "1") {
                        days = days + "Wednesday "
                    }
                    if(data.thursdays === "1") {
                        days = days + "Thursday "
                    }
                    if(data.fridays === "1") {
                        days = days + "Friday "
                    }
                    if(data.saturdays === "1") {
                        days = days + "Saturday "
                    }
                    console.log(days)
                    var info = data;
                    info.added_on = Moment(data.added_on).format("MM/DD/YYYY")
                    info.valid_date = formatDate(data.valid_from) + " - " + formatDate(data.valid_to)
                    info.applicable_days = days
                    info.commission_rate = numberFormat(data.commission_rate)
                    info.vat_rate = numberFormat(data.vat_rate)
                    info.other_fee = numberFormat(data.other_fee)
                    info.discount_amount = numberFormat(data.discount_amount)
                    info.shop = "mango"
                    return info;
                });
                setDiscounts(allData)
            }
            setShowLoader(false);
        } else if(filterConfig.shop === "potato") {
            const response = await getAllDiscountsPotato(filterConfig);
            console.log("response", response)
            if (response.error) {
                setDiscounts([])
            } else {
                var allData = response.data.data.map((data) => {
                    var days = ""
                    if(data.sundays === "1") {
                        days = "Sunday "
                    }
                    if(data.mondays === "1") {
                        days = days + "Monday "
                    }
                    if(data.tuesdays === "1") {
                        days = days + "Tuesday "
                    }
                    if(data.wednesdays === "1") {
                        days = days + "Wednesday "
                    }
                    if(data.thursdays === "1") {
                        days = days + "Thursday "
                    }
                    if(data.fridays === "1") {
                        days = days + "Friday "
                    }
                    if(data.saturdays === "1") {
                        days = days + "Saturday "
                    }
                    console.log(days)
                    var info = data;
                    info.added_on = Moment(data.added_on).format("MM/DD/YYYY")
                    info.valid_date = formatDate(data.valid_from) + " - " + formatDate(data.valid_to)
                    info.applicable_days = days
                    info.commission_rate = numberFormat(data.commission_rate)
                    info.vat_rate = numberFormat(data.vat_rate)
                    info.other_fee = numberFormat(data.other_fee)
                    info.discount_amount = numberFormat(data.discount_amount)
                    info.shop = "potato"
                    return info;
                });
                setDiscounts(allData)
            }
            setShowLoader(false);
        } else {

            const response = await getAllDiscounts(filterConfig);
            console.log("response", response)
            var array1 = []
            var array2 = []
            if (response.error) {
            } else {
                var allData = response.data.data.map((data) => {
                    var days = ""
                    if(data.sundays === "1") {
                        days = "Sunday "
                    }
                    if(data.mondays === "1") {
                        days = days + "Monday "
                    }
                    if(data.tuesdays === "1") {
                        days = days + "Tuesday "
                    }
                    if(data.wednesdays === "1") {
                        days = days + "Wednesday "
                    }
                    if(data.thursdays === "1") {
                        days = days + "Thursday "
                    }
                    if(data.fridays === "1") {
                        days = days + "Friday "
                    }
                    if(data.saturdays === "1") {
                        days = days + "Saturday "
                    }
                    console.log(days)

                    var info = data;
                    info.added_on = Moment(data.added_on).format("MM/DD/YYYY");
                    info.valid_date = formatDate(data.valid_from) + " - " + formatDate(data.valid_to);
                    info.applicable_days = days;
                    info.shop = "mango";
                    info.commission_rate = numberFormat(data.commission_rate)
                    info.vat_rate = numberFormat(data.vat_rate)
                    info.other_fee = numberFormat(data.other_fee)
                    info.discount_amount = numberFormat(data.discount_amount)
                    array1.push(info)
                    return info;
                });
            }

            const response2 = await getAllDiscountsPotato(filterConfig);
            console.log("response", response2)
            var array = []
            if (response2.error) {
            } else {
                var allData = response2.data.data.map((data) => {
                    var days = ""
                    if(data.sundays === "1") {
                        days = "Sunday "
                    }
                    if(data.mondays === "1") {
                        days = days + "Monday "
                    }
                    if(data.tuesdays === "1") {
                        days = days + "Tuesday "
                    }
                    if(data.wednesdays === "1") {
                        days = days + "Wednesday "
                    }
                    if(data.thursdays === "1") {
                        days = days + "Thursday "
                    }
                    if(data.fridays === "1") {
                        days = days + "Friday "
                    }
                    if(data.saturdays === "1") {
                        days = days + "Saturday "
                    }
                    console.log(days)
                    var info = data;
                    info.added_on = Moment(data.added_on).format("MM/DD/YYYY");
                    info.valid_date = formatDate(data.valid_from) + " - " + formatDate(data.valid_to);
                    info.applicable_days = days;
                    info.shop = "potato";
                    info.commission_rate = numberFormat(data.commission_rate)
                    info.vat_rate = numberFormat(data.vat_rate)
                    info.other_fee = numberFormat(data.other_fee)
                    info.discount_amount = numberFormat(data.discount_amount)
                    array2.push(info);
                    return info;
                });
            }  
            setDiscounts(array1.concat(array2))
        }
        setShowLoader(false);
    }

    async function handleDeleteDiscount() {
        console.log(selectedRow)
        if(selectedRow.shop === "mango") {
            const response = await deleteDiscounts(selectedRow.id);
            if (response.data) {
                toast.success("Discount Deleted Successfully!", {
                    style: toastStyle(),
                });
                setTimeout(() => refreshPage(), 1000);
            } else {
                toast.error("Error Deleting Discount", {
                    style: toastStyle(),
                });
            }
        } else if(selectedRow.shop === "potato") {
            const response = await deleteDiscountsPotato(selectedRow.id);
            if (response.data) {
                toast.success("Discount Deleted Successfully!", {
                    style: toastStyle(),
                });
                setTimeout(() => refreshPage(), 1000);
            } else {
                toast.error("Error Deleting Discount", {
                    style: toastStyle(),
                });
            }
        }
        
    }

    React.useEffect(() => {
        fetchEmployees();
    }, []);

    React.useEffect(() => {
        fetchData();
        fetchBranches();
    }, [filterConfig]);

    return (
        <div>
            <div className="page">
                <Navbar
                    onCollapse={(inactive) => {
                        setInactive(inactive);
                    }}
                    active={"MANAGE"}
                />
            </div>

            <div
                className={`manager-container ${
                    inactive ? "inactive" : "active"
                }`}
            >
                {/* headers */}
                <Row className="mb-3">
                    <Col xs={6}>
                        <h1 className="page-title"> DISCOUNTS </h1>
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <input
                            type="text"
                            name="code"
                            placeholder="Search code"
                            className="search-bar"
                            onChange={handleOnSearch}
                        ></input>
                        <DropdownButton
                            as={ButtonGroup}
                            title="Add"
                            id="bg-nested-dropdown"
                            className="add-btn"
                            // onSelect={handleAddSelect}
                        >
                            <Dropdown.Item
                                eventKey="MANGO MAGIC"
                                onClick={(e) => navigate("/discounts/add/mango")}
                            >
                                To Mango Magic
                            </Dropdown.Item>
                            <Dropdown.Item
                                eventKey="POTATO CORNER"
                                onClick={(e) => navigate("/discounts/add/potato")}
                            >
                                To Potato Corner
                            </Dropdown.Item>
                        </DropdownButton>
                    </Col>
                </Row>

                {/* <div className="row mb-4 my-2 PO-filters d-flex filter">
                    <Row>
                        <Col xs={6}>
                            <Select
                                className="dropsearch-filter-col px-2 py-0 me-2 "
                                classNamePrefix="react-select"
                                defaultValue={transactionValue}
                                options={transactionOptions}
                                onChange={(e) => {
                                    setFilterConfig((prev) => {
                                        return { ...prev, "transaction_type": e.value };
                                    });
                                }}
                            />
                        </Col>
                        <Col className="d-flex justify-content-end mb-4">
                            <div className="justify-content-center align-items-center ">
                                <CSVLink
                                    className="button-primary px-3 py-3 justify-content-center align-items-center download-csv"
                                    data={expenses}
                                    headers={excelHeaders}
                                    target="_blank"
                                    filename={`${getTodayDateISO()} Store Deposit`}
                                >
                                    <span className="me-2">
                                        <img
                                            width={20}
                                            height={20}
                                            src={downloadIcon}
                                        ></img>
                                    </span>
                                    Download Excel
                                </CSVLink>
                            </div>
                        </Col>
                    </Row>
                </div> */}

                <Tabs
                    activeKey={filterConfig.tab}
                    // defaultActiveKey={filterConfig.shop}
                    id="PO-tabs"
                    className="manager-tabs"
                    onSelect={handleTabSelect}
                >
                    <Tab eventKey="valid" title="active">

                        <div className="tab-content">
                            {/* filters */}
                            <div className="my-2 px-2 PO-filters d-flex">
                                <span className="me-4 align-middle mt-2 ps-label">
                                    Filter By:
                                </span>

                                <Select
                                    className="dropsearch-filter px-0 py-0 me-2"
                                    classNamePrefix="react-select"
                                    placeholder="Select Branch"
                                    styles={{
                                        control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        backgroundColor: state.isSelected ? 'white' : '#59c8e3',
                                        borderRadius: "7px",
                                        border: "0px",
                                        minHeight: "20px",
                                        maxHeight: "35px",
                                        }),
                                        input: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: state.isSelected? "white": "white",
                                            
                                        }),
                                        dropdownIndicator: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        singleValue: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        placeholder: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        
                                    }}
                                    // value={branch}
                                    options={branches}
                                    onChange={(e) => { setFilterConfig((prev) => {
                                            return { ...prev, "branch_id": e.value };
                                        });}}
                                />

                                <Form.Select
                                    name="shop"
                                    className="date-filter me-2"
                                    // defaultValue={filterConfig.shop}
                                    onChange={(e) => {
                                        setFilterConfig((prev) => {
                                            return { ...prev, shop: e.target.value };
                                        });
                                    }}
                                >
                                    <option value="" selected>
                                        All Shop
                                    </option>
                                    <option value="mango" >
                                        Mango Magic
                                    </option>
                                    <option value="potato" >
                                        Potato Corner
                                    </option>
                                </Form.Select>

                                <span className="me-4 align-middle mt-2 ps-label">
                                    From:
                                </span>
                                <DatePicker
                                    selected={filterConfig.date_from}
                                    name="date_from"
                                    placeholderText={"Select Date"}
                                    onChange={(date) => {
                                        setFilterConfig((prev) => {
                                            return { ...prev, date_from: date };
                                        });
                                    }}
                                    fixedHeight
                                    className="PI-date-btn me-3 form-control"
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    yearDropdownItemNumber={20}
                                    scrollableYearDropdown
                                />

                                <span className="me-4 align-middle mt-2 ps-label">
                                    To:
                                </span>
                                <DatePicker
                                    selected={filterConfig.date_to}
                                    name="date_to"
                                    placeholderText={"Select Date"}
                                    onChange={(date) => {
                                        setFilterConfig((prev) => {
                                            return { ...prev, date_to: date };
                                        });
                                    }}
                                    fixedHeight
                                    className="PI-date-btn me-3 form-control"
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    yearDropdownItemNumber={20}
                                    scrollableYearDropdown
                                />
                            </div>
                            <div className="below">
                                {/* table */}
                                <Table
                                    tableHeaders={[
                                        "-",
                                        "NO",
                                        "DATE ADDED",
                                        "VALID DATE",
                                        "APPLICABLE DAYS",
                                        "COMMISSION RATE",
                                        "VAT RATE",
                                        "OTHER FEES",
                                        "CUSTOMER DISCOUNT",
                                        "PREP BY",
                                        "ACTIONS",
                                    ]}
                                    headerSelector={[
                                        "-",
                                        "id",
                                        "added_on",
                                        "valid_date",
                                        "applicable_days",
                                        "commission_rate",
                                        "vat_rate",
                                        "other_fee",
                                        "discount_amount",
                                        "added_by_name",
                                    ]}
                                    tableData={discounts}
                                    ActionBtn={(row) => ActionBtn(row)}
                                    ViewBtn={(row) => ViewBtn(row)}
                                    showLoader={showLoader}
                                />
                            </div>
                            <div className="mb-2" />
                        </div>
                    </Tab>
                    <Tab eventKey="invalid" title="inactive">

                        <div className="tab-content">
                            {/* filters */}
                            <div className="my-2 px-2 PO-filters d-flex">
                                <span className="me-4 align-middle mt-2 ps-label">
                                    Filter By:
                                </span>

                                <Select
                                    className="dropsearch-filter px-0 py-0 me-2"
                                    classNamePrefix="react-select"
                                    placeholder="Select Branch"
                                    styles={{
                                        control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        backgroundColor: state.isSelected ? 'white' : '#59c8e3',
                                        borderRadius: "7px",
                                        border: "0px",
                                        minHeight: "20px",
                                        maxHeight: "35px",
                                        }),
                                        input: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: state.isSelected? "white": "white",
                                            
                                        }),
                                        dropdownIndicator: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        singleValue: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        placeholder: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        
                                    }}
                                    // value={branch}
                                    options={branches}
                                    onChange={(e) => { setFilterConfig((prev) => {
                                            return { ...prev, "branch_id": e.value };
                                        });}}
                                />

                                <Form.Select
                                    name="shop"
                                    className="date-filter me-2"
                                    // defaultValue={filterConfig.status}
                                    onChange={(e) => {
                                        setFilterConfig((prev) => {
                                            return { ...prev, shop: e.target.value };
                                        });
                                    }}
                                >
                                    <option value="" selected>
                                        All Shop
                                    </option>
                                    <option value="mango" >
                                        Mango Magic
                                    </option>
                                    <option value="potato" >
                                        Potato Corner
                                    </option>
                                </Form.Select>

                                <span className="me-4 align-middle mt-2 ps-label">
                                    From:
                                </span>
                                <DatePicker
                                    selected={filterConfig.date_from}
                                    name="date_from"
                                    placeholderText={"Select Date"}
                                    onChange={(date) => {
                                        setFilterConfig((prev) => {
                                            return { ...prev, date_from: date };
                                        });
                                    }}
                                    fixedHeight
                                    className="PI-date-btn me-3 form-control"
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    yearDropdownItemNumber={20}
                                    scrollableYearDropdown
                                />

                                <span className="me-4 align-middle mt-2 ps-label">
                                    To:
                                </span>
                                <DatePicker
                                    selected={filterConfig.date_to}
                                    name="date_to"
                                    placeholderText={"Select Date"}
                                    onChange={(date) => {
                                        setFilterConfig((prev) => {
                                            return { ...prev, date_to: date };
                                        });
                                    }}
                                    fixedHeight
                                    className="PI-date-btn me-3 form-control"
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    yearDropdownItemNumber={20}
                                    scrollableYearDropdown
                                />
                            </div>
                            <div className="below">
                                {/* table */}
                                <Table
                                   tableHeaders={[
                                    "-",
                                    "NO",
                                    "DATE ADDED",
                                    "VALID DATE",
                                    "APPLICABLE DAYS",
                                    "COMMISSION RATE",
                                    "VAT RATE",
                                    "OTHER FEES",
                                    "CUSTOMER DISCOUNT",
                                    "PREP BY",
                                    "ACTIONS",
                                ]}
                                headerSelector={[
                                    "-",
                                    "id",
                                    "added_on",
                                    "valid_date",
                                    "applicable_days",
                                    "commission_rate",
                                    "vat_rate",
                                    "other_fee",
                                    "discount_amount",
                                    "added_by_name",
                                ]}
                                    tableData={discounts}
                                    ActionBtn={(row) => ActionBtn(row)}
                                    ViewBtn={(row) => ViewBtn(row)}
                                    showLoader={showLoader}
                                />
                            </div>
                            <div className="mb-2" />
                        </div>
                    </Tab>
                </Tabs>
            </div>

            {/* modals */}
            <Delete
                show={showDeleteModal}
                onHide={handleCloseDeleteModal}
                text="discount"
                onDelete={handleDeleteDiscount}
            />

        </div>
    );
}
