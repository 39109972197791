import {
    formatDateNoTime,
    formatYDM,
    getToken,
    getToken2,
    getUser,
    numberFormatInt
} from "../../Utils/Common";
import Moment from "moment";
import { getAPICall, postAPICall } from "../axiosMethodCalls";

export const getShortageDeduction = async (id) => {
    try {
        const response = await getAPICall(
            process.env.REACT_APP_LINK + "shortage_deductions/get",
            {
                requester: getUser(),
                token: getToken(),
                deduction_id: id,
            }
        );
        return { data: response.data };
    } catch (error) {
        return { error: error };
    }
};

export const getAllShortageDeduction = async (data) => {
    try {
        const response = await getAPICall(
            process.env.REACT_APP_LINK + "shortage_deductions/search",
            {
                requester: getUser(),
                token: getToken(),
                employee_id: data.employee_id,
                branch_id: data.branch_id,
                employee_name: data.employee_name,
                status: data.status,
                source: data.source,
                date_from: data.date_from ? Moment(data.date_from).format("YYYY-MM-DD") : "",
                date_to: data.date_to ? Moment(data.date_to).format("YYYY-MM-DD") : "",

            }
        );  
        return { data: response.data };
    } catch (error) {
        return { error: error };
    }
};

export const createShortageDeduction = async (id, source, date, data) => {
    
    console.log(data, "create")
    let form_data = new FormData();
    form_data.append("requester", getUser());
    form_data.append("token", getToken());
    form_data.append("source", source);
    form_data.append("source_id", id);
    // form_data.append("times_payable", data.times_payable);
    form_data.append("date", Moment(date).format("YYYY-MM-DD"));
    data.map((data) => {
        form_data.append('employee_ids[]', data.employee_id);
    });
    data.map((data) => {
        form_data.append('amounts[]', data.amount);
    });

    try {
        const response = await postAPICall(
            process.env.REACT_APP_LINK + "shortage_deductions/create", form_data
        );
        return { data: response.data };
    } catch (error) {
        return { error: error };
    }
};

export const updateShortageDeduction = async (data) => {
    
    console.log(data, "update")
    let form_data = new FormData();
    form_data.append("requester", getUser());
    form_data.append("token", getToken());
    data.map((data) => {
        form_data.append('deduction_ids[]', data.deduction_id);
    });
    data.map((data) => {
        form_data.append('employee_ids[]', data.employee_id);
    });
    data.map((data) => {
        form_data.append('amounts[]', data.amount);
    });

    try {
        const response = await postAPICall(
            process.env.REACT_APP_LINK + "shortage_deductions/update", form_data
        );
        return { data: response.data };
    } catch (error) {
        return { error: error };
    }
};

export const deleteShortageDeduction = async (data) => {
    let form_data = new FormData();
    form_data.append("requester", getUser());
    form_data.append("token", getToken());
    data.map((data) => {
        form_data.append('deduction_ids[]', data.id);
    });

    try {
        const response = await postAPICall(
            process.env.REACT_APP_LINK + "shortage_deductions/delete", form_data
        );
        return { data: response.data };
    } catch (error) {
        return { error: error };
    }
};