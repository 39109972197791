import {
    formatDateNoTime,
    formatYDM,
    getToken,
    getToken2,
    getUser,
    numberFormatInt
} from "../../Utils/Common";
import Moment from "moment";
import { getAPICall, postAPICall } from "../axiosMethodCalls";

// VIEW TABLE
export const getGrabfoodReport = async (id, date) => {
    try {
        const response = await getAPICall(
            process.env.REACT_APP_LINK + "discounts/reports",
            {
                requester: getUser(),
                token: getToken(),
                branch_id: id,
                date: Moment(date).format("YYYY-MM-DD"),
                transaction_type: "grabfood",
            }
        );
        return { data: response.data };
    } catch (error) {
        return { error: error };
    }
};

// MANAGER TABLE
export const getAllGrabfoodReport = async (data) => {
    const ago = new Date();
    ago.setDate(ago.getDate() - 7);
    try {
        const response = await getAPICall(
            process.env.REACT_APP_LINK + "discounts/reports",
            {
                requester: getUser(),
                token: getToken(),
                branch_id: data.branch_id,
                status: data.status,
                branch_name: data.branch_name,
                date_from: data.date_from ? Moment(data.date_from).format("YYYY-MM-DD") :  Moment(ago).format("YYYY-MM-DD"),
                date_to: data.date_to ? Moment(data.date_to).format("YYYY-MM-DD") :  Moment().format("YYYY-MM-DD"),
                // date: data.date ? Moment(data.date).format("YYYY-MM-DD") :  Moment().format("YYYY-MM-DD"),
                transaction_type: "grabfood",
                by_branch: data.by_branch,
            }
        );
        return { data: response.data };
    } catch (error) {
        return { error: error };
    }
};

// PRINT
export const getGrabfoodInvoice = async (id, date) => {
    try {
        const response = await getAPICall(
            process.env.REACT_APP_LINK + "discounts/invoice",
            {
                requester: getUser(),
                token: getToken(),
                branch_id: id,
                date: Moment(date).format("YYYY-MM-DD"),
                transaction_type: "grabfood",
            }
        );
        return { data: response.data };
    } catch (error) {
        return { error: error };
    }
};

export const deleteGrabfoodReport = async (id) => {
    try {
        const response = await getAPICall(
            process.env.REACT_APP_LINK + "discounts_reports/delete",
            {
                requester: getUser(),
                token: getToken(),
                wastage_id: id,
                transaction_type: "grabfood",
            }
        );
        return { data: response.data };
    } catch (error) {
        return { error: error };
    }
};


export const updateGrabReportStatus = async (ids) => {
    try {
        const response = await getAPICall(
            process.env.REACT_APP_LINK + "discounts/mark_as_reviewed",
            {
                requester: getUser(),
                token: getToken(),
                transaction_type: "grabfood",
                grabfood_report_ids: ids.map((id) => {
                    return id.id;
                }),
            }
        );
        return { data: response.data };
    } catch (error) {
        return { error: error };
    }
};