import React, { useState } from "react";
import { Col, Form, Row, Tab, Tabs, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import Delete from "../../../Components/Modals/DeleteModal";
import Navbar from "../../../Components/Navbar/Navbar";
import Table from "../../../Components/TableTemplate/Table";
import AdjustmentTable from "./../../Inventory/Adjustment/AdjustmentTable";
import {
    dateFormat,
    formatDateNoTime,
    formatDate,
    numberFormat,
    refreshPage,
    toastStyle,
    getTodayDate,
    getType,
    TokenExpiry,
    getTodayDateISO
} from "../../../Helpers/Utils/Common";
import DatePicker from "react-datepicker";
import Moment from "moment";
import { CSVLink, CSVDownload } from "react-csv";
import downloadIcon from "../../../Assets/Images/download_icon.png";

import { getAllFranchisee } from "../../../Helpers/apiCalls/franchiseeApi";
import { getWastage, updateWastageStatus } from "../../../Helpers/apiCalls/Reports/DailyWastageApi";

export default function ViewDailyExpenses() {
    const { id } = useParams();
    let navigate = useNavigate();
    var dateToday = getTodayDate();
    const accountType = getType();
    const [inactive, setInactive] = useState(true);
    const [filterConfig, setFilterConfig] = useState({})
    const [franchisees, setFranchisees] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [wastage, setWastage] = useState([]);
    const [wastageItems, setWastageItems] = useState([]);
    const [wastageItemsCsv, setWastageItemsCsv] = useState([]);
    const [totalQuantity, setTotalQuantity] = useState([]);
    const [averagePrice, setAveragePrice] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [branchName, setBranchName] = useState("");
    const [branchId, setBranchId] = useState("");
    const [status, setStatus] = useState("");
    const [wastageDate, setWastageDate] = useState("");
    const [selectedRow, setSelectedRow] = useState([]);

     /* delete modal handler */
     const [showReviewModal, setShowReviewModal] = useState(false);
     const handleShowReviewModal = () => setShowReviewModal(true);
     const handleCloseReviewModal = () => setShowReviewModal(false);

    const excelHeaders = [
        { label: "Branch Name", key: "branch_name" },
        { label: "Date", key: "date" },
        { label: "Item", key: "name" },
        { label: "Quantity", key: "qty" },
        { label: "Unit", key: "unit" },
        { label: "Status", key: "status" },
        { label: "Charge Amount", key: "wastage_cost" },
        { label: "Charged To", key: "wastage_charged_to" },
        { label: "Reason", key: "reason" },
        { label: "Wastage By", key: "wasted_by_name" },
        { label: "Remarks", key: "remarks" },
        { label: "Payment Status", key: "payment_status" },
        { label: "Approved By", key: "approved_by_name" },
    ];

    const dummy = [
        {
            id: "1",
            item: "straw",
            quantity: "500",
            unit: "pc",
            reason: "asdfasdf",
        },
    ]

    function handleSelectChange(e, row) {
        setSelectedRow(row);
        console.log(row)

        if (e.target.value === "review") {
            handleShowReviewModal()
        } else if (e.target.value === "deduction") {
            navigate("/deductions/" + branchId + "/" + branchName + "/" + wastageDate, {
                state: { 
                    source: "wastage",
                    date: wastageDate,
                    details: row,
                },
            });
        }
    }
    console.log(selectedRow)

    function ActionBtn(row, type) {
        return (
            <Form.Select
                name="action"
                className="PO-select-action"
                onChange={(e) => handleSelectChange(e, row)}
            >
                <option value="" hidden selected>
                    Select
                </option>
                { accountType === "admin" ? (
                    <option value="review" className="color-options">
                        Review
                    </option>
                    ): null
                }
                { (accountType === "admin" && row.status === "approved") ? (
                    <option value="deduction" className="color-options">
                        Employee Deductions
                    </option>
                    ): null
                }
            </Form.Select>
        );
    }

    // SEARCH USER
    function handleOnSearch(e) {
        const { name, value } = e.target;
        setFilterConfig((prev) => {
            return { ...prev, [name]: value };
        });
    }

    async function fetchFranchisee() {
        setShowLoader(true);

        const response = await getAllFranchisee();
        if (response.error) {
        } else {
            setFranchisees(response.data.data);
            var allFranchisee = response.data.data.map((data) => {
                var franchise = data;
                franchise.franchise_name = data.name;
                franchise.franchise = data.name;
                franchise.contract = "0";
                franchise.sale = "0";
                franchise.sale_billing = "0";
                franchise.total = "0";
                return franchise;
            });
            setFranchisees(allFranchisee);
        }
        setShowLoader(false);
    }

    async function fetchData() {
        setShowLoader(true);
        setWastage([])
        setWastageItems([])

        const response = await getWastage(id, status);
        if (response.error) {
        } else {
            setBranchName(response.data.data[0].branch_name);
            setBranchId(response.data.data[0].branch_id);
            setWastageDate(response.data.data[0].wastage_date);
            var wastage = response.data.data.map((data) => {
                var info = data;
                return info;
            });
            setWastage(wastage)
            setWastageItems(wastage[0].wastage_item)

            var array = [{branch_name: response.data.data[0].branch_name, 
                        date: response.data.data[0].wastage_date} ]
            var wastage_items = wastage[0].wastage_item.map((data) => {
                var info = data;
                array.push(info)
                return info;
            });
            setWastageItemsCsv(array)
        }
        setShowLoader(false);
    }

    async function handleChangeWastageStatus(status) {
        const response = await updateWastageStatus(selectedRow.id, status);
        console.log(response)
        if (response.error) {
            toast.error(response.error.response.data.messages.error, {
                style: toastStyle(),
            });
        } else {
            toast.success("Successfully Updated Cash Advance Status!", {
                style: toastStyle(),
            });
            setTimeout(() => {
                refreshPage()
            }, 1000);
        }
    }

    console.log(totalAmount)

    React.useEffect(() => {
        fetchFranchisee();
    }, []);

    React.useEffect(() => {
        fetchData();
    }, [status]);

    return (
        <div>
            <div className="page">
                <Navbar
                    onCollapse={(inactive) => {
                        setInactive(inactive);
                    }}
                    active={"DS REPORTS"}
                />
            </div>

            <div
                className={`manager-container ${
                    inactive ? "inactive" : "active"
                }`}
            >
                {/* headers */}
                <Row className="mb-4">
                    <Col xs={6}>
                        <h1 className="page-title"> {branchName} </h1>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col xs={6}>
                        <h5 className="page-subtitle"> {new Date(wastageDate).toLocaleDateString( "en-us", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })} </h5>
                    </Col>
                </Row>

                <Row>
                    <Col className="d-flex justify-content-end mb-4">
                        <div className="justify-content-center align-items-center ">
                            <CSVLink
                                className="button-primary px-3 py-3 justify-content-center align-items-center download-csv"
                                data={wastageItemsCsv.reverse()}
                                headers={excelHeaders}
                                target="_blank"
                                filename={`${getTodayDateISO()} Wastage_${branchName}`}
                            >
                                <span className="me-2">
                                    <img
                                        width={20}
                                        height={20}
                                        src={downloadIcon}
                                    ></img>
                                </span>
                                Download Excel
                            </CSVLink>
                        </div>
                    </Col>
                </Row>

                <div className="tab-content">
                    {/* filters */}
                    <div className="my-2 px-2 PO-filters d-flex">
                        <span className="me-2 align-middle mt-2 ps-label">
                            Filter By:
                        </span>
                        <Form.Select
                            name="status"
                            className="date-filter me-2"
                            onChange={(e) => setStatus(e.target.value)}
                        >
                            <option value="" selected disabled>
                                Status
                            </option>
                            <option value="">All</option>
                            <option value="settled">Settled</option>
                            <option value="unsettled">Unsettled</option>
                        </Form.Select>
                    </div>

                    <div className="below">
                        {/* table */}
                        <Table
                            tableHeaders={[
                                "ITEM",
                                "QUANTITY",
                                "UNIT",
                                "STATUS",
                                "CHARGE",
                                "CHARGE TO",
                                "REASON",
                                "WASTAGE BY",
                                "REMARKS",
                                "PAY STATUS",
                                "APPROVED BY",
                                "ACTIONS",
                            ]}
                            headerSelector={[
                                "name",
                                "qty",
                                "unit",
                                "status",
                                "wastage_cost",
                                "wastage_charged_to",
                                "reason",
                                "wasted_by_name",
                                "remarks",
                                "payment_status",
                                "approved_by_name",
                            ]}
                            tableData={wastageItems}
                            showLoader={showLoader}
                            ActionBtn={(row) => ActionBtn(row)}
                        />
                    </div>
                    <div className="mb-2" />
                </div>
                
                <div className="d-flex justify-content-end pt-2 mb-3">
                    <button
                        name="action"
                        className="ms-2 button-primary justify-content-center align-items-center close-btn"
                        onClick={() => navigate("/dailywastage")}
                    >
                        Close
                    </button>
                </div>
            </div>
            
            <Modal show={showReviewModal} onHide={()=>handleCloseReviewModal()} size="lg" centered>
                <Modal.Header closeButton/>
                <Modal.Body className='pt-2'>
                    <div className='review-container p-3'>
                        <Row className="nc-modal-custom-row-view">
                            <Col xs={4}>
                                Item Name
                                <Row className="nc-modal-custom-row">
                                    <Col>
                                        {selectedRow?.name ||
                                            "N/A"}
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={4}>
                                Wasted by
                                <Row className="nc-modal-custom-row">
                                    <Col>
                                        {selectedRow?.wasted_by_name ||
                                            "N/A"}
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={4}>
                                Status
                                <Row className="nc-modal-custom-row">
                                    <Col>
                                        {selectedRow?.status ||
                                            "N/A"}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {/* <Row className="nc-modal-custom-row-view mt-3">
                            <Col xs={12}>
                                Charge Amount
                                <Row className="nc-modal-custom-row">
                                    <Col>
                                        <Form.Control
                                            type="amount"
                                            name="amount"
                                            className="nc-modal-custom-text"
                                            value={selectedRow.wastage_cost}
                                            onChange={(e) => setSelectedRow((prev) => {
                                                return {
                                                    ...prev,
                                                    wastage_cost: e.target.value
                                                }
                                            })}
                                            disabled={selectedRow.status !== "pending"}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row> */}
                    </div>
                    {
                        selectedRow.status === "pending" && (
                            <>
                                <div className='d-flex justify-content-end my-4'>
                                    <button
                                        className="button-warning-fill me-2"
                                        onClick={()=>handleChangeWastageStatus("rejected")}
                                    >
                                        Reject
                                    </button>
                                    <button
                                        className="button-primary"
                                        onClick={()=> handleChangeWastageStatus("approved")}
                                    >
                                        Approve
                                    </button>
                                </div>
                            </>
                        )
                    }
                
                </Modal.Body>
            </Modal>
        </div>
    );
}
