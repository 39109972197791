import {
    formatDateNoTime,
    formatYDM,
    getToken,
    getToken2,
    getUser,
} from "../../Utils/Common";
import { getAPICall, postAPICall } from "../axiosMethodCalls";
import Moment from "moment";
import formData from "react-form-data";

//GET
export const getAllDiscounts = async (data) => {
    try {
        const response = await getAPICall(
            process.env.REACT_APP_LINK + "discounts/search",
            {
                requester: getUser(),
                token: getToken(),
                branch_id: data.branch_id,
                code: data.code,
                date_from: data.date_from ? Moment(data.date_from).format("YYYY-MM-DD") : "",
                date_to: data.date_to ? Moment(data.date_to).format("YYYY-MM-DD") : "",
                validity: data.validity,
            }
        );
        return { data: response.data };
    } catch (error) {
        return { error: error };
    }
};

export const getAllDiscountsPotato = async (data) => {
    try {
        const response = await getAPICall(
            process.env.REACT_APP_POTATO + "discounts/search",
            {
                requester: getUser(),
                token: getToken2(),
                branch_id: data.branch_id,
                code: data.code,
                date_from: data.date_from ? Moment(data.date_from).format("YYYY-MM-DD") : "",
                date_to: data.date_to ? Moment(data.date_to).format("YYYY-MM-DD") : "",
                validity: data.validity,
            }, "potato"
        );
        return { data: response.data };
    } catch (error) {
        return { error: error.response };
    }
};

export const getDiscounts = async (id) => {
    try {
        const response = await getAPICall(
            process.env.REACT_APP_LINK + "discounts/get_discount",
            {
                requester: getUser(),
                token: getToken(),
                discount_id: id,
            }
        );
        return { data: response.data };
    } catch (error) {
        return { error: error };
    }
};

export const getDiscountsPotato = async (id) => {
    try {
        const response = await getAPICall(
            process.env.REACT_APP_POTATO + "discounts/get_discount",
            {
                requester: getUser(),
                token: getToken2(),
                discount_id: id,
            }, "potato"
        );
        return { data: response.data };
    } catch (error) {
        return { error: error.response };
    }
};


export const createDiscounts = async (data, days, branches) => {
    var daysArray = []
    days.map((data) => {
        daysArray.push(data.value)
    })
    console.log(daysArray)
    
    console.log(data, "create")
    let form_data = new FormData();
    form_data.append("requester", getUser());
    form_data.append("token", getToken());
    form_data.append("description", data.description);
    // form_data.append("code", data.code);
    form_data.append("valid_from", data.valid_from);
    form_data.append("valid_to", data.valid_to);
    form_data.append("sundays", daysArray.includes("sunday") ? 1 : 0);
    form_data.append("mondays", daysArray.includes("monday") ? 1 : 0);
    form_data.append("tuesdays", daysArray.includes("tuesday") ? 1 : 0);
    form_data.append("wednesdays", daysArray.includes("wednesday") ? 1 : 0);
    form_data.append("thursdays", daysArray.includes("thursday") ? 1 : 0);
    form_data.append("fridays", daysArray.includes("friday") ? 1 : 0);
    form_data.append("saturdays", daysArray.includes("saturday") ? 1 : 0);
    form_data.append("commission_rate", data.commission_rate);
    form_data.append("vat_rate", data.vat_rate);
    form_data.append("other_fee", data.other_fee);
    form_data.append("discount_amount", data.discount_amount);
    form_data.append("discount_source", data.discount_source);
    form_data.append("commission_base", data.commission_base);
    form_data.append("type", data.type);
    form_data.append("delivery_company", data.delivery_company);
    // form_data.append("mm_discount_share", data.mm_discount_share);
    form_data.append("delivery_discount_share", data.delivery_discount_share);
    form_data.append("remarks", data.remarks);
    branches.map((data) => {
        form_data.append('branches[]', data.id);
    });

    try {
        const response = await postAPICall(
            process.env.REACT_APP_LINK + "discounts/create", form_data
        );
        return { data: response.data };
    } catch (error) {
        return { error: error };
    }
};

export const createDiscountsPotato = async (data, days, branches) => {
    var daysArray = []
    days.map((data) => {
        daysArray.push(data.value)
    })
    console.log(daysArray)

    let form_data = new FormData();
    form_data.append("requester", getUser());
    form_data.append("token", getToken2());
    form_data.append("description", data.description);
    // form_data.append("code", data.code);
    form_data.append("valid_from", data.valid_from);
    form_data.append("valid_to", data.valid_to);
    form_data.append("sundays", daysArray.includes("sunday") ? 1 : 0);
    form_data.append("mondays", daysArray.includes("monday") ? 1 : 0);
    form_data.append("tuesdays", daysArray.includes("tuesday") ? 1 : 0);
    form_data.append("wednesdays", daysArray.includes("wednesday") ? 1 : 0);
    form_data.append("thursdays", daysArray.includes("thursday") ? 1 : 0);
    form_data.append("fridays", daysArray.includes("friday") ? 1 : 0);
    form_data.append("saturdays", daysArray.includes("saturday") ? 1 : 0);
    form_data.append("commission_rate", data.commission_rate);
    form_data.append("vat_rate", data.vat_rate);
    form_data.append("other_fee", data.other_fee);
    form_data.append("discount_amount", data.discount_amount);
    form_data.append("discount_source", data.discount_source);
    form_data.append("commission_base", data.commission_base);
    form_data.append("type", data.type);
    form_data.append("delivery_company", data.delivery_company);
    // form_data.append("mm_discount_share", data.mm_discount_share);
    form_data.append("delivery_discount_share", data.delivery_discount_share);
    form_data.append("remarks", data.remarks);
    branches.map((data) => {
        form_data.append('branches[]', data.id);
    });

    try {
        const response = await postAPICall(
            process.env.REACT_APP_POTATO + "discounts/create", form_data, "potato"
        );
        return { data: response.data };
    } catch (error) {
        return { error: error.response };
    }
};


export const updateDiscounts = async (data, days, branches) => {
    var daysArray = []
    days.map((data) => {
        daysArray.push(data.value)
    })
    console.log(daysArray)

    let form_data = new FormData();
    form_data.append("requester", getUser());
    form_data.append("token", getToken());
    form_data.append("discount_id", data.id);
    form_data.append("description", data.description);
    // form_data.append("code", data.code);
    form_data.append("valid_from", data.valid_from);
    form_data.append("valid_to", data.valid_to);
    form_data.append("sundays", daysArray.includes("sunday") ? 1 : 0);
    form_data.append("mondays", daysArray.includes("monday") ? 1 : 0);
    form_data.append("tuesdays", daysArray.includes("tuesday") ? 1 : 0);
    form_data.append("wednesdays", daysArray.includes("wednesday") ? 1 : 0);
    form_data.append("thursdays", daysArray.includes("thursday") ? 1 : 0);
    form_data.append("fridays", daysArray.includes("friday") ? 1 : 0);
    form_data.append("saturdays", daysArray.includes("saturday") ? 1 : 0);
    form_data.append("commission_rate", data.commission_rate);
    form_data.append("vat_rate", data.vat_rate);
    form_data.append("other_fee", data.other_fee);
    form_data.append("discount_amount", data.discount_amount);
    form_data.append("discount_source", data.discount_source);
    form_data.append("commission_base", data.commission_base);
    form_data.append("type", data.type);
    form_data.append("delivery_company", data.delivery_company);
    // form_data.append("mm_discount_share", data.mm_discount_share);
    form_data.append("delivery_discount_share", data.delivery_discount_share);
    form_data.append("remarks", data.remarks);
    branches.map((data) => {
        form_data.append('branches[]', data.id);
    });

    try {
        const response = await postAPICall(
            process.env.REACT_APP_LINK + "discounts/update", form_data
        );
        return { data: response.data };
    } catch (error) {
        return { error: error };
    }
};

export const updateDiscountsPotato = async (data, days, branches) => {
    var daysArray = []
    days.map((data) => {
        daysArray.push(data.value)
    })
    console.log(daysArray)

    let form_data = new FormData();
    form_data.append("requester", getUser());
    form_data.append("token", getToken2());
    form_data.append("discount_id", data.id);
    form_data.append("description", data.description);
    // form_data.append("code", data.code);
    form_data.append("valid_from", data.valid_from);
    form_data.append("valid_to", data.valid_to);
    form_data.append("sundays", daysArray.includes("sunday") ? 1 : 0);
    form_data.append("mondays", daysArray.includes("monday") ? 1 : 0);
    form_data.append("tuesdays", daysArray.includes("tuesday") ? 1 : 0);
    form_data.append("wednesdays", daysArray.includes("wednesday") ? 1 : 0);
    form_data.append("thursdays", daysArray.includes("thursday") ? 1 : 0);
    form_data.append("fridays", daysArray.includes("friday") ? 1 : 0);
    form_data.append("saturdays", daysArray.includes("saturday") ? 1 : 0);
    form_data.append("commission_rate", data.commission_rate);
    form_data.append("vat_rate", data.vat_rate);
    form_data.append("other_fee", data.other_fee);
    form_data.append("discount_amount", data.discount_amount);
    form_data.append("discount_source", data.discount_source);
    form_data.append("commission_base", data.commission_base);
    form_data.append("type", data.type);
    form_data.append("delivery_company", data.delivery_company);
    // form_data.append("mm_discount_share", data.mm_discount_share);
    form_data.append("delivery_discount_share", data.delivery_discount_share);
    form_data.append("remarks", data.remarks);
    branches.map((data) => {
        form_data.append('branches[]', data.id);
    });

    try {
        const response = await postAPICall(
            process.env.REACT_APP_POTATO + "discounts/update", form_data, "potato"
        );
        return { data: response.data };
    } catch (error) {
        return { error: error.response };
    }
};

export const deleteDiscounts = async (id) => {
    try {
        const response = await getAPICall(
            process.env.REACT_APP_LINK + "discounts/delete",
            {
                requester: getUser(),
                token: getToken(),
                discount_id: id,
            }
        );
        return { data: response.data };
    } catch (error) {
        return { error: error };
    }
};

export const deleteDiscountsPotato = async (id) => {
    try {
        const response = await getAPICall(
            process.env.REACT_APP_POTATO + "discounts/delete",
            {
                requester: getUser(),
                token: getToken2(),
                discount_id: id,
            }, "potato"
        );
        return { data: response.data };
    } catch (error) {
        return { error: error.response };
    }
};