import React, { useState, useEffect, Fragment } from 'react'
import { Button, Col, Form, Row, Table, Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import Select from "react-select";
import ReactLoading from "react-loading";
import {
    formatDate,
    formatDateNoTime,
    getTodayDate,
    getTodayDateISO,
    getTodayDateNoTime,
    numberFormat,
    refreshPage,
    toastStyle,
    TokenExpiry,
} from "../../../Helpers/Utils/Common";
import Navbar from "../../../Components/Navbar/Navbar";
import InputError from "../../../Components/InputError/InputError";
import Moment from "moment";
import cleanLogo from "../../../Assets/Images/Login/logo.png";

//api
import { getAllEmployees } from "../../../Helpers/apiCalls/employeesApi";
import { validateCashAdvance } from './../../../Helpers/Validation/Employees/CashAdvanceValidation';
import CashAdvanceModal from './CashAdvanceModal';
import { getCashAdvance, updateCashAdvanceStatus } from './../../../Helpers/apiCalls/Employees/CashAdvanceApi';


function ReviewCashAdvance() {
    //variables
    const { id, type } = useParams();
    let navigate = useNavigate();
    const [inactive, setInactive] = useState(true);
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);
    const [isSaveClicked, setIsSaveClicked] = useState(false);
    const [employees, setEmployees] = useState([]);
    const today = getTodayDateISO();
    /* approve modal handler */
    const [showApprovalModal, setShowApprovalModal] = useState(false);
    const handleShowApprovalModal = () => {
        setShowApprovalModal(true);
    };
    const handleCloseApprovalModal = () => setShowApprovalModal(false);
    /* approve modal handler */
    const [showDisapproveModal, setShowDisapproveModal] = useState(false);
    const handleShowDisapproveModal = () => {
        setShowDisapproveModal(true);
    };
    const handleCloseDisapproveModal = () => setShowDisapproveModal(false);
    /* print modal handler */
    const [showPrintModal, setShowPrintModal] = useState(false);
    const handleShowPrintModal = () => setShowPrintModal(true);
    const handleClosePrintModal = () => setShowPrintModal(false);
    const [employeeDetails, setEmployeeDetails] = useState([])
        const [addDetails, setAddDetails] = useState({
        employee_id: "",
        date: "",
        // billing_start_month: "",
        amount: "",
        terms: "",
        purpose: "",
        subtotal: 0,
        other_fees: 0,
        grand_total: 0,
    });
    console.log(addDetails)

    // FUNCTIONS
    function Print() {
        let printContents = document.getElementById("printablediv").innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print(printContents);
        document.body.innerHTML = originalContents;
        refreshPage();
    }

    function renderTable() {
        return (
            <Table className="ps-table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Amount</th>
                        <th>DISBURSEMENT METHOD</th>
                        {/* <th>NO. OF TIMES PAYABLE</th> */}
                        <th>REMARKS</th>
                    </tr>
                </thead>
                <tbody>
                    {employeeDetails.map((employee, index) => {
                        return (
                            <tr>
                                <td className="franchise-td-gray">{employee.employee_name}</td>
                                <td className="franchise-td-gray">{numberFormat(employee.amount)}</td>
                                <td className="franchise-td-gray">{employee.disbursement_method}</td>
                                {/* <td className="franchise-td-gray">{employee.times_payable}</td> */}
                                <td className="franchise-td-gray">{employee.remarks}</td>
                            </tr>
                        )
                    })}
                    <tr>
                        <td colSpan={2}></td>
                        <td
                            align="center"
                            className="print-table-footer-label grand-label"
                        >
                            GRAND TOTAL
                        </td>
                        <td
                            align="center"
                            className="franchise-td-gray"
                        >
                            {numberFormat(addDetails.grand_total)}
                        </td>
                    </tr>
                </tbody>
            </Table>
        );
    }

    //API
    async function handlePrintPI() {
        toast.loading("Printing Transfer Details", { style: toastStyle() });
        handleClosePrintModal();
        setTimeout(() => {
            toast.dismiss();
            Print();
        }, 1000);
    }

    async function handleApproveCashAdvance(type) {
        const response = await updateCashAdvanceStatus(id, "approved");
        console.log(response)
        if (response.error) {
            toast.error(response.error.response.data.messages.error, {
                style: toastStyle(),
            });
        } else {
            toast.success("Successfully Approved Cash Advance!", {
                style: toastStyle(),
            });
            setTimeout(() => {
                // handlePrintPI()
                navigate(-1)
            }, 1000);
        }
    }

    async function handleDisapproveCashAdvance(type) {
        const response = await updateCashAdvanceStatus(id, "disapproved");
        console.log(response)
        if (response.error) {
            toast.error(response.error.response.data.messages.error, {
                style: toastStyle(),
            });
        } else {
            toast.success("Successfully Disapproved Cash Advance!", {
                style: toastStyle(),
            });
            setTimeout(() => {
                // handlePrintPI()
                navigate(-1)
            }, 1000);
        }
    }

    async function fetchData() {
        setAddDetails([]);

        const response = await getCashAdvance(id);
        console.log(response);

        if (response.error) {
            // TokenExpiry(response);
        } else {
            var data = response.data.data.map((data) => {
                var info = data;
                info.grand_total = response.data.grand_total;
                info.employee_id_value = {
                    value: data.employee_id,
                    label: data.employee_name,
                }
                info.cash_advance_date = data.date
                // info.billing_start_month = (parseFloat(data.billing_start_month.split("-")[1])+1).toString()
                return info;
            });
            setAddDetails(data[0])
            setEmployeeDetails(data[0].employees)
            // setEmployeeDetails(data[0].employees)
        }
    }
    console.log("employees", employeeDetails)

    useEffect(() => {
        fetchData();
    }, []);


    return (
        <div>
            <div className="page">
                <Navbar
                    onCollapse={(inactive) => {
                        setInactive(inactive);
                    }}
                    active={"EMPLOYEES"}
                />
            </div>

            <div className={`container ${inactive ? "inactive" : "active"}`}>
                {/* header */}
                <div className="d-flex justify-content-between align-items-center my-3 pb-4">
                    <h1 className="page-title mb-0">
                        REVIEW CASH ADVANCE
                    </h1>
                    <div className="review-po">
                        <span className="pe-5">CASH ADVANCE ENTRY ID</span>
                        <span>{id}</span>
                    </div>
                </div>

                {/* content */}
                <div className="edit-form">
                    
                    <Container fluid>
                        <Row className="review-container py-3">
                            <Row>
                                <Col>
                                    <span className="review-label nc-modal-custom-row">
                                        Cash Advance Date
                                    </span>
                                </Col>
                                <Col>
                                    <span className="review-label nc-modal-custom-row">
                                        Disbursement Date
                                    </span>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <span className="review-data">
                                        {formatDate(
                                            addDetails.cash_advance_date
                                        )}
                                    </span>
                                </Col>
                                <Col>
                                    <span className="review-data">
                                        {formatDate(
                                            addDetails.disbursement_date
                                        )}
                                    </span>
                                </Col>
                            </Row>
                        </Row>
                    </Container>
                    
                    <Row className="nc-modal-custom-row">
                        <Col>EMPLOYEES</Col>
                    </Row>
                    <Row className="nc-modal-custom-row">
                        <div className="edit-purchased-items">
                            {employeeDetails.length === 0 ? (
                                <span>No Employee Found!</span>
                            ) : (
                                renderTable()
                            )}
                        </div>
                    </Row> 

                    {/* FOOTER: CANCEL & SUBMIT BUTTONS */}
                    {type === "for_approval" && (
                        <div className="d-flex justify-content-end pt-5">
                            <button
                                type="button"
                                className="button-primary me-3"
                                onClick={() => navigate("/cashadvance")}
                            >
                                Close
                            </button>
                            <button
                                type="button"
                                className="button-tertiary me-3"
                                onClick={() =>
                                    navigate(
                                        "/cashadvance/edit/" + id
                                    )
                                }
                            >
                                Edit
                            </button>
                            <button
                                type="button"
                                className="button-warning-fill me-3 w-15"
                                onClick={() => handleShowDisapproveModal()}
                            >
                                Disapprove
                            </button>
                            <button
                                type="button"
                                className="button-primary me-3 w-15"
                                onClick={() => handleShowApprovalModal()}
                            >
                                Approve
                            </button>
                        </div>
                    )}
                    {type !== "for_approval" && (
                        <div className="d-flex justify-content-end pt-5">
                            <button
                                type="button"
                                className="button-primary me-3"
                                onClick={handleShowPrintModal}
                            >
                                Print
                            </button>
                            <button
                                type="button"
                                className="button-secondary me-3"
                                onClick={() => navigate("/cashadvance")}
                            >
                                Close
                            </button>
                        </div>
                    )}
                </div>
            </div>
            <CashAdvanceModal
                show={showPrintModal}
                hide={handleClosePrintModal}
                text="print this cash advance"
                page="transaction"
                handler={handlePrintPI}
            />

            <CashAdvanceModal
                show={showApprovalModal}
                hide={handleCloseApprovalModal}
                text="approve this cash advance"
                page="transaction"
                handler={handleApproveCashAdvance}
            />

            <CashAdvanceModal
                show={showDisapproveModal}
                hide={handleCloseDisapproveModal}
                text="disapprove this cash advance"
                type="disapprove"
                page="transaction"
                handler={handleDisapproveCashAdvance}
            />

            {/* PRINT VIEW */}
            <div
                className="print-container px-3 py-2 display-none"
                id="printablediv"
             >
                <div className="text-end print-header d-flex flex-column">
                    <span>CASH ADVANCE NO. {addDetails.id}</span>
                    <span className="text-uppercase">
                        {Moment(getTodayDateISO()).format("MMMM DD, yyyy")}
                    </span>
                </div>
                <div className="d-flex justify-content-center py-1">
                    <img src={cleanLogo} className="print-logo" />
                </div>
                <div className="d-flex justify-content-center py-1 ">
                    <h5 className="print-header">
                        CASH ADVANCE
                    </h5>
                </div>

                <div className="print-body mt-5">
                    <Container fluid>
                        <Row className="review-container py-3">
                            <Col>
                                <div className="d-flex my-2 align-items-center">
                                    <Col xs={4} className="print-label">
                                        Cash Advance Date:
                                    </Col>
                                    <Col xs={7} className="print-data">
                                        {formatDate(
                                            addDetails.cash_advance_date
                                        )}
                                    </Col>
                                </div>
                                <div className="d-flex my-2 align-items-center">
                                    <Col xs={4} className="print-label">
                                        Disbursement Date:
                                    </Col>
                                    <Col xs={7} className="print-data">
                                        {formatDate(
                                            addDetails.disbursement_date
                                        )}
                                    </Col>
                                </div>
                                <div className="d-flex my-2 align-items-center">
                                    <Col xs={4} className="print-label">
                                        Remarks:
                                    </Col>
                                    <Col xs={7} className="print-data">
                                        {addDetails.remarks}
                                    </Col>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                    <div className="mt-4 d-flex flex-column">
                        <div className="review-purchased-items">
                            {renderTable()}
                        </div>
                    </div>
                    <div className="print-signatures">
                        <span className="text-center text-uppercase print-label">
                            {" "}
                        </span>
                        <span className="text-center text-uppercase print-label fw-bold">
                            {addDetails.prepared_by}
                        </span>
                    </div>
                    <div className="print-signatories pb-4 mb-4">
                        <span>Approved by</span>
                        <span>Prepared by</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReviewCashAdvance