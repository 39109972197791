import React, { useState } from "react";
import { Col, Form, Row, Tab, Tabs, Modal, Button, Table } from "react-bootstrap";
import toast from "react-hot-toast";
import Select from "react-select";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Delete from "../../../Components/Modals/DeleteModal";
import Navbar from "../../../Components/Navbar/Navbar";
import TableTemplate from "../../../Components/TableTemplate/Table";
import AdjustmentTable from "./../../Inventory/Adjustment/AdjustmentTable";
import ReactLoading from "react-loading";
import {
    dateFormat,
    formatDateNoTime,
    formatDate,
    numberFormat,
    refreshPage,
    toastStyle,
    getTodayDate,
    getType,
    TokenExpiry,
    getTodayDateISO
} from "../../../Helpers/Utils/Common";
import DatePicker from "react-datepicker";
import Moment from "moment";
import { CSVLink, CSVDownload } from "react-csv";
import downloadIcon from "../../../Assets/Images/download_icon.png";
import trash from "../../../Assets/Images/trash.png";

import { getWastage, updateWastageStatus } from "../../../Helpers/apiCalls/Reports/DailyWastageApi";
import { getAllEmployees } from "../../../Helpers/apiCalls/employeesApi";
import { createEmployeeDeduction, deleteEmployeeDeduction } from './../../../Helpers/apiCalls/Reports/DailySalesApi';
import { getAllShortageDeduction, createShortageDeduction, deleteShortageDeduction } from './../../../Helpers/apiCalls/Reports/ShortageDeductionApi';

export default function EmployeeDeductions() {
    const { id, branch_name, date } = useParams();
    const { state } = useLocation();
    console.log(state)
    let navigate = useNavigate();
    var dateToday = getTodayDate();
    const accountType = getType();
    const [inactive, setInactive] = useState(true);
    const [filterConfig, setFilterConfig] = useState({
        branch_id: id !== "0" ? id : "",
        date_from: state?.date ? state.date : "",
        date_to: state?.date ? state.date : "",
        source: state !== null ? state.source !== "sales" ? state.source : "cash_variance,inventory_variance" : "",
    })
    const [showLoader, setShowLoader] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [allData, setAllData] = useState([]);
    const [totalBalance, setTotalBalance] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [branchName, setBranchName] = useState("");
    const [wastageDate, setWastageDate] = useState("");
    const [title, setTitle] = useState("");
    const [selectedRow, setSelectedRow] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [addDetails, setAddDetails] = useState([{
        employee_id: "",
        amount: "",
    }])

     /* add modal handler */
     const [showAddModal, setShowAddModal] = useState(false);
     const handleShowAddModal = (title) => {
        setTitle(title)
        setShowAddModal(true);
    }
     const handleCloseAddModal = () => setShowAddModal(false);

     /* delete modal handler */
     const [showDeleteModal, setShowDeleteModal] = useState(false);
     const handleShowDeleteModal = () => setShowDeleteModal(true);
     const handleCloseDeleteModal = () => setShowDeleteModal(false); 

    const excelHeaders = [
        { label: "DATE", key: "added_on" },
        { label: "EMPLOYEE NAME", key: "employee_name" },
        { label: "SOURCE", key: "source" },
        { label: "CHARGE AMOUNT", key: "amount" },
        { label: "BALANCE", key: "balance" },
        { label: "STATUS", key: "status" },
    ];

    const dummy = [
        {
            id: "1",
            item: "straw",
            quantity: "500",
            unit: "pc",
            reason: "asdfasdf",
        },
    ]
    
    function AddEmployeeBtn() {
        const newProduct = { employee_id: "", amount: "" };
        setAddDetails((prev) => [...prev, newProduct]);
    }

    function handleRemoveEmployee(id) {
        const rowId = id;
        const newList = [...addDetails];
        newList.splice(rowId, 1);
        setAddDetails(newList);
    }
    
    function handleEmployeeChange(e, index, type) {
        if(type === "select") {
            const list = [...addDetails];
            list[index][e.for] = e.value;
            list[index][e.select_value] = {
                for: e.for,
                label: e.label,
                value: e.value,
            };
            setAddDetails(list);
        } else {
            const { name, value } = e.target;
            const list = [...addDetails];
            list[index][name] = value;
            setAddDetails(list);
        }
    }

    function handleSelectChange(e, row) {
        setSelectedRow(row);

        if (e.target.value === "delete") {
            handleShowDeleteModal()
        }
    }

    function ActionBtn(row, type) {
        return (
            <Form.Select
                name="action"
                className="PO-select-action"
                onChange={(e) => handleSelectChange(e, row)}
            >
                <option value="" hidden selected>
                    Select
                </option>
                { accountType === "admin" ? (
                    <option value="delete" className="color-options">
                        Delete
                    </option>
                    ): null
                }
            </Form.Select>
        );
    }

    // SEARCH USER
    function handleOnSearch(e) {
        const { name, value } = e.target;
        setFilterConfig((prev) => {
            return { ...prev, [name]: value };
        });
    }

    async function fetchEmployees() {

        const response = await getAllEmployees();
        if (response.error) {
            setEmployees([])
        } else {
            let result = response.data.data.map((data) => {
                var employee = data;
                employee.for = "employee_id"
                employee.select_value = "employee_id_value"
                employee.label = data.first_name + " " + data.last_name;
                employee.value = data.id;
                return employee;
            });
            setEmployees(result);
        }
    }
    console.log(employees)

    async function fetchData() {
        setShowLoader(true);
        setAllData([])
        setTotalAmount(0);
        setTotalBalance(0);
        const response = await getAllShortageDeduction(filterConfig);
        if (response.error) {
            setTotalAmount(0);
            setTotalBalance(0);
        } else {
            setBranchName(response.data.data[0].branch_name);
            setWastageDate(response.data.data[0].wastage_date);
            var allData = response.data.data.map((data) => {
                var info = data;
                var stat = data.source.split("_")
                info.source = stat.length < 2 ? data.source : stat[0] + " " + stat[1]

                info.added_on = Moment(data.added_on).format("MM/DD/YYYY")
                info.date = Moment(data.date).format("MM/DD/YYYY")
                return info;
            });
            setAllData(allData)

            
            var charge = response.data.summary? response.data.summary.total_charges : "0";
            var balance = response.data.summary? response.data.summary.total_balance : "0";
            setTotalAmount(charge);
            setTotalBalance(balance);
        }
        setShowLoader(false);
    }

    async function handleCreateEmployeeDeduction() {
        setIsClicked(true);
        var source_id = ""
        var date = ""
        if(state.source === "wastage") {
            source_id = state.details.id;
            date = state.details.added_on;
        } else {
            source_id = state.daily_sale_id;
            state.source = title === "Cash Variance" ? "cash_variance" : "inventory_variance";
            date = state.date;
        }
        // console.log("state", state)
        // console.log("source_id", source_id)
        // console.log("date", date)
        // console.log("state.source", state.source)
        // console.log("addDetails", addDetails)
        const response = await createShortageDeduction(source_id, state.source, date, addDetails);
        console.log(response)
        if (response.error) {
            toast.error(response.error.response.data.messages.error, {
                style: toastStyle(),
            });
        } else {
            toast.success("Successfully added employee deduction!", {
                style: toastStyle(),
            });
            setTimeout(() => {
                // navigate(-1)
                refreshPage()
            }, 1000);
        }
    }

    async function handleDeleteDeduction() {
        const response = await deleteShortageDeduction([{id: selectedRow.id}]);
        if (response.data) {
            toast.success("Employee Deduction Deleted Successfully!", {
                style: toastStyle(),
            });
            setTimeout(() => refreshPage(), 1000);
        } else {
            toast.error("Error Deleting Employee Deduction", {
                style: toastStyle(),
            });
        }
    }

    console.log(filterConfig)
    React.useEffect(() => {
        fetchEmployees()
    }, []);

    React.useEffect(() => {
        fetchData();
    }, [filterConfig]);

    console.log(addDetails)

    function renderTable() {
        return (
            <Table>
                <thead>
                    <tr>
                        <th>Employee</th>
                        <th>Amount</th>
                        {/* <th>No. of Times Payable</th> */}
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {addDetails.map((employee, index) => {
                        return (
                            <tr>
                                <td>
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        placeholder="Select Employee..."
                                        value={employee.employee_id_value}
                                        options={employees}
                                        onChange={(e) => handleEmployeeChange(e, index, "select")}
                                    />
                                </td>
                                <td>
                                    <Form.Control
                                        type="amount"
                                        name="amount"
                                        className="nc-modal-custom-text"
                                        value={employee.amount}
                                        onChange={(e) => handleEmployeeChange(e, index, "text")}
                                    />
                                </td>
                                {/* <td>
                                    <Form.Control
                                        type="times_payable"
                                        name="times_payable"
                                        className="nc-modal-custom-text"
                                        value={employee.times_payable}
                                        onChange={(e) => handleEmployeeChange(e, index, "text")}
                                    />
                                </td> */}
                                <td>
                                    <div className="align-middle">
                                        <img
                                            src={trash}
                                            alt="delete"
                                            onClick={() =>
                                                handleRemoveEmployee(index)
                                            }
                                            className="cursor-pointer"
                                        />
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        );
    }

    return (
        <div>
            <div className="page">
                <Navbar
                    onCollapse={(inactive) => {
                        setInactive(inactive);
                    }}
                    active={"EMPLOYEES"}
                />
            </div>

            <div
                className={`manager-container ${
                    inactive ? "inactive" : "active"
                }`}
            >
                {/* headers */}
                {
                    state?.source === "wastage" && id !== "0" && (
                        <>
                            <Row className="mb-4">
                                <Col xs={6}>
                                    <h1 className="page-subtitle"> EMPLOYEE DEDUCTIONS </h1>
                                    <h5 className="page-subtitle"> {branch_name} </h5>
                                    <h6 className="page-subtitle"> {Moment(date).format("MMM DD, YYYY")} </h6>
                                </Col>
                                <Col className="d-flex justify-content-end">
                                    <button
                                        className="add-btn"
                                        onClick={(e) => handleShowAddModal("Wastage")}
                                    >
                                        Add
                                    </button>
                                </Col>
                            </Row>
                        </>
                    )
                }
                {
                    state?.source === "sales" && id !== "0" && (
                        <>
                            <Row className="mb-4">
                                <Col xs={6}>
                                    <h1 className="page-subtitle"> EMPLOYEE DEDUCTIONS </h1>
                                    <h5 className="page-subtitle"> {branch_name} </h5>
                                    <h6 className="page-subtitle"> {Moment(date).format("MMM DD, YYYY")} </h6>
                                </Col>
                                <Col className="d-flex justify-content-end">
                                    <div className="my-2 PO-filters d-flex">
                                        <Form.Select
                                            name="add-type"
                                            className="date-filter me-3 add-type"
                                            onChange={(e) => handleShowAddModal(e.target.value)}
                                        >
                                            <option value="" hidden selected>
                                                Add
                                            </option>
                                            <option value="Cash Variance">Cash Variance</option>
                                            <option value="Inventory Variance">Inventory Variance</option>
                                        </Form.Select>
                                    </div>
                                </Col>
                            </Row>
                        </>
                    )
                }
                {
                    id === "0" && (
                        <>
                            <Row className="mb-4">
                                <Col xs={6}>
                                    <h1 className="page-subtitle"> EMPLOYEE DEDUCTIONS </h1>
                                </Col>
                            </Row>
                        </>
                    )
                }

                <Row>
                    <Col className="d-flex justify-content-end mb-4">
                        <div className="justify-content-center align-items-center ">
                            <CSVLink
                                className="button-primary px-3 py-3 justify-content-center align-items-center download-csv"
                                data={allData}
                                headers={excelHeaders}
                                target="_blank"
                                filename={`${getTodayDateISO()} - Employee Deductions`}
                            >
                                <span className="me-2">
                                    <img
                                        width={20}
                                        height={20}
                                        src={downloadIcon}
                                    ></img>
                                </span>
                                Download Excel
                            </CSVLink>
                        </div>
                    </Col>
                </Row>

                <div className="tab-content">
                    {/* filters */}
                    <div className="my-2 px-2 PO-filters d-flex">
                        <span className="me-4 align-middle mt-2 ps-label">
                            Filter By:
                        </span>

                        <Select
                            className="dropsearch-filter px-0 py-0 me-2"
                            classNamePrefix="react-select"
                            placeholder="Select Employee"
                            styles={{
                                control: (baseStyles, state) => ({
                                ...baseStyles,
                                backgroundColor: state.isSelected ? 'white' : '#59c8e3',
                                borderRadius: "7px",
                                border: "0px",
                                minHeight: "20px",
                                maxHeight: "35px",
                                }),
                                input: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: state.isSelected? "white": "white",
                                    
                                }),
                                dropdownIndicator: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: "white"
                                    
                                }),
                                singleValue: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: "white"
                                    
                                }),
                                placeholder: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: "white"
                                    
                                }),
                                
                            }}
                            // value={branch}
                            options={employees}
                            onChange={(e) => { setFilterConfig((prev) => {
                                    return { ...prev, "employee_id": e.value };
                                });}}
                        />

                        <Form.Select
                            name="source"
                            className="date-filter me-2"
                            defaultValue={filterConfig.source}
                            onChange={(e) => {
                                setFilterConfig((prev) => {
                                    return { ...prev, source: e.target.value };
                                });
                            }}
                        >
                            <option value="" selected>
                                All Sources
                            </option>
                            <option value="wastage" >
                                Wastage
                            </option>
                            <option value="cash_variance" >
                                Cash Variance
                            </option>
                            <option value="inventory_variance" >
                                Inventory Variance
                            </option>
                            
                        </Form.Select>
                        
                        <Form.Select
                            name="status"
                            className="date-filter me-2"
                            defaultValue={filterConfig.status}
                            onChange={(e) => {
                                setFilterConfig((prev) => {
                                    return { ...prev, status: e.target.value };
                                });
                            }}
                        >
                            <option value="" selected>
                                All Status
                            </option>
                            <option value="created" >
                                Created
                            </option>
                            <option value="unsettled" >
                                Unsettled
                            </option>
                            <option value="paid" >
                                Paid
                            </option>
                            
                        </Form.Select>
                    </div>

                    <div className=" PO-filters d-flex justify-content-center">
                        <span className="me-4 ml-4 mt-2 ps-label">
                            Total Charge Amount: {numberFormat(totalAmount)}
                        </span>
                        <span className="me-4 ml-4 mt-2 ps-label">
                            Total Balance: {numberFormat(totalBalance)}
                        </span>
                    </div>

                    <div className="below">
                        {/* table */}
                        <TableTemplate
                            tableHeaders={[
                                "DATE",
                                "EMPLOYEE NAME",
                                "SOURCE",
                                "CHARGE AMOUNT",
                                // "NO. OF TIMES PAYABLE",
                                "BALANCE",
                                "STATUS",
                                "ACTIONS",
                            ]}
                            headerSelector={[
                                "date",
                                "employee_name",
                                "source",
                                "amount",
                                // "times_payable",
                                "balance",
                                "status",
                            ]}
                            tableData={allData}
                            showLoader={showLoader}
                            ActionBtn={(row) => ActionBtn(row)}
                        />
                    </div>
                    <div className="mb-2" />
                </div>
                {
                    id !== "0" && (
                        <>
                            <div className="d-flex justify-content-end my-4 pb-5 ">
                                <button
                                    className="button-secondary me-3"
                                    onClick={() => navigate(-1)}
                                >
                                    Back
                                </button>
                            </div>
                        </>
                    )
                }
            </div>
            
            <Delete
                show={showDeleteModal}
                onHide={handleCloseDeleteModal}
                text="employee deduction"
                onDelete={handleDeleteDeduction}
            />
            
            <Modal show={showAddModal} onHide={()=>handleCloseAddModal()} size="lg" centered>
                <Modal.Header closeButton/>
                <Modal.Body className='pt-2'>
                    <p className="custom-modal-body-title">
                        {title} 
                    </p>
                    
                    <p className="custom-modal-body text-end green pe-4 mb-0">
                        {title === "Inventory Variance" ? "Total Variance: " + state?.inventory_variance : "Total Variance: " + state?.cash_variance } 
                    </p>
                    <div className=' p-3'>
                        <Row className="nc-modal-custom-row">
                            <div className="edit-purchased-items">
                                {addDetails.length === 0 ? (
                                    <span>No Employees Added!</span>
                                ) : (
                                    renderTable()
                                )}
                            </div>
                        </Row>
                        
                        <Row className="pt-3 PO-add-item">
                            <Button type="button" onClick={() => AddEmployeeBtn()}>
                                Add Employee
                            </Button>
                        </Row>
                    </div>
                    <div className='d-flex justify-content-end my-4'>
                        <button
                            className="button-secondary me-2"
                            onClick={()=>handleCloseAddModal()}
                        >
                            Cancel
                        </button>
                        {isClicked === true && (
                            <div className="button-primary d-flex justify-content-center">
                                <ReactLoading
                                    type="bubbles"
                                    color="#FFFFFF"
                                    height={50}
                                    width={50}
                                />
                            </div>
                        )}
                        {isClicked !== true && (
                            <button
                                className="button-primary"
                                onClick={()=> handleCreateEmployeeDeduction()}
                            >
                                Save
                            </button>
                        )}
                    </div>
                
                </Modal.Body>
            </Modal>
        </div>
    );
}
