import React, { useState, useEffect, Fragment } from 'react'
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import Select from "react-select";
import ReactLoading from "react-loading";
import {
    formatDateNoTime,
    getTodayDate,
    getTodayDateISO,
    getTodayDateNoTime,
    numberFormat,
    refreshPage,
    toastStyle,
    TokenExpiry,
} from "../../../Helpers/Utils/Common";
import Navbar from "../../../Components/Navbar/Navbar";
import InputError from "../../../Components/InputError/InputError";
//api
import { getAllEmployees } from "../../../Helpers/apiCalls/employeesApi";
import { validateDiscount } from './../../../Helpers/Validation/Manage/DiscountsValidation';
import { searchBranch } from './../../../Helpers/apiCalls/Manage/Branches';
import { searchBranchPotato } from './../../../Helpers/apiCalls/PotatoCorner/Manage/Branches';
import { getDiscounts, getDiscountsPotato } from './../../../Helpers/apiCalls/Manage/DiscountsApi';

function ViewDiscounts({ add, edit }) {
    //variables
    const { type, id } = useParams()
    let navigate = useNavigate();
    const [inactive, setInactive] = useState(true);
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);
    const [isSaveClicked, setIsSaveClicked] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [branches, setBranches] = useState([]);
    const today = getTodayDateISO();
    const [addDetails, setAddDetails] = useState({
        description: "",
        valid_from: "",
        valid_to: "",
    });
    const [isError, setIsError] = useState({
        description: false,
        valid_from: false,
        valid_to: false,
    });
    const days = [
        { value: "monday", label: "Monday" },
        { value: "tuesday", label: "Tuesday" },
        { value: "wednesday", label: "Wednesday" },
        { value: "thursday", label: "Thursday" },
        { value: "friday", label: "Friday" },
        { value: "saturday", label: "Saturday" },
        { value: "sunday", label: "Sunday" },
      ];
      const [selected, setSelected] = useState([]);
      const [selectedBranches, setSelectedBranches] = useState([]);


      async function fetchData() {
        setAddDetails([]);

        if(type === "mango") {
            const response = await getDiscounts(id);
            console.log(response);

            if (response.error) {
                // TokenExpiry(response);
            } else {
                var expense = response.data.data.map((data) => {
                    var info = data;
                    info.commission_base  = data.commission_base  === "gross_sales" ? "From gross sales" : "From sales revenue"
                    return info;
                });
                setAddDetails(expense[0])
                var days = []
                if(expense[0].sundays === "1") {
                    days.push({value: "sunday", label: "Sunday"})
                }
                if(expense[0].mondays === "1") {
                    days.push({value: "monday", label: "Monday"})
                }
                if(expense[0].tuesdays === "1") {
                    days.push({value: "tuesday", label: "Tuesday"})
                }
                if(expense[0].wednesdays === "1") {
                    days.push({value: "wednesday", label: "Wednesday"})
                }
                if(expense[0].thursdays === "1") {
                    days.push({value: "thursday", label: "Thursday"})
                }
                if(expense[0].fridays === "1") {
                    days.push({value: "friday", label: "Friday"})
                }
                if(expense[0].saturdays === "1") {
                    days.push({value: "saturday", label: "Saturday"})
                }
                console.log(days)
                setSelected(days)

                var branch = []
                if(expense[0].discount_branches.length > 0) {
                    expense[0].discount_branches.map((data) => {
                        branch.push({
                            value: data.id,
                            label: data.branch_name,
                        })
                    })
                }
                setSelectedBranches(branch)
            }
        } else if(type === "potato") {
            const response = await getDiscounts(id);
            console.log(response);

            if (response.error) {
                // TokenExpiry(response);
            } else {
                var expense = response.data.data.map((data) => {
                    var info = data;
                    return info;
                });
                setAddDetails(expense[0])
                var days = []
                if(expense[0].sundays === "1") {
                    days.push({value: "sunday", label: "Sunday"})
                }
                if(expense[0].mondays === "1") {
                    days.push({value: "monday", label: "Monday"})
                }
                if(expense[0].tuesdays === "1") {
                    days.push({value: "tuesday", label: "Tuesday"})
                }
                if(expense[0].wednesdays === "1") {
                    days.push({value: "wednesday", label: "Wednesday"})
                }
                if(expense[0].thursdays === "1") {
                    days.push({value: "thursday", label: "Thursday"})
                }
                if(expense[0].fridays === "1") {
                    days.push({value: "friday", label: "Friday"})
                }
                if(expense[0].saturdays === "1") {
                    days.push({value: "saturday", label: "Saturday"})
                }
                console.log(days)
                setSelected(days)

                var branch = []
                if(expense[0].discount_branches.length > 0) {
                    expense[0].discount_branches.map((data) => {
                        branch.push({
                            value: data.id,
                            label: data.branch_name,
                        })
                    })
                }
                setSelectedBranches(branch)
            }
        }
        
    }

    useEffect(() => {
        fetchData()
    }, []);

    return (
        <div>
            <div className="page">
                <Navbar
                    onCollapse={(inactive) => {
                        setInactive(inactive);
                    }}
                    active={"EMPLOYEES"}
                />
            </div>

            <div className={`container ${inactive ? "inactive" : "active"}`}>
                {/* header */}
                <div className="d-flex justify-content-between align-items-center my-3 pb-4">
                    <h1 className="page-title mb-0">
                        VIEW DISCOUNT
                    </h1>
                </div>

                {/* content */}
                <div className="edit-form">
                    {/* FRANCHISEE SALES INVOICE DETAILS */}
                    <Fragment>
                    <Row className="mt-4 mb-2">
                            <Col>
                                <span className="edit-label">
                                    Description
                                    <label className="badge-required">{` *`}</label>
                                </span>
                            </Col>
                            {/* <Col xs={2}>
                                <span className="edit-label">
                                    Code
                                    <label className="badge-required">{` *`}</label>
                                </span>
                            </Col> */}
                        </Row>
                        <Row className="mt-4 mb-2">
                            <Col>
                                <Form.Control
                                    type="text"
                                    name="description"
                                    className="nc-modal-custom-text"
                                    value={addDetails.description}
                                    disabled
                                />
                            </Col>
                            {/* <Col xs={2}>
                                <Form.Control
                                    type="text"
                                    name="code"
                                    className="nc-modal-custom-text"
                                    value={addDetails.code}
                                    disabled
                                />
                            </Col> */}
                        </Row>
                        <Row className="mt-4">
                            <Col xs={4}>
                                <span className="edit-label">
                                    Valid Date
                                    <label className="badge-required">{` *`}</label>
                                </span>
                            </Col>
                            <Col xs={8}>
                                <span className="edit-label">
                                    Applicable Days
                                    <label className="badge-required">{` *`}</label>
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4}>
                                <Row>
                                    <Col xs={2}>
                                        <span className="edit-label">
                                            From:
                                        </span>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            type="date"
                                            name="valid_from"
                                            className="nc-modal-custom-text"
                                            value={addDetails.valid_from}
                                            disabled
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col xs={2}>
                                        <span className="edit-label">
                                            To:
                                        </span>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            type="date"
                                            name="valid_to"
                                            className="nc-modal-custom-text"
                                            value={addDetails.valid_to}
                                            disabled
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={8}>
                                <Select
                                    name="applicable_days"
                                    className="nc-modal-custom-text"
                                    options={days}
                                    value={selected}
                                    onChange={setSelected}
                                    placeholder={`Select Days`}
                                    isMulti={true}
                                    isSearchable={true}
                                    isDisabled={true}
                                />
                            </Col>
                        </Row>
                        
                        <Row className="mt-4">
                            <Col>
                                <span className="edit-label">
                                    Discount Source
                                </span>
                            </Col>
                            <Col>
                                <span className="edit-label">
                                    Commission Base Amount
                                </span>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col>
                                <Form.Control
                                    type="text"
                                    name="merchant"
                                    className="nc-modal-custom-text"
                                    value={addDetails.merchant}
                                    disabled
                                />
                            </Col>
                            <Col>
                                <Form.Control
                                    type="text"
                                    name="commission_base"
                                    className="nc-modal-custom-text"
                                    value={addDetails.commission_base}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col>
                                <span className="edit-label">
                                    Commission Rate
                                </span>
                            </Col>
                            <Col>
                                <span className="edit-label">
                                    VAT Rate
                                </span>
                            </Col>
                            <Col>
                                <span className="edit-label">
                                    Other Fees
                                </span>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col>
                                <Form.Control
                                    type="text"
                                    name="commission_rate"
                                    className="nc-modal-custom-text"
                                    value={addDetails.commission_rate}
                                    disabled
                                />
                            </Col>
                            <Col>
                                <Form.Control
                                    type="text"
                                    name="vat_rate"
                                    className="nc-modal-custom-text"
                                    value={addDetails.vat_rate}
                                    disabled
                                />
                            </Col>
                            <Col>
                                <Form.Control
                                    type="text"
                                    name="other_fee"
                                    className="nc-modal-custom-text"
                                    value={addDetails.other_fee}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xs={4}>
                                <span className="edit-label">
                                    customer discount
                                </span>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col xs={4}>
                                <Form.Control
                                    type="text"
                                    name="discount_amount"
                                    className="nc-modal-custom-text"
                                    value={addDetails.discount_amount}
                                    disabled
                                />
                            </Col>
                            <Col xs={1}>
                                <Form.Group
                                    className="mt-3"
                                    controlId="formBasicCheckbox"
                                >
                                    <Form.Check
                                        type="radio"
                                        label="Fix"
                                        name="type"
                                        value="fixed"
                                        className="pt-2"
                                        checked={addDetails.type === "fixed"}
                                        disabled
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={1}>
                                <Form.Group
                                    className="mt-3"
                                    controlId="formBasicCheckbox"
                                >
                                    <Form.Check
                                        type="radio"
                                        label="Percentage"
                                        name="type"
                                        value="percentage"
                                        className="pt-2"
                                        checked={addDetails.type === "percentage"}
                                        disabled
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        
                        <Row className="mt-4">
                            <Col xs={4}>
                                <span className="edit-label">
                                    MM discount share
                                </span>
                            </Col>
                            <Col xs={4}>
                                <span className="edit-label">
                                    Delivery discount share
                                </span>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col xs={4}>
                                <Form.Control
                                    type="text"
                                    name="mm_discount_share"
                                    className="nc-modal-custom-text"
                                    value={addDetails.mm_discount_share}
                                    disabled
                                />
                            </Col>
                            <Col xs={4}>
                                <Form.Control
                                    type="text"
                                    name="delivery_discount_share"
                                    className="nc-modal-custom-text"
                                    value={addDetails.delivery_discount_share}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row className="mt-4 mb-2">
                            <Col>
                                <span className="edit-label">
                                    Remarks
                                </span>
                            </Col>
                        </Row>
                        <Row className="mt-4 mb-2">
                            <Col>
                                <Form.Control
                                    type="text"
                                    name="remarks"
                                    className="nc-modal-custom-text"
                                    value={addDetails.remarks}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row className="mt-4 mb-2">
                            <Col>
                                <span className="edit-label">
                                    Applicable Branches
                                </span>
                            </Col>
                        </Row>
                        <Row className="mt-4 mb-2">
                            <Col>
                                <Select
                                    name="applicable_branches"
                                    options={branches}
                                    className="nc-modal-custom-text"
                                    value={selectedBranches}
                                    onChange={setSelectedBranches}
                                    placeholder={`Select Branches`}
                                    isMulti={true}
                                    isSearchable={true}
                                    isDisabled={true}
                                />
                            </Col>
                        </Row>
                        
                    </Fragment>

                    {/* FOOTER: CANCEL & SUBMIT BUTTONS */}
                    <div className="d-flex justify-content-end pt-5 pb-3">
                        <button
                            type="button"
                            className="button-secondary me-3"
                            onClick={() => navigate(-1)}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewDiscounts