import {
    formatDateNoTime,
    formatYDM,
    getToken,
    getToken2,
    getUser,
    numberFormatInt
} from "../../Utils/Common";
import Moment from "moment";
import { getAPICall, postAPICall } from "../axiosMethodCalls";

export const getDailySale = async (data) => {
    try {
        const response = await getAPICall(
            process.env.REACT_APP_LINK + "daily_sales/get_daily_sale",
            {
                requester: getUser(),
                token: getToken(),
                daily_sale_id: data.id,
            }
        );
        return { data: response.data };
    } catch (error) {
        return { error: error };
    }
};

export const getDailyInventory = async (data) => {
    // console.log(data.branch_id)
    const branch_id = data.branch_id ? data.branch_id : '';
    const date_from = data.date_from ? data.date_from : '';
    const date_to = data.date_to ? data.date_to : '';
    try {
        const response = await getAPICall(
            process.env.REACT_APP_LINK + "inventory_reports/get_daily_inventories",
            {
                requester: getUser(),
                token: getToken(),
                branch_id: branch_id,
                date: data.date ? Moment(data.date).format("YYYY-MM-DD") : Moment().format("YYYY-MM-DD"),
            }
        );
        return { data: response.data };
    } catch (error) {
        return { error: error };
    }
};

export const getInventorySales = async (data) => {
    const branch_id = data.branch_id ? data.branch_id : '';
    const date_from = data.date_from ? data.date_from : '';
    try {
        const response = await getAPICall(
            process.env.REACT_APP_LINK + "daily_sales/get_inventory_sales",
            {
                requester: getUser(),
                token: getToken(),
                branch_id: branch_id,
                date: data.date ? Moment(data.date).format("YYYY-MM-DD") : Moment().format("YYYY-MM-DD"),
            }
        );
        return { data: response.data };
    } catch (error) {
        return { error: error };
    }
};

export const searchDailySales = async (data) => {
    const date = new Date();
    date.setDate(date.getDate() - 7);

    const nextDay = new Date();
    nextDay.setDate(nextDay.getDate() + 1);
    console.log(nextDay)
    
    try {
        const response = await getAPICall(
            process.env.REACT_APP_LINK + "daily_sales/search",
            {
                requester: getUser(),
                token: getToken(),
                status: data.status,
                branch_id: data.branch_id,
                is_deposited: data.is_deposited,
                inventory_variance_discrepancy: data.inventory_variance_discrepancy,
                cash_variance_discrepancy: data.cash_variance_discrepancy,
                total_variance: data.total_variance,
                date_from: data.date_from ? Moment(data.date_from).format("YYYY-MM-DD") : "",
                date_to: data.date_to ? Moment(data.date_to).format("YYYY-MM-DD") : "",
                date: data.date ? Moment(data.date).format("YYYY-MM-DD") : "",
                
            }
        );
        return { data: response.data };
    } catch (error) {
        return { error: error };
    }
}

export const updateDailySalesStatus = async (ids) => {
    try {
        const response = await getAPICall(
            process.env.REACT_APP_LINK + "daily_sales/mark_as_reviewed",
            {
                requester: getUser(),
                token: getToken(),
                daily_sale_ids: ids.map((id) => {
                    return id.id;
                }),
            }
        );
        return { data: response.data };
    } catch (error) {
        return { error: error };
    }
};

export const createEmployeeDeduction = async (id, data) => {
    
    console.log(data, "create")
    let form_data = new FormData();
    form_data.append("requester", getUser());
    form_data.append("token", getToken());
    form_data.append("daily_sale_id", id);
    data.map((data) => {
        form_data.append('employee_ids[]', data.employee_id);
    });
    data.map((data) => {
        form_data.append('amounts[]', data.amount);
    });
    data.map((data) => {
        form_data.append('times_payable[]', data.times_payable);
    });

    try {
        const response = await postAPICall(
            process.env.REACT_APP_LINK + "daily_sales/employee_deduction", form_data
        );
        return { data: response.data };
    } catch (error) {
        return { error: error };
    }
};

export const deleteEmployeeDeduction = async (id) => {
    try {
        const response = await getAPICall(
            process.env.REACT_APP_LINK + "employee_deduction/delete",
            {
                requester: getUser(),
                token: getToken(),
                deduction_id: id,
            }
        );
        return { data: response.data };
    } catch (error) {
        return { error: error };
    }
};