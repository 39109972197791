import {
    formatDateNoTime,
    formatYDM,
    getToken,
    getToken2,
    getUser,
} from "../../Utils/Common";
import { getAPICall, postAPICall } from "../axiosMethodCalls";
import Moment from "moment";
import formData from "react-form-data";

//GET
export const getAllCashAdvance = async (data) => {
    try {
        const response = await getAPICall(
            process.env.REACT_APP_LINK + "cash_advances/search",
            {
                requester: getUser(),
                token: getToken(),
                employee_name: data.employee_name,
                status: data.status,
                date_from: data.date_from ? Moment(data.date_from).format("YYYY-MM-DD") : "",
                date_to: data.date_to ? Moment(data.date_to).format("YYYY-MM-DD") : "",
                employee_id: data.employee_id,
                group_by_entry: data.group_by_entry,
            }
        );
        return { data: response.data };
    } catch (error) {
        return { error: error };
    }
};

export const getAllCashAdvancePotato = async (data) => {
    try {
        const response = await getAPICall(
            process.env.REACT_APP_POTATO + "cash_advances/search",
            {
                requester: getUser(),
                token: getToken2(),
                employee_name: data.employee_name,
                status: data.status,
                date_from: data.date_from ? Moment(data.date_from).format("YYYY-MM-DD") : "",
                date_to: data.date_to ? Moment(data.date_to).format("YYYY-MM-DD") : "",
                employee_id: data.employee_id,
            }, "potato"
        );
        return { data: response.data };
    } catch (error) {
        return { error: error.response };
    }
};

export const getCashAdvance = async (id) => {
    try {
        const response = await getAPICall(
            process.env.REACT_APP_LINK + "cash_advances/get",
            {
                requester: getUser(),
                token: getToken(),
                entry_id: id,
            }
        );
        return { data: response.data };
    } catch (error) {
        return { error: error };
    }
};

export const getCashAdvancePotato = async (id) => {
    try {
        const response = await getAPICall(
            process.env.REACT_APP_POTATO + "cash_advances/get",
            {
                requester: getUser(),
                token: getToken2(),
                cash_advance_id: id,
            }, "potato"
        );
        return { data: response.data };
    } catch (error) {
        return { error: error.response };
    }
};

export const updateCashAdvanceStatus = async (id, status) => {
    let form_data = new FormData();
    form_data.append("requester", getUser());
    form_data.append("token", getToken());
    form_data.append("entry_id", id);
    form_data.append("status", status);

    try {
        const response = await postAPICall(
            process.env.REACT_APP_LINK + "cash_advances/update_status", form_data
        );
        return { data: response.data };
    } catch (error) {
        return { error: error };
    }
};

export const createCashAdvance = async (data, employees) => {

    console.log(data, "create")
    let form_data = new FormData();
    form_data.append("requester", getUser());
    form_data.append("token", getToken());
    form_data.append("cash_advance_date", data.cash_advance_date);
    form_data.append("disbursement_date", data.disbursement_date);
    form_data.append("avail_date", data.avail_date);
    form_data.append("status", data.status);
    employees.map((employee) => {
        form_data.append('employee_ids[]', employee.employee_id);
    });
    employees.map((employee) => {
        form_data.append('amounts[]', employee.amount);
    });
    employees.map((employee) => {
        form_data.append('disbursement_methods[]', employee.disbursement_method);
    });
    // employees.map((employee) => {
    //     form_data.append('times_payables[]', employee.times_payable);
    // });
    employees.map((employee) => {
        form_data.append('remarks[]', employee.remarks);
    });

    try {
        const response = await postAPICall(
            process.env.REACT_APP_LINK + "cash_advances/create", form_data
        );
        return { data: response.data };
    } catch (error) {
        return { error: error };
    }
};

export const updateCashAdvance = async (data, employees) => {
    var date = new Date()
    var month = parseFloat(data.billing_start_month) - 1
    date.setMonth(month, 1)

    let form_data = new FormData();
    form_data.append("requester", getUser());
    form_data.append("token", getToken());
    form_data.append("cash_advance_date", data.cash_advance_date);
    form_data.append("disbursement_date", data.disbursement_date);
    form_data.append("avail_date", data.avail_date);
    form_data.append("status", data.status);
    employees.map((employee) => {
        form_data.append('cash_advance_ids[]', employee.id);
    });
    employees.map((employee) => {
        form_data.append('employee_ids[]', employee.employee_id);
    });
    employees.map((employee) => {
        form_data.append('amounts[]', employee.amount);
    });
    employees.map((employee) => {
        form_data.append('disbursement_methods[]', employee.disbursement_method);
    });
    // employees.map((employee) => {
    //     form_data.append('times_payables[]', employee.times_payable);
    // });
    employees.map((employee) => {
        form_data.append('remarks[]', employee.remarks);
    });

    try {
        const response = await postAPICall(
            process.env.REACT_APP_LINK + "cash_advances/update", form_data
        );
        return { data: response.data };
    } catch (error) {
        return { error: error };
    }
};

export const deleteCashAdvance = async (id) => {
    
    let form_data = new FormData();
    form_data.append("requester", getUser());
    form_data.append("token", getToken());
    form_data.append("entry_id", id);

    try {
        const response = await postAPICall(
            process.env.REACT_APP_LINK + "cash_advances/delete", form_data
            // {
            //     requester: getUser(),
            //     token: getToken(),
            //     cash_advance_id: id,
            // }
        );
        return { data: response.data };
    } catch (error) {
        return { error: error };
    }
};
