import React, { useState } from "react";
import { Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import toast from "react-hot-toast";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import Delete from "../../../Components/Modals/DeleteModal";
import Navbar from "../../../Components/Navbar/Navbar";
import Table from "../../../Components/TableTemplate/Table";
import AdjustmentTable from "./../../Inventory/Adjustment/AdjustmentTable";
import {
    dateFormat,
    formatDateNoTime,
    formatDate,
    numberFormat,
    refreshPage,
    toastStyle,
    getTodayDate,
    getType,
    TokenExpiry,
    getTime,
    getTodayDateISO
} from "../../../Helpers/Utils/Common";
import DatePicker from "react-datepicker";
import Moment from "moment";
import { CSVLink, CSVDownload } from "react-csv";
import downloadIcon from "../../../Assets/Images/download_icon.png";

import { getAllFranchisee } from "../../../Helpers/apiCalls/franchiseeApi";
import { searchWastage, deleteWastage } from "../../../Helpers/apiCalls/Reports/DailyWastageApi";
import { getAllBranches } from "../../../Helpers/apiCalls/Manage/Branches";
import { getAllItems } from "../../../Helpers/apiCalls/itemsApi";
import { searchDailyUsage } from './../../../Helpers/apiCalls/Reports/DailyUsageApi';
import { getAllProducts } from './../../../Helpers/apiCalls/Manage/Products';
import { getStoreDiscountReport } from './../../../Helpers/apiCalls/Reports/DailyOrdersApi';

export default function StoreDiscountReport() {
    let navigate = useNavigate();
    var dateToday = getTodayDate();
    const accountType = getType();

    const [inactive, setInactive] = useState(true);
    const [filterConfig, setFilterConfig] = useState({})
    const [branches, setBranches] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [allData, setAllData] = useState([]);
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [totalSales, setTotalSales] = useState(0);
    const [totalDiscount, setTotalDiscount] = useState(0);
    const [selectedRow, setSelectedRow] = useState([]);
    const [items, setItems] = useState([]);
    const today = Moment().format("MM/DD/YYYY");

    const excelHeaders = [
        { label: "ORDER NO", key: "order_no" },
        { label: "ORDER DATE", key: "order_date" },
        { label: "BRANCH", key: "branch" },
        { label: "SUBTOTAL", key: "subtotal" },
        { label: "DISCOUNT", key: "discount" },
        { label: "DISCOUNT DETAILS", key: "discount_details" },
        { label: "GRAND TOTAL", key: "grand_total" },
        { label: "REMARKS", key: "remarks" },
    ];

    async function fetchBranches() {

        const response = await getAllBranches();
        if (response.error) {
        } else {
            var allBranches = response.data.data.data
            .filter((data) => { return data.is_franchise !== "3"})
            .map((data) => {
                var branch = data;
                branch.label = data.name
                branch.value = data.id
                return branch;
            });
            setBranches([{label: "All Branches", value:""}, ...allBranches]);
        }
    }
    
    async function fetchAllProducts() {
        const response = await getAllProducts();

        if (response.data) {
            var data = response.data.data.data.map((item) => {
                var info = item;
                info.label = item.name
                info.value = item.id
                return info;
            });
            setItems([{label: "All Products", value:""}, ...data]);
        } else {
        }
    }

    async function fetchData() {
        setShowLoader(true);
        setAllData([])
        setTotalQuantity(0);
        setTotalSales(0);
        setTotalDiscount(0);

        const response = await getStoreDiscountReport(filterConfig);
        console.log(response)

        if (response.error) {
        } else {

            var allBills = response.data.data.discounts_per_order.map((bill) => {
                var items = ""
                var info = bill;
                info.order_date = Moment(bill.order_date).format("MM-DD-YYYY")
                info.subtotal = numberFormat(bill.subtotal)
                info.discount = numberFormat(bill.discount)
                info.grand_total = numberFormat(bill.grand_total)
                return info;
            });
            setAllData(allBills)

            // var total_qty = response.data.summary? response.data.summary.total_qty : "0";
            var total_sales = response.data.summary? response.data.summary.total_sales : "0";
            var total_discount = response.data.summary? response.data.summary.total_discount : "0";
            // setTotalQuantity(total_qty);
            setTotalSales(total_sales);
            setTotalDiscount(total_discount);
        }
        setShowLoader(false);
    }

    React.useEffect(() => {
        fetchBranches();
        // fetchAllProducts()
    }, []);

    React.useEffect(() => {
        fetchData();
    }, [filterConfig]);

    return (
        <div>
            <div className="page">
                <Navbar
                    onCollapse={(inactive) => {
                        setInactive(inactive);
                    }}
                    active={"STORE REPORTS"}
                />
            </div>

            <div
                className={`manager-container ${
                    inactive ? "inactive" : "active"
                }`}
            >
                {/* headers */}
                <Row className="mb-2">
                    <Col xs={6}>
                        <h1 className="page-title"> STORE DISCOUNT REPORT </h1>
                    </Col>
                </Row>

                <Row>
                    <Col className="d-flex justify-content-end mb-4">
                        <div className="justify-content-center align-items-center ">
                            <CSVLink
                                className="button-primary px-3 py-3 justify-content-center align-items-center download-csv"
                                data={allData}
                                headers={excelHeaders}
                                target="_blank"
                                filename={`${getTodayDateISO()} 'SalesPerProduct'`}
                            >
                                <span className="me-2">
                                    <img
                                        width={20}
                                        height={20}
                                        src={downloadIcon}
                                    ></img>
                                </span>
                                Download Excel
                            </CSVLink>
                        </div>
                    </Col>
                </Row>

                <div className="tab-content">
                    {/* filters */}
                    <div className="my-2 px-2 PO-filters d-flex">
                        <span className="me-4 align-middle mt-2 ps-label">
                            Filter By:
                        </span>
                        
                        {/* <Select
                            className="dropsearch-filter px-0 py-0 me-2"
                            classNamePrefix="react-select"
                            placeholder="Select Product"
                            styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  backgroundColor: state.isSelected ? 'white' : '#59c8e3',
                                  borderRadius: "7px",
                                  border: "0px",
                                  minHeight: "20px",
                                  maxHeight: "35px",
                                }),
                                input: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: state.isSelected? "white": "white",
                                    
                                  }),
                                  dropdownIndicator: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: "white"
                                    
                                  }),
                                  singleValue: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: "white"
                                    
                                  }),
                                  placeholder: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: "white"
                                    
                                  }),
                                  
                              }}
                            // value={branch}
                            options={items}
                            onChange={(e) => { setFilterConfig((prev) => {
                                    return { ...prev, "product_id": e.value };
                                });}}
                        /> */}

                        <Select
                            className="dropsearch-filter px-0 py-0 me-2"
                            classNamePrefix="react-select"
                            placeholder="Select Branch"
                            styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  backgroundColor: state.isSelected ? 'white' : '#59c8e3',
                                  borderRadius: "7px",
                                  border: "0px",
                                  minHeight: "20px",
                                  maxHeight: "35px",
                                }),
                                input: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: state.isSelected? "white": "white",
                                    
                                  }),
                                  dropdownIndicator: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: "white"
                                    
                                  }),
                                  singleValue: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: "white"
                                    
                                  }),
                                  placeholder: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: "white"
                                    
                                  }),
                                  
                              }}
                            options={branches}
                            onChange={(e) => { setFilterConfig((prev) => {
                                    return { ...prev, "branch_id": e.value };
                                });}}
                        />

                        <span className="me-4 align-middle mt-2 ps-label">
                            From:
                        </span>
                        <DatePicker
                            selected={filterConfig.date_from}
                            name="date_from"
                            placeholderText={today}
                            onChange={(date) => {
                                setFilterConfig((prev) => {
                                    return { ...prev, date_from: date };
                                });
                            }}
                            fixedHeight
                            className="PI-date-btn me-3 form-control"
                            showYearDropdown
                            dateFormatCalendar="MMMM"
                            yearDropdownItemNumber={20}
                            scrollableYearDropdown
                        />

                        <span className="me-4 align-middle mt-2 ps-label">
                            To:
                        </span>
                        <DatePicker
                            selected={filterConfig.date_to}
                            name="date_to"
                            placeholderText={today}
                            onChange={(date) => {
                                setFilterConfig((prev) => {
                                    return { ...prev, date_to: date };
                                });
                            }}
                            fixedHeight
                            className="PI-date-btn me-3 form-control"
                            showYearDropdown
                            dateFormatCalendar="MMMM"
                            yearDropdownItemNumber={20}
                            scrollableYearDropdown
                        />
                    </div>

                    <div className=" PO-filters d-flex justify-content-center">
                        {/* <span className="me-4 ml-4 mt-2 ps-label">
                            Total Quantity: {numberFormat(totalQuantity)}
                        </span> */}
                        <span className="me-4 ml-4 mt-2 ps-label">
                            Total Sales: {numberFormat(totalSales)}
                        </span>
                        <span className="me-4 ml-4 mt-2 ps-label">
                            Total Discount: {numberFormat(totalDiscount)}
                        </span>
                    </div>

                    <div className="below">
                        {/* table */}
                        <Table
                            tableHeaders={[
                                "ORDER NO",
                                "ORDER DATE",
                                "BRANCH",
                                "SUBTOTAL",
                                "DISCOUNT",
                                "DISCOUNT DETAILS",
                                "GRAND TOTAL",
                                "REMARKS",
                            ]}
                            headerSelector={[
                                "order_no",
                                "order_date",
                                "branch",
                                "subtotal",
                                "discount",
                                "discount_details",
                                "grand_total",
                                "remarks",
                            ]}
                            tableData={allData}
                            showLoader={showLoader}
                        />
                    </div>
                    <div className="mb-2" />
                </div>
            </div>
        </div>
    );
}
