import React, { useState } from "react";
import { Col, Form, Row, Tab, Tabs, Modal, Table } from "react-bootstrap";
import toast from "react-hot-toast";
import Select from "react-select";
import { useNavigate, useLocation } from "react-router-dom";
import Delete from "../../../Components/Modals/DeleteModal";
import Navbar from "../../../Components/Navbar/Navbar";
import PageTable from "../../../Components/TableTemplate/Table";
import TableWCheckBox from "./../../Inventory/Adjustment/AdjustmentTable";
import NoDataImg from "../../../Assets/Images/no-data-img.png"
import { SyncLoader } from "react-spinners";
import {
    dateFormat,
    formatDateNoTime,
    formatDate,
    numberFormat,
    refreshPage,
    toastStyle,
    getTodayDate,
    getType,
    TokenExpiry,
    getTodayDateISO
} from "../../../Helpers/Utils/Common"; 
import DatePicker from "react-datepicker";
import Moment from "moment";
import MarkModal from "./MarkModal";

import { getAllBanks } from "../../../Helpers/apiCalls/banksAPi";
import { getAllFranchisee } from "../../../Helpers/apiCalls/franchiseeApi";
import { searchStoreDeposit, updateStatusStoreDeposit, deleteStoreDeposit } from "../../../Helpers/apiCalls/Reports/StoreDepositApi";
import { getCashCountReport } from "../../../Helpers/apiCalls/Reports/DailyCashCountApi";
import { getAllBranches } from "../../../Helpers/apiCalls/Manage/Branches";
import { searchDailySales } from "../../../Helpers/apiCalls/Reports/DailySalesApi";
import { CSVLink, CSVDownload } from "react-csv";
import downloadIcon from "../../../Assets/Images/download_icon.png";
import { Fragment } from "react";
import { getStoreDepositImage } from './../../../Helpers/apiCalls/Reports/StoreDepositApi';


export default function StoreDeposit() {
    let navigate = useNavigate();
    var dateToday = getTodayDate();
    const accountType = getType();
    const [inactive, setInactive] = useState(true);
    const [filterConfig, setFilterConfig] = useState({
        transaction_type: "cash",
        status: "submitted",
        tab: "submitted,reviewed"
    })
    const date = new Date();
    date.setDate(date.getDate() - 7);
    const [dsrFilterConfig, setDsrFilterConfig] = useState({
        is_deposited: "N",
        date_from: "",
        date_to: "",
    })
    const [branches, setBranches] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [showImageLoader, setShowImageLoader] = useState(false);
    const [showDetails, setShowDetails] = useState(true);
    const [deposit, setDeposit] = useState([]);
    const [expenses, setExpenses] = useState([]);
    const [totalSales, setTotalSales] = useState([]);
    const [checkedPayments, setCheckedPayments] = useState([]);
    const [selectedTab, setSelectedTab] = useState("");
    const [dailySales, setDailySales] = useState([]);

     /* delete modal handler */
     const [showDeleteModal, setShowDeleteModal] = useState(false);
     const handleShowDeleteModal = () => setShowDeleteModal(true);
     const handleCloseDeleteModal = () => setShowDeleteModal(false); 

     /* done Modal */
     const [showMarkAsDoneModal, setShowMarkAsDoneModal] = useState(false);
     const handleShowMarkAsDoneModal = () => (
        checkedPayments.length != 0
            ? setShowMarkAsDoneModal(true)
            : toast.error("Please select payment to mark as done", {
                  style: toastStyle(),
              })
        );
     const handleCloseMarkAsDoneModal = () => setShowMarkAsDoneModal(false);

     /* posted Modal */
     const [showMarkAsPostedModal, setShowMarkAsPostedModal] = useState(false);
     const handleShowMarkAsPostedModal = () => (
        checkedPayments.length != 0
            ? setShowMarkAsPostedModal(true)
            : toast.error("Please select payment to mark as posted", {
                  style: toastStyle(),
              })
        );
     const handleCloseMarkAsPostedModal = () => setShowMarkAsPostedModal(false);

    const [image, setImage] = useState("");
    const [showViewModal, setShowViewModal] = useState(false);
    const handleShowViewModal = (img) => {
        setImage(img)
        setShowViewModal(true);
    }
    const handleCloseViewModal = () => {
        setShowViewModal(false);
        setImage("")
        // setShowDetails(false)
    };
    const datelastweek = new Date();
    datelastweek.setDate(datelastweek.getDate() - 7);

     const [selectedRow, setSelectedRow] = useState([]);
     const today = Moment().format("MM/DD/YYYY");

    const [banks, setBanks] = useState([]);
    const [transactionType, settransactionType] = useState({});
    const [transactionValue, settransactionValue] = useState([
        {
            name: "transaction_type",
            label: "Cash",
            value: "cash",
        }
    ]);
    const [transactionOptions, settransactionOptions] = useState([
        {
            name: "transaction_type",
            label: "Cash",
            value: "cash",
        },
        {
            name: "transaction_type",
            label: "GCash",
            value: "gcash",
        },
        {
            name: "transaction_type",
            label: "Food Panda",
            value: "food_panda",
        },
        {
            name: "transaction_type",
            label: "Grab Food",
            value: "grab_food",
        },
    ]);

    const excelHeaders = [
        { label: "Sales Date", key: "sales_date" },
        { label: "Deposit Date", key: "deposited_on" },
        { label: "Deposit Amount", key: "total_sales" },
        { label: "Branch Name", key: "branch_name" },
        { label: "Bank", key: "bank" },
        { label: "Total Sales", key: "total_sales" },
        // { label: "DSR No.", key: "dsr_no" },
        { label: "Transaction Type", key: "transaction_type" },
        // { label: "Deposited By", key: "deposited_by" },
    ];

    const dummy = [
        {
            id: "1",
            branch_name: "SM CITY CEBU",
            date: "December 2022",
            amount: "1000",
            added_by: "JOSE RIZAL",
        },
        {
            id: "2",
            branch_name: "SM CONSOLACION",
            date: "December 2022",
            amount: "1000",
            added_by: "JOSE RIZAL",
        },
    ]

         
    const inventoryVariance = [
        {value: '0', name: 'No Variance'},
        {value: '1', name: 'Over'},
        {value: '2', name: 'Short'},
    ];

    const cashVariance = [
        {name: 'No Variance', value: '0'},
        {name: 'Over', value:'1'},
        {name: 'Short', value:'2'},
    ];

    // SEARCH USER
    function handleOnSearch(e) {
        const { name, value } = e.target;
        setFilterConfig((prev) => {
            return { ...prev, [name]: value };
        });
    }
    function handleOnSearchDsr(e) {
        const { name, value } = e.target;
        setDsrFilterConfig((prev) => {
            return { ...prev, [name]: value };
        });
    }

    function handleSelectChange(e, row) {
        setSelectedRow(row);
        console.log(row)

        if (e.target.value === "view") {
            fetchReport(row.branch_id, row.sales_date)
            handleShowViewModal(row.image_attachment? row.image_attachment : "")
        } else if (e.target.value === "delete") {
            handleShowDeleteModal();
        }
    }


    function ActionBtn(row, type) {
        return (
            <Form.Select
                name="action"
                className="PO-select-action"
                onChange={(e) => handleSelectChange(e, row)}
            >
                <option value="" hidden selected>
                    Select
                </option>
                <option value="view" className="color-options">
                    View
                </option>
                { (accountType === "office" || accountType === "purchasing_staff") && filterConfig.tab !== "all" ? (
                    <option value="markposted" className="color-options">
                        Mark as Posted
                    </option>
                    ): null
                }
                { (accountType === "admin" || accountType === "purchasing_staff") && filterConfig.tab !== "all" ? (
                    <option value="markdone" className="color-options">
                        Mark as Done
                    </option>
                    ): null
                }
            </Form.Select>
        );
    }

    function ViewBtn(row) {
        return (
            <button
                name="action"
                className="btn btn-sm view-btn-table"
                id={row.id}
                onClick={() => handleView(row)}
            >
                View
            </button>
        );
    }
    function handleView(row) {
        if(filterConfig.tab !== "submitted,reviewed") {
            console.log(row)
            setSelectedRow(row);
            fetchReport(row.branch_id, row.sales_date)
            handleShowViewModal(row.image_attachment? row.image_attachment : "")
            fetchImage(row.deposit_id)
        } else {
            window.open('/dailysales/view/' + row.branch_id + "/" + row.date, '_blank')   
        }
    }

    const handleTabSelect = (key) => {
        setCheckedPayments([])
        if(key === "submitted" || key === "posted") {
            setSelectedTab(key);
        } else {
            setSelectedTab("");
        }

        if(key === "all" || key === "checked") {
            setFilterConfig((prev) => {
                return {
                    ...prev,
                    tab: key,
                    date_from: datelastweek,
                    date_to: new Date(),
                    status: key !== "all" ? key : ""};
            });
        } else {
            setFilterConfig((prev) => {
                return {
                    ...prev,
                    tab: key,
                    date_from: "",
                    date_to: "",
                    status: key !== "all" ? key : ""};
            });
        }
    };
    // console.log(filterConfig)

    function handleOnCheck(row) {
        setCheckedPayments(row.selectedRows);
    }

    
    function renderDepositTable() {
        return (
            <Table className="align-middle">
                <thead>
                    <tr>
                        <th>Denomination</th>
                        <th>Quantity</th>
                        <th>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td> PHP 1,000 </td>
                        <td> {deposit?.bill_1000} </td>
                        <td> {numberFormat(parseFloat(deposit?.bill_1000) * 1000)} </td>
                    </tr>
                    <tr>
                        <td> PHP 500 </td>
                        <td> {deposit?.bill_500} </td>
                        <td> {numberFormat(parseFloat(deposit?.bill_500) * 500)} </td>
                    </tr>
                    <tr>
                        <td> PHP 200 </td>
                        <td> {deposit?.bill_200} </td>
                        <td> {numberFormat(parseFloat(deposit?.bill_200) * 200)} </td>
                    </tr>
                    <tr>
                        <td> PHP 100 </td>
                        <td> {deposit?.bill_100} </td>
                        <td> {numberFormat(parseFloat(deposit?.bill_100) * 100)} </td>
                    </tr>
                    <tr>
                        <td> PHP 50 </td>
                        <td> {deposit?.bill_50} </td>
                        <td> {numberFormat(parseFloat(deposit?.bill_50) * 50)} </td>
                    </tr>
                    <tr>
                        <td> PHP 20 </td>
                        <td> {deposit?.bill_20} </td>
                        <td> {numberFormat(parseFloat(deposit?.bill_20) * 20)} </td>
                    </tr>
                    <tr>
                        <td> PHP 10 </td>
                        <td> {deposit?.coin_10} </td>
                        <td> {numberFormat(parseFloat(deposit?.coin_10) * 10)} </td>
                    </tr>
                    <tr>
                        <td> PHP 5  </td>
                        <td> {deposit?.coin_5} </td>
                        <td> {numberFormat(parseFloat(deposit?.coin_5) * 5)} </td>
                    </tr>
                    <tr>
                        <td> PHP 1  </td>
                        <td> {deposit?.coin_1} </td>
                        <td> {numberFormat(parseFloat(deposit?.coin_1) * 1)} </td>
                    </tr>
                    <tr>
                        <td> PHP 25 Cent </td>
                        <td> {deposit?.cent_25} </td>
                        <td> {numberFormat(parseFloat(deposit?.cent_25) * 25)} </td>
                    </tr>
                </tbody>
            </Table>
        );
    }
    
    async function fetchBanks() {
        const response = await getAllBanks();
        var banks = response.data.data.sort((a, b) =>
            a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );

        var cleanedArray = banks.map((bank) => {
            var info = bank;
            info.label = bank.name
            info.value = bank.id
            return info;
        });
        setBanks([{label: "All Banks", value:""}, ...cleanedArray]);
    }

    async function fetchBranches() {
        // setShowLoader(true);

        const response = await getAllBranches();
        if (response.error) {
        } else {
            var allBranches = response.data.data.data
            .filter((data) => {
                return data.is_franchise !== "3"
            })
            .map((data) => {
                var branch = data;
                branch.label = data.name
                branch.value = data.id
                return branch;
            });
            setBranches([{label: "All Branches", value:""}, ...allBranches]);
        }
        // setShowLoader(false);
    }

    async function fetchData() {
        setShowLoader(true);
        if(filterConfig.tab !== "submitted,reviewed") {
            setExpenses([])
            setTotalSales("")
    
            const response = await searchStoreDeposit(filterConfig);
            console.log(response)
            if (response.error) {
                setTotalSales("0")
            } else {
                var allBills = response.data.data.map((bill, index) => {
                    var info = bill;
                    info.deposit_id = bill.id
                    info.id = index //para mo sort ug tarong ang table
                    info.deposited_on = Moment(bill.deposited_on).format("MM-DD-YYYY");
                    info.sales_date = Moment(bill.sales_date).format("MM-DD-YYYY");
                    info.bank = bill.bank_deposited;
                    info.total_sales = numberFormat(bill.total_sales);
                    info.amount = numberFormat(bill.amount);
                    return info;
                });
                setExpenses(allBills)
    
                var total = response.data.summary? response.data.summary.total_deposited_amount : "0";
                setTotalSales(total);
    
            }
        } else {
            setExpenses([])

            const response = await searchDailySales(dsrFilterConfig);

            if (response.error) {
            } else {
                var allBills = response.data.data.map((bill) => {
                    var info = bill;
                    info.total_variance = numberFormat(parseFloat(bill.cash_sales_overage) + parseFloat(bill.overage_inventory_sales));
                    info.date = bill.date !== "0000-00-00" ? Moment(bill.date).format("MMM DD, YYYY") : "";
                    info.cash_sales_overage = numberFormat(bill.cash_sales_overage)
                    info.overage_inventory_sales = numberFormat(bill.overage_inventory_sales)
                    info.total_sales = numberFormat(bill.total_sales)
                    return info;
                });
                setExpenses(allBills.sort((a, b) =>
                new Date(...a.date?.split('/').reverse()) - new Date(...b.date?.split('/').reverse())
            ).reverse());
            }
        }
        setShowLoader(false);
    }
    console.log(expenses)

    async function fetchReport(id, date) {
        setShowLoader(true);
        setDailySales([])

        const response = await getCashCountReport(id, date);
        console.log(response);

        if (response.error) {
            console.log(response);
        } else {
            var deposit = response.data.deposit[0]
            var dailysales = response.data.daily_sales[0]
            dailysales.actual_total = response.data.deposit[0].total_count;
            setDeposit(deposit)
            setDailySales(dailysales)
        }
        setShowLoader(false);
    }

    async function fetchImage(id) {
        setShowImageLoader(true)
        setImage([])
        const response = await getStoreDepositImage(id);
        console.log(response)
        if (response.data) {
            var data = response.data.data.base64
            setImage(data)
        } else if (response.error) {
            setImage([])
        }
        setShowImageLoader(false)
    }

    async function handleDeleteExpense() {
        const response = await deleteStoreDeposit(selectedRow.id);
        if (response.data) {
            toast.success("Expense Deleted Successfully!", {
                style: toastStyle(),
            });
            setTimeout(() => refreshPage(), 1000);
        } else {
            toast.error("Error Deleting Expense", {
                style: toastStyle(),
            });
        }
    }

    async function handleMarkAsDone() {
        console.log(checkedPayments)
        const response = await updateStatusStoreDeposit(checkedPayments, "checked");
        console.log(response)
        if (response.data) {
            toast.success("Payments successfully marked as done!", {
                style: toastStyle(),
            });
            setTimeout(() => refreshPage(), 1000);
        } else if (response.error) {
            TokenExpiry(response);
            toast.error(
                "Something went wrong",
                { style: toastStyle() }
            );
            setTimeout(() => refreshPage(), 1000);
        }
    }

    async function handleMarkAsPosted() {
        console.log(checkedPayments)
        const response = await updateStatusStoreDeposit(checkedPayments, "posted");
        console.log(response)
        if (response.data) {
            toast.success("Payments successfully marked as posted!", {
                style: toastStyle(),
            });
            setTimeout(() => refreshPage(), 1000);
        } else if (response.error) {
            TokenExpiry(response);
            toast.error(
                "Something went wrong",
                { style: toastStyle() }
            );
            setTimeout(() => refreshPage(), 1000);
        }
    }

    function handleNewTab() {
        window.open("/dailysales/undeposited", "_blank")
    }

    function DepositedBtn(row) {
        return (
            <span >
                {row.is_deposited !== "0" ?  
                    <span >✅ </span> : 
                    <span >❌ </span> 
                }
            </span>
        );
    }
    function TotalVarianceBtn(row) {
        return (
            <span
                className={parseFloat(row.total_variance) > 0 ? "ml-auto ps-label green" : parseFloat(row.total_variance) < 0 ? "ml-auto ps-label red" : "ml-auto ps-label black"}
            >
                {row.total_variance}
            </span>
        );
    }

    function InvVarianceBtn(row) {
        return (
            <span
            className={parseFloat(row.overage_inventory_sales) > 0 ? "ml-auto ps-label green" : parseFloat(row.overage_inventory_sales) < 0 ? "ml-auto ps-label red" : "ml-auto ps-label black"}
            >
                {row.overage_inventory_sales}
            </span>
        );
    }

    function CashVarianceBtn(row) {
        return (
            <span
            className={parseFloat(row.cash_sales_overage) > 0 ? "ml-auto ps-label green" : parseFloat(row.cash_sales_overage) < 0 ? "ml-auto ps-label red" : "ml-auto ps-label black"}
            >
                {row.cash_sales_overage}
            </span>
        );
    }


    React.useEffect(() => {
        fetchBranches();
        fetchBanks();
    }, []);

    React.useEffect(() => {
        fetchData();
    }, [filterConfig, dsrFilterConfig]);

    return (
        <div>
            <div className="page">
                <Navbar
                    onCollapse={(inactive) => {
                        setInactive(inactive);
                    }}
                    active={"DS REPORTS"}
                />
            </div>

            <div
                className={`manager-container ${
                    inactive ? "inactive" : "active"
                }`}
            >
                {/* headers */}
                <Row className="mb-3">
                    <Col xs={6}>
                        <h1 className="page-title"> STORE DEPOSIT </h1>
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <input
                            type="text"
                            name="branch_name"
                            placeholder="Search branch name"
                            className="search-bar"
                            onChange={handleOnSearch}
                        ></input>
                    </Col>
                </Row>

                <div className="row mb-4 my-2 PO-filters d-flex filter">
                    <Row>
                        <Col xs={6}>
                            <Select
                                className="dropsearch-filter-col px-2 py-0 me-2 "
                                classNamePrefix="react-select"
                                defaultValue={transactionValue}
                                options={transactionOptions}
                                onChange={(e) => {
                                    setFilterConfig((prev) => {
                                        return { ...prev, "transaction_type": e.value };
                                    });
                                }}
                            />
                        </Col>
                        <Col className="d-flex justify-content-end mb-4">
                            <div className="justify-content-center align-items-center ">
                                <CSVLink
                                    className="button-primary px-3 py-3 justify-content-center align-items-center download-csv"
                                    data={expenses}
                                    headers={excelHeaders}
                                    target="_blank"
                                    filename={`${getTodayDateISO()} Store Deposit`}
                                >
                                    <span className="me-2">
                                        <img
                                            width={20}
                                            height={20}
                                            src={downloadIcon}
                                        ></img>
                                    </span>
                                    Download Excel
                                </CSVLink>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex justify-content-end mt-2 mb-0">
                            <div className="justify-content-center align-items-center ">
                                <button
                                    type="button"
                                    className="button-warning-fill "
                                    onClick={() => handleNewTab()}
                                >
                                    Undeposited
                                </button>
                            </div>
                        </Col>
                    </Row>
                </div>

                <Tabs
                    activeKey={filterConfig.shop}
                    // defaultActiveKey={filterConfig.shop}
                    id="PO-tabs"
                    className="manager-tabs"
                    onSelect={handleTabSelect}
                >
                    <Tab eventKey="submitted,reviewed" title="Undeposited">
                        <div className="tab-content">
                            {/* filters */}
                            <div className="my-2 px-2 PO-filters d-flex">
                                <span className="me-2 align-middle mt-2 ps-label">
                                    Filter By:
                                </span>
                                <Select
                                    className="dropsearch-filter px-0 py-0 me-2"
                                    classNamePrefix="react-select"
                                    placeholder="Select Branch"
                                    styles={{
                                        control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        backgroundColor: state.isSelected ? 'white' : '#59c8e3',
                                        borderRadius: "7px",
                                        border: "0px",
                                        minHeight: "20px",
                                        maxHeight: "35px",
                                        }),
                                        input: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: state.isSelected? "white": "white",
                                            
                                        }),
                                        dropdownIndicator: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        singleValue: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        placeholder: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        
                                    }}
                                    // value={branch}
                                    options={branches}
                                    onChange={(e) => { setDsrFilterConfig((prev) => {
                                            return { ...prev, "branch_id": e.value };
                                        });}}
                                />

                                <span className="me-2 align-middle mt-2 ps-label">
                                    From:
                                </span>
                                <DatePicker
                                    selected={dsrFilterConfig.date_from}
                                    name="date_from"
                                    placeholderText={"Select Date"}
                                    onChange={(date) => {
                                        setDsrFilterConfig((prev) => {
                                            return { ...prev, date_from: date };
                                        });
                                    }}
                                    fixedHeight
                                    className="PI-date-btn me-3 form-control"
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    yearDropdownItemNumber={20}
                                    scrollableYearDropdown
                                />

                                <span className="me-2 align-middle mt-2 ps-label">
                                    To:
                                </span>
                                <DatePicker
                                    selected={dsrFilterConfig.date_to}
                                    name="date_to"
                                    placeholderText={"Select Date"}
                                    onChange={(date) => {
                                        setDsrFilterConfig((prev) => {
                                            return { ...prev, date_to: date };
                                        });
                                    }}
                                    fixedHeight
                                    className="PI-date-btn me-3 form-control"
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    yearDropdownItemNumber={20}
                                    scrollableYearDropdown
                                />

                                {/* <Form.Select
                                    name="inventory_variance_discrepancy"
                                    className="date-filter me-2"
                                    onChange={(e) => handleOnSearchDsr(e)}
                                    value={dsrFilterConfig.inventory_variance_discrepancy}
                                >
                                    <option value="" selected>
                                        Inventory Variance
                                    </option>
                                    {
                                        inventoryVariance.map((varr) => (
                                            <option value={varr.value}>{varr.name}</option>
                                        ))
                                    }
                                </Form.Select> */}

                                {/* <Form.Select
                                    name="cash_variance_discrepancy"
                                    className="date-filter me-2"
                                    onChange={(e) => handleOnSearchDsr(e)}
                                    value={dsrFilterConfig.cash_variance_discrepancy}
                                >
                                    <option value="" selected>
                                        Cash Variance
                                    </option>
                                    {
                                        cashVariance.map((cash) => (
                                            <option value={cash.value}>{cash.name}</option>
                                        ))
                                    }
                                </Form.Select> */}
                                
                                {/* <Form.Select
                                    name="total_variance"
                                    className="date-filter me-2"
                                    onChange={(e) => handleOnSearchDsr(e)}
                                    value={dsrFilterConfig.total_variance}
                                >
                                    <option value="" selected>
                                        Total Variance
                                    </option>
                                    <option value="0"> No Variance </option>
                                    <option value="1"> Over </option>
                                    <option value="2"> Short </option>
                                </Form.Select>

                                <Form.Select
                                    name="is_deposited"
                                    className="date-filter me-2"
                                    onChange={(e) => handleOnSearchDsr(e)}
                                    value={dsrFilterConfig.is_deposited}
                                >
                                    <option value="" selected disabled>
                                        Is Deposited
                                    </option>
                                    <option value=""> All </option>
                                    <option value="Y"> Deposited </option>
                                    <option value="N"> Not Deposited </option>
                                </Form.Select> */}
                            </div>
                            {/* <div className="pl-5 PO-filters d-flex">
                                
                            </div> */}
                            <div className="below">
                                {/* table */}
                                <PageTable
                                    tableHeaders={[
                                        // "-",
                                        "DATE",
                                        "BRANCH",
                                        // "TOTAL SALES",
                                        // "TOTAL VARIANCE",
                                        // "INV VARIANCE",
                                        // "CASH VARIANCE",
                                        "DEPOSITED",
                                        "CANCELLED",
                                        "CASHIER",
                                        "PREPARED BY",
                                        // "ACTIONS",
                                    ]}
                                    headerSelector={[
                                        // "-",
                                        "date",
                                        "branch_name",
                                        // "total_sales",
                                        // "total_variance",
                                        // "overage_inventory_sales",
                                        // "cash_sales_overage",
                                        "is_deposited",
                                        "total_cancelled",
                                        "cashier_name",
                                        "preparer_name",
                                    ]}
                                    tableData={expenses}
                                    ActionBtn={(row) => ActionBtn(row)}
                                    ViewBtn={(row) => ViewBtn(row)}
                                    DepositedBtn={(row)  => DepositedBtn(row)} 
                                    TotalVarianceBtn={(row) => TotalVarianceBtn(row)}
                                    InvVarianceBtn={(row) => InvVarianceBtn(row)}
                                    CashVarianceBtn={(row) => CashVarianceBtn(row)}
                                    showLoader={showLoader}
                                />
                            </div>
                            <div className="mb-2" />
                        </div>
                    </Tab>
                    <Tab eventKey="submitted" title="Submitted">
                        <div className="tab-content">
                            {/* filters */}
                            <div className="my-2 px-2 PO-filters d-flex">
                                <span className="me-4 align-middle mt-2 ps-label">
                                    Filter By:
                                </span>

                                <Select
                                    className="dropsearch-filter px-0 py-0 me-2"
                                    classNamePrefix="react-select"
                                    placeholder="Select Branch"
                                    styles={{
                                        control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        backgroundColor: state.isSelected ? 'white' : '#59c8e3',
                                        borderRadius: "7px",
                                        border: "0px",
                                        minHeight: "20px",
                                        maxHeight: "35px",
                                        }),
                                        input: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: state.isSelected? "white": "white",
                                            
                                        }),
                                        dropdownIndicator: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        singleValue: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        placeholder: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        
                                    }}
                                    // value={branch}
                                    options={branches}
                                    onChange={(e) => { setFilterConfig((prev) => {
                                            return { ...prev, "branch_id": e.value };
                                        });}}
                                />

                                <Select
                                    className="dropsearch-filter px-0 py-0 me-2"
                                    classNamePrefix="react-select"
                                    placeholder="Select Bank"
                                    styles={{
                                        control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        backgroundColor: state.isSelected ? 'white' : '#59c8e3',
                                        borderRadius: "7px",
                                        border: "0px",
                                        minHeight: "20px",
                                        maxHeight: "35px",
                                        }),
                                        input: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: state.isSelected? "white": "white",
                                            
                                        }),
                                        dropdownIndicator: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        singleValue: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        placeholder: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        
                                    }}
                                    // value={branch}
                                    options={banks}
                                    onChange={(e) => { setFilterConfig((prev) => {
                                            return { ...prev, "bank_id": e.value };
                                        });}}
                                />

                                <span className="me-4 align-middle mt-2 ps-label">
                                    From:
                                </span>
                                <DatePicker
                                    selected={filterConfig.date_from}
                                    name="date_from"
                                    placeholderText={"Select Date"}
                                    onChange={(date) => {
                                        setFilterConfig((prev) => {
                                            return { ...prev, date_from: date };
                                        });
                                    }}
                                    fixedHeight
                                    className="PI-date-btn me-3 form-control"
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    yearDropdownItemNumber={20}
                                    scrollableYearDropdown
                                />

                                <span className="me-4 align-middle mt-2 ps-label">
                                    To:
                                </span>
                                <DatePicker
                                    selected={filterConfig.date_to}
                                    name="date_to"
                                    placeholderText={"Select Date"}
                                    onChange={(date) => {
                                        setFilterConfig((prev) => {
                                            return { ...prev, date_to: date };
                                        });
                                    }}
                                    fixedHeight
                                    className="PI-date-btn me-3 form-control"
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    yearDropdownItemNumber={20}
                                    scrollableYearDropdown
                                />
                            </div>

                            <div className=" PO-filters d-flex justify-content-center">
                                <span className="me-4 ml-4 mt-2 ps-label">
                                    Total Deposited Amount: {numberFormat(totalSales)}
                                </span>
                            </div>

                            <div className="below">
                                <TableWCheckBox
                                    tableHeaders={[
                                        "-",
                                        "SALES DATE",
                                        "DEPOSIT DATE",
                                        "DEPOSIT AMOUNT",
                                        "BRANCH",
                                        "BANK",
                                        "TOTAL DEPOSIT",
                                        "TRANSACTION TYPE",
                                        "ACTIONS",
                                    ]}
                                    headerSelector={[
                                        "-",
                                        "sales_date",
                                        "deposited_on",
                                        "total_sales",
                                        "branch_name",
                                        "bank",
                                        "total_sales",
                                        "transaction_type",
                                    ]}
                                    tableData={expenses}
                                    showLoader={showLoader}
                                    handleOnCheck={(row) => handleOnCheck(row)}
                                    ActionBtn={(row) => ActionBtn(row)}
                                    ViewBtn={(row) => ViewBtn(row)}
                                />
                            </div>
                            <div className="mb-2" />
                        </div>
                    </Tab>
                    <Tab eventKey="posted" title="Posted">
                        <div className="tab-content">
                            {/* filters */}
                            <div className="my-2 px-2 PO-filters d-flex">
                                <span className="me-4 align-middle mt-2 ps-label">
                                    Filter By:
                                </span>

                                <Select
                                    className="dropsearch-filter px-0 py-0 me-2"
                                    classNamePrefix="react-select"
                                    placeholder="Select Branch"
                                    styles={{
                                        control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        backgroundColor: state.isSelected ? 'white' : '#59c8e3',
                                        borderRadius: "7px",
                                        border: "0px",
                                        minHeight: "20px",
                                        maxHeight: "35px",
                                        }),
                                        input: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: state.isSelected? "white": "white",
                                            
                                        }),
                                        dropdownIndicator: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        singleValue: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        placeholder: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        
                                    }}
                                    // value={branch}
                                    options={branches}
                                    onChange={(e) => { setFilterConfig((prev) => {
                                            return { ...prev, "branch_id": e.value };
                                        });}}
                                />

                                <Select
                                    className="dropsearch-filter px-0 py-0 me-2"
                                    classNamePrefix="react-select"
                                    placeholder="Select Bank"
                                    styles={{
                                        control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        backgroundColor: state.isSelected ? 'white' : '#59c8e3',
                                        borderRadius: "7px",
                                        border: "0px",
                                        minHeight: "20px",
                                        maxHeight: "35px",
                                        }),
                                        input: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: state.isSelected? "white": "white",
                                            
                                        }),
                                        dropdownIndicator: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        singleValue: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        placeholder: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        
                                    }}
                                    options={banks}
                                    onChange={(e) => { setFilterConfig((prev) => {
                                            return { ...prev, "bank_id": e.value };
                                        });}}
                                />

                                <span className="me-4 align-middle mt-2 ps-label">
                                    From:
                                </span>
                                <DatePicker
                                    selected={filterConfig.date_from}
                                    name="date_from"
                                    placeholderText={"Select Date"}
                                    onChange={(date) => {
                                        setFilterConfig((prev) => {
                                            return { ...prev, date_from: date };
                                        });
                                    }}
                                    fixedHeight
                                    className="PI-date-btn me-3 form-control"
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    yearDropdownItemNumber={20}
                                    scrollableYearDropdown
                                />

                                <span className="me-4 align-middle mt-2 ps-label">
                                    To:
                                </span>
                                <DatePicker
                                    selected={filterConfig.date_to}
                                    name="date_to"
                                    placeholderText={"Select Date"}
                                    onChange={(date) => {
                                        setFilterConfig((prev) => {
                                            return { ...prev, date_to: date };
                                        });
                                    }}
                                    fixedHeight
                                    className="PI-date-btn me-3 form-control"
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    yearDropdownItemNumber={20}
                                    scrollableYearDropdown
                                />
                            </div>

                            <div className=" PO-filters d-flex justify-content-center">
                                <span className="me-4 ml-4 mt-2 ps-label">
                                    Total Sales: {numberFormat(totalSales)}
                                </span>
                            </div>

                            <div className="below">
                                <TableWCheckBox
                                    tableHeaders={[
                                        "-",
                                        "SALES DATE",
                                        "DEPOSIT DATE",
                                        "DEPOSIT AMOUNT",
                                        "BRANCH",
                                        "BANK",
                                        "TOTAL DEPOSIT",
                                        "TRANSACTION TYPE",
                                        "ACTIONS",
                                    ]}
                                    headerSelector={[
                                        "-",
                                        "sales_date",
                                        "deposited_on",
                                        "total_sales",
                                        "branch_name",
                                        "bank",
                                        "total_sales",
                                        "transaction_type",
                                    ]}
                                    tableData={expenses}
                                    ActionBtn={(row) => ActionBtn(row)}
                                    ViewBtn={(row) => ViewBtn(row)}
                                    handleOnCheck={(row) => handleOnCheck(row)}
                                    showLoader={showLoader}
                                />
                            </div>
                            <div className="mb-2" />
                        </div>
                    </Tab>
                    <Tab eventKey="checked" title="Done">
                    <div className="tab-content">
                            {/* filters */}
                            <div className="my-2 px-2 PO-filters d-flex">
                                <span className="me-4 align-middle mt-2 ps-label">
                                    Filter By:
                                </span>

                                <Select
                                    className="dropsearch-filter px-0 py-0 me-2"
                                    classNamePrefix="react-select"
                                    placeholder="Select Bank"
                                    styles={{
                                        control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        backgroundColor: state.isSelected ? 'white' : '#59c8e3',
                                        borderRadius: "7px",
                                        border: "0px",
                                        minHeight: "20px",
                                        maxHeight: "35px",
                                        }),
                                        input: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: state.isSelected? "white": "white",
                                            
                                        }),
                                        dropdownIndicator: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        singleValue: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        placeholder: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        
                                    }}
                                    options={banks}
                                    onChange={(e) => { setFilterConfig((prev) => {
                                            return { ...prev, "bank_id": e.value };
                                        });}}
                                />

                                <Select
                                    className="dropsearch-filter px-0 py-0 me-2"
                                    classNamePrefix="react-select"
                                    placeholder="Select Branch"
                                    styles={{
                                        control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        backgroundColor: state.isSelected ? 'white' : '#59c8e3',
                                        borderRadius: "7px",
                                        border: "0px",
                                        minHeight: "20px",
                                        maxHeight: "35px",
                                        }),
                                        input: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: state.isSelected? "white": "white",
                                            
                                        }),
                                        dropdownIndicator: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        singleValue: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        placeholder: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        
                                    }}
                                    options={branches}
                                    onChange={(e) => { setFilterConfig((prev) => {
                                            return { ...prev, "branch_id": e.value };
                                        });}}
                                />

                                <span className="me-4 align-middle mt-2 ps-label">
                                    From:
                                </span>
                                <DatePicker
                                    selected={filterConfig.date_from}
                                    name="date_from"
                                    placeholderText={today}
                                    onChange={(date) => {
                                        setFilterConfig((prev) => {
                                            return { ...prev, date_from: date };
                                        });
                                    }}
                                    fixedHeight
                                    className="PI-date-btn me-3 form-control"
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    yearDropdownItemNumber={20}
                                    scrollableYearDropdown
                                />

                                <span className="me-4 align-middle mt-2 ps-label">
                                    To:
                                </span>
                                <DatePicker
                                    selected={filterConfig.date_to}
                                    name="date_to"
                                    placeholderText={today}
                                    onChange={(date) => {
                                        setFilterConfig((prev) => {
                                            return { ...prev, date_to: date };
                                        });
                                    }}
                                    fixedHeight
                                    className="PI-date-btn me-3 form-control"
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    yearDropdownItemNumber={20}
                                    scrollableYearDropdown
                                />
                            </div>

                            <div className=" PO-filters d-flex justify-content-center">
                                <span className="me-4 ml-4 mt-2 ps-label">
                                    Total Sales: {numberFormat(totalSales)}
                                </span>
                            </div>

                            <div className="below">
                                <PageTable
                                    tableHeaders={[
                                        "-",
                                        "SALES DATE",
                                        "DEPOSIT DATE",
                                        "DEPOSIT AMOUNT",
                                        "BRANCH",
                                        "BANK",
                                        "TOTAL DEPOSIT",
                                        "TRANSACTION TYPE",
                                        "ACTIONS",
                                    ]}
                                    headerSelector={[
                                        "-",
                                        "sales_date",
                                        "deposited_on",
                                        "total_sales",
                                        "branch_name",
                                        "bank",
                                        "total_sales",
                                        "transaction_type",
                                    ]}
                                    tableData={expenses}
                                    ActionBtn={(row) => ActionBtn(row)}
                                    ViewBtn={(row) => ViewBtn(row)}
                                    showLoader={showLoader}
                                />
                            </div>
                            <div className="mb-2" />
                        </div>
                    </Tab>
                    <Tab eventKey="all" title="All">
                    <div className="tab-content">
                            <div className="my-2 px-2 PO-filters d-flex">
                                <span className="me-4 align-middle mt-2 ps-label">
                                    Filter By:
                                </span>

                                <Select
                                    className="dropsearch-filter px-0 py-0 me-2"
                                    classNamePrefix="react-select"
                                    placeholder="Select Branch"
                                    styles={{
                                        control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        backgroundColor: state.isSelected ? 'white' : '#59c8e3',
                                        borderRadius: "7px",
                                        border: "0px",
                                        minHeight: "20px",
                                        maxHeight: "35px",
                                        }),
                                        input: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: state.isSelected? "white": "white",
                                            
                                        }),
                                        dropdownIndicator: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        singleValue: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        placeholder: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        
                                    }}
                                    options={branches}
                                    onChange={(e) => { setFilterConfig((prev) => {
                                            return { ...prev, "branch_id": e.value };
                                        });}}
                                />

                                <Select
                                    className="dropsearch-filter px-0 py-0 me-2"
                                    classNamePrefix="react-select"
                                    placeholder="Select Bank"
                                    styles={{
                                        control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        backgroundColor: state.isSelected ? 'white' : '#59c8e3',
                                        borderRadius: "7px",
                                        border: "0px",
                                        minHeight: "20px",
                                        maxHeight: "35px",
                                        }),
                                        input: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: state.isSelected? "white": "white",
                                            
                                        }),
                                        dropdownIndicator: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        singleValue: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        placeholder: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        
                                    }}
                                    options={banks}
                                    onChange={(e) => { setFilterConfig((prev) => {
                                            return { ...prev, "bank_id": e.value };
                                        });}}
                                />

                                <span className="me-4 align-middle mt-2 ps-label">
                                    From:
                                </span>
                                <DatePicker
                                    selected={filterConfig.date_from}
                                    name="date_from"
                                    placeholderText={today}
                                    onChange={(date) => {
                                        setFilterConfig((prev) => {
                                            return { ...prev, date_from: date };
                                        });
                                    }}
                                    fixedHeight
                                    className="PI-date-btn me-3 form-control"
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    yearDropdownItemNumber={20}
                                    scrollableYearDropdown
                                />

                                <span className="me-4 align-middle mt-2 ps-label">
                                    To:
                                </span>
                                <DatePicker
                                    selected={filterConfig.date_to}
                                    name="date_to"
                                    placeholderText={today}
                                    onChange={(date) => {
                                        setFilterConfig((prev) => {
                                            return { ...prev, date_to: date };
                                        });
                                    }}
                                    fixedHeight
                                    className="PI-date-btn me-3 form-control"
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    yearDropdownItemNumber={20}
                                    scrollableYearDropdown
                                />
                            </div>

                            <div className=" PO-filters d-flex justify-content-center">
                                <span className="me-4 ml-4 mt-2 ps-label">
                                    Total Sales: {numberFormat(totalSales)}
                                </span>
                            </div>

                            <div className="below">
                                <PageTable
                                    tableHeaders={[
                                        "-",
                                        "SALES DATE",
                                        "DEPOSIT DATE",
                                        "DEPOSIT AMOUNT",
                                        "BRANCH",
                                        "BANK",
                                        "TOTAL DEPOSIT",
                                        "TRANSACTION TYPE",
                                        "ACTIONS",
                                    ]}
                                    headerSelector={[
                                        "-",
                                        "sales_date",
                                        "deposited_on",
                                        "total_sales",
                                        "branch_name",
                                        "bank",
                                        "total_sales",
                                        "transaction_type",
                                    ]}
                                    tableData={expenses}
                                    ActionBtn={(row) => ActionBtn(row)}
                                    ViewBtn={(row) => ViewBtn(row)}
                                    showLoader={showLoader}
                                />
                            </div>
                            <div className="mb-2" />
                        </div>
                    </Tab>
                </Tabs>
                {selectedTab === "submitted" && (accountType === "admin" || accountType === "purchasing_staff") ? (
                    <>
                        <div className="d-flex justify-content-end pt-3 mb-3 mr-3">
                            <button
                                type="button"
                                className="button-primary mr-3 "
                                onClick={() => handleShowMarkAsPostedModal()}
                            >
                                Mark as Posted
                            </button>
                        </div>
                    </>
                ) : (
                    <></>
                )}
                {selectedTab === "posted" && accountType === "admin" ? (
                    <>
                        <div className="d-flex justify-content-end pt-3 mb-3 mr-3">
                            <button
                                type="button"
                                className="button-primary mr-3 "
                                onClick={() => handleShowMarkAsDoneModal()}
                            >
                                Mark as Done
                            </button>
                        </div>
                    </>
                ) : (
                    <></>
                )}
            </div>

            {/* modals */}
            <Delete
                show={showDeleteModal}
                onHide={handleCloseDeleteModal}
                text="expenses"
                onDelete={handleDeleteExpense}
            />
            <MarkModal
                show={showMarkAsDoneModal}
                hide={handleCloseMarkAsDoneModal}
                type="mark this store deposit as done?"
                ids={checkedPayments}
                handler={handleMarkAsDone}
            />
            <MarkModal
                show={showMarkAsPostedModal}
                hide={handleCloseMarkAsPostedModal}
                type="mark this store deposit as posted?"
                ids={checkedPayments}
                handler={handleMarkAsPosted}
            />
            <Modal show={showViewModal} onHide={()=>handleCloseViewModal()} size="xl" centered>
                <Modal.Header closeButton className="return-header">
                    <span className="text-warning"> {selectedRow?.branch_name || ""} </span>
                </Modal.Header>
                <Modal.Body className='pt-5'>
                    <Row className="nc-modal-custom-row-view mb-3">
                        <Col xs={12} className="text-center">
                            Deposited Amount: {" "}
                            {selectedRow?.total_sales || "N/A"}
                            {/* {selectedRow?.amount || "N/A"} */}
                        </Col>
                    </Row>
                    {
                        showImageLoader ? (
                            <>
                                <div className="d-flex justify-content-center my-5 mt-5">
                                    <SyncLoader color="#59c8e3" size={10} />
                                </div>
                            </>
                        ) : (
                            <>
                                {
                                    image !== "" ? (
                                        <>
                                            <div className='row justify-content-center mb-4'>
                                                <div className='col-sm-12 align-center' style={{textAlignLast:"center"}}><img src={`data:image/png;base64,${image}`} alt="loading image" width={400} height={400} /></div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="no-data-cont mb-4">
                                                <div className='mt-5 mb-2' style={{textAlignLast:"center"}} alt="no data found"><img src={NoDataImg} width={400} height={400}/></div>
                                                <span>Uh Oh! No Receipt found.</span>
                                            </div>
                                        </>
                                    )
                                }
                            </>
                        )
                    }
                    {
                        showDetails && (
                            <>
                                <div className="review-container mx-2 p-3">
                                    <Row className="nc-modal-custom-row-view">
                                        <Col xs={4}>
                                            Sales Date
                                            <Row className="nc-modal-custom-row">
                                                <Col>
                                                    {selectedRow?.sales_date ||
                                                        "N/A"}
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={4}>
                                            Reference No
                                            <Row className="nc-modal-custom-row">
                                                <Col>
                                                    {selectedRow?.reference_no ||
                                                        "N/A"}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="nc-modal-custom-row-view">
                                        <Col>
                                            Deposited To
                                            <Row className="nc-modal-custom-row">
                                                <Col>
                                                    {selectedRow?.bank_deposited ||
                                                        "N/A"}
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col>
                                            Deposited On
                                            <Row className="nc-modal-custom-row">
                                                <Col>
                                                    {selectedRow?.deposited_on ||
                                                        "N/A"}
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col>
                                            Deposited By
                                            <Row className="nc-modal-custom-row">
                                                <Col>
                                                    {selectedRow?.deposited_by ||
                                                        "N/A"}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>

                                <div className="d-flex mt-1 justify-content-left">
                                    <Col xs={6}>
                                        <span className="edit-label">
                                            CASH DEPOSIT
                                        </span>
                                    </Col>
                                </div>
                                <div className="d-flex mt-2 mb-2 justify-content-evenly">
                                    {/* table */}
                                    <div className="print-table mt-3 mx-2">
                                        {renderDepositTable()}
                                    </div>
                                </div>

                                {/* <div className="d-flex green justify-content-center text-center">
                                    <h3 className=" green justify-content-center">
                                        ACTUAL AND SYSTEM CASH SALES COMPARISON
                                    </h3>
                                </div>
                                <div className="d-flex review-container">
                                    <Col xs={5}>
                                        <Row className=" d-flex mb-1 justify-content-center p-3 mx-2">
                                            <Row className=" justify-content-center">
                                                <Row className="text-center">
                                                    <span className="cash-breakdown-subheader justify-content-center green">
                                                        ACTUAL TOTAL CASH
                                                    </span>
                                                </Row>
                                                <Col xs={12} className='p-2 m-1'>
                                                    <Row className="mt-1">
                                                        <Col className="text-start " xs={8}><span className="print-data text-start ">Deposited Cash Sales</span></Col>
                                                        <Col className="text-end " xs={4}><span className="print-data text-end ">{dailySales ? numberFormat(dailySales.actual_cash_sales) : ''}</span></Col>
                                                    </Row>
                                                    <Row className="mt-1">
                                                        <Col className="text-start " xs={6}><span className="print-data text-start ">Total Expense</span></Col>
                                                        <Col className="text-end " xs={6}><span className="print-data text-end ">+{dailySales ? numberFormat(dailySales.total_expense) : '0.00'}</span></Col>
                                                    </Row>
                                                    <hr/>
                                                    <Row className="mt-1">
                                                        <Col xs={6}><h5 className="print-data"></h5></Col>
                                                        <Col className="text-end" xs={6}><span className="print-data text-end green">{dailySales ? numberFormat(parseFloat(dailySales.actual_cash_sales) + parseFloat(dailySales.total_expense)) : ''}</span></Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Row>
                                    </Col>
                                    <Col xs={2}>
                                        <div className="vl mt-5"></div>
                                    </Col>
                                    <Col xs={5}>
                                    <Row className="review-container d-flex mb-1 justify-content-center p-3 mx-2">
                                        <Row className=" justify-content-center">
                                                <Row className="text-center">
                                                    <span className="cash-breakdown-subheader justify-content-center green">
                                                        SYSTEM TOTAL CASH
                                                    </span>
                                                </Row>
                                                <Col xs={12} className='p-2 m-1'>
                                                    <Row className="mt-1">
                                                        <Col className="text-start " xs={8}><span className="print-data text-start ">System Cash Sales</span></Col>
                                                        <Col className="text-end " xs={4}><span className="print-data text-end ">{dailySales ? numberFormat(dailySales.system_cash_sales) : ''}</span></Col>
                                                    </Row>
                                                    <Row className="mt-1">
                                                        <Col className="text-start " xs={6}><span className="print-data text-start">Total Expense</span></Col>
                                                        <Col className="text-end " xs={6}><span className="print-data text-end ">-{dailySales ? numberFormat(dailySales.total_expense) : '0.00'}</span></Col>
                                                    </Row>
                                                    <hr/>
                                                    <Row className="mt-1">
                                                        <Col xs={6}><h5 className="print-data"></h5></Col>
                                                        <Col className="text-end" xs={6}><span className="print-data text-end green">{dailySales ? numberFormat(parseFloat(dailySales.system_cash_sales) - parseFloat(dailySales.total_expense)) : ''}</span></Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Row>
                                    </Col>
                                </div> */}
                                {/* <div className="d-flex green justify-content-center text-center">
                                    <h3 className=" green justify-content-center">
                                        NET ACTUAL AND SYSTEM CASH SALES COMPARISON
                                    </h3>
                                </div>
                                <div className="d-flex review-container">
                                    <Col xs={5}>
                                        <Row className=" d-flex mb-1 justify-content-center p-3 mx-2">
                                            <Row className=" justify-content-center">
                                                <Row className="text-center">
                                                    <span className="cash-breakdown-subheader justify-content-center green">
                                                        NET ACTUAL TOTAL CASH
                                                    </span>
                                                </Row>
                                                <Col xs={12} className='p-2 m-1'>
                                                    <Row className="mt-1">
                                                        <Col className="text-start " xs={8}><span className="print-data text-start ">Remitted Cash Sales</span></Col>
                                                        <Col className="text-end " xs={4}><span className="print-data text-end ">{dailySales ? numberFormat(dailySales.actual_cash_sales) : ''}</span></Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Row>
                                    </Col>
                                    <Col xs={2}>
                                                <div className="vl mt-5"></div>
                                            </Col>
                                    <Col xs={5}>
                                    <Row className="review-container d-flex mb-1 justify-content-center p-3 mx-2">
                                        <Row className=" justify-content-center">
                                                <Row className="text-center">
                                                    <span className="cash-breakdown-subheader justify-content-center green">
                                                        NET SYSTEM TOTAL CASH
                                                    </span>
                                                </Row>
                                                <Col xs={12} className='p-2 m-1'>
                                                    <Row className="mt-1">
                                                        <Col className="text-start " xs={8}><span className="print-data text-start ">System Cash Sales</span></Col>
                                                        <Col className="text-end " xs={4}><span className="print-data text-end ">{dailySales ? numberFormat(dailySales.net_system_sales) : ''}</span></Col>
                                                    </Row>
                                                    <Row className="mt-1">
                                                        <Col className="text-start " xs={6}><span className="print-data text-start ">Total Expense</span></Col>
                                                        <Col className="text-end " xs={6}><span className="print-data text-end ">-{dailySales ? numberFormat(dailySales.total_expense) : '0.00'}</span></Col>
                                                    </Row>
                                                    <hr/>
                                                    <Row className="mt-1">
                                                        <Col xs={6}><h5 className="print-data"></h5></Col>
                                                        <Col className="text-end" xs={6}><span className="print-data text-end green">{dailySales ? numberFormat(parseFloat(dailySales.net_system_sales) - parseFloat(dailySales.total_expense)) : ''}</span></Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Row>
                                    </Col>
                                </div> */}
                                {/* <Fragment>
                                    <Row className="review-container py-3 me-2 ms-2 mt-3">
                                        <Row className="text-center">
                                            <span className="cash-breakdown-subheader justify-content-center green">
                                                CASH DEPOSIT SUMMARY
                                            </span>
                                        </Row>
                                        <Row>
                                            <Col xs={8}  className="print-data mt-2 mb-1">
                                                <Row className="justify-content-left ms-5 mt-1 mb-1">
                                                    Actual Cash Deposit Total   
                                                </Row>
                                                <Row className="justify-content-left ms-5 mt-1 mb-1">
                                                    System Cash Deposit Total
                                                </Row>
                                            </Col>
                                            <Col xs={4}  className="print-data pl-2 mt-2 mb-1">
                                                <Row className="justify-content-end me-5">
                                                    {dailySales.actual_total ? numberFormat(dailySales.actual_total) : "0.00" }
                                                </Row>
                                                <Row className="justify-content-end mt-1 me-5">
                                                    {dailySales ? numberFormat(parseFloat(dailySales.system_cash_sales) - parseFloat(dailySales.total_expense)) : ''}
                                                </Row>
                                            </Col>
                                        </Row>
                                        <div className="break "> </div>
                                        <Row>
                                            <Col xs={8}  className="print-data mt-2 mb-1">
                                                <Row className="justify-content-left ms-5">
                                                    OVERAGE/SHORTAGE
                                                </Row>
                                            </Col>
                                            <Col xs={4}  className="print-data mt-2 mb-1">
                                                <Row className="justify-content-end me-5">
                                                    {dailySales.cash_sales_overage ? numberFormat(dailySales.cash_sales_overage) : "0.00"}
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Row>
                                </Fragment>
                                <Fragment>
                                    <Row className="review-container py-3 me-2 ms-2 mt-3">
                                        <Row className="text-center">
                                            <span className="cash-breakdown-subheader justify-content-center green">
                                                TOTAL SALES SUMMARY
                                            </span>
                                        </Row>
                                        <Row>
                                            <Col xs={5}></Col>
                                            <Col xs={3} className="print-data mt-2 mb-1">
                                                <Row className="justify-content-center mt-1 mb-1">
                                                    Actual Cash Sales   
                                                </Row>
                                                <Row className="justify-content-center mt-1 mb-1">
                                                    {dailySales.actual_cash_sales ? numberFormat(dailySales.actual_cash_sales) : "0.00"}
                                                </Row>
                                            </Col>
                                            <Col xs={1}>
                                                <div className="vl"></div>
                                            </Col>
                                            <Col xs={3} className="print-data mt-2 mb-1">
                                                <Row className="justify-content-center mt-1 mb-1">
                                                    System Cash Sales   
                                                </Row>
                                                <Row className="justify-content-center mt-1 mb-1">
                                                    {dailySales ? numberFormat(parseFloat(dailySales.system_cash_sales) - parseFloat(dailySales.total_expense)) : ''}
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={3}  className="print-data mt-2 mb-1">
                                                <Row className="justify-content-right ms-5 mt-1 mb-1">
                                                    GCASH Sales
                                                </Row>
                                                <Row className="justify-content-right ms-5 mt-1 mb-1">
                                                    Food Panda Sales
                                                </Row>
                                                <Row className="justify-content-right ms-5 mt-1 mb-1">
                                                    Grab Food Sales
                                                </Row>
                                            </Col>
                                            <Col xs={4}></Col>
                                            <Col xs={3}  className="print-data mt-2 mb-1">
                                                <Row className="justify-content-center mt-1 mb-1">
                                                    {dailySales.gcash_sales ? numberFormat(dailySales.gcash_sales) : "0.00"}
                                                </Row>
                                                <Row className="justify-content-center mt-1 mb-1">
                                                    {dailySales.food_panda_sales ? numberFormat(dailySales.food_panda_sales) : "0.00"}
                                                </Row>
                                                <Row className="justify-content-center mt-1 mb-1">
                                                    {dailySales.grab_food_sales ? numberFormat(dailySales.grab_food_sales) : "0.00"}
                                                </Row>
                                            </Col>
                                        </Row>
                                        <div className="break "> </div>
                                        <Row>
                                            <Col xs={5}  className="print-data mt-2 mb-1">
                                                <Row className="justify-content-right ms-5 green">
                                                    TOTAL SALES
                                                </Row>
                                            </Col>
                                            <Col xs={3}  className="print-data mt-2 mb-1">
                                                <Row className="justify-content-center green">
                                                    {dailySales.actual_total_sales ? numberFormat(dailySales.actual_total_sales) : "0.00"}
                                                </Row>
                                            </Col>
                                            <Col xs={1}></Col>
                                            <Col xs={3}  className="print-data mt-2 mb-1">
                                                <Row className="justify-content-center green">
                                                    {dailySales.system_total_sales ? numberFormat(dailySales.system_total_sales): "0.00"}
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Row>
                                </Fragment> */}
                            </>
                        )
                    }

                    <div className='d-flex justify-content-end my-4'>
                        <button
                            className="button-secondary"
                            onClick={()=>handleCloseViewModal()}
                        >
                            Close
                        </button>
                        {/* {
                            !showDetails && (
                                <button
                                    className="button-primary ms-2"
                                    onClick={()=>setShowDetails(!showDetails)}
                                >
                                    View Details
                                </button>
                            )
                        } */}
                    </div>
                
                </Modal.Body>
            </Modal>
        </div>
    );
}
