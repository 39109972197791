import { handleValidationChange } from "../CommonValidation";

export const validateFranchiseSale = (data, items, items2, setIsError) => {
    //console.log(items)
    //Required
    /**
     *  franchised_on: "",
        branch_id: "",
        name: "",
        opening_start: "",
        contact_person: "",
        contact_no: "",
        franchise_fee: 0,
        marketing_fee: 0,
        amount: "",
        payment_method: "",
        payment_date: "",
     */

    var isValid = true;

    if (data.franchisee_id === "") {
        handleValidationChange("franchisee_id", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("franchisee_id", false, setIsError);
    }

    if (data.delivery_address === "") {
        handleValidationChange("delivery_address", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("delivery_address", false, setIsError);
    }

    if (data.delivery_date === "") {
        handleValidationChange("delivery_date", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("delivery_date", false, setIsError);
    }

    // if (data.seller_branch_id === "") {
    //     handleValidationChange("seller_branch_id", true, setIsError);
    //     isValid = false;
    // } else {
    //     handleValidationChange("seller_branch_id", false, setIsError);
    // }

    // if(data.sales_invoice_no === "") {
    //     handleValidationChange("sales_invoice_no", true, setIsError);
    //     isValid=false;
    // } else {
    //     handleValidationChange("sales_invoice_no", false, setIsError);
    // }

    if (items.length < 1 && items2.length < 1) {
        handleValidationChange("list", true, setIsError);
        isValid = false;
    } else {
        handleValidationChange("list", false, setIsError);
    }


    return isValid;
};


export const validateSalesInvoicePayment = (data, balance, setIsError) => {
    console.log(data)
    var isValid = true;

    if(data.payment_type !== "") {
        console.log(data)
        console.log(data.amount)
        if(data.invoice_no === "" || data.invoice_no === undefined) {
            handleValidationChange("invoice_no", true, setIsError);
            isValid=false;
        } else {
            handleValidationChange("invoice_no", false, setIsError);
        }
        if(data.deposit_date === "" || data.deposit_date === undefined) {
            handleValidationChange("deposit_date", true, setIsError);
            isValid=false;
        } else {
            handleValidationChange("deposit_date", false, setIsError);
        }
        if(data.to_bank_id === "" || data.to_bank_id === undefined) {
            handleValidationChange("to_bank_id", true, setIsError);
            isValid=false;
        } else {
            handleValidationChange("to_bank_id", false, setIsError);
        }
        if(balance === undefined || balance === "") {
            if(data.paid_amount === "" || data.paid_amount === undefined) {
                handleValidationChange("paid_amount", true, setIsError);
                isValid=false;
            } else {
                handleValidationChange("paid_amount", false, setIsError);
            }
        }

    }


    return isValid;
};