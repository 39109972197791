import {
    formatDateNoTime,
    formatYDM,
    getToken,
    getToken2,
    getUser,
    numberFormatInt
} from "../../Utils/Common";
import Moment from "moment";
import { getAPICall, postAPICall } from "../axiosMethodCalls";

export const searchDailyUsage = async (data) => {
    try {
        const response = await getAPICall(
            process.env.REACT_APP_LINK + "orders/usage_report",
            {
                requester: getUser(),
                token: getToken(),
                branch_id: data.branch_id,
                item_id: data.item_id,
                date_from: data.date_from ? Moment(data.date_from).format("YYYY-MM-DD") : "",
                date_to: data.date_to ? Moment(data.date_to).format("YYYY-MM-DD") : "",
            }
        );
        return { data: response.data };
    } catch (error) {
        return { error: error };
    }
};
