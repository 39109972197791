import React, { useState, useEffect, Fragment } from 'react'
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import Select from "react-select";
import ReactLoading from "react-loading";
import {
    formatDateNoTime,
    getTodayDate,
    getTodayDateISO,
    getTodayDateNoTime,
    numberFormat,
    refreshPage,
    toastStyle,
    TokenExpiry,
} from "../../../Helpers/Utils/Common";
import Navbar from "../../../Components/Navbar/Navbar";
import InputError from "../../../Components/InputError/InputError";
import trash from "../../../Assets/Images/trash.png";
//api
import { getAllEmployees } from "../../../Helpers/apiCalls/employeesApi";
import { validateCashAdvance } from './../../../Helpers/Validation/Employees/CashAdvanceValidation';
import { createCashAdvance, updateCashAdvance, getCashAdvance } from './../../../Helpers/apiCalls/Employees/CashAdvanceApi';

function FormCashAdvance({ add, edit }) {
    //variables
    const {id} = useParams();
    let navigate = useNavigate();
    const [inactive, setInactive] = useState(true);
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);
    const [isSaveClicked, setIsSaveClicked] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [employees, setEmployees] = useState([]);
    const today = getTodayDateISO();
    const [employeeDetails, setEmployeeDetails] = useState([
        {
            employee_id: "",
            amount: "",
            disbursement_method: "",
        }
    ])
    const [addDetails, setAddDetails] = useState({
        employee_id: "",
        cash_advance_date: "",
        disbursement_date: "",
        avail_date: "",
        amount: "",
        terms: "",
        purpose: "",
        remarks: "",
        subtotal: 0,
        other_fees: 0,
        grand_total: 0,
    });
    const [isError, setIsError] = useState({
        cash_advance_date: false,
        disbursement_date: false,
        avail_date: false,
        listInfo: false,
        list: false,
    });

    //functions
    const handleAddChange = (e, type) => {
        if(type === "select") {
            setAddDetails((prevState) => ({
                ...prevState,
                [e.for]: e.value,
                [e.select_value]: {
                    for: e.for,
                    label: e.label,
                    value: e.value,
                }
            }));
        } else {
            const { name, value } = e.target;
            console.log(e)
            setAddDetails((prevState) => ({
            ...prevState,
            [name]: value,
            }));
        }
    }
    console.log(addDetails)

    const handleSubmit = () => {
        if (add) handleCreateCashAdvance("submit");
        else if (edit) handleUpdateCashAdvance("submit");
    };

    const handleSave = () => {
        if (add) handleCreateCashAdvance("save");
        else if (edit) handleUpdateCashAdvance("save");
    };
    
    function addEmployeeBtn() {
        const newEmployee = {
            id:null,
            employee_id: "",
            amount: "0",
            disbursement_method: "",
        };
        setEmployeeDetails((prev) => [...prev, newEmployee]);
    }
    function handleRemoveEmployee(id) {
        setIsChanged(true);
        const rowId = id;
        const newEmployeeList = [...employeeDetails];
        newEmployeeList.splice(rowId, 1);
        setEmployeeDetails(newEmployeeList);
        setTimeout(() => setIsChanged(false), 1);
    }
    
    function handleItemsChange(e, row, type) {
        setIsChanged(true);
        console.log(e)
        var temp = employeeDetails;
        if (type === "select") {
            temp[row].employee_id_value = { label: e.label, value: e.value };
            temp[row].employee_id = e.value;

        } else {
            const { name, value } = e.target;
            temp[row][name] = value;
        }
        setEmployeeDetails(temp);
        setTimeout(() => setIsChanged(false), 1);
    }
    console.log("employeeDetails", employeeDetails)

    function renderTable() {
        return (
            <Table className="ps-table">
                <thead>
                    <tr>
                        <th className="color-gray">Name</th>
                        <th className="color-gray">Amount</th>
                        <th className="color-gray">Disbursement Method</th>
                        {/* <th className="color-gray">No. of times payable</th> */}
                        <th className="color-gray">Remarks</th>
                        <th className="color-gray">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {employeeDetails.map((employee, index) => {
                        return (
                            <tr>
                                <td>
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        placeholder="Select Employee..."
                                        value={employee.employee_id_value}
                                        options={employees}
                                        onChange={(e) => handleItemsChange(e, index, "select")}
                                    />
                                </td>
                                <td>
                                    <Form.Control
                                        type="text"
                                        name="amount"
                                        className="nc-modal-custom-text"
                                        value={employee.amount}
                                        onChange={(e) => handleItemsChange(e, index, "input")} 
                                    />
                                </td>
                                <td>
                                    <Form.Select
                                        type="select"
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        placeholder="Select Type..."
                                        name="disbursement_method"
                                        value={employee.disbursement_method}
                                        onChange={(e) => handleItemsChange(e, index, "input")}
                                    >
                                        <option disabled selected value="">
                                            Select
                                        </option>
                                        <option value="atm">
                                            ATM
                                        </option>
                                        <option value="palawan">
                                            Palawan
                                        </option>
                                        <option value="cash">
                                            Cash
                                        </option>
                                        <option value="others">
                                            Others
                                        </option>
                                    </Form.Select>
                                </td>
                                {/* <td>
                                    <Form.Control
                                        type="text"
                                        name="times_payable"
                                        className="nc-modal-custom-text"
                                        value={employee.times_payable}
                                        onChange={(e) => handleItemsChange(e, index, "input")} 
                                    />
                                </td> */}
                                <td>
                                    <Form.Control
                                        type="text"
                                        name="remarks"
                                        className="nc-modal-custom-text"
                                        value={employee.remarks}
                                        onChange={(e) => handleItemsChange(e, index, "input")} 
                                    />
                                </td>
                                <td>
                                    <div className="align-middle">
                                        <img
                                            src={trash}
                                            alt="delete"
                                            onClick={() =>
                                                handleRemoveEmployee(index)
                                            }
                                            className="cursor-pointer"
                                        />
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        );
    }

    //API
    async function fetchEmployees() {

        const response = await getAllEmployees();
        if (response.error) {
            setEmployees([])
        } else {
            let result = response.data.data.map((data) => {
                var employee = data;
                employee.for = "employee_id"
                employee.select_value = "employee_id_value"
                employee.label = data.first_name + " " + data.last_name;
                employee.value = data.id;
                return employee;
            });
            setEmployees(result);
        }
    }
    console.log(employees)

    async function handleCreateCashAdvance(type) {
        console.log(addDetails)
        if (isSubmitClicked) {
            return;
        }
        if (validateCashAdvance(addDetails, employeeDetails, setIsError)) {
            if(type === "submit") {
                addDetails.status = "for_approval";
                setIsSubmitClicked(true);
                const response = await createCashAdvance(addDetails, employeeDetails);
                console.log(response)
                if (response.error) {
                    toast.error(response.error.data.messages.error, {
                        style: toastStyle(),
                    });
                } else {
                    toast.success("Successfully Created Cash Advance!", {
                        style: toastStyle(),
                    });
                    setTimeout(() => {
                        navigate(-1)
                    }, 1000);
                }
            } else if(type === "save") {
                addDetails.status = "pending";
                setIsSaveClicked(true);
                const response = await createCashAdvance(addDetails, employeeDetails);
                console.log(response)
                if (response.error) {
                    toast.error(response.error.data.messages.error, {
                        style: toastStyle(),
                    });
                } else {
                    toast.success("Successfully Created Cash Advance!", {
                        style: toastStyle(),
                    });
                    setTimeout(() => {
                        navigate(-1)
                    }, 1000);
                }
            }
        } else {
            toast.error("Please fill in all fields", {
                style: toastStyle(),
            });
        }
    }

    async function handleUpdateCashAdvance(type) {
        if (isSubmitClicked) {
            return;
        }
        if (validateCashAdvance(addDetails, employeeDetails, setIsError)) {
            if(type === "submit") {
                addDetails.status = "for_approval";
                setIsSubmitClicked(true);
                const response = await updateCashAdvance(addDetails, employeeDetails);
                console.log(response)
                if (response.error) {
                    toast.error(response.error.data.messages.error, {
                        style: toastStyle(),
                    });
                } else {
                    toast.success("Successfully Updated Cash Advance!", {
                        style: toastStyle(),
                    });
                    setTimeout(() => {
                        navigate(-1)
                    }, 1000);
                }
            } else if(type === "save") {
                addDetails.status = "pending";
                setIsSaveClicked(true);
                const response = await updateCashAdvance(addDetails, employeeDetails);
                console.log(response)
                if (response.error) {
                    toast.error(response.error.data.messages.error, {
                        style: toastStyle(),
                    });
                } else {
                    toast.success("Successfully Updated Cash Advance!", {
                        style: toastStyle(),
                    });
                    setTimeout(() => {
                        navigate(-1)
                    }, 1000);
                }
            }
        } else {
            toast.error("Please fill in all fields", {
                style: toastStyle(),
            });
        }
    }
    async function fetchData() {
        setIsChanged(true);
        setAddDetails([]);

        const response = await getCashAdvance(id);
        console.log(response);

        if (response.error) {
            // TokenExpiry(response);
        } else {
            var data = response.data.data.map((data) => {
                var info = data;
                info.cash_advance_date = data.date
                return info;
            });
            var employeeData = response.data.data[0].employees.map((data) => {
                var info = data;
                info.employee_id = data.employee_id
                info.employee_id_value = {
                    value: data.employee_id,
                    label: data.employee_name,
                }
                info.cash_advance_date = data.date
                return info;
            });
            setAddDetails(data[0])
            setEmployeeDetails(data[0].employees)
            // setEmployeeDetails(data[0].employees)
        }
        setTimeout(() => setIsChanged(false), 1);
    }

    useEffect(() => {
        fetchEmployees();
        if(edit) {
            fetchData()
        }
    }, []);

    useEffect(() => {

        var _grandTotal = employeeDetails
            .map((employee) => parseFloat(employee.amount))
            .reduce((a, b) => a + b, 0);
            
        setAddDetails((prev) => {
            return {
                ...prev,
                grand_total: _grandTotal.toFixed(2),
            };
        });
    }, [isChanged]);


    return (
        <div>
            <div className="page">
                <Navbar
                    onCollapse={(inactive) => {
                        setInactive(inactive);
                    }}
                    active={"EMPLOYEES"}
                />
            </div>

            <div className={`container ${inactive ? "inactive" : "active"}`}>
                {/* header */}
                <div className="d-flex justify-content-between align-items-center my-3 pb-4">
                    <h1 className="page-title mb-0">
                        {add ? "ADD " : "EDIT "} CASH ADVANCE
                    </h1>
                </div>

                {/* content */}
                <div className="edit-form">
                    {/* FRANCHISEE SALES INVOICE DETAILS */}
                    <Fragment>
                        <Row className="pt-3 mb-2">
                            {/* <Col xs={8}>
                                <span className="edit-label">
                                    EMPLOYEE NAME
                                    <label className="badge-required">{` *`}</label>
                                </span>
                            </Col> */}
                            <Col xs={4}>
                                <span className="edit-label">
                                    CASH ADVANCE DATE
                                    <label className="badge-required">{` *`}</label>
                                </span>
                            </Col>
                            <Col xs={4}>
                                <span className="edit-label">
                                    DISBURSEMENT DATE
                                    <label className="badge-required">{` *`}</label>
                                </span>
                            </Col>
                            <Col xs={4}>
                                <span className="edit-label">
                                    AVAIL DATE
                                    <label className="badge-required">{` *`}</label>
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            {/* <Col xs={8}>
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    placeholder="Select Employee..."
                                    value={addDetails.employee_id_value}
                                    options={employees}
                                    onChange={(e) => handleAddChange(e, "select")}
                                />
                                <InputError
                                    isValid={isError.employee_id}
                                    message={"Employee is required"}
                                />
                            </Col> */}
                            <Col xs={4}>
                                <Form.Control
                                    type="date"
                                    name="cash_advance_date"
                                    className="nc-modal-custom-text"
                                    value={addDetails.cash_advance_date}
                                    onChange={(e) => handleAddChange(e, false)}
                                />
                                <InputError
                                    isValid={isError.cash_advance_date}
                                    message={"Cash advance date is required"}
                                />
                            </Col>
                            <Col xs={4}>
                                <Form.Control
                                    type="date"
                                    name="disbursement_date"
                                    className="nc-modal-custom-text"
                                    value={addDetails.disbursement_date}
                                    onChange={(e) => handleAddChange(e, false)}
                                />
                                <InputError
                                    isValid={isError.disbursement_date}
                                    message={"Disbursement date is required"}
                                />
                            </Col>
                            <Col xs={4}>
                                <Form.Control
                                    type="date"
                                    name="avail_date"
                                    className="nc-modal-custom-text"
                                    value={addDetails.avail_date}
                                    onChange={(e) => handleAddChange(e, false)}
                                />
                                <InputError
                                    isValid={isError.avail_date}
                                    message={"avail date is required"}
                                />
                            </Col>
                        </Row>
                        
                        <Row className="nc-modal-custom-row">
                            <Col>EMPLOYEES</Col>
                        </Row>
                        <Row className="nc-modal-custom-row">
                            <div className="edit-purchased-items">
                                {employeeDetails.length === 0 ? (
                                    <span>No Employee Found!</span>
                                ) : (
                                    renderTable()
                                )}
                            </div>
                        </Row>
                        <Row className="pt-3 PO-add-item">
                            <InputError
                                isValid={isError.list}
                                message={
                                    "There must be at least one employee!"
                                }
                            />
                            <InputError
                                isValid={isError.listInfo}
                                message={
                                    "Please make sure to fill all inputs for each employee!"
                                }
                            />
                        </Row>
                        <Row className="pt-3 PO-add-item">
                            <Button type="button" onClick={() => addEmployeeBtn()}>
                                Add Employee
                            </Button>
                        </Row>
                    </Fragment>
                    
                    {/* <Row className="align-right py-3">
                        <Col xs={2} className="text-end">
                            <span className="edit-label color-gray">
                                Subtotal
                            </span>
                        </Col>
                        <Col xs={1} className="text-end">
                            <span className="edit-label align-middle">
                                PHP
                            </span>
                        </Col>
                        <Col xs={3} className="text-end">
                            <span className="edit-label align-middle">
                                {addDetails.subtotal} 
                            </span>
                        </Col>
                    </Row>
                    <Row className="align-right pt-3">
                        <Col xs={2} className="text-end">
                            <span className="edit-label color-gray">
                                Other Fees
                            </span>
                        </Col>
                        <Col xs={1} className="text-end">
                            <span className="edit-label align-middle">PHP</span>
                        </Col>
                        <Col xs={3}>
                            <Form.Control
                                type="number"
                                name="other_fees"
                                min={0}
                                // step="0.01"
                                value={addDetails.other_fees}
                                className="align-middle nc-modal-custom-text"
                                onChange={(e) => handleAddChange(e)} 
                            />
                        </Col>
                    </Row> */}
                    <Row className="align-right py-5">
                        <Col xs={2} className="text-end">
                            <span className="edit-label color-gray grand-total-text">
                                Grand Total
                            </span>
                        </Col>
                        <Col xs={1} className="text-end">
                            <span className="edit-label align-middle grand-total-text">
                                PHP
                            </span>
                        </Col>
                        <Col xs={3} className="text-end">
                            <span className="edit-label align-middle grand-total-text">
                                {numberFormat(addDetails.grand_total)} 
                            </span>
                        </Col>
                    </Row>

                    {/* FOOTER: CANCEL & SUBMIT BUTTONS */}
                    <div className="d-flex justify-content-end pt-5 pb-3">
                        <button
                            type="button"
                            className="button-secondary me-3"
                            onClick={() => navigate(-1)}
                        >
                            Cancel
                        </button>
                        {isSaveClicked ? (
                            <div className="button-primary me-3 d-flex justify-content-center">
                                <ReactLoading
                                    type="bubbles"
                                    color="#FFFFFF"
                                    height={50}
                                    width={50}
                                />
                            </div>
                        ) : (
                            <button
                                type="button"
                                className="button-primary me-3"
                                onClick={handleSave}
                            >
                                Save
                            </button>
                        )}
                        {isSubmitClicked ? (
                            <div className="button-primary d-flex justify-content-center">
                                <ReactLoading
                                    type="bubbles"
                                    color="#FFFFFF"
                                    height={50}
                                    width={50}
                                />
                            </div>
                        ) : (
                            <button
                                type="button"
                                className="button-primary"
                                onClick={handleSubmit}
                            >
                                Submit
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormCashAdvance