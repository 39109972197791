import React, { useState } from "react";
import { Button, Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import {
    formatDateNoTime,
    getTodayDate,
    getTodayDateISO,
    getType,
} from "../../../Helpers/Utils/Common";
import {
    getAllItemList,
    getItemHistory,
    searchHistory
} from "../../../Helpers/apiCalls/Inventory/ItemListApi";
import {
    dateFormat,
    numberFormat,
    refreshPage,
    toastStyle,
    TokenExpiry,
} from "../../../Helpers/Utils/Common";
import {
    getAllItems,
    getItem,
    searchItem,
} from "../../../Helpers/apiCalls/itemsApi";
import {
    getAllItemsPotato,
} from "../../../Helpers/apiCalls/PotatoCorner/Inventory/ItemApi";
import { CSVLink, CSVDownload } from "react-csv";
import Select from "react-select";
import { getAllBranches } from "../../../Helpers/apiCalls/Purchases/purchaseOrderApi";
import { getAllBranchesPotato } from "../../../Helpers/apiCalls/PotatoCorner/Manage/Branches";
import { searchOrder } from './../../../Helpers/apiCalls/Reports/DailyOrdersApi';

//components
import Navbar from "../../../Components/Navbar/Navbar";
import Table from "../../../Components/TableTemplate/NoSortTable";
import Delete from "../../../Components/Modals/DeleteModal";
import downloadIcon from "../../../Assets/Images/download_icon.png";
import Moment from "moment";
import DatePicker from "react-datepicker";
import Smoothie from "../../../Assets/Images/Report/smoothie.png"
import ViewModal from "../../../Components/Modals/ViewModal";
//css
import "./../Inventory.css";
import "../../../Components/Navbar/Navbar.css";

export default function ItemHistorySearch() {
    const { branch_id, item_id, item_unit_id, unit } = useParams();

    let item_unit;

    let navigate = useNavigate();
    const [userType, setUserType] = useState(getType());
    const [isChanged, setIsChanged] = useState(false);
    const [inactive, setInactive] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [tempItemList, setTempItemList] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [itemName, setItemName] = useState("");
    const [itemUnit, setItemUnit] = useState("");
    const [source_api, setSource_api] = useState("mango_magic");
    const [branchName, setBranchName] = useState("");
    const [inventories, setInventories] = useState([]);
    const [branches, setBranches] = useState([]);
    const [itemNames, setItemNames] = useState([]);
    const [itemNamesValue, setItemNamesValue] = useState({
        name: "item_id",
        label: "",
        value: "",
    });
    const [branchValue, setBranchValue] = useState({
        name: "branch_id",
        label: "Warehouse",
        value: "1",
    });

    
    const [sourceValue, setSourceValue] = useState({
        name: "api",
        label: "MANGO MAGIC",
        value: "mango_magic",
    });

    const [sourceOptions, setSourceOptions] = useState([
        {
        name: "api",
        label: "MANGO MAGIC",
        value: "mango_magic",
        },
        {
        name: "api",
        label: "POTATO CORNER",
        value: "potato_corner",
        },
    ]);
    
    const excelHeaders = [
        { label: "Date", key: "date" },
        { label: "DOC Type", key: "doc_type" },
        { label: "Slip No.", key: "slip_no" },
        { label: "In", key: "in" },
        { label: "Out", key: "out" },
        { label: "Encoded On", key: "encoded_on" },
        { label: "Added By", key: "added_by_name" },
        { label: "Current Qty", key: "current_qty" },
    ];

    const date = new Date();
    date.setDate(date.getDate() - 7);
    const nextDay = new Date();
    nextDay.setDate(nextDay.getDate() + 1);

    const [filterConfig, setFilterConfig] = useState({
        doc_type: "",
        doc_no: "",
        date_from: date,
        date_to: nextDay,
    });
    const [selectedRow, setSelectedRow] = useState([]);
    // VIEW ORDER MODAL
    const [showViewModal, setShowViewModal] = useState(false);
    const handleShowViewModal = () => setShowViewModal(true);
    const handleCloseViewModal = () => setShowViewModal(false);

    const handleShowDeleteModal = () => setShowDeleteModal(true);
    const handleCloseDeleteModal = () => refreshPage();

    const handleAddChange = (e, type) => {
        setIsChanged(!isChanged);
        if(type === "item"){
            setItemNamesValue({
                name: e.name,
                label: e.label,
                value: e.value,
            });
            setItemUnit(e.label.split("-")[1])
        } else if(type === "branch"){
            setBranchValue({
                name: e.name,
                label: e.label,
                value: e.value,
                type: e.type
            });
        } else {
            const { name, value } = e.target;
            setFilterConfig((prev) => {
                return { ...prev, [name]: value };
            });
        }
        
    };

    async function fetchAllItems(source_api) {
        if(source_api === "mango_magic") {
            const response = await getAllItems();

            if (response.response.data) {
                var itemNames = response.response.data.sort((a, b) =>
                    a.name > b.name ? 1 : b.name > a.name ? -1 : 0
                );
                var cleanedArray = itemNames.map((item) => {
                    var info = {};
        
                    info.name = "item_id";
                    info.label = `${item.name}-${item.item_units[0]?.inventory_unit}`;
                    info.value = `${item.id}`;
                    
                    return info;
                });
                setItemNames(cleanedArray);
            } else {

            }
        } else if(source_api === "potato_corner") {
            const response = await getAllItemsPotato();

            if (response.response.data) {
                var itemNames = response.response.data.sort((a, b) =>
                    a.name > b.name ? 1 : b.name > a.name ? -1 : 0
                );
                var cleanedArray = itemNames.map((item) => {
                    var info = {};
        
                    info.name = "item_id";
                    info.label = `${item.name}-${item.item_units[0]?.inventory_unit}`;
                    info.value = `${item.id}`;
                    
                    return info;
                });
                setItemNames(cleanedArray);
            } else {
            }
        }
        
    }

    async function fetchBranches() {
        if(sourceValue.value === "mango_magic") {
            const response = await getAllBranches();
    
            setBranches([]);
    
            if (response.data) {
                var data = response.data.data;
                data.map((d) => {
                    // if(d.is_franchise === "3") {
                        var info = {};
                        info.name = "branch_id";
                        info.label = `${d.name}`;
                        info.value = `${d.id}`;
                        info.type = "mango";
                        setBranches((prev) => [...prev, info]);
                    // }
                });
            }
            setBranchValue({
                name: "branch_id",
                label: response.data.data[0].name,
                value: response.data.data[0].id,
            })
        } else if(sourceValue.value === "potato_corner") {
            const response2 = await getAllBranchesPotato();
    
            setBranches([]);
    
            if (response2.data) {
                var data = response2.data.data.data;
                data.map((d) => {
                    // if(d.is_franchise === "3") {
                        var info = {};
                        info.name = "branch_id";
                        info.label = `${d.name}`;
                        info.value = `${d.id}`;
                        info.type = "potato";
                        setBranches((prev) => [...prev, info]);
                    // }
                });
            }
            setBranchValue({
                name: "branch_id",
                label: response2.data.data[0].name,
                value: response2.data.data[0].id,
            })
        }
        
    }

    async function fetchOrderDetail(data) {
        setSelectedRow([])
        handleShowViewModal()
        const response = await searchOrder({order_id: data.doc_id, date_from: data.doc_date, date_to: data.doc_date});
        console.log(response)
        if (response.error) {
            setSelectedRow([])
        } else {

            var allBills = response.data.data.map((bill, index) => {
                var paytype = bill.payment[0]?.payment_type.split("_")
                var info = bill;
                info.added_by = ""
                info.amount = numberFormat(bill.subtotal)
                info.transaction_type = bill.transaction_type
                info.qty = bill.order_detail?.map((item) => item.qty)
                            .reduce((a, b) => parseInt(a) + parseInt(b), 0);
                info.amount = bill.order_detail?.map((item) => item.subtotal)
                    .reduce((a, b) => parseInt(a) + parseInt(b), 0);
                
                info.subtotal = bill.payment[0]?.subtotal
                info.discount = bill.payment[0]?.discount
                info.computed_grand_total = numberFormat((parseFloat(bill.grand_total) - parseFloat(bill.discount)) + parseFloat(bill.payment[0]?.delivery_fee))
                info.delivery_fee = numberFormat(bill.payment[0]?.delivery_fee)
                info.grand_total = numberFormat(bill.grand_total)
                info.payment_type = paytype.length < 2 ? bill.payment[0]?.payment_type : paytype[0] + " " + paytype[1]
                info.pay_ref_no = bill.payment[0]?.reference_no
                info.tran_ref_no = bill.payment[0]?.transaction_no ? bill.payment[0]?.transaction_no : bill.payment[0]?.reference_no ? bill.payment[0]?.reference_no : ""
                info.time = new Date(bill.added_on).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})
                return info;
            });
            setSelectedRow(allBills[0])
        }
    }

    function changeApi(e) {
        setSourceValue({
            name: e.name,
            label: e.label,
            value: e.value,
        });
        setItemNamesValue({
            name: "item_id",
            label: "",
            value: "",
        })
        setBranchValue({
            name: "branch_id",
            label: "",
            value: "",
        })
        setItemList([])
        setSource_api(e.value)
        fetchAllItems(e.value);
    }

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    async function searchHistoryFilter() {
        setShowLoader(true);
        const response = await searchHistory(
            branchValue.value,
            itemNamesValue.value,
            source_api,
            filterConfig
        );
        if (response.error) {
            setShowLoader(false);
        } else if (response.data.status === "success"){
            var history = response.data.history;
            setInventories(response.data.inventory);
            setItemName(response.data.inventory[0].item_name);
            setBranchName(response.data.inventory[0].branch_name);
            setItemNamesValue({
                name: "item_id",
                label: response.data.inventory[0].item_name,
                value: response.data.inventory[0].item_id,
            })
            setBranchValue({
                name: "branch_id",
                label: response.data.inventory[0].branch_name,
                value: response.data.inventory[0].branch_id,
            })

            history.map((PO) => {
                var info = PO;

                info.date =
                    PO.doc_date == "Beginning Inventory"
                        ? PO.doc_date
                        : Moment(PO.doc_date).format("MM-DD-YYYY");
                info.encoded_on = PO.encoded_on
                    ? Moment(PO.encoded_on).format("MM-DD-YYYY")
                    : "";
                info.doc_type = PO.doc_type;
                info.doc_no = PO.doc_no ? PO.doc_no : "";
                info.doc_id = PO.doc_id;
                info.slip_no = PO.slip_no ? PO.slip_no : PO.doc_type === "Transfer" ? PO.doc_no + " - " + PO.doc_id : PO.doc_no;
                info.added_by_name = PO.added_by_name;
                info.supplier = PO.supplier_name;
                info.branch = PO.branch_name;
                info.in =
                    PO.qty_in !== null
                        ? parseFloat(PO?.qty_in).toFixed(2)
                        : "0.00";

                info.out =
                    PO.qty_out !== null
                        ? parseFloat(PO?.qty_out).toFixed(2)
                        : "0.00";

                info.current_qty =
                    PO.current_qty !== null
                        ? parseFloat(PO?.current_qty).toFixed(2)
                        : "0.00";

                info.in = numberWithCommas(info.in);
                info.out = numberWithCommas(info.out);
                info.current_qty = numberWithCommas(info.current_qty);

                setItemList((prev) => [...prev, info]);
            });
        }
        
        if (response.data?.status === "success"){
            setShowLoader(false);
        } else {
            setShowLoader(false);
        }
    }

    function DocBtn(row) {
        return (
            <span
                className="me-4 align-middle ps-label blue"
                onClick={() =>
                    handleViewDoc(row.doc_id, row.doc_type, source_api, row)
                }
            >
                {row.slip_no}
            </span>
        );
    }
    function handleViewDoc(id, type, api, row) {
        if (type === "Adjustment") {
            window.open("/adjustments/view/" + id + "/" + api);
        } else if (type === "Transfer" || type === "Transfer Receive") {
            window.open(
                "/transfers/view/" +
                    api
                        .split("_")[0]
                        .replace(/\b[a-z]/g, (x) => x.toUpperCase()) +
                    "-" +
                    id +
                    "/" +
                    "completed"
            );
        } else if (type === "Franchisee Sale") {
            window.open("/salesinvoice/print/" + id);
        } else if (type === "Build Item") {
            window.open("/buildItem/" + id);
        } else if (type === "Purchase Invoice") {
            window.open(
                "/purchaseinvoices/print/" + id + "/" + api.split("_")[0]
            );
        } else if (type === "Order") {
            fetchOrderDetail(row)
        }
    }

    React.useEffect(() => {
        fetchAllItems(source_api);
        fetchBranches()
    }, []);

    React.useEffect(() => {
        fetchBranches()
    }, [sourceValue]);

    React.useEffect(() => {
        setItemList([]);
        searchHistoryFilter();
        setItemList([]);

    }, [isChanged, filterConfig]);


    return (
        <div>
            <div className="page">
                <Navbar
                    onCollapse={(inactive) => {
                        setInactive(inactive);
                    }}
                    active={"WH INVENTORY"} 
                />
            </div>
            <div
                className={`manager-container ${
                    inactive ? "inactive" : "active"
                }`}
            >
                <div className="row">
                    <div className="col-sm-6">
                        <h1 className="page-title"> ITEM HISTORY </h1>
                    </div>
                    {/* input search button */}
                    <div className="col-sm-6 d-flex justify-content-end">
                        <input
                            type="text"
                            placeholder="Search doc no..."
                            className="search-bar"
                            name="doc_no"
                            defaultValue=""
                            onKeyPress={(e) =>
                                e.key === ("Enter" || "NumpadEnter") &&
                                handleAddChange(e)
                            }
                        ></input>
                    </div>{" "}
                </div>{" "}
                <Row className="row mb-4">
                    <Col xs={2}>
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            value={sourceValue}
                            options={sourceOptions}
                            onChange={(e) => changeApi(e)}
                        />
                    </Col>
                    <Col xs={1}>
                        <h3 className="page-subtitle mb-0 mt-1">
                            {" "}
                            <strong>Item:</strong> 
                        </h3>
                    </Col>
                    <Col xs={3} className="front">
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            placeholder="Select Item..."
                            value={itemNamesValue}
                            options={itemNames}
                            onChange={(e) => handleAddChange(e, "item")}
                        />
                    </Col>
                    <Col xs={2}>
                        <h3 className="page-subtitle text-capitalize mb-0 mt-1">
                            {" "}
                            <strong>Unit:</strong> {itemUnit}{" "}
                        </h3>
                    </Col>
                    <Col xs={1}>
                        <h3 className="page-subtitle text-capitalize mb-0 mt-1">
                            {" "}
                            <strong>Branch:</strong> 
                        </h3>
                    </Col>
                    <Col xs={3} className="front">
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            placeholder="Select Branch..."
                            value={branchValue}
                            options={branches}
                            onChange={(e) => handleAddChange(e, "branch")}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex justify-content-end mb-4">
                        <div className="justify-content-center align-items-center ">
                            <CSVLink
                                className="button-primary px-3 py-3 justify-content-center align-items-center download-csv"
                                data={itemList.slice().reverse()}
                                headers={excelHeaders}
                                target="_blank"
                                filename={`${getTodayDateISO()} History-${itemName.replace(
                                    ".",
                                    "_"
                                )}_${branchName}`}
                            >
                                <span className="me-2">
                                    <img
                                        width={20}
                                        height={20}
                                        src={downloadIcon}
                                    ></img>
                                </span>
                                Download Excel
                            </CSVLink>
                        </div>
                    </Col>
                </Row>
                <div className="tab-content">
                    <div className="my-2 ms-2 PO-filters PI-filters d-flex">
                        <span className="me-3 align-middle mt-2">
                            FILTER BY:
                        </span>
                        <Form.Select
                            name="doc_type"
                            className="date-filter me-2"
                            onChange={(e) => handleAddChange(e)}
                        >
                            <option value="" selected> All Doc Types </option>
                            <option value="Transfer" > Transfer </option>
                            <option value="Adjustment" > Adjustment </option>
                            <option value="order" > Order </option>
                            <option value="Build Item" > Build Item </option>
                            <option value="Transfer Receive" > Transfer Receive </option>
                            <option value="Franchisee Sale" > Franchisee Sale </option>
                            <option value="Purchase Invoice" > Purchase Invoice </option>
                        </Form.Select>
                        <span className="me-3 align-middle mt-2">
                            Date From:
                        </span>
                        <DatePicker
                            selected={filterConfig.date_from}
                            placeholderText={"Select Date"}
                            name="date_from"
                            onChange={(date) => {
                                setFilterConfig((prev) => {
                                    return { ...prev, date_from: date };
                                });
                            }}
                            fixedHeight
                            className="PI-date-btn me-3 form-control"
                            showYearDropdown
                            dateFormatCalendar="MMMM"
                            yearDropdownItemNumber={20}
                            scrollableYearDropdown
                        />

                        <span className="me-3 align-middle mt-2">To:</span>
                        <DatePicker
                            selected={filterConfig.date_to}
                            placeholderText={"Select Date"}
                            name="date_to"
                            onChange={(date) => {
                                setFilterConfig((prev) => {
                                    return { ...prev, date_to: date };
                                });
                            }}
                            minDate={filterConfig.date_from}
                            className="PI-date-btn me-3 form-control"
                            showYearDropdown
                            dateFormatCalendar="MMMM"
                            yearDropdownItemNumber={20}
                            scrollableYearDropdown
                        />
                    </div>

                    <Table
                        tableType={"item_history"}
                        tableHeaders={[
                            "DOC DATE",
                            "DOC TYPE",
                            "DOC NO.",
                            "IN",
                            "OUT",
                            "CURRENT",
                            // "REQUESTED BY",
                            // "APPROVED BY",
                            "ENCODED ON",
                            "ENCODED BY",
                        ]}
                        headerSelector={[
                            "date",
                            "doc_type",
                            "slip_no",
                            "in",
                            "out",
                            "current_qty",
                            // "requested_by_name",
                            // "approved_by_name",
                            "encoded_on",
                            "added_by_name",
                        ]}
                        tableData={itemList.slice().reverse()}
                        ContractBtn={(row) => DocBtn(row)}
                        showLoader={showLoader}
                    />
                    <div className="mb-2" />
                </div>
                <div className="d-flex justify-content-end pt-5 pb-3">
                    <button
                        type="button"
                        className="button-primary me-3"
                        onClick={() => navigate(-1)}
                    >
                        Back
                    </button>
                </div>
            </div>

            
            <ViewModal
                withCloseButtons
                show={showViewModal}
                onHide={handleCloseViewModal}
            >
                <div className="mt-0">
                    <div className="col-sm-12 m-0 space">
                        <Row className="custom-modal-body-title-daily-orders w-100">
                            <Col xs={3} className="custom-modal-pic-right">
                                <img
                                    width={50}
                                    height={50}
                                    src={Smoothie}
                                ></img>
                            </Col>
                            <Col xs={6}>
                                <Row className="justify-content-center">
                                    Order  {selectedRow.id}
                                </Row>
                                <Row className="custom-modal-sub-daily-orders " >
                                    {selectedRow.time}
                                </Row>
                            </Col>
                            <Col xs={3} className="custom-modal-pic-left">
                                <img
                                    width={50}
                                    height={50}
                                    src={Smoothie}
                                ></img>
                            </Col>
                        </Row>
                        <Row className="custom-modal-body-title-branch-details-no-pic w-100">
                            <Col xs={12} className="justify-content-center">
                                {branchName}
                            </Col>
                        </Row>
                        {/* <span className="custom-modal-body-title-branch-details-no-pic">
                            {branchName}
                        </span> */}
                        <div className="mt-3 ">
                            <Row className="nc-modal-custom-row-view">
                                <Col xl={9}>
                                    <Col className="ms-3">
                                        {
                                            selectedRow?.order_detail?.length > 0 ? (
                                                selectedRow.order_detail.map((data) => {
                                                    return (
                                                        <>
                                                            <Row> {data.product_name.split("-")[0]  + " - " + data.product_name.split("-")[1]} </Row>
                                                            <Row className="custom-modal-sub-product">
                                                                PHP {numberFormat(data.price)}
                                                            </Row>
                                                            {
                                                                data.product_detail.length > 0 ? (
                                                                    data.product_detail.map((prod) => {
                                                                        return (
                                                                            <>
                                                                                <Row className="custom-modal-sub-product">
                                                                                    {"x" + prod.qty + " " + prod.product_name + " = P" + prod.price}
                                                                                </Row>
                                                                            </>
                                                                        )
                                                                    })
                                                                )
                                                                :""
                                                            }
                                                        </>
                                                    )
                                                })
                                            )
                                            :""
                                        }
                                    </Col>
                                </Col>
                                <Col xl={3}>
                                    <Col className="nc-modal-custom-row">
                                        {
                                            selectedRow?.order_detail?.length > 0 ? (
                                                selectedRow.order_detail.map((data) => {
                                                    return (
                                                        <>
                                                            <Row className="custom-modal-sub-product-yellow justify-content-center"> {data.qty} </Row>
                                                            <Row className="justify-content-center">
                                                                PHP {numberFormat(data.priceWithAddOn)}
                                                            </Row>
                                                            {
                                                                data.product_detail.length > 0 ? (
                                                                    data.product_detail.map((prod) => {
                                                                        return (
                                                                            <>
                                                                                <Row className="custom-modal-sub-product-white">
                                                                                    {"-"}
                                                                                </Row>
                                                                            </>
                                                                        )
                                                                    })
                                                                )
                                                                :""
                                                            }
                                                        </>
                                                    )
                                                })
                                            )
                                            :""
                                        }
                                    </Col>
                                </Col>
                            </Row>
                        </div>
                        <br/>
                        <div className="break"> </div>
                        <div className="mt-3 ">
                            <Row className="nc-modal-custom-row-view">
                                {selectedRow?.payment ? (
                                    selectedRow?.payment[0]?.discounts?.length > 0 && (
                                        <>
                                            <Row className="ms-1 me-1">
                                                Discount Details
                                            </Row>
                                            {
                                                selectedRow.payment ? (
                                                    selectedRow?.payment[0]?.discounts?.length > 0 ? (
                                                        selectedRow?.payment[0]?.discounts.map((data) => {
                                                            return (
                                                                <>
                                                                    <Row className="ms-1 me-1">
                                                                        <Col xs={6} className="">
                                                                            <Row  className="custom-modal-sub-product"> {data.name} </Row>
                                                                        </Col>
                                                                        <Col xs={6} className="">
                                                                            <Row  className="custom-modal-sub-product justify-content-end"> {data.id_no || "N/A"} </Row>
                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                            )
                                                        })
                                                    )
                                                    :""
                                                ) : ""
                                            }
                                        </>
                                    )
                                ) : ""}
                                <Col xl={12}>
                                    <Row className="ms-1 me-1">
                                        <Col xs={6} className="">
                                            <Row> Transaction Method </Row>
                                            <Row  className="custom-modal-sub-product"> Reference No. </Row>
                                        </Col>
                                        <Col xs={6} className="">
                                            <Row className="justify-content-end"> {selectedRow?.transaction_type} </Row>
                                            <Row  className="custom-modal-sub-product justify-content-end"> {selectedRow?.tran_ref_no || "N/A"} </Row>
                                        </Col>
                                    </Row>
                                    <Row className="ms-1 me-1">
                                        <Col xs={6} className="">
                                            <Row> Payment Method </Row>
                                            <Row  className="custom-modal-sub-product"> Reference No. </Row>
                                        </Col>
                                        <Col xs={6} className="">
                                            <Row className="justify-content-end"> {selectedRow?.payment_type} </Row>
                                            <Row  className="custom-modal-sub-product justify-content-end"> {selectedRow?.pay_ref_no || "N/A"} </Row>
                                        </Col>
                                    </Row>
                                    <div className="break"> </div>
                                    <Row className="ms-1 me-1">
                                        <Col xs={6} className="">
                                            <Row className="bolder system-total"> System Total </Row>
                                            <Row> Delivery Fee </Row>
                                            <Row> Discount </Row>
                                            <Row> Discount Code </Row>
                                            <Row className="bolder system-total"> Grand Total </Row>
                                        </Col>
                                        <Col xs={6} className="">
                                            {/* <Row className="justify-content-end"> PHP {selectedRow?.subtotal} </Row> */}
                                            <Row className="justify-content-end bolder system-total"> PHP {selectedRow?.grand_total} </Row>
                                            <Row className="justify-content-end">+ PHP {selectedRow?.delivery_fee} </Row>
                                            <Row className="justify-content-end">- PHP {selectedRow?.discount} </Row>
                                            <Row className="justify-content-end"> {selectedRow?.discount_code !== "" ? selectedRow?.discount_code : "N/A"} </Row>
                                            <Row className="justify-content-end bolder system-total"> PHP {selectedRow?.computed_grand_total} </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </ViewModal>
        </div>
    );
}
