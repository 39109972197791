import {handleValidationChange} from '../CommonValidation';

export const validateDiscount = (data, days, branches, setIsError) => {
    var isValid = true;

    if(days.length === 0) {
        handleValidationChange("days", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("days", false, setIsError);
    }

    if(branches.length === 0) {
        handleValidationChange("branches", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("branches", false, setIsError);
    }

    if(data.discount_source === "") {
        handleValidationChange("discount_source", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("discount_source", false, setIsError);
    }
    
    if(data.commission_base === "") {
        handleValidationChange("commission_base", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("commission_base", false, setIsError);
    }

    return isValid
  
}