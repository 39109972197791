import React, { useState } from "react";
import { Col, Form, Row, Tab, Tabs, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import Delete from "../../../Components/Modals/DeleteModal";
import Navbar from "../../../Components/Navbar/Navbar";
import Table from "../../../Components/TableTemplate/Table";
import AdjustmentTable from "./../../Inventory/Adjustment/AdjustmentTable";
import NoDataImg from "../../../Assets/Images/no-data-img.png"
import {
    dateFormat,
    formatDateNoTime,
    formatDate,
    numberFormat,
    refreshPage,
    toastStyle,
    getTodayDate,
    getType,
    TokenExpiry,
    getTodayDateISO
} from "../../../Helpers/Utils/Common"; 
import DatePicker from "react-datepicker";
import Moment from "moment";

import { getAllBanks } from "../../../Helpers/apiCalls/banksAPi";
import { getAllFranchisee } from "../../../Helpers/apiCalls/franchiseeApi";
import { searchStoreDeposit, updateStatusStoreDeposit, deleteStoreDeposit } from "../../../Helpers/apiCalls/Reports/StoreDepositApi";
import { getCashCountReport } from "../../../Helpers/apiCalls/Reports/DailyCashCountApi";
// import { getAllBranches } from "../../../Helpers/apiCalls/Manage/Branches";
import { getAllEmployees } from "../../../Helpers/apiCalls/employeesApi";
import { CSVLink, CSVDownload } from "react-csv";
import downloadIcon from "../../../Assets/Images/download_icon.png";
import { Fragment } from "react";
import { getAllCashAdvance, deleteCashAdvance } from './../../../Helpers/apiCalls/Employees/CashAdvanceApi';
//api

export default function CashAdvance() {
    let navigate = useNavigate();
    var dateToday = getTodayDate();
    const accountType = getType();
    const [inactive, setInactive] = useState(true);
    const [filterConfig, setFilterConfig] = useState({
        employee_name: "",
        date_from: "",
        date_to: "",
        status: "pending",
        employee_id: "",
        group_by_entry: "1"
    })
    // const [branches, setBranches] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [allData, setAllData] = useState([]);
    const [totalCashAdvance, setTotalCashAdvance] = useState([]);
    const [checkedPayments, setCheckedPayments] = useState([]);
    const [selectedTab, setSelectedTab] = useState("submitted");
    const [dailySales, setDailySales] = useState([]);

     /* delete modal handler */
     const [showDeleteModal, setShowDeleteModal] = useState(false);
     const handleShowDeleteModal = () => setShowDeleteModal(true);
     const handleCloseDeleteModal = () => setShowDeleteModal(false); 

     const [selectedRow, setSelectedRow] = useState([]);
     const today = Moment().format("MM/DD/YYYY");

    const excelHeaders = [
        // { label: "Sales Date", key: "sales_date" },
        { label: "Total Sales", key: "total_sales" },
        { label: "Branch Name", key: "branch_name" },
        { label: "Bank", key: "bank" },
        // { label: "DSR No.", key: "dsr_no" },
        { label: "Deposit Date", key: "deposited_on" },
        { label: "Transaction Type", key: "transaction_type" },
        // { label: "Deposited By", key: "deposited_by" },
    ];

    const dummy = [
        {
            id: "1",
            branch_name: "SM CITY CEBU",
            date: "December 2022",
            amount: "1000",
            added_by: "JOSE RIZAL",
        },
        {
            id: "2",
            branch_name: "SM CONSOLACION",
            date: "December 2022",
            amount: "1000",
            added_by: "JOSE RIZAL",
        },
    ]

    // SEARCH USER
    function handleOnSearch(e) {
        const { name, value } = e.target;
        setFilterConfig((prev) => {
            return { ...prev, [name]: value };
        });
    }

    function handleSelectChange(e, row) {
        setSelectedRow(row);
        console.log(row)

        if (e.target.value === "view") {
            navigate("/cashadvance/view/" + row.entry_id + "/" + row.status)
        } else if (e.target.value === "edit") {
            navigate("/cashadvance/edit/" + row.entry_id)
        } else if (e.target.value === "delete") {
            handleShowDeleteModal();
        }
    }

    console.log(filterConfig)
    function ActionBtn(row, type) {
        return (
            <Form.Select
                name="action"
                className="PO-select-action"
                onChange={(e) => handleSelectChange(e, row)}
            >
                <option value="" hidden selected>
                    Select
                </option>
                <option value="view" className="color-options">
                    View
                </option>
                {   (filterConfig.tab !== "unsettled,approved" && filterConfig.tab !== "settled" && filterConfig.tab !== "disapproved") && (
                        <option value="edit" className="color-options">
                            Edit
                        </option>
                    )
                }
                <option value="delete" className="color-red">
                    Delete
                </option>
            </Form.Select>
        );
    }

    function ViewBtn(row) {
        return (
            <button
                name="action"
                className="btn btn-sm view-btn-table"
                id={row.id}
                onClick={() => handleView(row)}
            >
                View
            </button>
        );
    }
    function handleView(row) {
        console.log(row)
        setSelectedRow(row);
        navigate("/cashadvance/view/" + row.entry_id + "/" + row.status)
    }

    function ReviewBtn(row) {
        return (
            <button
                name="action"
                className="btn btn-sm view-btn-table"
                id={row.id}
                onClick={() => handleReview(row)}
            >
                Review
            </button>
        );
    }
    function handleReview(row) {
        console.log(row)
        setSelectedRow(row);
        navigate("/cashadvance/view/" + row.entry_id + "/" + row.status)
    }

    function EditBtn(row) {
        return (
            <button
                name="action"
                className="btn btn-sm view-btn-table"
                id={row.id}
                onClick={() => handleEdit(row)}
            >
                Edit
            </button>
        );
    }
    function handleEdit(row) {
        console.log(row)
        setSelectedRow(row);
        navigate("/cashadvance/edit/" + row.entry_id)
        // fetchReport(row.branch_id, row.sales_date)
    }

    const handleTabSelect = (key) => {
        setFilterConfig((prev) => {
            return {
                ...prev,
                tab: key,
                status: key !== "all" ? key : ""};
        });
    };
    // console.log(filterConfig)

    function handleOnCheck(row) {
        setCheckedPayments(row.selectedRows);
    }
    
    // async function fetchBanks() {
    //     const response = await getAllBanks();
    //     var banks = response.data.data.sort((a, b) =>
    //         a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    //     );

    //     var cleanedArray = banks.map((bank) => {
    //         var info = bank;
    //         info.label = bank.name
    //         info.value = bank.id
    //         return info;
    //     });
    //     setBanks([{label: "All Banks", value:""}, ...cleanedArray]);
    // }

    async function fetchEmployees() {

        const response = await getAllEmployees();
        if (response.error) {
            setEmployees([])
        } else {
            let result = response.data.data.map((data) => {
                var employee = data;
                employee.label = data.first_name + " " + data.last_name;
                employee.value = data.id;
                return employee;
            });
            setEmployees([{label: "All Employees", value:""}, ...result]);
        }
    }

    async function fetchData() {
        setShowLoader(true);
        setAllData([])
        setTotalCashAdvance("")

        const response = await getAllCashAdvance(filterConfig);
        console.log(response)
        if (response.error) {
            setTotalCashAdvance("0")
            setAllData([])
        } else {
            var allBills = response.data.data.map((bill) => {
                var info = bill;
                info.ca_date = Moment(bill.added_on).format("YYYY-MM-DD")
                info.dt_date = bill.disbursement_date ? Moment(bill.disbursement_date).format("YYYY-MM-DD") : ""
                info.cash_advance = bill.amount ? numberFormat(bill.amount) : "0.00"
                info.balance = numberFormat(bill.balance)
                info.paid_amount = numberFormat(bill.paid_amount)
                info.type = bill.disbursement_type
                return info;
            });
            setAllData(allBills)

            var total = response.data.summary? response.data.summary.total_cash_advance : "0";
            setTotalCashAdvance(total);

        }
        setShowLoader(false);
    }

    async function handleDeleteExpense() {
        const response = await deleteCashAdvance(selectedRow.entry_id);
        if (response.data) {
            toast.success("Cash Advance Deleted Successfully!", {
                style: toastStyle(),
            });
            setTimeout(() => refreshPage(), 1000);
        } else {
            toast.error("Error Deleting Cash Advance", {
                style: toastStyle(),
            });
        }
    }

    React.useEffect(() => {
        fetchEmployees();
        // fetchBanks();
    }, []);

    React.useEffect(() => {
        fetchData();
    }, [filterConfig]);

    return (
        <div>
            <div className="page">
                <Navbar
                    onCollapse={(inactive) => {
                        setInactive(inactive);
                    }}
                    active={"EMPLOYEES"}
                />
            </div>

            <div
                className={`manager-container ${
                    inactive ? "inactive" : "active"
                }`}
            >
                {/* headers */}
                <Row className="mb-3">
                    <Col xs={6}>
                        <h1 className="page-title"> CASH ADVANCE </h1>
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <input
                            type="text"
                            name="employee_name"
                            placeholder="Search employee name"
                            className="search-bar"
                            onChange={handleOnSearch}
                        ></input>
                        <button
                            className="add-btn"
                            onClick={(e) => {navigate("/cashadvance/add")}}
                        >
                            Add
                        </button>
                    </Col>
                </Row>

                {/* <div className="row mb-4 my-2 PO-filters d-flex filter">
                    <Row>
                        <Col xs={6}>
                            <Select
                                className="dropsearch-filter-col px-2 py-0 me-2 "
                                classNamePrefix="react-select"
                                defaultValue={transactionValue}
                                options={transactionOptions}
                                onChange={(e) => {
                                    setFilterConfig((prev) => {
                                        return { ...prev, "transaction_type": e.value };
                                    });
                                }}
                            />
                        </Col>
                        <Col className="d-flex justify-content-end mb-4">
                            <div className="justify-content-center align-items-center ">
                                <CSVLink
                                    className="button-primary px-3 py-3 justify-content-center align-items-center download-csv"
                                    data={allData}
                                    headers={excelHeaders}
                                    target="_blank"
                                    filename={`${getTodayDateISO()} Store Deposit`}
                                >
                                    <span className="me-2">
                                        <img
                                            width={20}
                                            height={20}
                                            src={downloadIcon}
                                        ></img>
                                    </span>
                                    Download Excel
                                </CSVLink>
                            </div>
                        </Col>
                    </Row>
                </div> */}

                <Tabs
                    activeKey={filterConfig.shop}
                    // defaultActiveKey={filterConfig.shop}
                    id="PO-tabs"
                    className="manager-tabs"
                    onSelect={handleTabSelect}
                >
                    <Tab eventKey="pending" title="pending">
                        <div className="tab-content">
                            {/* filters */}
                            <div className="my-2 px-2 PO-filters d-flex">
                                <span className="me-4 align-middle mt-2 ps-label">
                                    Filter By:
                                </span>

                                <Select
                                    className="dropsearch-filter px-0 py-0 me-2"
                                    classNamePrefix="react-select"
                                    placeholder="Select Employee"
                                    styles={{
                                        control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        backgroundColor: state.isSelected ? 'white' : '#59c8e3',
                                        borderRadius: "7px",
                                        border: "0px",
                                        minHeight: "20px",
                                        maxHeight: "35px",
                                        }),
                                        input: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: state.isSelected? "white": "white",
                                            
                                        }),
                                        dropdownIndicator: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        singleValue: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        placeholder: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        
                                    }}
                                    // value={branch}
                                    options={employees}
                                    onChange={(e) => { setFilterConfig((prev) => {
                                            return { ...prev, "employee_id": e.value };
                                        });}}
                                />

                                {/* <Form.Select
                                    name="cash_advance_status"
                                    className="date-filter me-2"
                                    defaultValue={filterConfig.cash_advance_status}
                                    onChange={(e) => {
                                        setFilterConfig((prev) => {
                                            return { ...prev, cash_advance_status: e.target.value };
                                        });
                                    }}
                                >
                                    <option value="all" selected>
                                        All Status
                                    </option>
                                    <option value="pending" >
                                        Pending
                                    </option>
                                    <option value="approved" >
                                        Approved
                                    </option>
                                    
                                </Form.Select> */}

                                <span className="me-4 align-middle mt-2 ps-label">
                                    From:
                                </span>
                                <DatePicker
                                    selected={filterConfig.date_from}
                                    name="date_from"
                                    placeholderText={"Select Date"}
                                    onChange={(date) => {
                                        setFilterConfig((prev) => {
                                            return { ...prev, date_from: date };
                                        });
                                    }}
                                    fixedHeight
                                    className="PI-date-btn me-3 form-control"
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    yearDropdownItemNumber={20}
                                    scrollableYearDropdown
                                />

                                <span className="me-4 align-middle mt-2 ps-label">
                                    To:
                                </span>
                                <DatePicker
                                    selected={filterConfig.date_to}
                                    name="date_to"
                                    placeholderText={"Select Date"}
                                    onChange={(date) => {
                                        setFilterConfig((prev) => {
                                            return { ...prev, date_to: date };
                                        });
                                    }}
                                    fixedHeight
                                    className="PI-date-btn me-3 form-control"
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    yearDropdownItemNumber={20}
                                    scrollableYearDropdown
                                />
                            </div>

                            <div className=" PO-filters d-flex justify-content-center">
                                <span className="me-4 ml-4 mt-2 ps-label">
                                    Total Cash Advance: {numberFormat(totalCashAdvance)}
                                </span>
                            </div>

                            <div className="below">
                                {/* table */}
                                <Table
                                    tableHeaders={[
                                        "-",
                                        "CA NO.",
                                        "CA DATE",
                                        "DT DATE",
                                        "DATE",
                                        "NO. OF EMPLOYEE",
                                        "TOTAL CA",
                                        "BALANCE",
                                        "PREP BY",
                                        "APPROVED BY",
                                        "ACTIONS",
                                    ]}
                                    headerSelector={[
                                        "-",
                                        "id",
                                        "ca_date",
                                        "dt_date",
                                        "date",
                                        "total_employees",
                                        "cash_advance",
                                        "balance",
                                        "prepared_by_name",
                                        "approved_by_name",
                                    ]}
                                    tableData={allData}
                                    ActionBtn={(row) => ActionBtn(row)}
                                    ViewBtn={(row) => EditBtn(row)}
                                    showLoader={showLoader}
                                />
                            </div>
                            <div className="mb-2" />
                        </div>
                    </Tab>
                    <Tab eventKey="for_approval" title="for approval">
                        <div className="tab-content">
                            {/* filters */}
                            <div className="my-2 px-2 PO-filters d-flex">
                                <span className="me-4 align-middle mt-2 ps-label">
                                    Filter By:
                                </span>

                                {/* <Form.Select
                                    name="transaction_type"
                                    className="date-filter me-2"
                                    onChange={(e) => handleOnSearch(e)}
                                >
                                    <option value="" selected>
                                        All Types
                                    </option>
                                    <option value="cash"> Cash Deposit</option>
                                    <option value="foodpanda"> Food Panda Deposit</option>
                                    <option value="grabfood"> Grab Food Deposit </option>
                                </Form.Select> */}

                                <Select
                                    className="dropsearch-filter px-0 py-0 me-2"
                                    classNamePrefix="react-select"
                                    placeholder="Select Employee"
                                    styles={{
                                        control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        backgroundColor: state.isSelected ? 'white' : '#59c8e3',
                                        borderRadius: "7px",
                                        border: "0px",
                                        minHeight: "20px",
                                        maxHeight: "35px",
                                        }),
                                        input: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: state.isSelected? "white": "white",
                                            
                                        }),
                                        dropdownIndicator: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        singleValue: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        placeholder: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        
                                    }}
                                    // value={branch}
                                    options={employees}
                                    onChange={(e) => { setFilterConfig((prev) => {
                                            return { ...prev, "employee_id": e.value };
                                        });}}
                                />

                                <span className="me-4 align-middle mt-2 ps-label">
                                    From:
                                </span>
                                <DatePicker
                                    selected={filterConfig.date_from}
                                    name="date_from"
                                    placeholderText={"Select Date"}
                                    onChange={(date) => {
                                        setFilterConfig((prev) => {
                                            return { ...prev, date_from: date };
                                        });
                                    }}
                                    fixedHeight
                                    className="PI-date-btn me-3 form-control"
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    yearDropdownItemNumber={20}
                                    scrollableYearDropdown
                                />

                                <span className="me-4 align-middle mt-2 ps-label">
                                    To:
                                </span>
                                <DatePicker
                                    selected={filterConfig.date_to}
                                    name="date_to"
                                    placeholderText={"Select Date"}
                                    onChange={(date) => {
                                        setFilterConfig((prev) => {
                                            return { ...prev, date_to: date };
                                        });
                                    }}
                                    fixedHeight
                                    className="PI-date-btn me-3 form-control"
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    yearDropdownItemNumber={20}
                                    scrollableYearDropdown
                                />
                            </div>

                            <div className=" PO-filters d-flex justify-content-center">
                                <span className="me-4 ml-4 mt-2 ps-label">
                                    Total Cash Advance: {numberFormat(totalCashAdvance)}
                                </span>
                            </div>

                            <div className="below">
                                {/* table */}
                                <Table
                                    tableHeaders={[
                                        "-",
                                        "CA NO.",
                                        "CA DATE",
                                        "DT DATE",
                                        "DATE",
                                        "NO. OF EMPLOYEE",
                                        "TOTAL CA",
                                        "BALANCE",
                                        "PREP BY",
                                        "APPROVED BY",
                                        "ACTIONS",
                                    ]}
                                    headerSelector={[
                                        "-",
                                        "id",
                                        "ca_date",
                                        "dt_date",
                                        "date",
                                        "total_employees",
                                        "cash_advance",
                                        "balance",
                                        "prepared_by_name",
                                        "approved_by_name",
                                    ]}
                                    tableData={allData}
                                    ActionBtn={(row) => ActionBtn(row)}
                                    ViewBtn={(row) => ReviewBtn(row)}
                                    showLoader={showLoader}
                                />
                            </div>
                            <div className="mb-2" />
                        </div>
                    </Tab>
                    {/* <Tab eventKey="approved" title="approved">
                        <div className="tab-content">
                            <div className="my-2 px-2 PO-filters d-flex">
                                <span className="me-4 align-middle mt-2 ps-label">
                                    Filter By:
                                </span>

                                <Select
                                    className="dropsearch-filter px-0 py-0 me-2"
                                    classNamePrefix="react-select"
                                    placeholder="Select Employee"
                                    styles={{
                                        control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        backgroundColor: state.isSelected ? 'white' : '#59c8e3',
                                        borderRadius: "7px",
                                        border: "0px",
                                        minHeight: "20px",
                                        maxHeight: "35px",
                                        }),
                                        input: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: state.isSelected? "white": "white",
                                            
                                        }),
                                        dropdownIndicator: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        singleValue: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        placeholder: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        
                                    }}
                                    // value={branch}
                                    options={employees}
                                    onChange={(e) => { setFilterConfig((prev) => {
                                            return { ...prev, "employee_id": e.value };
                                        });}}
                                />

                                <span className="me-4 align-middle mt-2 ps-label">
                                    From:
                                </span>
                                <DatePicker
                                    selected={filterConfig.date_from}
                                    name="date_from"
                                    placeholderText={"Select Date"}
                                    onChange={(date) => {
                                        setFilterConfig((prev) => {
                                            return { ...prev, date_from: date };
                                        });
                                    }}
                                    fixedHeight
                                    className="PI-date-btn me-3 form-control"
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    yearDropdownItemNumber={20}
                                    scrollableYearDropdown
                                />

                                <span className="me-4 align-middle mt-2 ps-label">
                                    To:
                                </span>
                                <DatePicker
                                    selected={filterConfig.date_to}
                                    name="date_to"
                                    placeholderText={"Select Date"}
                                    onChange={(date) => {
                                        setFilterConfig((prev) => {
                                            return { ...prev, date_to: date };
                                        });
                                    }}
                                    fixedHeight
                                    className="PI-date-btn me-3 form-control"
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    yearDropdownItemNumber={20}
                                    scrollableYearDropdown
                                />
                            </div>

                            <div className=" PO-filters d-flex justify-content-center">
                                <span className="me-4 ml-4 mt-2 ps-label">
                                    Total Cash Advance: {numberFormat(totalCashAdvance)}
                                </span>
                            </div>

                            <div className="below">
                                <Table
                                    tableHeaders={[
                                        "-",
                                        "CA NO.",
                                        "CA DATE",
                                        "DT DATE",
                                        "DATE",
                                        "NO. OF EMPLOYEE",
                                        "TOTAL CA",
                                        "BALANCE",
                                        "PREP BY",
                                        "APPROVED BY",
                                        "ACTIONS",
                                    ]}
                                    headerSelector={[
                                        "-",
                                        "id",
                                        "ca_date",
                                        "dt_date",
                                        "date",
                                        "total_employees",
                                        "cash_advance",
                                        "balance",
                                        "prepared_by_name",
                                        "approved_by_name",
                                    ]}
                                    tableData={allData}
                                    // tableData={allData}
                                    ActionBtn={(row) => ActionBtn(row)}
                                    ViewBtn={(row) => ViewBtn(row)}
                                    showLoader={showLoader}
                                />
                            </div>
                            <div className="mb-2" />
                        </div>
                    </Tab> */}
                    <Tab eventKey="unsettled,approved" title="unsettled">
                        <div className="tab-content">
                            {/* filters */}
                            <div className="my-2 px-2 PO-filters d-flex">
                                <span className="me-4 align-middle mt-2 ps-label">
                                    Filter By:
                                </span>

                                <Select
                                    className="dropsearch-filter px-0 py-0 me-2"
                                    classNamePrefix="react-select"
                                    placeholder="Select Employee"
                                    styles={{
                                        control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        backgroundColor: state.isSelected ? 'white' : '#59c8e3',
                                        borderRadius: "7px",
                                        border: "0px",
                                        minHeight: "20px",
                                        maxHeight: "35px",
                                        }),
                                        input: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: state.isSelected? "white": "white",
                                            
                                        }),
                                        dropdownIndicator: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        singleValue: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        placeholder: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        
                                    }}
                                    // value={branch}
                                    options={employees}
                                    onChange={(e) => { setFilterConfig((prev) => {
                                            return { ...prev, "employee_id": e.value };
                                        });}}
                                />

                                <span className="me-4 align-middle mt-2 ps-label">
                                    From:
                                </span>
                                <DatePicker
                                    selected={filterConfig.date_from}
                                    name="date_from"
                                    placeholderText={"Select Date"}
                                    onChange={(date) => {
                                        setFilterConfig((prev) => {
                                            return { ...prev, date_from: date };
                                        });
                                    }}
                                    fixedHeight
                                    className="PI-date-btn me-3 form-control"
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    yearDropdownItemNumber={20}
                                    scrollableYearDropdown
                                />

                                <span className="me-4 align-middle mt-2 ps-label">
                                    To:
                                </span>
                                <DatePicker
                                    selected={filterConfig.date_to}
                                    name="date_to"
                                    placeholderText={"Select Date"}
                                    onChange={(date) => {
                                        setFilterConfig((prev) => {
                                            return { ...prev, date_to: date };
                                        });
                                    }}
                                    fixedHeight
                                    className="PI-date-btn me-3 form-control"
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    yearDropdownItemNumber={20}
                                    scrollableYearDropdown
                                />
                            </div>

                            <div className=" PO-filters d-flex justify-content-center">
                                <span className="me-4 ml-4 mt-2 ps-label">
                                    Total Cash Advance: {numberFormat(totalCashAdvance)}
                                </span>
                            </div>

                            <div className="below">
                                {/* table */}
                                <Table
                                    tableHeaders={[
                                        "-",
                                        "CA NO.",
                                        "CA DATE",
                                        "DT DATE",
                                        "DATE",
                                        "NO. OF EMPLOYEE",
                                        "TOTAL CA",
                                        "BALANCE",
                                        "PREP BY",
                                        "APPROVED BY",
                                        "ACTIONS",
                                    ]}
                                    headerSelector={[
                                        "-",
                                        "id",
                                        "ca_date",
                                        "dt_date",
                                        "date",
                                        "total_employees",
                                        "cash_advance",
                                        "balance",
                                        "prepared_by_name",
                                        "approved_by_name",
                                    ]}
                                    tableData={allData}
                                    // tableData={allData}
                                    ActionBtn={(row) => ActionBtn(row)}
                                    ViewBtn={(row) => ViewBtn(row)}
                                    showLoader={showLoader}
                                />
                            </div>
                            <div className="mb-2" />
                        </div>
                    </Tab>
                    <Tab eventKey="settled" title="settled">
                        <div className="tab-content">
                            {/* filters */}
                            <div className="my-2 px-2 PO-filters d-flex">
                                <span className="me-4 align-middle mt-2 ps-label">
                                    Filter By:
                                </span>

                                {/* <Form.Select
                                    name="transaction_type"
                                    className="date-filter me-2"
                                    onChange={(e) => handleOnSearch(e)}
                                >
                                    <option value="" selected>
                                        All Types
                                    </option>
                                    <option value="cash"> Cash Deposit</option>
                                    <option value="foodpanda"> Food Panda Deposit</option>
                                    <option value="grabfood"> Grab Food Deposit </option>
                                </Form.Select> */}

                                <Select
                                    className="dropsearch-filter px-0 py-0 me-2"
                                    classNamePrefix="react-select"
                                    placeholder="Select Employee"
                                    styles={{
                                        control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        backgroundColor: state.isSelected ? 'white' : '#59c8e3',
                                        borderRadius: "7px",
                                        border: "0px",
                                        minHeight: "20px",
                                        maxHeight: "35px",
                                        }),
                                        input: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: state.isSelected? "white": "white",
                                            
                                        }),
                                        dropdownIndicator: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        singleValue: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        placeholder: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        
                                    }}
                                    // value={branch}
                                    options={employees}
                                    onChange={(e) => { setFilterConfig((prev) => {
                                            return { ...prev, "employee_id": e.value };
                                        });}}
                                />

                                {/* <Form.Select
                                    name="cash_advance_status"
                                    className="date-filter me-2"
                                    defaultValue={filterConfig.cash_advance_status}
                                    onChange={(e) => {
                                        setFilterConfig((prev) => {
                                            return { ...prev, cash_advance_status: e.target.value };
                                        });
                                    }}
                                >
                                    <option value="all" selected>
                                        All Status
                                    </option>
                                    <option value="pending" >
                                        Pending
                                    </option>
                                    <option value="approved" >
                                        Approved
                                    </option>
                                    
                                </Form.Select> */}

                                <span className="me-4 align-middle mt-2 ps-label">
                                    From:
                                </span>
                                <DatePicker
                                    selected={filterConfig.date_from}
                                    name="date_from"
                                    placeholderText={"Select Date"}
                                    onChange={(date) => {
                                        setFilterConfig((prev) => {
                                            return { ...prev, date_from: date };
                                        });
                                    }}
                                    fixedHeight
                                    className="PI-date-btn me-3 form-control"
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    yearDropdownItemNumber={20}
                                    scrollableYearDropdown
                                />

                                <span className="me-4 align-middle mt-2 ps-label">
                                    To:
                                </span>
                                <DatePicker
                                    selected={filterConfig.date_to}
                                    name="date_to"
                                    placeholderText={"Select Date"}
                                    onChange={(date) => {
                                        setFilterConfig((prev) => {
                                            return { ...prev, date_to: date };
                                        });
                                    }}
                                    fixedHeight
                                    className="PI-date-btn me-3 form-control"
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    yearDropdownItemNumber={20}
                                    scrollableYearDropdown
                                />
                            </div>

                            <div className=" PO-filters d-flex justify-content-center">
                                <span className="me-4 ml-4 mt-2 ps-label">
                                    Total Cash Advance: {numberFormat(totalCashAdvance)}
                                </span>
                            </div>

                            <div className="below">
                                {/* table */}
                                <Table
                                    tableHeaders={[
                                        "-",
                                        "CA NO.",
                                        "CA DATE",
                                        "DT DATE",
                                        "DATE",
                                        "NO. OF EMPLOYEE",
                                        "TOTAL CA",
                                        "BALANCE",
                                        "PREP BY",
                                        "APPROVED BY",
                                        "ACTIONS",
                                    ]}
                                    headerSelector={[
                                        "-",
                                        "id",
                                        "ca_date",
                                        "dt_date",
                                        "date",
                                        "total_employees",
                                        "cash_advance",
                                        "balance",
                                        "prepared_by_name",
                                        "approved_by_name",
                                    ]}
                                    tableData={allData}
                                    // tableData={allData}
                                    ActionBtn={(row) => ActionBtn(row)}
                                    ViewBtn={(row) => ViewBtn(row)}
                                    showLoader={showLoader}
                                />
                            </div>
                            <div className="mb-2" />
                        </div>
                    </Tab>
                    <Tab eventKey="disapproved" title="disapproved">
                        <div className="tab-content">
                            {/* filters */}
                            <div className="my-2 px-2 PO-filters d-flex">
                                <span className="me-4 align-middle mt-2 ps-label">
                                    Filter By:
                                </span>

                                {/* <Form.Select
                                    name="transaction_type"
                                    className="date-filter me-2"
                                    onChange={(e) => handleOnSearch(e)}
                                >
                                    <option value="" selected>
                                        All Types
                                    </option>
                                    <option value="cash"> Cash Deposit</option>
                                    <option value="foodpanda"> Food Panda Deposit</option>
                                    <option value="grabfood"> Grab Food Deposit </option>
                                </Form.Select> */}

                                <Select
                                    className="dropsearch-filter px-0 py-0 me-2"
                                    classNamePrefix="react-select"
                                    placeholder="Select Employee"
                                    styles={{
                                        control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        backgroundColor: state.isSelected ? 'white' : '#59c8e3',
                                        borderRadius: "7px",
                                        border: "0px",
                                        minHeight: "20px",
                                        maxHeight: "35px",
                                        }),
                                        input: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: state.isSelected? "white": "white",
                                            
                                        }),
                                        dropdownIndicator: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        singleValue: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        placeholder: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        
                                    }}
                                    // value={branch}
                                    options={employees}
                                    onChange={(e) => { setFilterConfig((prev) => {
                                            return { ...prev, "employee_id": e.value };
                                        });}}
                                />

                                {/* <Form.Select
                                    name="cash_advance_status"
                                    className="date-filter me-2"
                                    defaultValue={filterConfig.cash_advance_status}
                                    onChange={(e) => {
                                        setFilterConfig((prev) => {
                                            return { ...prev, cash_advance_status: e.target.value };
                                        });
                                    }}
                                >
                                    <option value="all" selected>
                                        All Status
                                    </option>
                                    <option value="pending" >
                                        Pending
                                    </option>
                                    <option value="approved" >
                                        Approved
                                    </option>
                                    
                                </Form.Select> */}

                                <span className="me-4 align-middle mt-2 ps-label">
                                    From:
                                </span>
                                <DatePicker
                                    selected={filterConfig.date_from}
                                    name="date_from"
                                    placeholderText={"Select Date"}
                                    onChange={(date) => {
                                        setFilterConfig((prev) => {
                                            return { ...prev, date_from: date };
                                        });
                                    }}
                                    fixedHeight
                                    className="PI-date-btn me-3 form-control"
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    yearDropdownItemNumber={20}
                                    scrollableYearDropdown
                                />

                                <span className="me-4 align-middle mt-2 ps-label">
                                    To:
                                </span>
                                <DatePicker
                                    selected={filterConfig.date_to}
                                    name="date_to"
                                    placeholderText={"Select Date"}
                                    onChange={(date) => {
                                        setFilterConfig((prev) => {
                                            return { ...prev, date_to: date };
                                        });
                                    }}
                                    fixedHeight
                                    className="PI-date-btn me-3 form-control"
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    yearDropdownItemNumber={20}
                                    scrollableYearDropdown
                                />
                            </div>

                            <div className=" PO-filters d-flex justify-content-center">
                                <span className="me-4 ml-4 mt-2 ps-label">
                                    Total Cash Advance: {numberFormat(totalCashAdvance)}
                                </span>
                            </div>

                            <div className="below">
                                {/* table */}
                                <Table
                                    tableHeaders={[
                                        "-",
                                        "CA NO.",
                                        "CA DATE",
                                        "DT DATE",
                                        "DATE",
                                        "NO. OF EMPLOYEE",
                                        "TOTAL CA",
                                        "BALANCE",
                                        "PREP BY",
                                        "APPROVED BY",
                                        "ACTIONS",
                                    ]}
                                    headerSelector={[
                                        "-",
                                        "id",
                                        "ca_date",
                                        "dt_date",
                                        "date",
                                        "total_employees",
                                        "cash_advance",
                                        "balance",
                                        "prepared_by_name",
                                        "approved_by_name",
                                    ]}
                                    tableData={allData}
                                    // tableData={allData}
                                    ActionBtn={(row) => ActionBtn(row)}
                                    ViewBtn={(row) => ViewBtn(row)}
                                    showLoader={showLoader}
                                />
                            </div>
                            <div className="mb-2" />
                        </div>
                    </Tab>
                    <Tab eventKey="all" title="All">
                        <div className="tab-content">
                            {/* filters */}
                            <div className="my-2 px-2 PO-filters d-flex">
                                <span className="me-4 align-middle mt-2 ps-label">
                                    Filter By:
                                </span>

                                {/* <Form.Select
                                    name="transaction_type"
                                    className="date-filter me-2"
                                    onChange={(e) => handleOnSearch(e)}
                                >
                                    <option value="" selected>
                                        All Types
                                    </option>
                                    <option value="cash"> Cash Deposit</option>
                                    <option value="foodpanda"> Food Panda Deposit</option>
                                    <option value="grabfood"> Grab Food Deposit </option>
                                </Form.Select> */}

                                <Select
                                    className="dropsearch-filter px-0 py-0 me-2"
                                    classNamePrefix="react-select"
                                    placeholder="Select Employee"
                                    styles={{
                                        control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        backgroundColor: state.isSelected ? 'white' : '#59c8e3',
                                        borderRadius: "7px",
                                        border: "0px",
                                        minHeight: "20px",
                                        maxHeight: "35px",
                                        }),
                                        input: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: state.isSelected? "white": "white",
                                            
                                        }),
                                        dropdownIndicator: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        singleValue: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        placeholder: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        
                                    }}
                                    // value={branch}
                                    options={employees}
                                    onChange={(e) => { setFilterConfig((prev) => {
                                            return { ...prev, "employee_id": e.value };
                                        });}}
                                />

                                <span className="me-4 align-middle mt-2 ps-label">
                                    From:
                                </span>
                                <DatePicker
                                    selected={filterConfig.date_from}
                                    name="date_from"
                                    placeholderText={"Select Date"}
                                    onChange={(date) => {
                                        setFilterConfig((prev) => {
                                            return { ...prev, date_from: date };
                                        });
                                    }}
                                    fixedHeight
                                    className="PI-date-btn me-3 form-control"
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    yearDropdownItemNumber={20}
                                    scrollableYearDropdown
                                />

                                <span className="me-4 align-middle mt-2 ps-label">
                                    To:
                                </span>
                                <DatePicker
                                    selected={filterConfig.date_to}
                                    name="date_to"
                                    placeholderText={"Select Date"}
                                    onChange={(date) => {
                                        setFilterConfig((prev) => {
                                            return { ...prev, date_to: date };
                                        });
                                    }}
                                    fixedHeight
                                    className="PI-date-btn me-3 form-control"
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    yearDropdownItemNumber={20}
                                    scrollableYearDropdown
                                />
                            </div>

                            <div className=" PO-filters d-flex justify-content-center">
                                <span className="me-4 ml-4 mt-2 ps-label">
                                    Total Cash Advance: {numberFormat(totalCashAdvance)}
                                </span>
                            </div>

                            <div className="below">
                                {/* table */}
                                <Table
                                    tableHeaders={[
                                        "-",
                                        "CA NO.",
                                        "CA DATE",
                                        "DT DATE",
                                        "DATE",
                                        "NO. OF EMPLOYEE",
                                        "TOTAL CA",
                                        "BALANCE",
                                        "PREP BY",
                                        "APPROVED BY",
                                        // "ACTIONS",
                                    ]}
                                    headerSelector={[
                                        "-",
                                        "id",
                                        "ca_date",
                                        "dt_date",
                                        "date",
                                        "total_employees",
                                        "cash_advance",
                                        "balance",
                                        "prepared_by_name",
                                        "approved_by_name",
                                    ]}
                                    tableData={allData}
                                    // tableData={allData}
                                    ActionBtn={(row) => ActionBtn(row)}
                                    ViewBtn={(row) => ViewBtn(row)}
                                    showLoader={showLoader}
                                />
                            </div>
                            <div className="mb-2" />
                        </div>
                    </Tab>
                </Tabs>
            </div>

            {/* modals */}
            <Delete
                show={showDeleteModal}
                onHide={handleCloseDeleteModal}
                text="cash advance"
                onDelete={handleDeleteExpense}
            />
        </div>
    );
}
