import React, { useState, useEffect, Fragment } from 'react'
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import Select from "react-select";
import ReactLoading from "react-loading";
import {
    formatDateNoTime,
    getTodayDate,
    getTodayDateISO,
    getTodayDateNoTime,
    numberFormat,
    refreshPage,
    toastStyle,
    TokenExpiry,
} from "../../../Helpers/Utils/Common";
import Navbar from "../../../Components/Navbar/Navbar";
import InputError from "../../../Components/InputError/InputError";
//api
import { getAllEmployees } from "../../../Helpers/apiCalls/employeesApi";
import { validateDiscount } from './../../../Helpers/Validation/Manage/DiscountsValidation';
import { searchBranch } from './../../../Helpers/apiCalls/Manage/Branches';
import { searchBranchPotato } from './../../../Helpers/apiCalls/PotatoCorner/Manage/Branches';
import { createDiscounts, createDiscountsPotato, updateDiscounts, updateDiscountsPotato, getDiscounts } from './../../../Helpers/apiCalls/Manage/DiscountsApi';

function FormDiscounts({ add, edit }) {
    //variables
    const { type, id } = useParams()
    let navigate = useNavigate();
    const [inactive, setInactive] = useState(true);
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);
    const [isSaveClicked, setIsSaveClicked] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [branches, setBranches] = useState([]);
    const today = getTodayDateISO();
    const [addDetails, setAddDetails] = useState({
        description: "",
        remarks: "",
        type: "",
        valid_from: "",
        valid_to: "",
        discount_source: "",
        commission_base: "",
        commission_rate: 0,
        vat_rate: 0,
        other_fee: 0,
        discount_amount: 0,
        mm_discount_share: 0,
        delivery_discount_share: 0,
    });
    const [isError, setIsError] = useState({
        days: false,
        discount_source: false,
        commission_base: false,
        branches: false,
    });
    const days = [
        { value: "sunday", label: "Sunday" },
        { value: "monday", label: "Monday" },
        { value: "tuesday", label: "Tuesday" },
        { value: "wednesday", label: "Wednesday" },
        { value: "thursday", label: "Thursday" },
        { value: "friday", label: "Friday" },
        { value: "saturday", label: "Saturday" },
      ];
      const [selected, setSelected] = useState([]);
      const [selectedBranches, setSelectedBranches] = useState([]);

    //functions
    const handleAddChange = (e, type) => {
        console.log(e)
        if(type === "select") {
            setAddDetails((prevState) => ({
                ...prevState,
                [e.for]: e.value,
                [e.select_value]: {
                    for: e.for,
                    label: e.label,
                    value: e.value,
                }
            }));
        } else {
            const { name, value } = e.target;
            console.log(name, value)
            setAddDetails((prevState) => ({
            ...prevState,
            [name]: value,
            }));
        }
    }
    console.log(addDetails)
    console.log(selected)
    console.log(selectedBranches)

    const handleSubmit = () => {
        if (add) handleCreateDiscount();
        else if (edit) handleUpdateDiscount();
    };

    //API
    async function fetchEmployees() {

        const response = await getAllEmployees();
        if (response.error) {
            setEmployees([])
        } else {
            let result = response.data.data.map((data) => {
                var employee = data;
                employee.for = "employee_id"
                employee.select_value = "employee_id_value"
                employee.label = data.first_name + " " + data.last_name;
                employee.value = data.id;
                return employee;
            });
            setEmployees(result);
        }
    }

    async function fetchBranches() {
        setBranches([]);
        //Mango
        if (type === "mango") {
            const response = await searchBranch({is_franchise: 0});
            if (response.data) {
                var result = response.data.data.map((data) => {
                    var branch = data;
                    branch.label = data.name;
                    branch.value = data.id;
                    return branch;
                });
                setBranches(result);
            } else if (response.error) {
                TokenExpiry(response);
            }
        } else if (type === "potato") {
            const response2 = await searchBranchPotato({is_franchise: 0});

            if (response2.data) {
                var result = response2.data.data.map((data) => {
                    var branch = data;
                    branch.label = data.name;
                    branch.value = data.id;
                    return branch;
                });
                setBranches(result);
            } else if (response2.error) {
                TokenExpiry(response2);
            }
        }
    }

    async function handleCreateDiscount() {
        if (isSubmitClicked) {
            return;
        }
        if (validateDiscount(addDetails, selected, selectedBranches, setIsError)) {
            setIsSubmitClicked(true);
            if(type === "mango") {
                const response = await createDiscounts(addDetails, selected, selectedBranches);
                console.log(response)
                if (response.data) {
                    if(response.data.status === "success") {
                        toast.success("Successfully created discount!", {
                            style: toastStyle(),
                        });
                        setTimeout(() => {
                            navigate(-1)
                        }, 1000);
                    }
                } else {
                    toast.error(response.error.response.data.messages.response, {
                        style: toastStyle(),
                    });
                    setTimeout(() => {
                        navigate(-1)
                    }, 1000);
                }
            } else if(type === "potato") {
                const response = await createDiscountsPotato(addDetails, selected, selectedBranches);
                console.log(response)
                if (response.data) {
                    if(response.data.status === "success") {
                        toast.success("Successfully created discount!", {
                            style: toastStyle(),
                        });
                        setTimeout(() => {
                            navigate(-1)
                        }, 1000);
                    }
                } else {
                    toast.error(response.error.response.data.messages.response, {
                        style: toastStyle(),
                    });
                    setTimeout(() => {
                        navigate(-1)
                    }, 1000);
                }
            } 
            


        } else {
            toast.error("Please fill in all fields", {
                style: toastStyle(),
            });
        }
    }

    async function handleUpdateDiscount() {
        if (isSubmitClicked) {
            return;
        }
        if (validateDiscount(addDetails, selected, selectedBranches, setIsError)) {
            setIsSubmitClicked(true);
            if(type === "mango") {
                const response = await updateDiscounts(addDetails, selected, selectedBranches);
                console.log(response)
                if (response.data) {
                    if(response.data.status === "success") {
                        toast.success("Successfully created discount!", {
                            style: toastStyle(),
                        });
                        setTimeout(() => {
                            navigate(-1)
                        }, 1000);
                    } else {
                        toast.error("Something went wrong", {
                            style: toastStyle(),
                        });
                    }
                } 
            } else if(type === "potato") {
                const response = await updateDiscountsPotato(addDetails, selected, selectedBranches);
                console.log(response)
                if (response.data) {
                    if(response.data.status === "success") {
                        toast.success("Successfully created discount!", {
                            style: toastStyle(),
                        });
                    } else {
                        toast.error("Something went wrong", {
                            style: toastStyle(),
                        });
                    }
                } 
            }   
                
        } else {
            toast.error("Please fill in all fields", {
                style: toastStyle(),
            });
        }
    }

    async function fetchData() {
        setAddDetails([]);

        if(type === "mango") {
            const response = await getDiscounts(id);
            console.log(response);

            if (response.error) {
                // TokenExpiry(response);
            } else {
                var expense = response.data.data.map((data) => {
                    var info = data;
                    info.discount_source = data.discount_source ? data.discount_source : data.merchant
                    return info;
                });
                setAddDetails(expense[0])
                var days = []
                if(expense[0].sundays === "1") {
                    days.push({value: "sunday", label: "Sunday"})
                }
                if(expense[0].mondays === "1") {
                    days.push({value: "monday", label: "Monday"})
                }
                if(expense[0].tuesdays === "1") {
                    days.push({value: "tuesday", label: "Tuesday"})
                }
                if(expense[0].wednesdays === "1") {
                    days.push({value: "wednesday", label: "Wednesday"})
                }
                if(expense[0].thursdays === "1") {
                    days.push({value: "thursday", label: "Thursday"})
                }
                if(expense[0].fridays === "1") {
                    days.push({value: "friday", label: "Friday"})
                }
                if(expense[0].saturdays === "1") {
                    days.push({value: "saturday", label: "Saturday"})
                }
                console.log(days)
                setSelected(days)

                var branch = []
                if(expense[0].discount_branches.length > 0) {
                    expense[0].discount_branches.map((data) => {
                        branch.push({
                            id: data.id,
                            value: data.id,
                            label: data.branch_name,
                        })
                    })
                }
                setSelectedBranches(branch)
            }
        } else if(type === "potato") {
            const response = await getDiscounts(id);
            console.log(response);

            if (response.error) {
                // TokenExpiry(response);
            } else {
                var expense = response.data.data.map((data) => {
                    var info = data;
                    return info;
                });
                setAddDetails(expense[0])
                var days = []
                if(expense[0].sundays === "1") {
                    days.push({value: "sunday", label: "Sunday"})
                }
                if(expense[0].mondays === "1") {
                    days.push({value: "monday", label: "Monday"})
                }
                if(expense[0].tuesdays === "1") {
                    days.push({value: "tuesday", label: "Tuesday"})
                }
                if(expense[0].wednesdays === "1") {
                    days.push({value: "wednesday", label: "Wednesday"})
                }
                if(expense[0].thursdays === "1") {
                    days.push({value: "thursday", label: "Thursday"})
                }
                if(expense[0].fridays === "1") {
                    days.push({value: "friday", label: "Friday"})
                }
                if(expense[0].saturdays === "1") {
                    days.push({value: "saturday", label: "Saturday"})
                }
                console.log(days)
                setSelected(days)

                var branch = []
                if(expense[0].discount_branches.length > 0) {
                    expense[0].discount_branches.map((data) => {
                        branch.push({
                            id: data.id,
                            value: data.id,
                            label: data.branch_name,
                        })
                    })
                }
                setSelectedBranches(branch)
            }
        }
        
    }
    console.log(selectedBranches)

    useEffect(() => {
        fetchEmployees();
        fetchBranches();
        if(edit) {
            fetchData()
        }
    }, []);


    return (
        <div>
            <div className="page">
                <Navbar
                    onCollapse={(inactive) => {
                        setInactive(inactive);
                    }}
                    active={"EMPLOYEES"}
                />
            </div>

            <div className={`container ${inactive ? "inactive" : "active"}`}>
                {/* header */}
                <div className="d-flex justify-content-between align-items-center my-3 pb-4">
                    <h1 className="page-title mb-0">
                        {add ? "ADD " : "EDIT "} DISCOUNT
                    </h1>
                </div>

                {/* content */}
                <div className="edit-form">
                    {/* FRANCHISEE SALES INVOICE DETAILS */}
                    <Fragment>
                        <Row className="mt-4 mb-2">
                            <Col>
                                <span className="edit-label">
                                    Description
                                    <label className="badge-required">{` *`}</label>
                                </span>
                            </Col>
                            {/* <Col xs={2}>
                                <span className="edit-label">
                                    Code
                                    <label className="badge-required">{` *`}</label>
                                </span>
                            </Col> */}
                        </Row>
                        <Row className="mt-4 mb-2">
                            <Col>
                                <Form.Control
                                    type="text"
                                    name="description"
                                    className="nc-modal-custom-text"
                                    value={addDetails.description}
                                    onChange={(e) => handleAddChange(e)} 
                                />
                            </Col>
                            {/* <Col xs={2}>
                                <Form.Control
                                    type="text"
                                    name="code"
                                    className="nc-modal-custom-text"
                                    value={addDetails.code}
                                    onChange={(e) => handleAddChange(e)} 
                                />
                                <InputError
                                    isValid={isError.code}
                                    message={"code is required"}
                                />
                            </Col> */}
                        </Row>
                        <Row className="mt-4">
                            <Col xs={4}>
                                <span className="edit-label">
                                    Valid Date
                                    <label className="badge-required">{` *`}</label>
                                </span>
                            </Col>
                            <Col xs={8}>
                                <span className="edit-label">
                                    Applicable Days
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4}>
                                <Row>
                                    <Col xs={2}>
                                        <span className="edit-label">
                                            From:
                                        </span>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            type="date"
                                            name="valid_from"
                                            className="nc-modal-custom-text"
                                            value={addDetails.valid_from}
                                            onChange={(e) => handleAddChange(e, false)}
                                        />
                                        {/* <InputError
                                            isValid={isError.valid_from}
                                            message={"Valid from is required"}
                                        /> */}
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col xs={2}>
                                        <span className="edit-label">
                                            To:
                                        </span>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            type="date"
                                            name="valid_to"
                                            className="nc-modal-custom-text"
                                            value={addDetails.valid_to}
                                            onChange={(e) => handleAddChange(e, false)}
                                        />
                                        {/* <InputError
                                            isValid={isError.valid_to}
                                            message={"Valid to is required"}
                                        /> */}
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={8}>
                                <Select
                                    name="applicable_days"
                                    className="nc-modal-custom-text"
                                    options={days}
                                    value = {selected}
                                    onChange={setSelected}
                                    placeholder={`Select Days`}
                                    isMulti={true}
                                    isSearchable={true}
                                />
                                <InputError
                                    isValid={isError.days}
                                    message={"Days is required"}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col>
                                <span className="edit-label">
                                    Discount Source
                                </span>
                            </Col>
                            <Col>
                                <span className="edit-label">
                                    Commission Base Amount
                                </span>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col>
                                <Form.Select
                                    name="discount_source"
                                    className="date-filter me-2"
                                    value={addDetails.discount_source}
                                    onChange={(e) => {handleAddChange(e)}}
                                >
                                    <option value="" selected disabled>
                                        Select
                                    </option>
                                    <option value="foodpanda" >
                                        Foodpanda
                                    </option>
                                    <option value="grab" >
                                        Grab
                                    </option>
                                </Form.Select>
                                <InputError
                                    isValid={isError.discount_source}
                                    message={"Discount source is required"}
                                />
                            </Col>
                            <Col>
                                <Form.Select
                                    name="commission_base"
                                    className="date-filter me-2"
                                    value={addDetails.commission_base}
                                    onChange={(e) => {handleAddChange(e)}}
                                >
                                    <option value="" selected disabled>
                                        Select
                                    </option>
                                    <option value="gross_sales" >
                                        From gross sales
                                    </option>
                                    <option value="sales_revenue" >
                                        From sales revenue
                                    </option>
                                </Form.Select>
                                <InputError
                                    isValid={isError.commission_base}
                                    message={"commission_base is required"}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col>
                                <span className="edit-label">
                                    Commission Rate
                                </span>
                            </Col>
                            <Col>
                                <span className="edit-label">
                                    VAT Rate
                                </span>
                            </Col>
                            <Col>
                                <span className="edit-label">
                                    Other Fees
                                </span>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col>
                                <Form.Control
                                    type="text"
                                    name="commission_rate"
                                    className="nc-modal-custom-text"
                                    value={addDetails.commission_rate}
                                    onChange={(e) => handleAddChange(e)} 
                                />
                                {/* <InputError
                                    isValid={isError.commission_rate}
                                    message={"commission_rate is required"}
                                /> */}
                            </Col>
                            <Col>
                                <Form.Control
                                    type="text"
                                    name="vat_rate"
                                    className="nc-modal-custom-text"
                                    value={addDetails.vat_rate}
                                    onChange={(e) => handleAddChange(e)} 
                                />
                                {/* <InputError
                                    isValid={isError.vat_rate}
                                    message={"vat_rate is required"}
                                /> */}
                            </Col>
                            <Col>
                                <Form.Control
                                    type="text"
                                    name="other_fee"
                                    className="nc-modal-custom-text"
                                    value={addDetails.other_fee}
                                    onChange={(e) => handleAddChange(e)} 
                                />
                                {/* <InputError
                                    isValid={isError.other_fee}
                                    message={"other_fee is required"}
                                /> */}
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xs={4}>
                                <span className="edit-label">
                                    customer discount
                                </span>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col xs={4}>
                                <Form.Control
                                    type="text"
                                    name="discount_amount"
                                    className="nc-modal-custom-text"
                                    value={addDetails.discount_amount}
                                    onChange={(e) => handleAddChange(e)} 
                                />
                                {/* <InputError
                                    isValid={isError.discount_amount}
                                    message={"discount_amount is required"}
                                /> */}
                            </Col>
                            <Col xs={1}>
                                <Form.Group
                                    className="mt-3"
                                    controlId="formBasicCheckbox"
                                >
                                    <Form.Check
                                        type="radio"
                                        label="Amount"
                                        name="type"
                                        value="fixed"
                                        className="pt-2"
                                        checked={addDetails.type === "fixed"}
                                        onChange={(e) => handleAddChange(e)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={4}>
                                <Form.Group
                                    className="mt-3"
                                    controlId="formBasicCheckbox"
                                >
                                    <Form.Check
                                        type="radio"
                                        label="Percentage of Order Amount"
                                        name="type"
                                        value="percentage"
                                        className="pt-2"
                                        checked={addDetails.type === "percentage"}
                                        onChange={(e) => handleAddChange(e)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xs={4}>
                                <span className="edit-label">
                                    MM DISCOUNT SHARE
                                </span>
                            </Col>
                            <Col xs={4}>
                                <span className="edit-label">
                                    DELIVERY COMPANY
                                </span>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col xs={4}>
                                <Form.Control
                                    type="text"
                                    name="mm_discount_share"
                                    className="nc-modal-custom-text"
                                    value={addDetails.mm_discount_share}
                                    onChange={(e) => handleAddChange(e)} 
                                />
                            </Col>
                            <Col xs={4}>
                                <Form.Control
                                    type="text"
                                    name="delivery_company"
                                    className="nc-modal-custom-text"
                                    value={addDetails.delivery_company}
                                    onChange={(e) => handleAddChange(e)} 
                                />
                            </Col>
                        </Row>
                        <Row className="mt-4 mb-2">
                            <Col>
                                <span className="edit-label">
                                    Remarks
                                </span>
                            </Col>
                        </Row>
                        <Row className="mt-4 mb-2">
                            <Col>
                                <Form.Control
                                    type="text"
                                    name="remarks"
                                    className="nc-modal-custom-text"
                                    value={addDetails.remarks}
                                    onChange={(e) => handleAddChange(e)} 
                                />
                            </Col>
                        </Row>
                        <Row className="mt-4 mb-2">
                            <Col>
                                <span className="edit-label">
                                    Applicable Branches
                                </span>
                            </Col>
                        </Row>
                        <Row className="mt-4 mb-2">
                            <Col>
                                <Select
                                    name="applicable_branches"
                                    className="nc-modal-custom-text"
                                    options={branches}
                                    value={selectedBranches}
                                    onChange={setSelectedBranches}
                                    placeholder={`Select Branches`}
                                    isMulti={true}
                                    isSearchable={true}
                                />
                                 <InputError
                                    isValid={isError.branches}
                                    message={"branches is required"}
                                />
                            </Col>
                        </Row>
                        
                    </Fragment>

                    {/* FOOTER: CANCEL & SUBMIT BUTTONS */}
                    <div className="d-flex justify-content-end pt-5 pb-3">
                        <button
                            type="button"
                            className="button-secondary me-3"
                            onClick={() => navigate(-1)}
                        >
                            Cancel
                        </button>
                        {/* {isSaveClicked ? (
                            <div className="button-secondary d-flex justify-content-center">
                                <ReactLoading
                                    type="bubbles"
                                    color="#FFFFFF"
                                    height={50}
                                    width={50}
                                />
                            </div>
                        ) : (
                            <button
                                type="button"
                                className="button-primary me-3"
                                onClick={handleSave}
                            >
                                Save
                            </button>
                        )} */}
                        {isSubmitClicked ? (
                            <div className="button-primary d-flex justify-content-center">
                                <ReactLoading
                                    type="bubbles"
                                    color="#FFFFFF"
                                    height={50}
                                    width={50}
                                />
                            </div>
                        ) : (
                            <button
                                type="button"
                                className="button-primary"
                                onClick={handleSubmit}
                            >
                                Save
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormDiscounts