import {handleValidationChange} from '../CommonValidation';

export const validatePayroll = (data, setIsError) => {
    var isValid = true;

    if(data.date_from === "") {
        handleValidationChange("date_from", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("date_from", false, setIsError);
    }

    if(data.date_to === "") {
        handleValidationChange("date_to", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("date_to", false, setIsError);
    }

    if(data.disbursement_date === "") {
        handleValidationChange("disbursement_date", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("disbursement_date", false, setIsError);
    }

    if(data.employee_id === "") {
        handleValidationChange("employee_id", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("employee_id", false, setIsError);
    }


    // if(data.rate === "") {
    //     handleValidationChange("rate", true, setIsError);
    //     isValid=false;
    // } else {
    //     handleValidationChange("rate", false, setIsError);
    // }

    // if(data.basic_pay === "" || data.basic_pay === 0) {
    //     handleValidationChange("basic_pay", true, setIsError);
    //     isValid=false;
    // } else {
    //     handleValidationChange("basic_pay", false, setIsError);
    // }

    // if(data.total_working_hours === "") {
    //     handleValidationChange("total_working_hours", true, setIsError);
    //     isValid=false;
    // } else {
    //     handleValidationChange("total_working_hours", false, setIsError);
    // }

    return isValid
  
}

export const validatePayrollModal = (employee, from, to, setIsError) => {
    var isValid = true;

    if(employee.value === "") {
        handleValidationChange("employee_id", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("employee_id", false, setIsError);
    }

    if(from === "") {
        handleValidationChange("payroll_month_from", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("payroll_month_from", false, setIsError);
    }

    if(to === "") {
        handleValidationChange("payroll_month_to", true, setIsError);
        isValid=false;
    } else {
        handleValidationChange("payroll_month_to", false, setIsError);
    }

    return isValid
  
}

