import { Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { SyncLoader } from "react-spinners";
import NoDataPrompt from "../../../Components/NoDataPrompt/NoDataPrompt";
import "../../../Components/TableTemplate/Table.css";

export default function Table({
    tableHeaders,
    headerSelector,
    tableData,
    ActionBtn,
    ViewBtn,
    PendingBtn,
    showLoader,
    withActionData,
    onRowClicked,
    SaleBtn,
    DepositedBtn,
    InvVarianceBtn,
    CashVarianceBtn,
    TotalVarianceBtn,
    SaleBillingBtn,
    checkBtn,
    handleOnCheck,
    OngoingPOBadges,
    branchStatus,
    cashVariance,
}) {
    const columns = tableHeaders.map((header, index) => {
        if (header === "-") {
            return {
                name: "",
                selector: (row) => row[headerSelector[index]],
                button: true,
                cell: ViewBtn,
                width: "5vw",
                reorder: false,
                wordWrap: "break-word",
            };
        } else if (header === "MODE" || 
                    header === "REF/CHK" ||
                    header === "CHK DATE" ||
                    header === "DATE PAID" || 
                    header === "PAID AMT" ||
                    header === "CUR QTY" ||
                    header === "ENCODED ON" ||
                    header === "DPST DATE" ||
                    header === "SALES DATE" ||
                    header === "DATE"
                    ) {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                width: "8vw",
                reorder: true,
                sortable: true,
                // wrap: true,
            };
        } else if (header === "PO") {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                button: true,
                cell: OngoingPOBadges,
                reorder: false,
                wordWrap: "break-word",
            };
        } else if (header === ".") {
            return {
                name: "",
                selector: (row) => row[headerSelector[index]],
                button: true,
                cell: checkBtn,
                width: "3vw",
                reorder: false,
                wordWrap: "break-word",
            };
        } else if (header === "DEPOSITED") {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]].toLowerCase(),
                right: true,
                button: true,
                width: "8vw",
                cell: DepositedBtn,
                reorder: true,
            };
        } else if (header === "TOTAL VARIANCE") {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]].toLowerCase(),
                right: true,
                button: true,
                width: "8vw",
                cell: TotalVarianceBtn,
                reorder: true,
            };
        } else if (header === "CASH VARIANCE") {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]].toLowerCase(),
                right: true,
                button: true,
                width: "8vw",
                cell: CashVarianceBtn,
                reorder: true,
            };
        } else if (header === "INV VARIANCE") {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]].toLowerCase(),
                right: true,
                button: true,
                width: "8vw",
                cell: InvVarianceBtn,
                reorder: true,
            };
        } else if (header === "DEPOSIT AMOUNT" || header === "TOTAL DEPOSIT") {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                right: true,
                sortable: true,
                width: "11vw",
                reorder: true,
                wrap: true,
            };    
        } else if ((header === "MIN" || 
                header === "MAX" || 
                header.includes("QTY") || 
                header.includes("COUNT") ||
                header.includes("DIFFERENCE")) && header !== "COUNTED BY") {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                right: true,
                sortable: true,
                width: "8vw",
                reorder: true,
                wrap: true,
        };
        } else if (header.includes("SALES") || header.includes("CANCELLED")) {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                right: true,
                sortable: true,
                width: "8vw",
                reorder: true,
                wrap: true,
            };
        } else if (
            header.includes("TOTAL")||
            header.includes("AMOUNT")
            ) {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                right: true,
                sortable: true,
                width: "8vw",
                reorder: true,
                wrap: true,
            };
        }  else if (header.includes("UNIT")) {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                sortable: true,
                width: "6vw",
                reorder: true,
                wrap: true,
            };
        } else if (header === "ACTIONS" && !withActionData) {
            return {
                name: header,
                button: true,
                cell: ActionBtn,
                width: "7vw",
                reorder: false,
                center: true,
            };
        } else if (header === "remarks") {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                width: "12vw",
                reorder: false,
                wrap: true,
            };
        } else if (header === "ITEM") {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                sortable: true,
                width: "8vw",
                reorder: true,
                wrap: true,
            };    
        } else if (header === "CASHIER" || header === "PREPARED BY" || header === "BRANCH") {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                sortable: true,
                width: "9vw",
                reorder: true,
                wrap: true,
            };    
        } else if (header === "BRANCH") {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                sortable: true,
                width: "10vw",
                reorder: true,
                wrap: true,
            };    
        } else if (header === "BRANCH STATUS") {
        // } else if (header === "BRANCH STATUS" || header === "INVENTORY VARIANCE") {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]].toLowerCase(),
                left: true,
                button: true,
                width: "10vw",
                cell: branchStatus,
                reorder: true,
            };
        } 
        // else if (header === "CASH VARIANCE") {
        //     return {
        //         name: header,
        //         selector: (row) => row[headerSelector[index]].toLowerCase(),
        //         left: true,
        //         button: true,
        //         width: "10vw",
        //         cell: cashVariance,
        //         reorder: true,
        //     };
        // } 
        else {
            return {
                name: header,
                selector: (row) => row[headerSelector[index]],
                sortable: true,
                width: "12vw",
                reorder: true,
                wrap: true,
            };
        }
    });

    const paginationComponentOptions = {
        rowsPerPageText: "",
        noRowsPerPage: true,
    };

    const customStyles = {
        rows: {
            style: {
                minHeight: "5.2vh",
            },
        },
    };

    return showLoader ? (
        <div className="d-flex justify-content-center my-5">
            <SyncLoader color="#59c8e3" size={15} />
        </div>
    ) : (
        <DataTable
            pagination
            striped
            fixedHeader
            fixedHeaderScrollHeight="50vh"
            columns={columns}
            data={tableData}
            customStyles={customStyles}
            paginationComponentOptions={paginationComponentOptions}
            noDataComponent={<NoDataPrompt />}
            selectableRows
            onSelectedRowsChange={handleOnCheck}
        />
    );
}
