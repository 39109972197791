/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { refreshPage, removeUserSession } from "../../Helpers/Utils/Common";
import { getName, getType, getRole } from "../../Helpers/Utils/Common";
import MenuItem from "./MenuItem";
import { logoutUser } from "../../Helpers/apiCalls/authApi";
import { toastStyle } from "../../Helpers/Utils/Common";

import toast from "react-hot-toast";

//css
import "../Navbar/Navbar.css";

//icons
import logout from "../../Assets/Images/Navbar/logout.png";
import logo from "../../Assets/Images/Login/MYT_logo_small.png";
import po from "../../Assets/Images/Navbar/po.png";
import supplies from "../../Assets/Images/Navbar/supplies.png";
import sales from "../../Assets/Images/Navbar/sales.png";
import cp from "../../Assets/Images/Navbar/cp.png";
import reports from "../../Assets/Images/Navbar/reports.png";
import storeReport from "../../Assets/Images/Navbar/storeReport.png";
import dsReport from "../../Assets/Images/Navbar/dsReports.png";
import franchiseReport from "../../Assets/Images/Navbar/franchiseReport.png";
import inventory from "../../Assets/Images/Navbar/inventory.png";
import manage from "../../Assets/Images/Navbar/manage.png";
import employee from "../../Assets/Images/Navbar/employee.png";
import pettycashicon from "../../Assets/Images/Navbar/pettycash.png";
import transfer from "../../Assets/Images/Navbar/shipped.png";
import store from "../../Assets/Images/Navbar/store.png";

const Navbar = (props) => {
    //ADMIN
    const adminMenu = [
        {
            name: "DASHBOARD",
            exact: true,
            to: "/dashboard",
            iconClassName: cp,
            expand: true,
        },
        {
            name: "FRANCHISE",
            exact: true,
            to: "/",
            iconClassName: sales,
            subMenus: [
                { name: "Franchise Register", to: "/franchise" },
                { name: "Franchise Sales Invoice", to: "/salesinvoice" },
                { name: "Monthly Sales Billing", to: "/franchisebilling" },
                { name: "Franchisee Payments", to: "/payments" },
                { name: "Item Prices", to: "/itemprices" },
            ],
            expand: true,
        },
        {
            name: "PURCHASES",
            exact: true,
            to: "/",
            iconClassName: po,
            subMenus: [
                { name: "Purchase Order Register", to: "/purchaseorders" },
                { name: "Purchase Invoice Register", to: "/purchaseinvoices" },
                { name: "Pay Supplier", to: "/paysuppliers" },
            ],
            expand: true,
        },
        {
            name: "SUPPLIES",
            exact: true,
            to: "/",
            iconClassName: supplies,
            subMenus: [
                { name: "Supplies PO Register", to: "/suppliesexpenses" },
                // { name: "Purchase Order Register", to: "/se/purchaseorders" },
                {
                    name: "Supplies Invoice Register",
                    to: "/se/purchaseinvoices",
                },
                { name: "Pay Supplies Payables", to: "/se/paysuppliers" },
            ],
            expand: true,
        },
        {
            name: "WH INVENTORY",
            exact: true,
            to: "/",
            iconClassName: inventory,
            subMenus: [
                { name: "Items List", to: "/itemlists" },
                { name: "Low on Stock", to: "/lowonstock" },
                { name: "Items History", to: "/itemhistorysearch" },
                { name: "Office Adjustments", to: "/adjustments" },
                { name: "Build Item", to: "/buildItem" },
            ],
            expand: true,
        },
        {
            name: "TRANSFERS",
            exact: true,
            to: "/",
            iconClassName: transfer,
            subMenus: [
                { name: "Requests", to: "/requests" },
                { name: "Transfer Register", to: "/transfers" },
                { name: "Transfer History", to: "/transferhistory" },
            ],
            expand: true,
        },
        {
            name: "STORE INVENTORY",
            exact: true,
            to: "/",
            iconClassName: store,
            subMenus: [
                { name: "Branch Inventory", to: "/branchinventory" },
                { name: "Store Adjustments", to: "/storeadjustments" },
                { name: "Daily Usage", to: "/dailyusage" },
            ],
            expand: true,
        },
        {
            name: "DS REPORTS",
            exact: true,
            to: "/",
            iconClassName: dsReport,
            subMenus: [
                { name: "Daily Sales", to: "/dailysales" },
                { name: "Daily Orders", to: "/dailyorders" },
                { name: "Store Deposit", to: "/storedeposit" },
                { name: "Daily Cash Count", to: "/dailycashcount" },
                { name: "Daily Expenses", to: "/dailyexpenses" },
                { name: "Daily Wastage", to: "/dailywastage" },
                { name: "Wastage Reports", to: "/wastagereports" },
            ],
            expand: true,
        },
        {
            name: "STORE REPORTS",
            exact: true,
            to: "/",
            iconClassName: storeReport,
            subMenus: [
                { name: "List of Stores", to: "/listofstores" },
                { name: "Daily Attendance List", to: "/dailyattendancelist" },
                { name: "Grabfood Report", to: "/grabfoodreport" },
                { name: "Foodpanda Report", to: "/foodpandareport" },
                { name: "Sales Per Product Report", to: "/salesperproduct" },
                { name: "Store Discount Report", to: "/storediscountreport" },
            ],
            expand: true,
        },
        {
            name: "OFFICE REPORTS",
            exact: true,
            to: "/",
            iconClassName: reports,
            subMenus: [
                { name: "Branch Status", to: "/listofbranches" },
                { name: "Payables", to: "/payables" },
                { name: "Purchased Items", to: "/purchaseditems" },
                { name: "Production Report", to: "/productionreport" },
            ],
            expand: true,
        },
        {
            name: "FRANCHISE REPORTS",
            exact: true,
            to: "/",
            iconClassName: franchiseReport,
            subMenus: [
                // { name: "Franchisee Sales Report", to: "/franchiseesalesreport" },
                { name: "Receivables", to: "/receivables" },
                { name: "Monthly Sales", to: "/franchisebilling/reports" },
                { name: "Sales By Type", to: "/totalsales" },
                { name: "Sales by Item", to: "/salesbyitem" },
            ],
            expand: true,
        },
        {
            name: "MANAGE",
            exact: true,
            to: "/",
            iconClassName: manage,
            subMenus: [
                { name: "Banks", to: "/banks" },
                { name: "Branch Group", to: "/branchgroup" },
                { name: "Branches", to: "/branches" },
                { name: "Discounts", to: "/discounts" },
                { name: "DSR Items", to: "/dsritems" },
                { name: "Forwarders", to: "/forwarders" },
                { name: "Inventory Level", to: "/inventorylevel" },
                { name: "Items", to: "/items" },
                { name: "Item Prices", to: "/itemprices" },
                { name: "Price Levels", to: "/pricelevels" },
                { name: "Products", to: "/products" },
                { name: "Suppliers", to: "/suppliers" },
                { name: "Users", to: "/users" },
                { name: "Vendor", to: "/vendors" },
                { name: "Warehouses", to: "/warehouses" },
            ],
            expand: true,
        },
        {
            name: "EMPLOYEES",
            exact: true,
            to: "/",
            iconClassName: employee,
            subMenus: [
                // { name: "Cash Advance", to: "/cashadvance" },
                { name: "Employees", to: "/employees" },
                { name: "Employee Deductions", to: "/deductions/0/all/all" },
                // { name: "Payroll", to: "/payroll" },
                { name: "Total Working Hours", to: "/employeehours" },
            ],
            expand: true,
        },
        {
            name: "PETTY CASH",
            exact: true,
            to: "/",
            iconClassName: pettycashicon,
            subMenus: [
                { name: "Petty Cash Register", to: "/pettycash" },
            ],
            expand: true,
        },
    ];

    // SUPERVISOR
    const supervisorMenu = [
        // {
        //     name: "DASHBOARD",
        //     exact: true,
        //     to: "/dashboard",
        //     iconClassName: cp,
        //     expand: true,
        // },
        {
            name: "WH INVENTORY",
            exact: true,
            to: "/",
            iconClassName: inventory,
            subMenus: [
                { name: "Items History", to: "/itemhistorysearch" },
            ],
            expand: true,
        },
        {
            name: "STORE INVENTORY",
            exact: true,
            to: "/",
            iconClassName: store,
            subMenus: [
                { name: "Branch Inventory", to: "/branchinventory" },
                { name: "Store Adjustments", to: "/storeadjustments" },
                // { name: "Daily Usage", to: "/dailyusage" },
            ],
            expand: true,
        },
        {
            name: "DS REPORTS",
            exact: true,
            to: "/",
            iconClassName: dsReport,
            subMenus: [
                { name: "Daily Sales", to: "/dailysales" },
                { name: "Daily Orders", to: "/dailyorders" },
                { name: "Store Deposit", to: "/storedeposit" },
                { name: "Daily Cash Count", to: "/dailycashcount" },
                { name: "Daily Expenses", to: "/dailyexpenses" },
                { name: "Daily Wastage", to: "/dailywastage" },
            ],
            expand: true,
        },
        {
            name: "STORE REPORTS",
            exact: true,
            to: "/",
            iconClassName: storeReport,
            subMenus: [
                { name: "List of Stores", to: "/listofstores" },
                { name: "Daily Attendance List", to: "/dailyattendancelist" },
                // { name: "Daily Usage", to: "/dailyusage" },
                // { name: "Grabfood Report", to: "/grabfoodreport" },
                // { name: "Foodpanda Report", to: "/foodpandareport" },
                // { name: "Wastage Reports", to: "/wastagereports" },
            ],
            expand: true,
        },
        {
            name: "MANAGE",
            exact: true,
            to: "/",
            iconClassName: manage,
            subMenus: [
                { name: "Branch Group", to: "/branchgroup" },
            ],
            expand: true,
        },
    ];

    // COMMISSARY OFFICER
    const commissaryOfficerMenu = [
        // {
        //     name: "INVENTORY",
        //     exact: true,
        //     to: "/",
        //     iconClassName: inventory,
        //     subMenus: [
        //         { name: "Items List", to: "/itemlists" },
        //         { name: "Low on Stock", to: "/lowonstock" },
        //         { name: "Transfers", to: "/transfers" },
        //         // { name: "Incoming Transfers", to: "/incomingtransfers" },
        //         { name: "Requests", to: "/requests" },
        //         { name: "Office Adjustments", to: "/adjustments" },
        //         { name: "Build Item", to: "/buildItem" },
        //     ],
        //     expand: true,
        // },
        {
            name: "WH INVENTORY",
            exact: true,
            to: "/",
            iconClassName: inventory,
            subMenus: [
                { name: "Items List", to: "/itemlists" },
                { name: "Low on Stock", to: "/lowonstock" },
                // { name: "Items History", to: "/itemhistorysearch" },
                { name: "Office Adjustments", to: "/adjustments" },
                { name: "Build Item", to: "/buildItem" },
            ],
            expand: true,
        },
        {
            name: "TRANSFERS",
            exact: true,
            to: "/",
            iconClassName: transfer,
            subMenus: [
                { name: "Requests", to: "/requests" },
                { name: "Transfer Register", to: "/transfers" },
            ],
            expand: true,
        },
        {
            name: "STORE INVENTORY",
            exact: true,
            to: "/",
            iconClassName: store,
            subMenus: [
                { name: "Store Adjustments", to: "/storeadjustments" },
            ],
            expand: true,
        },
    ];

    // INVENTORY OFFICER
    const inventoryOfficerMenu = [
        // {
        //     name: "DASHBOARD",
        //     exact: true,
        //     to: "/dashboard",
        //     iconClassName: cp,
        //     expand: true,
        // },
        {
            name: "PURCHASES",
            exact: true,
            to: "/",
            iconClassName: po,
            subMenus: [
                { name: "Purchase Order Register", to: "/purchaseorders" },
                { name: "Purchase Invoice Register", to: "/purchaseinvoices" },
                { name: "Pay Supplier", to: "/paysuppliers" },
            ],
            expand: true,
        },
        {
            name: "SUPPLIES",
            exact: true,
            to: "/",
            iconClassName: supplies,
            subMenus: [
                { name: "Purchase Order Register", to: "/suppliesexpenses" },
                // { name: "Purchase Order Register", to: "/se/purchaseorders" },
                {
                    name: "Purchase Invoice Register",
                    to: "/se/purchaseinvoices",
                },
                { name: "Pay Supplier", to: "/se/paysuppliers" },
            ],
            expand: true,
        },
        // {
        //     name: "INVENTORY",
        //     exact: true,
        //     to: "/",
        //     iconClassName: inventory,
        //     subMenus: [
        //         { name: "Items List", to: "/itemlists" },
        //         { name: "Low on Stock", to: "/lowonstock" },
        //         { name: "Transfers", to: "/transfers" },
        //         // { name: "Incoming Transfers", to: "/incomingtransfers" },
        //         { name: "Requests", to: "/requests" },
        //         { name: "Branch Inventory", to: "/branchinventory" },
        //         { name: "Office Adjustments", to: "/adjustments" },
        //         { name: "Build Item", to: "/buildItem" },
        //     ],
        //     expand: true,
        // },
        {
            name: "STORE INVENTORY",
            exact: true,
            to: "/",
            iconClassName: store,
            subMenus: [
                { name: "Branch Inventory", to: "/branchinventory" },
                { name: "Store Adjustments", to: "/storeadjustments" },
            ],
            expand: true,
        },
        {
            name: "WH INVENTORY",
            exact: true,
            to: "/",
            iconClassName: inventory,
            subMenus: [
                { name: "Items List", to: "/itemlists" },
                { name: "Low on Stock", to: "/lowonstock" },
                // { name: "Items History", to: "/itemhistorysearch" },
                { name: "Office Adjustments", to: "/adjustments" },
                { name: "Build Item", to: "/buildItem" },
            ],
            expand: true,
        },
        {
            name: "TRANSFERS",
            exact: true,
            to: "/",
            iconClassName: transfer,
            subMenus: [
                { name: "Requests", to: "/requests" },
                { name: "Transfer Register", to: "/transfers" },
            ],
            expand: true,
        },
    ];

    // PURCHASING OFFICER
    const purchasingOfficerMenu = [
        {
            name: "PURCHASES",
            exact: true,
            to: "/",
            iconClassName: po,
            subMenus: [
                { name: "Purchase Order Register", to: "/purchaseorders" },
                { name: "Purchase Invoice Register", to: "/purchaseinvoices" },
                { name: "Pay Supplier", to: "/paysuppliers" },
            ],
            expand: true,
        },
        {
            name: "SUPPLIES",
            exact: true,
            to: "/",
            iconClassName: supplies,
            subMenus: [
                { name: "Supplies PO Register", to: "/suppliesexpenses" },
                {
                    name: "Supplies Invoice Register",
                    to: "/se/purchaseinvoices",
                },
                { name: "Pay Supplies Payables", to: "/se/paysuppliers" },
            ],
            expand: true,
        },
        {
            name: "WH INVENTORY",
            exact: true,
            to: "/",
            iconClassName: inventory,
            subMenus: [
                { name: "Items List", to: "/itemlists" },
                { name: "Low on Stock", to: "/lowonstock" },
                { name: "Office Adjustments", to: "/adjustments" },
                { name: "Build Item", to: "/buildItem" },
            ],
            expand: true,
        },
        {
            name: "TRANSFERS",
            exact: true,
            to: "/",
            iconClassName: transfer,
            subMenus: [
                { name: "Requests", to: "/requests" },
                { name: "Transfer Register", to: "/transfers" },
            ],
            expand: true,
        },
        {
            name: "DS REPORTS",
            exact: true,
            to: "/",
            iconClassName: dsReport,
            subMenus: [
                { name: "Daily Orders", to: "/dailyorders" },
                { name: "Daily Sales", to: "/dailysales" },
                { name: "Store Deposit", to: "/storedeposit" },
            ],
            expand: true,
        },
        {
            name: "STORE INVENTORY",
            exact: true,
            to: "/",
            iconClassName: store,
            subMenus: [
                { name: "Branch Inventory", to: "/branchinventory" },
                { name: "Store Adjustments", to: "/storeadjustments" },
            ],
            expand: true,
        },
        {
            name: "MANAGE",
            exact: true,
            to: "/",
            iconClassName: manage,
            subMenus: [
                { name: "Banks", to: "/banks" },
                { name: "Branches", to: "/branches" },
                { name: "Branch Group", to: "/branchgroup" },
                { name: "Discounts", to: "/discounts" },
                { name: "Forwarders", to: "/forwarders" },
                { name: "Inventory Level", to: "/inventorylevel" },
                { name: "Items", to: "/items" },
                { name: "Products", to: "/products" },
                { name: "Price Levels", to: "/pricelevels" },
                { name: "Suppliers", to: "/suppliers" },
                { name: "Users", to: "/users" },
                { name: "Vendor", to: "/vendors" },
                { name: "Warehouses", to: "/warehouses" },

            ],
            expand: true,
        },
        {
            name: "OFFICE REPORTS",
            exact: true,
            to: "/",
            iconClassName: reports,
            subMenus: [
                { name: "Payables", to: "/payables" },
            ],
            expand: true,
        },
        {
            name: "PETTY CASH",
            exact: true,
            to: "/",
            iconClassName: pettycashicon,
            subMenus: [
                { name: "Petty Cash Register", to: "/pettycash" },
            ],
            expand: true,
        },
    ];

    // PURCHASING STAFF
    const purchasingStaffMenu = [
        {
            name: "PURCHASES",
            exact: true,
            to: "/",
            iconClassName: po,
            subMenus: [
                { name: "Purchase Order Register", to: "/purchaseorders" },
                { name: "Purchase Invoice Register", to: "/purchaseinvoices" },
                { name: "Pay Supplier", to: "/paysuppliers" },
            ],
            expand: true,
        },
        // {
        //     name: "INVENTORY",
        //     exact: true,
        //     to: "/",
        //     iconClassName: inventory,
        //     subMenus: [
        //         { name: "Items List", to: "/itemlists" },
        //         { name: "Low on Stock", to: "/lowonstock" },
        //     ],
        //     expand: true,
        // },
        {
            name: "WH INVENTORY",
            exact: true,
            to: "/",
            iconClassName: inventory,
            subMenus: [
                { name: "Items List", to: "/itemlists" },
                { name: "Low on Stock", to: "/lowonstock" },
            ],
            expand: true,
        },
        {
            name: "SUPPLIES",
            exact: true,
            to: "/",
            iconClassName: supplies,
            subMenus: [
                { name: "Supplies PO Register", to: "/suppliesexpenses" },
                {
                    name: "Supplies Invoice Register",
                    to: "/se/purchaseinvoices",
                },
                { name: "Pay Supplies Payables", to: "/se/paysuppliers" },
            ],
            expand: true,
        },
        {
            name: "DS REPORTS",
            exact: true,
            to: "/",
            iconClassName: dsReport,
            subMenus: [
                { name: "Daily Orders", to: "/dailyorders" },
                { name: "Daily Sales", to: "/dailysales" },
                { name: "Store Deposit", to: "/storedeposit" },
            ],
            expand: true,
        },
        {
            name: "STORE REPORTS",
            exact: true,
            to: "/",
            iconClassName: storeReport,
            subMenus: [
                { name: "Grabfood Report", to: "/grabfoodreport" },
                { name: "Foodpanda Report", to: "/foodpandareport" },
            ],
            expand: true,
        },
    ];

    // FRANCHISE OFFICER
    const franchiseOfficerMenu = [
        {
            name: "FRANCHISE",
            exact: true,
            to: "/",
            iconClassName: sales,
            subMenus: [
                { name: "Franchise Register", to: "/franchise" },
                { name: "Franchise Sales Invoice", to: "/salesinvoice" },
                { name: "Monthly Sales Billing", to: "/franchisebilling" },
                { name: "Franchisee Payments", to: "/payments" },
                { name: "Item Prices", to: "/itemprices" },
            ],
            expand: true,
        },
        // {
        //     name: "INVENTORY",
        //     exact: true,
        //     to: "/",
        //     iconClassName: inventory,
        //     subMenus: [
        //         { name: "Items List", to: "/itemlists" },
        //         { name: "Low on Stock", to: "/lowonstock" },
        //     ],
        //     expand: true,
        // },
        {
            name: "WH INVENTORY",
            exact: true,
            to: "/",
            iconClassName: inventory,
            subMenus: [
                { name: "Items List", to: "/itemlists" },
                { name: "Low on Stock", to: "/lowonstock" },
            ],
            expand: true,
        },
        {
            name: "MANAGE",
            exact: true,
            to: "/",
            iconClassName: manage,
            subMenus: [
                { name: "Branches", to: "/branches" },
                { name: "Branch Group", to: "/branchgroup" },
                { name: "Discounts", to: "/discounts" },
                { name: "Inventory Level", to: "/inventorylevel" },
                { name: "Item Prices", to: "/itemprices" },
                { name: "Warehouses", to: "/warehouses" },
            ],
            expand: true,
        },
    ];

    // HR OFFICER
    const hrOfficerMenu = [
          {
            name: "DASHBOARD",
            exact: true,
            to: "/dashboard",
            iconClassName: cp,
            expand: true,
        },
        {
            name: "PURCHASES",
            exact: true,
            to: "/",
            iconClassName: po,
            subMenus: [
                { name: "Purchase Order Register", to: "/purchaseorders" },
                { name: "Purchase Invoice Register", to: "/purchaseinvoices" },
                { name: "Pay Supplier", to: "/paysuppliers" },
            ],
            expand: true,
        },
        {
            name: "SUPPLIES",
            exact: true,
            to: "/",
            iconClassName: supplies,
            subMenus: [
                { name: "Supplies PO Register", to: "/suppliesexpenses" },
                {
                    name: "Supplies Invoice Register",
                    to: "/se/purchaseinvoices",
                },
                { name: "Pay Supplies Payables", to: "/se/paysuppliers" },
            ],
            expand: true,
        },
        // {
        //     name: "WH INVENTORY",
        //     exact: true,
        //     to: "/",
        //     iconClassName: inventory,
        //     subMenus: [
        //         { name: "Items List", to: "/itemlists" },
        //         { name: "Low on Stock", to: "/lowonstock" },
        //         { name: "Office Adjustments", to: "/adjustments" },
        //         { name: "Build Item", to: "/buildItem" },
        //     ],
        //     expand: true,
        // },
        // {
        //     name: "TRANSFERS",
        //     exact: true,
        //     to: "/",
        //     iconClassName: transfer,
        //     subMenus: [
        //         { name: "Requests", to: "/requests" },
        //         { name: "Transfer Register", to: "/transfers" },
        //     ],
        //     expand: true,
        // },
        // {
        //     name: "STORE INVENTORY",
        //     exact: true,
        //     to: "/",
        //     iconClassName: store,
        //     subMenus: [
        //         { name: "Branch Inventory", to: "/branchinventory" },
        //         { name: "Store Adjustments", to: "/storeadjustments" },
        //     ],
        //     expand: true,
        // },
        {
            name: "MANAGE",
            exact: true,
            to: "/",
            iconClassName: manage,
            subMenus: [
                // { name: "Banks", to: "/banks" },
                { name: "Branches", to: "/branches" },
                // { name: "Branch Group", to: "/branchgroup" },
                // { name: "Discounts", to: "/discounts" },
                // { name: "Forwarders", to: "/forwarders" },
                // { name: "Inventory Level", to: "/inventorylevel" },
                // { name: "Items", to: "/items" },
                // { name: "Products", to: "/products" },
                // { name: "Price Levels", to: "/pricelevels" },
                // { name: "Suppliers", to: "/suppliers" },
                // { name: "Users", to: "/users" },
                // { name: "Vendor", to: "/vendors" },
                // { name: "Warehouses", to: "/warehouses" },

            ],
            expand: true,
        },
        {
            name: "EMPLOYEES",
            exact: true,
            to: "/",
            iconClassName: employee,
            subMenus: [
                // { name: "Cash Advance", to: "/cashadvance" },
                { name: "Employees", to: "/employees" },
                { name: "Employee Deductions", to: "/deductions/0/all/all" },
                // { name: "Payroll", to: "/payroll" },
                { name: "Total Working Hours", to: "/employeehours" },
            ],
            expand: true,
        },
        {
            name: "STORE REPORTS",
            exact: true,
            to: "/",
            iconClassName: storeReport,
            subMenus: [
                { name: "Daily Attendance List", to: "/dailyattendancelist" },
            ],
            expand: true,
        },
        // {
        //     name: "OFFICE REPORTS",
        //     exact: true,
        //     to: "/",
        //     iconClassName: reports,
        //     subMenus: [
        //         { name: "Payables", to: "/payables" },
        //     ],
        //     expand: true,
        // },
        // {
        //     name: "PETTY CASH",
        //     exact: true,
        //     to: "/",
        //     iconClassName: pettycashicon,
        //     subMenus: [
        //         { name: "Petty Cash Register", to: "/pettycash" },
        //     ],
        //     expand: true,
        // },
    ];

    // ACCOUNTS OFFICER
    const accountsOfficerMenu = [
        {
            name: "DS REPORTS",
            exact: true,
            to: "/",
            iconClassName: dsReport,
            subMenus: [
                { name: "Daily Sales", to: "/dailysales" },
                { name: "Daily Orders", to: "/dailyorders" },
                { name: "Daily Cash Count", to: "/dailycashcount" },
                { name: "Daily Expenses", to: "/dailyexpenses" },
                { name: "Daily Wastage", to: "/dailywastage" },
            ],
            expand: true,
        },
    ];

    // OPERATIONS MANAGER
    const operationsManagerMenu = [
        {
            name: "DS REPORTS",
            exact: true,
            to: "/",
            iconClassName: dsReport,
            subMenus: [
                { name: "Daily Sales", to: "/dailysales" },
                { name: "Daily Orders", to: "/dailyorders" },
                { name: "Store Deposit", to: "/storedeposit" },
                { name: "Daily Cash Count", to: "/dailycashcount" },
                { name: "Daily Expenses", to: "/dailyexpenses" },
                { name: "Daily Wastage", to: "/dailywastage" },
            ],
            expand: true,
        },
        {
            name: "STORE REPORTS",
            exact: true,
            to: "/",
            iconClassName: storeReport,
            subMenus: [
                { name: "Daily Attendance List", to: "/dailyattendancelist" },
            ],
            expand: true,
        },
        {
            name: "INVENTORY",
            exact: true,
            to: "/",
            iconClassName: inventory,
            subMenus: [
                // { name: "Low on Stock", to: "/lowonstock" },
                { name: "Branch Inventory", to: "/branchinventory" },
            ],
            expand: true,
        },
        {
            name: "WH INVENTORY",
            exact: true,
            to: "/",
            iconClassName: inventory,
            subMenus: [
                { name: "Low on Stock", to: "/lowonstock" },
            ],
            expand: true,
        },
    ];

    // FIELD OFFICER 1
    const fieldOfficer1Menu = [
        {
            name: "DS REPORTS",
            exact: true,
            to: "/",
            iconClassName: dsReport,
            subMenus: [
                { name: "Daily Sales", to: "/dailysales" },
                { name: "Daily Orders", to: "/dailyorders" },
                { name: "Store Deposit", to: "/storedeposit" },
                { name: "Daily Cash Count", to: "/dailycashcount" },
                { name: "Daily Expenses", to: "/dailyexpenses" },
                { name: "Daily Wastage", to: "/dailywastage" },
            ],
            expand: true,
        },
        {
            name: "STORE REPORTS",
            exact: true,
            to: "/",
            iconClassName: storeReport,
            subMenus: [
                { name: "Daily Attendance List", to: "/dailyattendancelist" },
            ],
            expand: true,
        },
        {
            name: "STORE INVENTORY",
            exact: true,
            to: "/",
            iconClassName: store,
            subMenus: [
                { name: "Branch Inventory", to: "/branchinventory" },
            ],
            expand: true,
        },
        {
            name: "WH INVENTORY",
            exact: true,
            to: "/",
            iconClassName: inventory,
            subMenus: [
                { name: "Low on Stock", to: "/lowonstock" },
            ],
            expand: true,
        },
    ];

    // FIELD OFFICER 2
    const fieldOfficer2Menu = [
        {
            name: "DS REPORTS",
            exact: true,
            to: "/",
            iconClassName: dsReport,
            subMenus: [
                { name: "Daily Sales", to: "/dailysales" },
                { name: "Daily Orders", to: "/dailyorders" },
                { name: "Daily Cash Count", to: "/dailycashcount" },
                { name: "Daily Expenses", to: "/dailyexpenses" },
                { name: "Daily Wastage", to: "/dailywastage" },
            ],
            expand: true,
        },
        {
            name: "STORE INVENTORY",
            exact: true,
            to: "/",
            iconClassName: store,
            subMenus: [
                { name: "Branch Inventory", to: "/branchinventory" },
            ],
            expand: true,
        },
        {
            name: "WH INVENTORY",
            exact: true,
            to: "/",
            iconClassName: inventory,
            subMenus: [
                { name: "Low on Stock", to: "/lowonstock" },
            ],
            expand: true,
        },
    ];

    // COMMISSARY OFFICER
    const officeStaffMenu = [
        
        {
            name: "PETTY CASH",
            exact: true,
            to: "/",
            iconClassName: pettycashicon,
            subMenus: [
                { name: "Petty Cash Register", to: "/pettycash" },
            ],
            expand: true,
        },
    ];

    // logout handler
    async function handleLogout() {
        const response = await logoutUser();
        removeUserSession();
        window.localStorage.clear();
        toast.success("Logging you out...", {
            style: toastStyle(),
        });
        setTimeout(() => refreshPage(), 1000);
    }

    const [inactive, setInactive] = useState(true);
    const [menuItems, setMenuItems] = useState([]);
    const [expandManage, setExpandManage] = useState(true);

    const handleExpand = (index) => {
        var list = [...menuItems];

        if (!inactive) {
            for (var i = 0; i < list.length; i++) {
                if (i !== index) {
                    list[i]["expand"] = true;
                }
            }

            list[index]["expand"] = !list[index]["expand"];

            setMenuItems(list);
        }
    };

    useEffect(() => {
        if (inactive) {
            removeActiveClassFromSubMenu();
        }

        props.onCollapse(inactive);
    }, [inactive]);

    const removeActiveClassFromSubMenu = () => {
        document.querySelectorAll(".sub-menu").forEach((el) => {
            el.classList.remove("active");
        });
    };

    useEffect(() => {
        const type = getType();

        switch (type) {
            case "admin":
                setMenuItems(adminMenu);
                break;
            case "supervisor":
                setMenuItems(supervisorMenu);
                break;
            case "commissary_officer":
                setMenuItems(commissaryOfficerMenu);
                break;
            case "inventory_officer":
                setMenuItems(inventoryOfficerMenu);
                break;
            case "purchasing_officer":
                setMenuItems(purchasingOfficerMenu);
                break;
            case "purchasing_staff":
                setMenuItems(purchasingStaffMenu);
                break;
            case "franchise_officer":
                setMenuItems(franchiseOfficerMenu);
                break;
            case "hr_officer":
                setMenuItems(hrOfficerMenu);
                break;
            case "accounts_officer":
                setMenuItems(accountsOfficerMenu);
                break;
            case "operations_manager":
                setMenuItems(commissaryOfficerMenu);
                break;
            case "fielder_officer_1":
                setMenuItems(fieldOfficer1Menu);
                break;
            case "fielder_officer_2":
                setMenuItems(fieldOfficer2Menu);
                break;
            case "office_staff":
                setMenuItems(officeStaffMenu);
                break;
                
            default:
                break;
        }

        let menuItemsList = document.querySelectorAll(".menu-item");
        menuItemsList.forEach((el, index) => {
            if (menuItems[index].name == props.active) {
                el.classList.add("active");
            }
            el.addEventListener("click", (e) => {
                const next = el.nextElementSibling;
                removeActiveClassFromSubMenu();
                menuItemsList.forEach((el) => el.classList.remove("active"));
                el.classList.toggle("active");
                if (next !== null) {
                    next.classList.toggle("active");
                }
            });
        });
    }, []);

    return (
        <div className={`side-menu ${inactive ? "inactive" : ""}`}>
            <div className="top-section">
                <div className="logo d-flex justify-content-center">
                    <img
                        src={logo}
                        className="navbar-logo"
                        onClick={() => setInactive(!inactive)}
                    />
                </div>
                <div
                    onClick={() => setInactive(!inactive)}
                    className="toggle-menu-btn"
                >
                    {inactive ? (
                        <div className="max-menu-cont">
                            <FontAwesomeIcon
                                icon={"angle-double-right"}
                                alt={"open"}
                                className={"max-menu"}
                                aria-hidden="true"
                            />
                        </div>
                    ) : (
                        <FontAwesomeIcon
                            icon={"angle-double-left"}
                            alt={"close"}
                            className={"min-menu"}
                            aria-hidden="true"
                        />
                    )}
                </div>
            </div>

            <div className={inactive ? "main-menu" : "main-menu active-menu"}>
                {menuItems.map((menuItem, index) => (
                    <MenuItem
                        key={index}
                        name={menuItem.name}
                        exact={menuItem.exact.toString()}
                        to={menuItem.to}
                        subMenus={menuItem.subMenus || []}
                        iconClassName={menuItem.iconClassName}
                        expandManage={menuItem.expand}
                        setExpandManage={handleExpand}
                        index={index}
                        activeSub={menuItem.name === props.active}
                        onClick={(e) => {
                            if (inactive) {
                                setInactive(false);
                            }
                        }}
                    />
                ))}
            </div>
            <div className="side-menu-footer">
                {!inactive && (
                    <div className="user-details-footer">
                        {/* <div className="account-label">Account</div> */}
                        <span className="navbar-user-label">{getName()}</span>
                        <br />
                        <span className="user-type-label">{getType()}</span>
                    </div>
                )}
                <div className="logout-cont" onClick={() => handleLogout()}>
                    <img src={logout} className="logout-btn" />
                    <span className="logout-label">LOGOUT</span>
                </div>
            </div>
        </div>
    );
};

export default Navbar;
