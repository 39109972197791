import React, { useState } from "react";
import { Col, Form, Row, Tab, Tabs, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import Delete from "../../../Components/Modals/DeleteModal";
import Navbar from "../../../Components/Navbar/Navbar";
import Table from "../../../Components/TableTemplate/Table";
import AdjustmentTable from "./../../Inventory/Adjustment/AdjustmentTable";
import NoDataImg from "../../../Assets/Images/no-data-img.png"
import {
    dateFormat,
    formatDateNoTime,
    formatDate,
    numberFormat,
    refreshPage,
    toastStyle,
    getTodayDate,
    getType,
    TokenExpiry,
    getTodayDateISO
} from "../../../Helpers/Utils/Common"; 
import DatePicker from "react-datepicker";
import { Fragment } from "react";
import Moment from "moment";
import InputError from "../../../Components/InputError/InputError";
import { validatePayrollModal } from './../../../Helpers/Validation/Employees/PayrollValidation';
import { CSVLink, CSVDownload } from "react-csv";
import downloadIcon from "../../../Assets/Images/download_icon.png";

import { getAllBanks } from "../../../Helpers/apiCalls/banksAPi";
import { getAllEmployees } from "../../../Helpers/apiCalls/employeesApi";
import { getAllPayroll, deletePayroll } from './../../../Helpers/apiCalls/Employees/PayrollApi';

export default function Payroll() {
    let navigate = useNavigate();
    var dateToday = getTodayDate();
    const accountType = getType();
    const [inactive, setInactive] = useState(true);
    const [filterConfig, setFilterConfig] = useState({
        employee_id: "",
        employee_name: "",
        date_from: "",
        date_to: "",
    })
    const [isError, setIsError] = useState({
        employee_id: false,
        payroll_month_from: false,
        payroll_month_to: false,
    });
    // const [branches, setBranches] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [allData, setAllData] = useState([]);
    const [totalNet, setTotalNet] = useState("0");
    const [checkedPayments, setCheckedPayments] = useState([]);
    const [selectedTab, setSelectedTab] = useState("submitted");
    const [dailySales, setDailySales] = useState([]);

     /* delete modal handler */
     const [showDeleteModal, setShowDeleteModal] = useState(false);
     const handleShowDeleteModal = () => setShowDeleteModal(true);
     const handleCloseDeleteModal = () => setShowDeleteModal(false); 
     /* add modal handler */
     const [showAddModal, setShowAddModal] = useState(false);
     const handleShowAddModal = () => setShowAddModal(true);
     const handleCloseAddModal = () => setShowAddModal(false); 

     const [selectedRow, setSelectedRow] = useState([]);
     const [selectedEmployee, setSelectedEmployee] = useState({
        label: "",
        value: "",
     });
     const [selectedMonthFrom, setSelectedMonthFrom] = useState("");
     const [selectedMonthTo, setSelectedMonthTo] = useState("");
     const today = Moment().format("MM/DD/YYYY");

     console.log(selectedEmployee)
     console.log(selectedMonthFrom)
     console.log(selectedMonthTo)
    
    const excelHeaders = [
        { label: "Payroll Range", key: "payroll_range" },
        // { label: "Payroll Date", key: "payroll_date" },
        { label: "Disbursement Date", key: "disbursement_date" },
        { label: "Type", key: "disbursement_type" },
        { label: "Employee Name", key: "employee_name" },
        { label: "Amount", key: "grand_total" },
    ];

    const dummy = [
        {
            id: "1",
            branch_name: "SM CITY CEBU",
            date: "December 2022",
            amount: "1000",
            added_by: "JOSE RIZAL",
        },
        {
            id: "2",
            branch_name: "SM CONSOLACION",
            date: "December 2022",
            amount: "1000",
            added_by: "JOSE RIZAL",
        },
    ]

    // SEARCH USER
    function handleOnSearch(e) {
        const { name, value } = e.target;
        setFilterConfig((prev) => {
            return { ...prev, [name]: value };
        });
    }

    function handleAddModal(e) {
        if(validatePayrollModal(selectedEmployee, selectedMonthFrom, selectedMonthTo, setIsError)) {
            navigate(
                `/payroll/add/${selectedEmployee.value}`, {
                    state: { selectedEmployee: selectedEmployee, date_from: selectedMonthFrom, date_to: selectedMonthTo  },
                }
            )
        }
    }

    function handleSelectChange(e, row) {
        setSelectedRow(row);
        console.log(row)

        if (e.target.value === "view") {
            navigate("/payroll/view/" + row.id, {
                state: { date_from: row.date_from, date_to: row.date_to  },
            })
        } else if (e.target.value === "edit") {
            navigate("/payroll/edit/" + row.id, {
                state: { date_from: row.date_from, date_to: row.date_to  },
            })
        } else if (e.target.value === "delete") {
            handleShowDeleteModal();
        }
    }


    function ActionBtn(row, type) {
        return (
            <Form.Select
                name="action"
                className="PO-select-action"
                onChange={(e) => handleSelectChange(e, row)}
            >
                <option value="" hidden selected>
                    Select
                </option>
                <option value="view" className="color-options">
                    View
                </option>
                {/* <option value="edit" className="color-options">
                    Edit
                </option> */}
                <option value="delete" className="color-red">
                    Delete
                </option>
            </Form.Select>
        );
    }

    function ViewBtn(row) {
        return (
            <button
                name="action"
                className="btn btn-sm view-btn-table"
                id={row.id}
                onClick={() => navigate("/payroll/view/" + row.id)}
            >
                View
            </button>
        );
    }

    async function fetchEmployees() {

        const response = await getAllEmployees();
        if (response.error) {
            setEmployees([])
        } else {
            let result = response.data.data.map((data) => {
                var employee = data;
                employee.label = data.first_name + " " + data.last_name;
                employee.value = data.id;
                return employee;
            });
            setEmployees([{label: "Select Employee", value: ""}, ...result]);
        }
    }

    async function fetchData() {
        setShowLoader(true);
        setAllData([])
        setTotalNet("0")

        const response = await getAllPayroll(filterConfig);

        if (response.error) {
            setAllData([])
    } else {
            var allBills = response.data.data.map((bill) => {
                var info = bill;
                info.payroll_range = bill.date_from + " to " + bill.date_to;
                // info.deposited_on = Moment(bill.deposited_on).format("MM-DD-YYYY");
                info.grand_total = numberFormat(bill.grand_total);
                return info;
            });
            setAllData(allBills)

            var total = response.data.summary? response.data.summary.total_net : "0";
            setTotalNet(total);

        }
        setShowLoader(false);
    }

    async function handleDeleteExpense() {
        const response = await deletePayroll(selectedRow.id);
        if (response.data) {
            toast.success("Payroll Deleted Successfully!", {
                style: toastStyle(),
            });
            setTimeout(() => refreshPage(), 1000);
        } else {
            toast.error("Error Deleting Payroll", {
                style: toastStyle(),
            });
        }
    }

    React.useEffect(() => {
        fetchEmployees();
    }, []);

    React.useEffect(() => {
        fetchData();
    }, [filterConfig]);

    return (
        <div>
            <div className="page">
                <Navbar
                    onCollapse={(inactive) => {
                        setInactive(inactive);
                    }}
                    active={"EMPLOYEES"}
                />
            </div>

            <div
                className={`manager-container ${
                    inactive ? "inactive" : "active"
                }`}
            >
                {/* headers */}
                <Row className="mb-3">
                    <Col xs={6}>
                        <h1 className="page-title"> PAYROLL HISTORY </h1>
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <input
                            type="text"
                            name="employee_name"
                            placeholder="Search employee name"
                            className="search-bar"
                            onChange={handleOnSearch}
                        ></input>
                        <button
                            className="add-btn"
                            onClick={(e) => handleShowAddModal()}
                        >
                            Add
                        </button>
                    </Col>
                </Row>

                <Row>
                    <Col className="d-flex justify-content-end mb-4">
                        <div className="justify-content-center align-items-center ">
                            <CSVLink
                                className="button-primary px-3 py-3 justify-content-center align-items-center download-csv"
                                data={allData}
                                headers={excelHeaders}
                                target="_blank"
                                filename={`${getTodayDateISO()} PayrollHistory`}
                            >
                                <span className="me-2">
                                    <img
                                        width={20}
                                        height={20}
                                        src={downloadIcon}
                                    ></img>
                                </span>
                                Download Excel
                            </CSVLink>
                        </div>
                    </Col>
                </Row>

                <div className="tab-content">
                    {/* filters */}
                    <div className="my-2 px-2 PO-filters d-flex">
                        <span className="me-4 align-middle mt-2 ps-label">
                            Filter By:
                        </span>

                        <Select
                            className="dropsearch-filter px-0 py-0 me-2"
                            classNamePrefix="react-select"
                            placeholder="Select Employee"
                            styles={{
                                control: (baseStyles, state) => ({
                                ...baseStyles,
                                backgroundColor: state.isSelected ? 'white' : '#59c8e3',
                                borderRadius: "7px",
                                border: "0px",
                                minHeight: "20px",
                                maxHeight: "35px",
                                }),
                                input: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: state.isSelected? "white": "white",
                                    
                                }),
                                dropdownIndicator: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: "white"
                                    
                                }),
                                singleValue: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: "white"
                                    
                                }),
                                placeholder: (baseStyles, state) => ({
                                    ...baseStyles,
                                    color: "white"
                                    
                                }),
                                
                            }}
                            // value={branch}
                            options={employees}
                            onChange={(e) => { setFilterConfig((prev) => {
                                    return { ...prev, "employee_id": e.value };
                                });}}
                        />

                        
                        <Form.Select
                            type="select"
                            className="react-select-container"
                            classNamePrefix="react-select"
                            placeholder="Select Type..."
                            name="disbursement_type"
                            value={filterConfig.disbursement_type}
                            onChange={(e) => setFilterConfig((prev) => {
                                return { ...prev, "disbursement_type": e.target.value };
                            })}
                        >
                            <option disabled selected value="">
                                Select Type
                            </option>
                            <option value="">
                                All
                            </option>
                            <option value="atm">
                                ATM deposit
                            </option>
                            <option value="palawan">
                                Palawan
                            </option>
                            <option value="cash">
                                Cash
                            </option>
                            <option value="others">
                                Others
                            </option>
                        </Form.Select>

                        <span className="me-4 align-middle mt-2 ps-label">
                            From:
                        </span>
                        <DatePicker
                            selected={filterConfig.date_from}
                            name="date_from"
                            placeholderText={"Select Date"}
                            onChange={(date) => {
                                setFilterConfig((prev) => {
                                    return { ...prev, date_from: date };
                                });
                            }}
                            fixedHeight
                            className="PI-date-btn me-3 form-control"
                            showYearDropdown
                            dateFormatCalendar="MMMM"
                            yearDropdownItemNumber={20}
                            scrollableYearDropdown
                        />

                        <span className="me-4 align-middle mt-2 ps-label">
                            To:
                        </span>
                        <DatePicker
                            selected={filterConfig.date_to}
                            name="date_to"
                            placeholderText={"Select Date"}
                            onChange={(date) => {
                                setFilterConfig((prev) => {
                                    return { ...prev, date_to: date };
                                });
                            }}
                            fixedHeight
                            className="PI-date-btn me-3 form-control"
                            showYearDropdown
                            dateFormatCalendar="MMMM"
                            yearDropdownItemNumber={20}
                            scrollableYearDropdown
                        />
                    </div>

                    <div className=" PO-filters d-flex justify-content-center">
                        <span className="me-4 ml-4 mt-2 ps-label">
                            Total NET: {numberFormat(totalNet)}
                        </span>
                    </div>

                    <div className="below">
                        {/* table */}
                        <Table
                            tableHeaders={[
                                "-",
                                "PAYROLL RANGE",
                                // "PAYROLL DATE",
                                "DISBURSEMENT DATE",
                                "TYPE",
                                "EMPLOYEE NAME",
                                "AMOUNT",
                                // "SSS CONTRIBUTION",
                                // "SSS LOAN",
                                // "PAGIBIG CONTRIBUTION",
                                // "PAGIBIG LOAN",
                                "ACTIONS",
                            ]}
                            headerSelector={[
                                "-",
                                "payroll_range",
                                // "payroll_date",
                                "disbursement_date",
                                "disbursement_type",
                                "employee_name",
                                "grand_total",
                                // "sss",
                                // "sss_loan",
                                // "pagibig",
                                // "pagibig_loan",
                            ]}
                            tableData={allData}
                            ActionBtn={(row) => ActionBtn(row)}
                            ViewBtn={(row) => ViewBtn(row)}
                            showLoader={showLoader}
                        />
                    </div>
                    <div className="mb-2" />
                </div>
            </div>

            {/* modals */}
            <Delete
                show={showDeleteModal}
                onHide={handleCloseDeleteModal}
                text="payroll"
                onDelete={handleDeleteExpense}
            />

            <Modal show={showAddModal} onHide={handleCloseAddModal} size="lg" centered>
                <Modal.Body>
                    <span className="PI-modal-header">
                        ADD PAYROLL
                    </span>
                    <div className="PI-modal-body mt-3">
                        <span className="PI-modal-label">EMPLOYEE NAME</span>
                        <Select
                            className="react-select-container mb-4"
                            classNamePrefix="react-select"
                            placeholder="Select Employee..."
                            value={selectedEmployee}
                            options={employees}
                            onChange={(e) => setSelectedEmployee({
                                label: e.label,
                                value: e.value
                            })}
                        />
                        <InputError
                            isValid={isError.employee_id}
                            message={"Employee is required"}
                        />
                       <span className="PI-modal-label ">DATE FROM</span>
                       <Form.Control
                            type="date"
                            name="payroll_month_from"
                            className="nc-modal-custom-text"
                            onChange={(e) => { setSelectedMonthFrom(e.target.value) }}
                        />
                        <InputError
                            isValid={isError.payroll_month_from}
                            message={"Month from is required"}
                        />
                        <span className="PI-modal-label ">DATE TO</span>
                       <Form.Control
                            type="date"
                            name="payroll_month_to"
                            className="nc-modal-custom-text"
                            onChange={(e) => { setSelectedMonthTo(e.target.value) }}
                        />
                        <InputError
                            isValid={isError.payroll_month_to}
                            message={"Month to is required"}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className="PI-modal-buttons">
                    <button
                        type="button"
                        onClick={handleCloseAddModal}
                        className="button-secondary"
                    >
                        Close
                    </button>
                    <button
                        type="button"
                        onClick={handleAddModal}
                        className="button-primary"
                    >
                        Continue
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
