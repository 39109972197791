import React, { useState } from "react";
import { Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import toast from "react-hot-toast";
import Select from "react-select";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Delete from "../../../Components/Modals/DeleteModal";
import Navbar from "../../../Components/Navbar/Navbar";
import Table from "../../../Components/TableTemplate/Table";
import AdjustmentTable from "./../../Inventory/Adjustment/AdjustmentTable";
import {
    dateFormat,
    formatDateNoTime,
    formatDate,
    numberFormat,
    refreshPage,
    toastStyle,
    getTodayDate,
    getType,
    TokenExpiry,
    getTodayDateISO
} from "../../../Helpers/Utils/Common";
import DatePicker from "react-datepicker";
import Moment from "moment";
import { CSVLink, CSVDownload } from "react-csv";
import downloadIcon from "../../../Assets/Images/download_icon.png";

import { getAllFranchisee } from "../../../Helpers/apiCalls/franchiseeApi";
import { getWastage } from "../../../Helpers/apiCalls/Reports/DailyWastageApi";
import { getGrabfoodReport } from './../../../Helpers/apiCalls/Reports/GrabfoodReportApi';

export default function ViewGrabfoodReport() {
    const { id, branch_name, dateparam  } = useParams();
    const { state } = useLocation();
    let navigate = useNavigate();
    var dateToday = getTodayDate();
    const accountType = getType();
    const [inactive, setInactive] = useState(true);
    const [filterConfig, setFilterConfig] = useState({
        by_branch: 0,
    })
    const [franchisees, setFranchisees] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [allData, setAllData] = useState([]);
    const [totalQuantity, setTotalQuantity] = useState([]);
    const [averagePrice, setAveragePrice] = useState([]);
    const [totalAmount, setTotalAmount] = useState([]);
    const [branchName, setBranchName] = useState(branch_name);
    // const [wastageDate, setWastageDate] = useState("");
    const date = dateparam;

    const excelHeaders = [
        { label: "Invoice No.", key: "reference_no" },
        { label: "Invoice Date", key: "added_on" },
        { label: "Partner Name", key: "branch_name" },
        { label: "Order Date", key: "added_on" },
        { label: "Order Value", key: "gross_value" },
        { label: "Discount", key: "partner_funded_discount" },
        { label: "Additional Discount", key: "additional_discounts" },
        { label: "Discount Sharing", key: "discount_sharing" },
        { label: "Sales Revenue", key: "sales_revenue" },
    ];

    const dummy = [
        {
            id: "1",
            item: "straw",
            quantity: "500",
            unit: "pc",
            reason: "asdfasdf",
        },
    ]

    // SEARCH USER
    function handleOnSearch(e) {
        const { name, value } = e.target;
        setFilterConfig((prev) => {
            return { ...prev, [name]: value };
        });
    }

    function handlePrint() {
        navigate("/grabfoodreport/print/" + id, {
            state: { 
                date: date,
                branch_name: branchName }
        })
    }

    async function fetchFranchisee() {
        setShowLoader(true);

        const response = await getAllFranchisee();
        if (response.error) {
        } else {
            setFranchisees(response.data.data);
            var allFranchisee = response.data.data.map((data) => {
                var franchise = data;
                franchise.franchise_name = data.name;
                franchise.franchise = data.name;
                franchise.contract = "0";
                franchise.sale = "0";
                franchise.sale_billing = "0";
                franchise.total = "0";
                return franchise;
            });
            setFranchisees(allFranchisee);
        }
        setShowLoader(false);
    }

    async function fetchData() {
        setShowLoader(true);
        setAllData([])

        const response = await getGrabfoodReport(id, date);
        if (response.error) {
        } else {
            var allData = response.data.data.map((data) => {
                var info = data;
                info.added_on = Moment(data.added_on).format("MM-DD-YYYY")
                info.gross_value = numberFormat(data.gross_value)
                info.partner_funded_discount = numberFormat(data.partner_funded_discount)
                info.additional_discounts = numberFormat(data.additional_discounts)
                info.discount_sharing = numberFormat(data.discount_sharing)
                info.sales_revenue = numberFormat(data.sales_revenue)
                return info;
            });
            setAllData(allData)

            var total = response.data.data.summary? response.data.data.summary.total_paid_amount : "0";
            setTotalAmount(total);
        }
        setShowLoader(false);
    }

    React.useEffect(() => {
        fetchFranchisee();
        fetchData();
    }, []);

    React.useEffect(() => {
    }, [filterConfig]);

    return (
        <div>
            <div className="page">
                <Navbar
                    onCollapse={(inactive) => {
                        setInactive(inactive);
                    }}
                    active={"STORE REPORTS"}
                />
            </div>

            <div
                className={`manager-container ${
                    inactive ? "inactive" : "active"
                }`}
            >
                {/* headers */}
                <Row className="mb-4">
                    <Col xs={6}>
                        <h1 className="page-title"> {branchName} </h1>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col xs={6}>
                        <h5 className="page-subtitle"> {new Date(date).toLocaleDateString( "en-us", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })} </h5>
                    </Col>
                </Row>

                <Row>
                    <Col xs={6}>
                        <h5 className="page-subtitle"> Total Paid Amount: {totalAmount} </h5>
                    </Col>
                    <Col className="d-flex justify-content-end mb-4">
                        <div className="justify-content-center align-items-center ">
                            <CSVLink
                                className="button-primary px-3 py-3 justify-content-center align-items-center download-csv"
                                data={allData}
                                headers={excelHeaders}
                                target="_blank"
                                filename={`${getTodayDateISO()} GrabFoodReports_${branchName}`}
                            >
                                <span className="me-2">
                                    <img
                                        width={20}
                                        height={20}
                                        src={downloadIcon}
                                    ></img>
                                </span>
                                Download Excel
                            </CSVLink>
                        </div>
                    </Col>
                </Row>

                <div className="tab-content">
                    {/* filters */}
                    <div className="my-2 px-2 PO-filters d-flex">
                    </div>

                    <div className="below">
                        {/* table */}
                        <Table
                            tableHeaders={[
                                "INVOICE NO",
                                "INVOICE DATE",
                                "PARTNER NAME",
                                "ORDER DATE",
                                "ORDER VALUE",
                                "DSC VIA DEALS",
                                "ADDT'L DSC",
                                "DSC SHARE",
                                "SALES REVENUE",
                            ]}
                            headerSelector={[
                                "reference_no",
                                "added_on",
                                "branch_name",
                                "added_on",
                                "gross_value",
                                "partner_funded_discount",
                                "additional_discounts",
                                "discount_sharing",
                                "sales_revenue",
                            ]}
                            tableData={allData}
                            showLoader={showLoader}
                        />
                    </div>
                    <div className="mb-2" />
                </div>

                <div className="d-flex justify-content-end my-4 pb-5 ">
                    <button
                        className="button-secondary me-3"
                        onClick={() => navigate("/grabfoodreport")}
                    >
                        Back
                    </button>
                    <button className="button-primary" onClick={() => handlePrint()}>
                        Print
                    </button>
                </div>
            </div>
        </div>
    );
}
