import {
    formatDateNoTime,
    formatYDM,
    getToken,
    getToken2,
    getUser,
} from "../../Utils/Common";
import { getAPICall, postAPICall } from "../axiosMethodCalls";
import Moment from "moment";
import formData from "react-form-data";

//GET
export const getAllPayroll = async (data) => {
    try {
        const response = await getAPICall(
            process.env.REACT_APP_LINK + "payrolls/search",
            {
                requester: getUser(),
                token: getToken(),
                employee_id: data.employee_id,
                employee_name: data.employee_name,
                disbursement_type: data.disbursement_type,
                date_from: data.date_from ? Moment(data.date_from).format("YYYY-MM-DD") : "",
                date_to: data.date_to ? Moment(data.date_to).format("YYYY-MM-DD") : "",
            }
        );
        return { data: response.data };
    } catch (error) {
        return { error: error };
    }
};

export const getPayroll = async (id) => {
    try {
        const response = await getAPICall(
            process.env.REACT_APP_LINK + "payrolls/search",
            {
                requester: getUser(),
                token: getToken(),
                payroll_id: id,
                // date_from: date_from ? Moment(date_from).format("YYYY-MM-DD") : "",
                // date_to: date_to ? Moment(date_to).format("YYYY-MM-DD") : "",
            }
        );
        return { data: response.data };
    } catch (error) {
        return { error: error };
    }
};

export const createPayroll = async (data, cash_advance, shortage) => {
    console.log(data)

    console.log(data, "create")
    let form_data = new FormData();
    form_data.append("requester", getUser());
    form_data.append("token", getToken());
    form_data.append("employee_id", data.employee_id);
    form_data.append("date_from", data.date_from);
    form_data.append("date_to", data.date_to);
    form_data.append("disbursement_date", data.disbursement_date);
    form_data.append("disbursement_type", data.disbursement_type);
    form_data.append("rate", data.rate);
    form_data.append("total_working_hours", data.total_working_hours);
    form_data.append("basic_pay", data.basic_pay);
    form_data.append("philhealth", data.philhealth);
    form_data.append("sss", data.sss);
    form_data.append("pagibig", data.pagibig);
    form_data.append("sss_loan", data.sss_loan);
    form_data.append("pagibig_loan", data.pagibig_loan);
    
    cash_advance.map((cash) => {
        form_data.append('cash_advance_ids[]', cash.id);
    });
    
    cash_advance.map((cash) => {
        form_data.append('cash_advance_amounts[]', cash.amount !== "" ? cash.amount : "0");
    });
    
    shortage.map((shortage) => {
        form_data.append('shortage_deduction_ids[]', shortage.id);
    });
    
    shortage.map((shortage) => {
        form_data.append('shortage_deduction_amounts[]', shortage.amount);
    });

    form_data.append("shortage", data.shortage);
    form_data.append("transportation_allowance", data.transportation_allowance);
    form_data.append("other_allowance", data.other_allowance);
    form_data.append("holidays", data.holidays);
    form_data.append("holiday_description", data.holiday_description);
    form_data.append("thirteenth_month", data.thirteenth_month);
    form_data.append("bonus", data.bonus);
    form_data.append("service_incentive", data.service_incentive);
    form_data.append("total_deduction", data.total_deduction);
    form_data.append("total_additions", data.total_additions);
    form_data.append("grand_total", data.grand_total);
    form_data.append("remarks", data.remarks);

    try {
        const response = await postAPICall(
            process.env.REACT_APP_LINK + "payrolls/create", form_data
        );
        return { data: response.data };
    } catch (error) {
        return { error: error };
    }
};

export const updatePayroll = async (data, cash_advance, shortage) => {

    let form_data = new FormData();
    form_data.append("requester", getUser());
    form_data.append("token", getToken());
    form_data.append("employee_id", data.employee_id);
    form_data.append("date_from", data.date_from);
    form_data.append("date_to", data.date_to);
    form_data.append("disbursement_date", data.disbursement_date);
    form_data.append("disbursement_type", data.disbursement_type);
    form_data.append("rate", data.rate);
    form_data.append("total_working_hours", data.total_working_hours);
    form_data.append("basic_pay", data.basic_pay);
    form_data.append("philhealth", data.philhealth);
    form_data.append("sss", data.sss);
    form_data.append("pagibig", data.pagibig);
    form_data.append("sss_loan", data.sss_loan);
    form_data.append("pagibig_loan", data.pagibig_loan);
    
    cash_advance.map((cash) => {
        form_data.append('cash_advance_ids[]', cash.id);
    });
    
    cash_advance.map((cash) => {
        form_data.append('cash_advance_amounts[]', cash.balance);
    });
    
    shortage.map((shortage) => {
        form_data.append('shortage_deduction_ids[]', shortage.id);
    });
    
    shortage.map((shortage) => {
        form_data.append('shortage_deduction_amounts[]', shortage.total_balance);
    });

    // form_data.append("shortage", data.shortage);
    form_data.append("transportation_allowance", data.transportation_allowance);
    form_data.append("other_allowance", data.other_allowance);
    form_data.append("holidays", data.holidays);
    form_data.append("holiday_description", data.holiday_description);
    form_data.append("thirteenth_month", data.thirteenth_month);
    form_data.append("bonus", data.bonus);
    form_data.append("service_incentive", data.service_incentive);
    form_data.append("total_deduction", data.total_deduction);
    form_data.append("total_additions", data.total_additions);
    form_data.append("grand_total", data.grand_total);
    form_data.append("remarks", data.remarks);

    try {
        const response = await postAPICall(
            process.env.REACT_APP_LINK + "payrolls/update", form_data
        );
        return { data: response.data };
    } catch (error) {
        return { error: error };
    }
};

export const deletePayroll = async (id) => {
    
    let form_data = new FormData();
    form_data.append("requester", getUser());
    form_data.append("token", getToken());
    form_data.append("payroll_id", id);

    try {
        const response = await postAPICall(
            process.env.REACT_APP_LINK + "payrolls/delete", form_data
            // {
            //     requester: getUser(),
            //     token: getToken(),
            //     payroll_id: id,
            // }
        );
        return { data: response.data };
    } catch (error) {
        return { error: error };
    }
};

export const computePayroll = async (data) => {
    try {
        const response = await getAPICall(
            process.env.REACT_APP_LINK + "payrolls/compute_payroll",
            {
                requester: getUser(),
                token: getToken(),
                employee_id: data.employee_id,
                date_from: data.date_from ? Moment(data.date_from).format("YYYY-MM-DD") : "",
                date_to: data.date_to ? Moment(data.date_to).format("YYYY-MM-DD") : "",
            }
        );
        return { data: response.data };
    } catch (error) {
        return { error: error };
    }
};