import React from "react";
import { useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Table } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import {
    numberFormat,
} from "../../../Helpers/Utils/Common";

// Components
import Navbar from "../../../Components/Navbar/Navbar";

// Images
import logo from '../../../Assets/Images/Login/logo.png'
import DailySales from './DailySales';

export default function PrintDailySales() {
    const [inactive, setInactive] = useState(true);
    const location = useLocation();
    console.log(location.state)
    const expenseItems = location.state.expenseDetails;
    const wastageItems = location.state.wastageItems;
    const cashDeposit = location.state.deposit;
    const cashChangeFunds = location.state.changeFunds;
    const initialInventory = location.state.initialInventory;
    const endInventory = location.state.endInventory;
    const actualInventorySales = location.state.actualInventorySales;
    const systemInventorySales = location.state.systemInventorySales;
    const salesSummary = location.state.salesSummary;
    const actualTotalSales = location.state.actualTotalSales;
    const systemTotalSales = location.state.systemTotalSales;
    const totalCashDeposit = location.state.totalCashDeposit;
    const totalCashFunds = location.state.totalCashFunds;
    const dailySales = location.state.dailySales;

    const [showCashBreakDown, setShowCashBreakDown] = useState(location.state.showCashBreakDown);
    const [showInitial, setShowInitial] = useState(location.state.showInitial);
    const [showEnd, setShowEnd] = useState(location.state.showEnd);

    console.log(dailySales)
    const componentRef = useRef();
    const navigate = useNavigate();
    // console.log(location)
    // console.log(cashDeposit, cashChangeFunds);
    // console.log(location);
    // console.log(wastageItems)

    function renderExpenseTable() {
        return (
            <Table className="align-middle">
                <thead>
                    <tr>
                        <th>Item</th>
                        <th className="text-end">Quantity</th>
                        <th>Unit</th>
                        <th className="text-end">UNIT PRICE</th>
                        <th className="text-end">Amount</th>
                        <th>Time Added</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        expenseItems.map((data) => {
                            return (
                                <tr>
                                    <td>{data.name}</td>
                                    <td className="text-end">{data.qty}</td>
                                    <td>{data.unit}</td>
                                    <td className="text-end">{numberFormat(data.price)}</td>
                                    <td className="text-end">{numberFormat(data.total)}</td>
                                    <td>{data.added_on}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        );
    }

    function renderWastageTable() {
        return (
            <Table className="align-middle">
                <thead>
                    <tr>
                        <th>Item</th>
                        <th className="text-end">Quantity</th>
                        <th>Unit</th>
                        <th>Reason</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        wastageItems.map((data) => {
                            return (
                                <tr>
                                    <td>{data.name}</td>
                                    <td className="text-end">{data.qty}</td>
                                    <td>{data.unit}</td>
                                    <td>{data.reason}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        );
    }

    function renderDepositTable() {
        return (
            <Table className="align-middle">
                <thead>
                    <tr>
                        <th>Denomination</th>
                        <th className="text-end">Quantity</th>
                        <th className="text-end">Amount</th>
                        {/* <th>Reason</th> */}
                    </tr>
                </thead>
                <tbody>
                    {
                        cashDeposit.map((data) => {
                            return (
                                <tr>
                                    <td>{data.denomination}</td>
                                    <td className="text-end">{data.quantity}</td>
                                    <td className="text-end">{data.amount ? (data.amount) : "0.00"}</td>
                                    {/* <td>{data.reason}</td> */}
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        );
    }

    function renderChangeFundsTable() {
        return (
            <Table className="align-middle">
                <thead>
                    <tr>
                        <th>Denomination</th>
                        <th className="text-end">Quantity</th>
                        <th className="text-end">Amount</th>
                        {/* <th>Reason</th> */}
                    </tr>
                </thead>
                <tbody>
                    {
                        cashChangeFunds.map((data) => {
                            return (
                                <tr>
                                    <td>{data.denomination}</td>
                                    <td className="text-end">{data.quantity}</td>
                                    <td className="text-end">{data.amount ? (data.amount) : "0.00"}</td>
                                    {/* <td>{data.reason}</td> */}
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        );
    }

    function renderInitialInventoryTable() {
        return (
            <Table className="align-middle">
                <thead>
                    <tr>
                        <th>Item</th>
                        <th className="text-end">Beginning</th>
                        <th className="text-end">Delivered</th>
                        <th className="text-end">Total</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        initialInventory.map((data) => {
                            return (
                                <tr>
                                    <td>{data.name}</td>
                                    <td className="text-end">{data.beginning}</td>
                                    <td className="text-end">{data.delivered}</td>
                                    <td className="text-end">{numberFormat(data.total)}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        );
    }

    function renderEndInventoryTable() {
        return (
            <Table className="align-middle">
                <thead>
                    <tr>
                        <th>Item</th>
                        <th className="text-end">Actual End</th>
                        <th className="text-end">System End</th>
                        <th className="text-end">Actual Usage</th>
                        <th className="text-end">System Usage</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        endInventory.map((data) => {
                            return (
                                <tr>
                                    <td>{data.name}</td>
                                    <td className="text-end">{data.actual_end}</td>
                                    <td className="text-end">{data.system_end}</td>
                                    <td className="text-end">{data.actual_usage}</td>
                                    <td className="text-end">{data.system_usage}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        );
    }

    function renderActualInventorySalesTable() {
        return (
            <Table className="align-middle">
                <thead>
                    <tr>
                        <th>Item</th>
                        <th className="text-end">Usage</th>
                        <th className="text-end">Price</th>
                        <th className="text-end">Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        actualInventorySales.map((data) => {
                            return (
                                <tr>
                                    <td>{data.name}</td>
                                    <td className="text-end">{data.usage}</td>
                                    <td className="text-end">{numberFormat(data.price)}</td>
                                    <td className="text-end">{numberFormat(data.amount)}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        );
    }

    function renderSystemInventorySalesTable() {
        return (
            <Table className="align-middle">
                <thead>
                    <tr>
                        <th>Item</th>
                        <th className="text-end">Usage</th>
                        <th className="text-end">Price</th>
                        <th className="text-end">Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        systemInventorySales.map((data) => {
                            return (
                                <tr>
                                    <td>{data.name}</td>
                                    <td className="text-end">{data.usage}</td>
                                    <td className="text-end">{numberFormat(data.price)}</td>
                                    <td className="text-end">{numberFormat(data.amount)}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        );
    }


    return (
        <div>
            <div className="page">
                <Navbar
                    onCollapse={(inactive) => {
                        setInactive(inactive);
                    }}
                    active={"DS REPORTS"}
                />
            </div>

            <div
                className={`manager-container container edit-form${
                    inactive ? "inactive" : "active"
                }`}
            >
                <div className="print-container w-100 px-3 py-2" ref={componentRef}>
                    <Row className="d-flex justify-content-center mt-4">
                        <div className="print-logo-container">
                            <img src={logo} alt="logo" className="print-logo" />
                        </div>
                    </Row>
                    <Row className="">
                        <h5 className="d-flex justify-content-center report-subheader">DAILY SALES REPORT</h5>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <h5 className="label-data p-1"> <span className="label"> Branch: </span> {salesSummary?.branch_name ? salesSummary?.branch_name : ''} </h5>
                        </Col>
                        <Col xs={6} className="d-flex justify-content-end">
                            <h5 className="label-data p-1"> <span className="label"> Cashier: </span> {salesSummary?.cashier_name ? salesSummary?.cashier_name : ''} </h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <h5 className="label-data p-1"> <span className="label"> Date: </span>{salesSummary?.date ? new Date(salesSummary?.date).toLocaleDateString( "en-us", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) : ''}</h5>
                        </Col>
                        <Col xs={6} className="d-flex justify-content-end">
                            <h5 className="label-data p-1"> <span className="label"> Prepared by: </span> {salesSummary?.preparer_name ? salesSummary?.preparer_name : ''}</h5>
                        </Col>
                    </Row>

                    {/* SALES SUMMARY */}
                    <Row className="d-flex mt-4 mb-2 justify-content-evenly ps-3 pe-3">
                        <Col className=' print-table noscroll me-2'>
                            <Row className="">
                                <h5 className="report-subheader justify-content-center green">SYTEM SALES SUMMARY</h5>
                            </Row>
                            <Row className=" mx-2">
                                
                                <Col className='p-3'>
                                    <Row>
                                        <Col xs={3}></Col>
                                        <Col xs={3} className="text-end"><span className="cash-breakdown-subheader justify-content-center green">w/ Disc. DF</span></Col>
                                        <Col xs={3} className="text-end"><span className="cash-breakdown-subheader justify-content-center green">System</span></Col>
                                        <Col xs={3} className="text-end"><span className="cash-breakdown-subheader justify-content-center green">Store Price</span></Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col xs={6}><span className="cash-breakdown-subheader justify-content-center green">Sales Order Total</span></Col>
                                        <Col xs={3} className="text-end"><span className="cash-breakdown-subheader justify-content-center">{salesSummary ? numberFormat(salesSummary.system_cash_sales) : ''}</span></Col>
                                        <Col xs={3} className="text-end"><span className="cash-breakdown-subheader justify-content-center">{salesSummary ? numberFormat(salesSummary.system_cash_sales) : ''}</span></Col>
                                    </Row>
                                    {/* <hr/> */}
                                    <Row className="mt-3">
                                        <Col xs={3}><span className="cash-breakdown-subheader justify-content-center green">Food Panda</span></Col>
                                        <Col xs={3} className="text-end"><span className="cash-breakdown-subheader justify-content-center">{salesSummary ? numberFormat(salesSummary.food_panda_df) : ''}</span></Col>
                                        <Col xs={3} className="text-end"><span className="cash-breakdown-subheader justify-content-center">{salesSummary ? numberFormat(salesSummary.food_panda_sales) : ''}</span></Col>
                                        <Col xs={3} className="text-end"><span className="cash-breakdown-subheader justify-content-center">{salesSummary ? numberFormat(salesSummary.food_panda_store_sales) : ''}</span></Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col xs={3}><span className="cash-breakdown-subheader justify-content-center green">Grab Food</span></Col>
                                        <Col xs={3} className="text-end"><span className="cash-breakdown-subheader justify-content-center">{salesSummary ? numberFormat(salesSummary.grab_food_df) : ''}</span></Col>
                                        <Col xs={3} className="text-end"><span className="cash-breakdown-subheader justify-content-center">{salesSummary ? numberFormat(salesSummary.grab_food_sales) : ''}</span></Col>
                                        <Col xs={3} className="text-end"><span className="cash-breakdown-subheader justify-content-center">{salesSummary ? numberFormat(salesSummary.grab_food_store_sales) : ''}</span></Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col xs={6}><span className="cash-breakdown-subheader justify-content-center green">Total Store Discount</span></Col>
                                        <Col xs={3} className="text-end"><span className="cash-breakdown-subheader justify-content-center ">{salesSummary ? numberFormat(salesSummary.store_discounts) : ''}</span></Col>
                                        <Col xs={3} className="text-end"><span className="cash-breakdown-subheader justify-content-center ">{salesSummary ? numberFormat(salesSummary.store_discounts) : ''}</span></Col>
                                    </Row>
                                    <hr/>
                                    <Row className="">
                                        <Col xs={6}><h5 className="cash-breakdown-subheader">TOTAL SALES</h5></Col>
                                        <Col xs={3} className="text-end"><span className="cash-breakdown-subheader justify-content-center total-sales-cont">{dailySales ? numberFormat(parseFloat(dailySales.system_cash_sales) + parseFloat(dailySales.food_panda_sales) + parseFloat(dailySales.grab_food_sales) + parseFloat(dailySales.store_discounts)) : ''}</span></Col>
                                        <Col xs={3} className="text-end"><span className="cash-breakdown-subheader justify-content-center total-sales-cont">{dailySales ? numberFormat(parseFloat(dailySales.system_cash_sales) + parseFloat(dailySales.food_panda_store_sales) + parseFloat(dailySales.grab_food_store_sales) + parseFloat(dailySales.store_discounts)) : ''}</span></Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col className=' print-table noscroll ms-2'>
                            <Row>
                                <span className="cash-breakdown-subheader green text-center mt-4">
                                    TOTAL VARIANCE
                                </span>
                            </Row>
                            <Row className="mt-5">
                                <Col className="text-start " xs={8}><span className="print-data text-start ">Cash Variance</span></Col>
                                <Col className="text-end " xs={4}><span className="print-data text-end ">{dailySales ? numberFormat(dailySales.cash_sales_overage) : ''}</span></Col>
                            </Row>
                            <Row className="mt-1">
                                <Col className="text-start " xs={6}><span className="print-data text-start ">Inventory Variance</span></Col>
                                <Col className="text-end " xs={6}><span className="print-data text-end ">{dailySales ? numberFormat(dailySales.overage_inventory_sales) : '0.00'}</span></Col>
                            </Row>
                            <hr/>
                            <Row className="mt-2">
                                {
                                    dailySales ? 
                                        (parseFloat(dailySales.cash_sales_overage) - parseFloat(dailySales.overage_inventory_sales)) > 0 ? 
                                            <>
                                            <Col xs={6}><span className="print-data">VARIANCE</span></Col>
                                            <Col xs={3} className="text-end"><span className="print-data">OVER</span></Col>
                                            <Col xs={3} ><span className="print-data right total-sales-cont">{dailySales ? numberFormat(parseFloat(dailySales.cash_sales_overage) - parseFloat(dailySales.overage_inventory_sales)) : ''}</span></Col>
                                            </>
                                        : 
                                            <>
                                            <Col xs={6}><span className="print-data">VARIANCE</span></Col>
                                            <Col xs={3} className="text-end"><span className="print-data">SHORT</span></Col>
                                            <Col xs={3} ><span className="print-data right total-sales-cont-red">{dailySales ? numberFormat(parseFloat(dailySales.cash_sales_overage) - parseFloat(dailySales.overage_inventory_sales)) : ''}</span></Col>
                                            </>
                                    : ""
                                }
                            </Row>
                            <Row className="mt-5 mb-5">
                                {
                                    dailySales ? 
                                        (parseFloat(dailySales.total_cancelled)) <= 0 ? 
                                            <>
                                            <Col xs={6}><span className="print-data">TOTAL CANCELLED</span></Col>
                                            <Col xs={3} className="text-end"><span className="print-data"></span></Col>
                                            <Col xs={3} ><span className="print-data right total-sales-cont">{dailySales ? dailySales.total_cancelled : ''}</span></Col>
                                            </>
                                        : 
                                            <>
                                            <Col xs={6}><span className="print-data">TOTAL CANCELLED</span></Col>
                                            <Col xs={3} className="text-end"><span className="print-data"></span></Col>
                                            <Col xs={3} ><span className="print-data right total-sales-cont-red">{dailySales ? dailySales.total_cancelled : ''}</span></Col>
                                            </>
                                    : ""
                                }
                            </Row>
                        </Col>
                    </Row>

                    <Row className="mt-4">
                        <h5 className="report-subheader justify-content-center green">CASH SALES AND DEPOSIT COMPARISON</h5>
                    </Row>
                    <Row className="d-flex mb-2 justify-content-center ps-3 pe-3">
                        <Row className="print-table mx-2 justify-content-center">
                            <Col xs={5}>
                                <Row className=" d-flex mb-1 justify-content-center ">
                                    <Row className=" justify-content-center">
                                        <Row className="text-center">
                                            <span className="cash-breakdown-subheader justify-content-center green mt-2">
                                                ACTUAL TOTAL CASH
                                            </span>
                                        </Row>
                                        <Col xs={12} className='p-2 m-1'>
                                            {/* <Row>
                                                <Col className="text-end" xs={6}><span className="print-data justify-content-center green">Remitted Amount</span></Col>
                                                <Col className="text-end" xs={6}><span className="print-data justify-content-center green">System</span></Col>
                                            </Row> */}
                                            <Row className="mt-1">
                                                <Col className="text-start " xs={6}><span className="print-data text-start">Cash Received</span></Col>
                                                <Col className="text-end" xs={6}><span className="print-data text-end">{dailySales ? numberFormat(parseFloat(dailySales.actual_cash_sales) + parseFloat(dailySales.total_expense)) : ''}</span></Col>
                                            </Row>
                                            <Row className="mt-1">
                                                <Col className="text-start " xs={6}><span className="print-data text-start ">Expenses</span></Col>
                                                <Col className="text-end " xs={6}><span className="print-data text-end ">-{dailySales ? numberFormat(dailySales.total_expense) : '0.00'}</span></Col>
                                            </Row>
                                            <hr/>
                                            <Row className="mt-1">
                                                <Col className="text-start " xs={8}><span className="print-data text-start "> Cash to be Deposited</span></Col>
                                                <Col className="text-end " xs={4}><span className="print-data text-end ">{dailySales ? numberFormat(dailySales.actual_cash_sales) : ''}</span></Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Row>
                                <Row className=" d-flex mb-1 justify-content-center ">
                                    <Row className=" justify-content-center">
                                        <Row className="text-center">
                                            <span className="cash-breakdown-subheader justify-content-center green mt-3">
                                                SYSTEM TOTAL CASH
                                            </span>
                                        </Row>
                                        <Col xs={12} className='p-2 m-1'>
                                            <Row className="mt-1">
                                                <Col className="text-start " xs={6}><span className="print-data text-start">Cash Received</span></Col>
                                                <Col className="text-end" xs={6}><span className="cash-breakdown-subheader text-end">{dailySales ? numberFormat(parseFloat(dailySales.system_cash_sales) ) : ''}</span></Col>
                                            </Row>
                                            <Row className="mt-1">
                                                <Col className="text-start " xs={6}><span className="print-data text-start">Expenses</span></Col>
                                                <Col className="text-end " xs={6}><span className="print-data text-end ">-{dailySales ? numberFormat(dailySales.total_expense) : '0.00'}</span></Col>
                                            </Row>
                                            <hr/>
                                            <Row className="mt-1">
                                                <Col className="text-start " xs={8}><span className="print-data text-start ">Cash To Be Deposited</span></Col>
                                                <Col className="text-end " xs={4}><span className="print-data text-end ">{dailySales ? numberFormat(parseFloat(dailySales.system_cash_sales) - parseFloat(dailySales.total_expense)): ''}</span></Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Row>
                            </Col>
                            <Col xs={2}>
                                <div className="vl mt-5"></div>
                            </Col>
                            <Col xs={5}>
                                <Row className="justify-content-center">
                                    <Col className='me-3'>
                                        <Row className="ms-2 mt-3">
                                            <button
                                                type="button"
                                                className={showCashBreakDown ? "button-primary-full me-3" : "button-primary-clear-full me-3"}
                                                onClick={(e) => setShowCashBreakDown(!showCashBreakDown)}
                                            >
                                                <Row>
                                                    <Col xs={7} className="text-left"><span>Actual Cash Deposit Total</span></Col>
                                                    <Col><span className=" right">{dailySales ? numberFormat(dailySales.actual_total) : "0.00" }</span></Col>
                                                </Row>
                                            </button>
                                        </Row>
                                        <Row className="ms-2 mt-3">
                                            <Col><span className="cash-breakdown-subheader">System Cash Deposit Total</span></Col>
                                            <Col><span className="cash-breakdown-subheader right">{dailySales ? numberFormat(parseFloat(dailySales.system_cash_sales) - parseFloat(dailySales.total_expense)) : ''}</span></Col>
                                        </Row>
                                        <hr/>
                                        <Row className="ms-2">
                                            {
                                                salesSummary ? 
                                                    (parseFloat(salesSummary.cash_sales_overage)) > 0 ? 
                                                        <>
                                                        <Col xs={6}><span className="cash-breakdown-subheader">VARIANCE</span></Col>
                                                        <Col xs={3} className="text-end"><span className="cash-breakdown-subheader align-end">OVER</span></Col>
                                                        <Col xs={3}><span className="cash-breakdown-subheader right total-sales-cont">{salesSummary ? numberFormat(salesSummary.cash_sales_overage) : ''}</span></Col>
                                                        </>
                                                    : 
                                                        <>
                                                        <Col xs={6}><span className="cash-breakdown-subheader">VARIANCE</span></Col>
                                                        <Col xs={3} className="text-end"><span className="cash-breakdown-subheader">SHORT</span></Col>
                                                        <Col xs={3}><span className="cash-breakdown-subheader right total-sales-cont-red">{salesSummary ? numberFormat(salesSummary.cash_sales_overage) : ''}</span></Col>
                                                        </>
                                                : ""
                                            }
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Row>

                    {
                        <div className={showCashBreakDown ? "" : "display-none"}>
                            {/* CASH BREAKDOWN */}
                            <Row className="mt-4">
                                <h5 className="report-subheader green">CASH BREAKDOWN</h5>
                            </Row>
                            <Row>
                                <Col xs={3}><h5 className="report-subheader justify-content-start">DEPOSIT</h5></Col>
                                <Col xs={3}><h5 className="report-subheader justify-content-end">Total: {totalCashDeposit}</h5></Col>
                                <Col xs={3}><h5 className="report-subheader justify-content-start">CHANGE FUNDS</h5></Col>
                                <Col xs={3}><h5 className="report-subheader justify-content-end">Total: {totalCashFunds}</h5></Col>
                            </Row>
                            <div className="d-flex mb-2 justify-content-evenly">
                                    {/* table */}
                                <div className="print-table mx-2 me-3">
                                    {renderDepositTable()}
                                </div>
                                <div className="print-table mx-2">
                                    {renderChangeFundsTable()}
                                </div>
                            </div>

                            <div className="d-flex mx-2 mt-2 mb-2 justify-content-evenly">
                                <Col xs={6}>
                                    <Row className="text-end">
                                        <div className="d-flex my-2 justify-content-end ">
                                            <Col className="print-data">
                                                Total Cash Deposit: {dailySales ? numberFormat(dailySales.actual_total) : "0.00" }
                                            </Col>
                                        </div>
                                    </Row>
                                </Col>
                                <Col xs={6}>
                                    <Row className="text-end">
                                        <div className="d-flex my-2 justify-content-end ">
                                            <Col className="print-data">
                                                Total Cash Funds: {dailySales ? numberFormat(dailySales.system_total) : "0.00" }
                                            </Col>
                                        </div>
                                    </Row>
                                    <Row className="text-end">
                                        <div className="d-flex my-2 justify-content-end ">
                                            <Col className="print-data">
                                                Initial Cash in Drawer: {salesSummary.initial_cash_in_drawer ? numberFormat(salesSummary.initial_cash_in_drawer) : "0.00" }
                                            </Col>
                                        </div>
                                    </Row>
                                </Col>
                            </div>
                        </div>
                    }

                    <Row className="d-flex mt-4 mb-2 justify-content-evenly ps-3 pe-3">
                        <Col className=' print-table noscroll'>
                            <Row>
                                <h5 className="report-subheader justify-content-center green">INVENTORY SALES COMPARISON</h5>
                            </Row>
                            <Row className="ms-2 mt-2 p-2 me-3">
                                <Col xs={7}><span className="cash-breakdown-subheader justify-content-center green lighter">Actual Inventory Sales</span></Col>
                                <Col xs={5} className='d-flex justify-content-end'><span className="cash-breakdown-subheader">{salesSummary ? numberFormat(salesSummary.actual_inventory_sales) : "0.00"}</span></Col>
                            </Row>
                            <Row className="ms-2 p-2 me-3">
                                <Col xs={8}><span className="cash-breakdown-subheader justify-content-center green lighter">System Inventory Sales</span></Col>
                                <Col xs={4} className='d-flex justify-content-end'><span className="cash-breakdown-subheader">-{salesSummary ? numberFormat(salesSummary.system_inventory_sales) : "0.00"}</span></Col>
                            </Row>
                            <hr/>
                            <Row className="ms-2 mb-2 p-2 me-3">
                                {
                                    salesSummary ? 
                                        (parseFloat(salesSummary.overage_inventory_sales)) > 0 ? 
                                            <>
                                            <Col xs={6}><span className="cash-breakdown-subheader">VARIANCE</span></Col>
                                            <Col xs={4} className="text-end"><span className="cash-breakdown-subheader">OVER</span></Col>
                                            <Col xs={2}><span className="cash-breakdown-subheader right total-sales-cont">{salesSummary ? numberFormat(salesSummary.overage_inventory_sales) : ''}</span></Col>
                                            </>
                                        : 
                                            <>
                                            <Col xs={6}><span className="cash-breakdown-subheader">VARIANCE</span></Col>
                                            <Col xs={4} className="text-end"><span className="cash-breakdown-subheader">SHORT</span></Col>
                                            <Col xs={2}><span className="cash-breakdown-subheader right total-sales-cont-red">{salesSummary ? numberFormat(salesSummary.overage_inventory_sales) : ''}</span></Col>
                                            </>
                                    : ""
                                }
                            </Row>
                        </Col>
                    </Row>
                    
                    <hr/>
                    {/* INVENTORY SALES */}
                     <Row className="mt-5">
                        <Col xs={8}>
                            <h3 className="report-subheader green">INVENTORY SALES</h3>
                        </Col>
                        <Col xs={2}>
                            <button
                                type="button"
                                className={showInitial ? "button-primary-full me-3 right" : "button-primary-clear-full me-3 right"}
                                onClick={(e) => setShowInitial(!showInitial)}
                            >
                                Initial
                            </button>
                        </Col>
                        <Col xs={2}>
                            <button
                                type="button"
                                className={showEnd ? "button-primary-full me-3 right" : "button-primary-clear-full me-3 right"}
                                onClick={(e) => setShowEnd(!showEnd)}
                            >
                                End
                            </button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={3}><h5 className="report-subheader justify-content-start">ACTUAL</h5></Col>
                        <Col xs={3}><h5 className="report-subheader justify-content-end">Total: {actualTotalSales ? numberFormat(actualTotalSales) : "0.00"}</h5></Col>
                        <Col xs={3}><h5 className="report-subheader justify-content-start">SYSTEM</h5></Col>
                        <Col xs={3}><h5 className="report-subheader justify-content-end">Total: {systemTotalSales ? numberFormat(systemTotalSales) : "0.00"}</h5></Col>
                    </Row>
                    <div className="d-flex mb-2 justify-content-evenly row-baseline">
                            {/* table */}
                        <div className="print-table mx-2 me-3">
                            {renderActualInventorySalesTable()}
                        </div>
                        <div className="print-table mx-2">
                            {renderSystemInventorySalesTable()}
                        </div>
                    </div>


                    {/* ENDING INVENTORY */}
                    
                    {
                        <div className={showInitial ? "" : "display-none"}>
                            <hr/>
                            <Row>
                                <Col xs={12}><h5 className="report-subheader justify-content-center">INITIAL</h5></Col>
                            </Row>
                            <div className="d-flex mb-2 justify-content-evenly">
                                <div className="print-table mx-2">
                                    {renderInitialInventoryTable()}
                                </div>
                            </div>
                        </div>
                    }
                    {
                        <div className={showEnd ? "" : "display-none"}>
                            <hr/>
                            <Row>
                                <Col xs={12}><h5 className="report-subheader justify-content-center">END</h5></Col>
                            </Row>
                            <div className="d-flex mb-2 justify-content-evenly">
                                <div className="print-table mx-2">
                                {renderEndInventoryTable()}
                                </div>
                            </div>
                        </div>
                    }
                    <hr/>
                    {/* EXPENSE REPORT */}
                    <Row className="mt-4">
                        <h5 className="report-subheader green">EXPENSE REPORT</h5>
                    </Row>
                    <Row className="d-flex mb-2 justify-content-evenly">
                        <div className="print-table mx-2">{renderExpenseTable()}</div>
                    </Row>
                    <hr/>
                    {/* WASTAGE REPORT */}
                    <Row className="mt-4">
                        <h5 className="report-subheader green">WASTAGE REPORT</h5>
                    </Row>
                    <Row className="d-flex mb-2 justify-content-evenly">
                        <div className="print-table mx-2">{renderWastageTable()}</div>
                    </Row>

                </div>
                 {/* Print and Close Button */}
                <div className="d-flex justify-content-end pt-2 mb-3">
                    <ReactToPrint
                        trigger={() => 
                            <button name="action" className="button-primary justify-content-center align-items-center print-btn d-flex justify-content-end"
                            >Print</button>}
                        content={() => componentRef.current}
                    />
                    <button
                        name="action"
                        className="ms-2 button-primary justify-content-center align-items-center close-btn"
                        onClick={() => navigate(-1)}
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    )
}