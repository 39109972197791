import { getToken, getUser } from "../Utils/Common";
import { getAPICall, postAPICall } from "./axiosMethodCalls";
import Moment from 'moment';

export const getDashboardLogs = async (date) => {
    try {
        const response = await getAPICall(process.env.REACT_APP_LINK + "dashboard/web", 
        {
            requester: getUser(),
            token: getToken(),
            bo_logs_date: Moment(date).format("YYYY-MM-DD"),
        })
        return { data: response.data }
    } catch (error) {
        return { error: error.response }
    }
}

export const getDashboardLogsPotato = async (date) => {
    try {
        const response = await getAPICall(process.env.REACT_APP_POTATO + "dashboard/web", 
        {
            requester: getUser(),
            token: getToken(),
            bo_logs_date: Moment(date).format("YYYY-MM-DD"),
        }, "potato")
        return { data: response.data }
    } catch (error) {
        return { error: error.response }
    }
}