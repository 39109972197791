import React, { useState, useEffect, Fragment } from 'react'
import { Button, Col, Form, Row, Table, Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import Select from "react-select";
import ReactLoading from "react-loading";
import {
    formatDateNoTime,
    formatDate,
    dateFormat,
    getTodayDate,
    getTodayDateISO,
    getTodayDateNoTime,
    numberFormat,
    refreshPage,
    toastStyle,
    TokenExpiry,
} from "../../../Helpers/Utils/Common";
import Navbar from "../../../Components/Navbar/Navbar";
import InputError from "../../../Components/InputError/InputError";
import Employees from "../Employees.css"
import Moment from "moment";
import cleanLogo from "../../../Assets/Images/Login/logo.png";
//api
import { getAllEmployees } from "../../../Helpers/apiCalls/employeesApi";
import { validatePayroll } from './../../../Helpers/Validation/Employees/PayrollValidation';
import CashAdvanceModal from './../CashAdvance/CashAdvanceModal';
import { getPayroll } from './../../../Helpers/apiCalls/Employees/PayrollApi';


function ViewPayroll() {
    //variables
    const {id} = useParams()
    let navigate = useNavigate();
    const [inactive, setInactive] = useState(true);
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);
    const [isSaveClicked, setIsSaveClicked] = useState(false);
    const [employees, setEmployees] = useState([]);
    const today = getTodayDateISO();
    const [addDetails, setAddDetails] = useState({
        employee_id: "",
        payroll_date: "",
        payroll_month: "",
        rate: "",
        total_working_days: "",
        basic_pay: 0,
        total_deduction: 0,
        total_addition: 0,
        grand_total: 0,
    });
    /* print modal handler */
    const [showPrintModal, setShowPrintModal] = useState(false);
    const handleShowPrintModal = () => setShowPrintModal(true);
    const handleClosePrintModal = () => setShowPrintModal(false);

    // FUNCTIONS
    function Print() {
        let printContents = document.getElementById("printablediv").innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print(printContents);
        document.body.innerHTML = originalContents;
        refreshPage();
    }

    async function handlePrintPI() {
        toast.loading("Printing Transfer Details", { style: toastStyle() });
        handleClosePrintModal();
        setTimeout(() => {
            toast.dismiss();
            Print();
        }, 1000);
    }


    //API

    async function fetchData() {
        setAddDetails([]);

        const response = await getPayroll(id);
        console.log(response);

        if (response.error) {
            // TokenExpiry(response);
        } else {
            var expense = response.data.data.map((data) => {
                var info = data;
                info.month = dateFormat(data.date_from) + " - " + dateFormat(data.date_to);
                return info;
            });
            setAddDetails(expense[0])
        }
    }

    useEffect(() => {
        fetchData()
    }, []);

    function renderTable() {
        return (
            <Table className="ps-table">
                <thead>
                    <tr>
                        <th className="text-start">Employee Information</th>
                        <th colSpan={3}></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="franchise-td-gray text-start bolder">Name</td>
                        <td className="franchise-td-gray">{addDetails.employee_name}</td>
                    </tr>
                    {/* <tr>
                        <td className="franchise-td-gray text-start bolder">Address</td>
                        <td className="franchise-td-gray">{addDetails.address}</td>
                    </tr>
                    <tr>
                        <td className="franchise-td-gray text-start bolder">Contact Number</td>
                        <td className="franchise-td-gray">{addDetails.contact_number}</td>
                    </tr> */}
                    {/* <tr>
                        <td colSpan={3}></td>
                        <td
                            align="center"
                            className="print-table-footer-label grand-label"
                        >
                            GRAND TOTAL
                        </td>
                        <td
                            align="center"
                            className="franchise-td-gray"
                        >
                            {numberFormat(addDetails.grand_total)}
                        </td>
                    </tr> */}
                </tbody>
            </Table>
        );
    }

    function renderTableEarnings() {
        return (
            <Table className="ps-table">
                <thead>
                    <tr>
                        <th className="text-start">Earnings</th>
                        <th className="text-end">Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="franchise-td-gray text-start">Basic Pay</td>
                        <td className="franchise-td-gray text-end">{numberFormat(addDetails.basic_pay)}</td>
                    </tr>
                    <tr>
                        <td className="franchise-td-gray text-start">Transportation Allowance</td>
                        <td className="franchise-td-gray text-end">{numberFormat(addDetails.transportation_allowance)}</td>
                    </tr>
                    <tr>
                        <td className="franchise-td-gray text-start">Other Allowances</td>
                        <td className="franchise-td-gray text-end">{numberFormat(addDetails.other_allowances)}</td>
                    </tr>
                    <tr>
                        <td className="franchise-td-gray text-start">Holidays</td>
                        <td className="franchise-td-gray text-end">{numberFormat(addDetails.holidays)}</td>
                    </tr>
                    <tr>
                        <td className="franchise-td-gray text-start">13th Month</td>
                        <td className="franchise-td-gray text-end">{numberFormat(addDetails.thirteenth_month)}</td>
                    </tr>
                    <tr>
                        <td className="franchise-td-gray text-start">Bonus</td>
                        <td className="franchise-td-gray text-end">{numberFormat(addDetails.bonus)}</td>
                    </tr>
                    <tr>
                        <td className="franchise-td-gray text-start">Service Incentive</td>
                        <td className="franchise-td-gray text-end">{numberFormat(addDetails.service_incentive)}</td>
                    </tr>
                    <tr>
                        <td colSpan={2}> <hr/> </td>
                    </tr>
                    <tr>
                        <td className="franchise-td-gray text-start">Gross Earnings</td>
                        <td className="franchise-td-gray green text-end">{numberFormat(parseFloat(addDetails.total_addition) + parseFloat(addDetails.basic_pay))}</td>
                    </tr>
                </tbody>
            </Table>
        );
    }

    function renderTableDeductions() {
        return (
            <Table className="ps-table">
                <thead>
                    <tr>
                        <th className="text-start">Deductions</th>
                        <th className="text-end">Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="franchise-td-gray text-start">PhilHealth</td>
                        <td className="franchise-td-gray text-end">{numberFormat(addDetails.philhealth)}</td>
                    </tr>
                    <tr>
                        <td className="franchise-td-gray text-start">SSS</td>
                        <td className="franchise-td-gray text-end">{numberFormat(addDetails.sss)}</td>
                    </tr>
                    <tr>
                        <td className="franchise-td-gray text-start">Pag-Ibig</td>
                        <td className="franchise-td-gray text-end">{numberFormat(addDetails.pagibig)}</td>
                    </tr>
                    <tr>
                        <td className="franchise-td-gray text-start">SSS Loan</td>
                        <td className="franchise-td-gray text-end">{numberFormat(addDetails.sss_loan)}</td>
                    </tr>
                    <tr>
                        <td className="franchise-td-gray text-start">Pag-Ibig Loan</td>
                        <td className="franchise-td-gray text-end">{numberFormat(addDetails.pagibig_loan)}</td>
                    </tr>
                    <tr>
                        <td className="franchise-td-gray text-start">Total Shortage</td>
                        <td className="franchise-td-gray text-end">{numberFormat(addDetails.shortage)}</td>
                    </tr>
                    <tr>
                        <td className="franchise-td-gray text-start">Total Cash Advance</td>
                        <td className="franchise-td-gray text-end">{numberFormat(addDetails.cash_advance)}</td>
                    </tr>
                    <tr>
                        <td colSpan={2}> <hr/> </td>
                    </tr>
                    <tr>
                        <td className="franchise-td-gray text-start">Total Deductions</td>
                        <td className="franchise-td-gray green text-end">{numberFormat(addDetails.total_deduction)}</td>
                    </tr>
                    <tr>
                        <td className="franchise-td-gray text-start bolder">Net Salary</td>
                        <td className="franchise-td-gray green text-end">{numberFormat(addDetails.grand_total)}</td>
                    </tr>
                </tbody>
            </Table>
        );
    }


    return (
        <div>
            <div className="page">
                <Navbar
                    onCollapse={(inactive) => {
                        setInactive(inactive);
                    }}
                    active={"EMPLOYEES"}
                />
            </div>

            <div className={`container ${inactive ? "inactive" : "active"}`}>
                {/* header */}
                <div className="d-flex justify-content-between align-items-center my-3 pb-4">
                    <h1 className="page-title mb-0">
                        VIEW PAYROLL
                    </h1>
                </div>
                
                <div
                    // display-none
                    className="print-container px-3 py-2 "
                    id="printablediv"
                 >
                    <div className="text-end print-header d-flex flex-column">
                        <span>PAYROLL</span>
                        <span className="text-uppercase">
                            {Moment(getTodayDateISO()).format("MMMM DD, yyyy")}
                        </span>
                    </div>
                    <div className="d-flex justify-content-center py-1">
                        <img src={cleanLogo} className="print-logo" />
                    </div>
                    {/* <div className="d-flex justify-content-center py-1 ">
                        <h5 className="print-header">
                            PAYROLL
                        </h5>
                    </div> */}

                    <div className="print-body mt-5">

                        <div className="d-flex mx-3 mt-5 mb-2 justify-content-evenly">
                            {/* table */}
                            <div className="print-table mt-3 mx-5">
                                {renderTable()}
                            </div>
                        </div>

                        <Row className="mx-3 py-3">
                            <Col xs={6}>
                                <div className=" mx-5 d-flex my-2 align-items-center">
                                    <Col xs={4} className="print-label">
                                        For the month of:
                                    </Col>
                                    <Col xs={7} className="print-data">
                                        {addDetails.month}
                                    </Col>
                                </div>
                            </Col>
                        </Row>

                        <div className="d-flex mx-3 mt-1 marginb-5 justify-content-evenly">
                            {/* table */}
                            <div className="print-table mx-5">
                                {renderTableEarnings()}
                            </div>
                            <div className="print-table mx-5">
                                {renderTableDeductions()}
                            </div>
                        </div>
                        <div className="print-signatures mt-5">
                            <span className="text-center text-uppercase print-label">
                                {" "}
                            </span>
                            <span className="text-center text-uppercase print-label fw-bold">
                                {addDetails.prepared_by}
                            </span>
                        </div>
                        <div className="print-signatories pb-4 mb-4">
                            <span>Employer's Signature</span>
                            <span>Employee's Signature</span>
                        </div>
                    </div>
                </div>
                
                <div className="d-flex justify-content-end pt-5 pb-3">
                    <button
                        type="button"
                        className="button-secondary me-3"
                        onClick={() => navigate(-1)}
                    >
                        Close
                    </button>
                    <button
                        type="button"
                        className="button-primary me-3"
                        onClick={handleShowPrintModal}
                    >
                        Print
                    </button>
                </div>
            </div>

            <CashAdvanceModal
                show={showPrintModal}
                hide={handleClosePrintModal}
                type="print this payroll"
                page="transaction"
                handler={handlePrintPI}
            />
            
        </div>
    )
}

export default ViewPayroll