import React, { useState, useRef } from "react";
import { Col, Row, Modal} from "react-bootstrap";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Navbar from "../../../Components/Navbar/Navbar";
import Table from "../../../Components/TableTemplate/Table";
import {
    numberFormat,
    getTodayDate,
    getType,
    refreshPage,
    toastStyle,
} from "../../../Helpers/Utils/Common";
import Moment from "moment";
import toast from "react-hot-toast";
import NoDataImg from "../../../Assets/Images/no-data-img.png"

import { getAllFranchisee } from "../../../Helpers/apiCalls/franchiseeApi";
import { getExpenses, searchExpensesByDate } from "../../../Helpers/apiCalls/Reports/DailyExpensesApi";
import { getWastage, searchWastageByDate } from "../../../Helpers/apiCalls/Reports/DailyWastageApi";
import { searchCashCountByDate, getCashCountReport } from "../../../Helpers/apiCalls/Reports/DailyCashCountApi";
import { getDailyInventory, getInventorySales, searchDailySales } from "../../../Helpers/apiCalls/Reports/DailySalesApi";
import ReactToPrint from "react-to-print";
import DailySales from './DailySales';

export default function ViewDailySales() {
    const componentRef = useRef();
    const {id, date} = useParams();
    const { state } = useLocation();
    console.log(state)
    const navigate = useNavigate();
    const [inactive, setInactive] = useState(true);
    const [showLoader, setShowLoader] = useState(false);
    const [expenses, setExpenses] = useState([]);
    const [expensesItems, setExpensesItems] = useState([]);
    const [branchName, setBranchName] = useState("");
    const [brachId, setBranchId] = useState("");
    const [cashier, setCashier] = useState("");
    const [prepBy, setPrepBy] = useState("");
    const [expenseDate, setExpenseDate] = useState("");
    const [foodpandaAttachment, setFoodpandaAttachment] = useState("");
    const [grabAttachment, setGrabAttachment] = useState("");
    const [wastage, setWastage] = useState([]);
    const [wastageItems, setWastageItems] = useState([]);
    const [uniqueWastageItems, setUniqueWastageItems] = useState([])
    const [cashDeposit, setCashDeposit] = useState([{
        denomination: '',
        quantity: '',
        amount: ''
    }]);
    const [cashChangeFunds, setCashChangeFunds] = useState([{
        denomination: '',
        quantity: '',
        amount: ''
    }]);
    const [totalCashFunds, setTotalCashFunds] = useState(0);
    const [totalCashDeposit, setTotalCashDeposit] = useState(0);
    const [totalActualSales, setTotalActualSales] = useState(0);
    const [totalSystemSales, setTotalSystemSales] = useState(0);
    const [dailySales, setDailySales] = useState([]);
    const [deposit, setDeposit] = useState([]);
    const [changeFunds, setChangeFunds] = useState([]);
    const [showCashBreakDown, setShowCashBreakDown] = useState(false);
    const [showInitial, setShowInitial] = useState(false);
    const [showEnd, setShowEnd] = useState(false);
    const [filterConfig, setFilterConfig] = useState({
        id: id,
        branch_id: id,
        branch_name: '',
        // date_from: Moment(state.date_from).format("YYYY-MM-DD"),
        // date_to: Moment(state.date_to).format("YYYY-MM-DD"),
        date: date
    })

    const [image, setImage] = useState("");
    const [imageModal, setImageModal] = useState("");
    const [showViewModal, setShowViewModal] = useState(false);
    const handleShowViewModal = (img, type) => {
        setImage(img)
        setImageModal(type)
        setShowViewModal(true);
    }
    const handleCloseViewModal = () => {
        setShowViewModal(false);
        setImage("")
        setImageModal("")
    };

    const [attachment, setAttachment] = useState([]);
    const [showExpenseModal, setShowExpenseModal] = useState(false);
    const handleShowExpenseModal = () => {
        setShowExpenseModal(true);
    }
    const handleCloseExpenseModal = () => {
        setShowExpenseModal(false);
    };


    function handlePrint () {
        // window.open('/dailysales/print/' + id ,'_blank')
        navigate('/dailysales/print/' + id,{state: {
            expenseDetails: expensesItems, 
            wastageItems: wastageItems, 
            deposit: cashDeposit, 
            changeFunds: cashChangeFunds,
            initialInventory: initialInventory,
            endInventory: endInventory,
            actualInventorySales: actualInventorySales,
            systemInventorySales: systemInventorySales,
            salesSummary: salesSummary,
            actualTotalSales: actualTotalSales,
            systemTotalSales: systemTotalSales,
            totalCashDeposit: totalCashDeposit,
            totalCashFunds: totalCashFunds,
            dailySales: dailySales,
            showCashBreakDown: showCashBreakDown,
            showInitial: showInitial,
            showEnd: showEnd,
        }})
    }

    // async function handlePrint() {
    //     toast.loading("Printing sales invoice...", { style: toastStyle() });
    //     setTimeout(() => {
    //         toast.dismiss();
    //         Print();
    //     }, 1000);
    // }

    // function Print() {
    //     let printContents = document.getElementById("printablediv").innerHTML;
    //     let originalContents = document.body.innerHTML;
    //     document.body.innerHTML = printContents;
    //     window.print(printContents);
    //     document.body.innerHTML = originalContents;
    //     refreshPage();
    // }


    // Fetch expense report data
    async function fetchData() {
        setShowLoader(true);
        setExpenses([])
        setExpensesItems([])
        setAttachment([])
        var expenseItem = []

        // const response = await searchExpensesByDate(filterConfig);
        filterConfig.date_to = filterConfig.date_from;
        const response = await searchExpensesByDate(filterConfig);
        if (response.error) {
        } else {
            filterConfig.branch_id = response.data.data[0].branch_id;
            filterConfig.branch_name = response.data.data[0].branch_name;
            var expense = response.data.data.map((expense) => {
                var info = expense;

                expense.expense_item.map((data) => {
                    var info = data;
                    info.approved_by_name = expense.approved_by_name;
                    info.added_on = new Date(data.added_on).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})
                    expenseItem.push(info)
                    // return info;
                });

                return info;
            });
            setExpenses(expense[0])
            setAttachment(expense[0].attachment)
            setExpensesItems(expenseItem)
        }
            setShowLoader(false);
    }
    console.log("attachment", attachment)

    // Fetch Wastage Report
    async function fetchWastage () {
        setShowLoader(true);
        setWastage([]);
        setWastageItems([]);
        const response = await searchWastageByDate(filterConfig);
        if (response.error) {
            // console.log(response.error)
        } else {
            var wastage = response.data.data.map((item) => {
                var info = item;
                info.added_on = Moment(item.added_on).format("MM-DD-YYYY")
                return info;
            });
            setWastage(wastage)

            if (wastage.length != 0) {
                var arr = [];
                wastage[0].wastage_items.map((item) => {
                    async function fetchWastage () {
                        // var res = await searchWastageByDate(item.id); 
                        var res = item;
                        // var temp = res.data.data[0].wastage_items[0];
                        var temp = res;
                        // setWastageItems(oldArray => [...oldArray, temp]);
                        arr.push(temp)
                    }
                    fetchWastage();
                })
                setWastageItems(arr);
            }
        }
        setShowLoader(false);
    }

    // Fetch table data for cash breakdown
    async function fetchCashBreakdown () {
        setShowLoader(true);
        setCashDeposit([]);
        setCashChangeFunds([]);
        const denomination_labels = ["PHP 1000","PHP 500","PHP 200","PHP 100","PHP 50","PHP 20","PHP 20 Coin","PHP 10 Coin","PHP 5 Coin","PHP 1 Coin","PHP 25 Cents"]
        const cc_response = await searchCashCountByDate(filterConfig);
        if (cc_response.error) {
            // console.log(cc_response.error)
        } else {
            var allBills = cc_response.data.data.map((bill) => {
                var info = bill;
                info.count_date = Moment(bill.count_date).format("MM-DD-YYYY")
                info.amount = numberFormat(bill.total_count);
                info.total = numberFormat(bill.total_count);
                const quantity = []
                const amount = []
                quantity.push(info.bill_1000, info.bill_500, info.bill_200, info.bill_100, info.bill_50, info.bill_20, info.coin_20, info.coin_10, info.coin_5, info.coin_1, info.cent_25, info.cent_10, info.cent_5, info.cent_1);
                amount.push((parseFloat(info.bill_1000) * 1000), (parseFloat(info.bill_500) * 500), (parseFloat(info.bill_200) * 200), (parseFloat(info.bill_100) * 100), (parseFloat(info.bill_50) * 50),(parseFloat(info.bill_20) * 20),(parseFloat(info.coin_20) * 20),(parseFloat(info.coin_10) * 10),(parseFloat(info.coin_5) * 5),(parseFloat(info.coin_1) * 1),(parseFloat(info.cent_25) * 25))


                if (bill.type == "deposit") {
                    console.log("-----------------attachments-------------")
                    console.log(bill.food_panda_attachment)
                    console.log(bill.grab_food_attachment)
                    setFoodpandaAttachment(bill.food_panda_attachment ? bill.food_panda_attachment : "")
                    setGrabAttachment(bill.grab_food_attachment ? bill.grab_food_attachment : "")
                    var arr = [];
                    let cashDepositTotal = 0;
                    denomination_labels.map((lab, index) => {
                        cashDepositTotal += amount[index] ? parseFloat(quantity[index]) * parseFloat(lab.replace(/\D/g,'')) : 0;
                        let obj = {'denomination': lab, 'quantity':quantity[index]?numberFormat(quantity[index]):'', 'amount':amount[index]?numberFormat(amount[index]):'' }
                        arr.push(obj);
                    })
                    setCashDeposit(arr);
                    setTotalCashDeposit(numberFormat(cashDepositTotal))
                }  else if (bill.type=="change_funds") {
                    var arr = []
                    let cashFundTotal = 0;
                    denomination_labels.map((lab, index) => {
                        cashFundTotal += amount[index] ? parseFloat(quantity[index]) * parseFloat(lab.replace(/\D/g,'')) : 0;
                        let obj = {'denomination': lab, 'quantity':quantity[index]?numberFormat(quantity[index]):'', 'amount':amount[index]?numberFormat(amount[index]):''}
                        arr.push(obj)
                    })
                    setCashChangeFunds(arr);
                    setTotalCashFunds(numberFormat(cashFundTotal))
                }

                return info;
            });
        }

        setShowLoader(false);
    }

    console.log(cashDeposit)

    async function fetchReport() {
        setDailySales([])

        const response = await getCashCountReport(id, date);
        // console.log(response);

        if (response.error) {
            // console.log(response);
        } else {
            var dailysales = response.data.daily_sales[0]
            dailysales.actual_total = response.data.deposit[0].total_count;
            dailysales.system_total = response.data.change_funds[0].total_count;
            
            var deposit = response.data.deposit[0]
            var changefunds = response.data.change_funds[0]

            setDailySales(dailysales)
        }
    }
    console.log("daily sales", dailySales)

    // Fetch table data for ending inventory
    const [initialInventory, setInitialInventory] = useState([]);
    const [endInventory, setEndInventory] = useState([]);
    async function fetchInventory() {
        setShowLoader(true);
        setInitialInventory([]);
        setEndInventory([]);
        const response = await getDailyInventory(filterConfig);
        // console.log(response)
        if (response.error) {
        // console.log(response)
        } else {
            var initial = response.data.initial_inventories.map((item) => {
                var info = []
                info.name = item.item;
                info.beginning = numberFormat(item.beginning);
                info.delivered = numberFormat(item.delivered);
                info.total = numberFormat(item.initial_total);
                return info;
            })
            // console.log('initial', initial);
            setInitialInventory(initial);

            var end = response.data.ending_inventories.map((item, index) => {
                var info = []
                info.name = item.item;
                info.actual_end = numberFormat(item.actual_end);
                info.system_end = numberFormat(item.system_end);
                info.actual_usage = response.data.usage_inventories[index].actual_usage;
                info.system_usage = response.data.usage_inventories[index].system_usage;
                info.variance = response.data.usage_inventories[index].usage_variance;
                return info;
            })

            // console.log('end:', end)
            setEndInventory(end);
            setShowLoader(false);
        }
    }

    // FETCH INVENTORY SALES DATA
    const [actualInventorySales, setActualInventorySales] = useState([]);
    const [systemInventorySales, setSystemInventorySales] = useState([]);
    const [actualTotalSales, setActualTotalSales] = useState("");
    const [systemTotalSales, setSystemTotalSales] = useState("");
    async function fetchInventorySales () {
        setShowLoader(true);
        const response = await getInventorySales(filterConfig);
        if (response.error) {
            // error
        } else {
            var actual = response.data.actual_sales.map((data) => {
                var info = [];
                info.name = data.item_name;
                info.usage = numberFormat(data.usage);
                info.price = numberFormat(data.price);
                info.amount = numberFormat(parseInt(data.usage)*parseFloat(data.price));
                return info;
            });
            var actual_total_sales = response.data.total_actual_sales;
            setActualTotalSales(actual_total_sales.grand_total);
            setActualInventorySales(actual);

            var system = response.data.system_sales.map((data) => {
                var info = [];
                info.name = data.item_name;
                info.usage = numberFormat(data.usage);
                info.price = numberFormat(data.price);
                info.amount = numberFormat(parseInt(data.usage)*parseFloat(data.price));
                return info;
            });
            var system_total_sales = response.data.total_system_sales;
            setSystemTotalSales(system_total_sales.grand_total);
            setSystemInventorySales(system);

            setShowLoader(false);
        }
    }

    // FETCH SALES SUMMARY DETAILS
    const [salesSummary, setSalesSummary] = useState({})
    async function fetchSalesSummary() {
        setSalesSummary([]);
        const response = await searchDailySales(filterConfig);
        // console.log(response.data.data);
        if (response.error) {
            // error
        } else {
            const res = response.data.data[0];
            setSalesSummary(res);
        }
    }

    React.useEffect(() => {
        fetchData();
        fetchWastage();
        fetchCashBreakdown();
        fetchReport();
        fetchInventory();
        fetchInventorySales();
        fetchSalesSummary();
    }, []);
    console.log("sales summary", salesSummary)
    // console.log("image", image)

    return (
        <div>
            <div className="page">
                <Navbar
                    onCollapse={(inactive) => {
                        setInactive(inactive);
                    }}
                    active={"DS REPORTS"}
                />
            </div>

            <div id="printablediv" ref={componentRef}
                className={`manager-container ${
                    inactive ? "inactive" : "active"
                }`}
            >
                {/* headers */}
                <Row className="mb-4">
                    <Col xs={6}>
                        <h1 className="page-title"> {salesSummary?.branch_name ? salesSummary?.branch_name : ''} </h1>
                    </Col>
                </Row>
                <Row className="mb-2 ms-2">
                    <Col xs={6} className=''>
                    <Row>
                        <h5 className="label-data p-1 green"> <span className="label"> Cashier: </span> {salesSummary?.cashier_name ? salesSummary?.cashier_name : ''}</h5>
                    </Row>
                    <Row >
                        <h5 className="label-data p-1 green"> <span className="label"> Prepared By: </span> {salesSummary?.preparer_name ? salesSummary?.preparer_name : ''} </h5>
                    </Row>
                    <Row>
                        <h5 className="label-data p-1 green"> <span className="label"> Date: </span> {salesSummary?.date ? new Date(salesSummary?.date).toLocaleDateString( "en-us", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) : ''} </h5>
                    </Row>
                    </Col>
                </Row>

                <div className="tab-content noscroll mt-4">
                    <div className="me-2 mt-3 ms-2 mt-1 mb-3 noscroll">
                        <Row className="row-baseline">
                            <Col className='tab-content noscroll p-2 me-2'>
                                <Row className="summ-header m-0 p-0">
                                    <span className="cash-breakdown-subheader d-flex justify-content-center pt-2 pb-2 green">SYTEM SALES SUMMARY</span>
                                </Row>
                                <Row className="mx-3 p-3 justify-content-center">
                                    <Col className=''>
                                        <Row>
                                            <Col xs={3}></Col>
                                            <Col xs={3} className="text-end"><span className="cash-breakdown-subheader justify-content-center green">w/ Disc. DF</span></Col>
                                            <Col xs={3} className="text-end"><span className="cash-breakdown-subheader justify-content-center green">System</span></Col>
                                            <Col xs={3} className="text-end"><span className="cash-breakdown-subheader justify-content-center green">Store Price</span></Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col xs={6}><span className="cash-breakdown-subheader justify-content-center green">Sales Order Total</span></Col>
                                            <Col xs={3} className="text-end"><span className="cash-breakdown-subheader justify-content-center">{salesSummary ? numberFormat(salesSummary.system_cash_sales) : ''}</span></Col>
                                            <Col xs={3} className="text-end"><span className="cash-breakdown-subheader justify-content-center">{salesSummary ? numberFormat(salesSummary.system_cash_sales) : ''}</span></Col>
                                            {/* <Col xs={3}><span className="cash-breakdown-subheader justify-content-center">{salesSummary ? numberFormat((salesSummary.system_cash_sales)) : ''}</span></Col> */}
                                        </Row>
                                        {/* <hr/> */}
                                        {/* <Row className="mt-3">
                                            <Col xs={6}><span className="cash-breakdown-subheader justify-content-center green">GCASH Sales</span></Col>
                                            <Col xs={3}><span className="cash-breakdown-subheader justify-content-center">{salesSummary ? numberFormat(salesSummary.gcash_sales) : ''}</span></Col>
                                            <Col xs={3}><span className="cash-breakdown-subheader justify-content-center">{salesSummary ? numberFormat(salesSummary.gcash_sales) : ''}</span></Col>
                                        </Row> */}
                                        <Row className="mt-3">
                                            <button
                                                type="button"
                                                className={imageModal === "foodpanda" ? "button-primary-full me-3" : "button-primary-clear-full me-3"}
                                                onClick={(e) => handleShowViewModal(foodpandaAttachment, "foodpanda")}
                                            >
                                                <Row className="">
                                                    <Col className="text-left" xs={3}><span>Food Panda</span></Col>
                                                    <Col xs={3} className="text-end"><span className="">{salesSummary ? numberFormat(salesSummary.food_panda_overall) : ''}</span></Col>
                                                    <Col xs={3} className="text-end"><span className="">{salesSummary ? numberFormat(salesSummary.food_panda_sales) : ''}</span></Col>
                                                    <Col xs={3} className="text-end"><span className="">{salesSummary ? numberFormat(salesSummary.food_panda_store_sales) : ''}</span></Col>
                                                </Row>
                                            </button>
                                            {/* <Col xs={6}><span className="cash-breakdown-subheader justify-content-center green">Food Panda Sales</span></Col>
                                            <Col xs={3} className="text-end"><span className="cash-breakdown-subheader justify-content-center">{salesSummary ? numberFormat(salesSummary.food_panda_sales) : ''}</span></Col>
                                            <Col xs={3} className="text-end"><span className="cash-breakdown-subheader justify-content-center">{salesSummary ? numberFormat(salesSummary.food_panda_store_sales) : ''}</span></Col> */}
                                        </Row>
                                        <Row className="mt-3">
                                            <button
                                                type="button"
                                                className={imageModal === "grab" ? "button-primary-full me-3" : "button-primary-clear-full me-3"}
                                                onClick={(e) => handleShowViewModal(grabAttachment, "grab")}
                                            >
                                                <Row className="">
                                                    <Col className="text-left" xs={3}><span>Grab Food</span></Col>
                                                    <Col xs={3} className="text-end"><span className="">{salesSummary ? numberFormat(salesSummary.grab_food_overall) : ''}</span></Col>
                                                    <Col xs={3} className="text-end"><span className="">{salesSummary ? numberFormat(salesSummary.grab_food_sales) : ''}</span></Col>
                                                    <Col xs={3} className="text-end"><span className="">{salesSummary ? numberFormat(salesSummary.grab_food_store_sales) : ''}</span></Col>
                                                </Row>
                                            </button>
                                            {/* <Col xs={6}><span className="cash-breakdown-subheader justify-content-center green">Grab Food Sales</span></Col>
                                            <Col xs={3} className="text-end"><span className="cash-breakdown-subheader justify-content-center">{salesSummary ? numberFormat(salesSummary.grab_food_sales) : ''}</span></Col>
                                            <Col xs={3} className="text-end"><span className="cash-breakdown-subheader justify-content-center">{salesSummary ? numberFormat(salesSummary.grab_food_store_sales) : ''}</span></Col> */}
                                        </Row>
                                        <Row className="mt-3">
                                            <Col xs={6}><span className="cash-breakdown-subheader justify-content-center green">Total Store Discount</span></Col>
                                            <Col xs={3} className="text-end"><span className="cash-breakdown-subheader justify-content-center">{salesSummary ? numberFormat(salesSummary.store_discounts) : ''}</span></Col>
                                            <Col xs={3} className="text-end"><span className="cash-breakdown-subheader justify-content-center">{salesSummary ? numberFormat(salesSummary.store_discounts) : ''}</span></Col>
                                        </Row>
                                        <hr/>
                                        <Row className="">
                                            <Col xs={6}><h5 className="cash-breakdown-subheader">TOTAL SALES</h5></Col>
                                            <Col xs={3} className="text-end"><span className="cash-breakdown-subheader justify-content-center total-sales-cont">{dailySales ? numberFormat(parseFloat(dailySales.system_cash_sales) + parseFloat(dailySales.food_panda_sales) + parseFloat(dailySales.grab_food_sales) + parseFloat(dailySales.store_discounts)) : ''}</span></Col>
                                            <Col xs={3} className="text-end"><span className="cash-breakdown-subheader justify-content-center total-sales-cont">{dailySales ? numberFormat(parseFloat(dailySales.system_cash_sales) + parseFloat(dailySales.food_panda_store_sales) + parseFloat(dailySales.grab_food_store_sales) + parseFloat(dailySales.store_discounts)) : ''}</span></Col>
                                        </Row>                                                                                             
                                    </Col>
                                </Row>
                            </Col>
                            <Col className='tab-content noscroll p-2 me-2 mt-0'>
                                <Row className="summ-header m-0 p-0">
                                    <span className="cash-breakdown-subheader d-flex justify-content-center pt-2 pb-2 green">TOTAL VARIANCE</span>
                                </Row>
                                <Row className="ms-2 mt-4">
                                    <Col><span className="cash-breakdown-subheader">Cash Variance</span></Col>
                                    <Col><span className="cash-breakdown-subheader right">{dailySales ? numberFormat(dailySales.cash_sales_overage) : "0.00" }</span></Col>
                                </Row>
                                    <Row className="ms-2 mt-3">
                                    <Col><span className="cash-breakdown-subheader">Inventory Variance</span></Col>
                                    <Col><span className="cash-breakdown-subheader right">{dailySales ? numberFormat(parseFloat(dailySales.overage_inventory_sales)) : ''}</span></Col>
                                </Row>
                                <hr/>
                                <Row className="ms-2">
                                    {
                                        dailySales ? 
                                            (parseFloat(dailySales.cash_sales_overage) + parseFloat(dailySales.overage_inventory_sales)) >= 0 ? 
                                                <>
                                                <Col xs={6}><span className="cash-breakdown-subheader">VARIANCE</span></Col>
                                                <Col xs={3} className="text-end"><span className="cash-breakdown-subheader">OVER</span></Col>
                                                <Col xs={3} ><span className="cash-breakdown-subheader right total-sales-cont">{dailySales ? numberFormat(parseFloat(dailySales.cash_sales_overage) + parseFloat(dailySales.overage_inventory_sales)) : ''}</span></Col>
                                                </>
                                            : 
                                                <>
                                                <Col xs={6}><span className="cash-breakdown-subheader">VARIANCE</span></Col>
                                                <Col xs={3} className="text-end"><span className="cash-breakdown-subheader">SHORT</span></Col>
                                                <Col xs={3} ><span className="cash-breakdown-subheader right total-sales-cont-red">{dailySales ? numberFormat(parseFloat(dailySales.cash_sales_overage) + parseFloat(dailySales.overage_inventory_sales)) : ''}</span></Col>
                                                </>
                                        : ""
                                    }
                                </Row>
                                <Row className="ms-2 me-1 mt-5 mb-4" >
                                    <button
                                        type="button"
                                        className={parseFloat(dailySales.cancelled_transactions) <= 0 ? "button-primary-clear-full me-3" : "button-warning me-3"}
                                        onClick={(e) => {
                                            navigate("/dailyorders/branch/" + filterConfig.branch_id, {
                                                state: { 
                                                    branch_id: filterConfig.branch_id,
                                                    branch_name: salesSummary?.branch_name,
                                                    date: filterConfig.date,
                                                    date_from: filterConfig.date,
                                                    date_to: filterConfig.date,
                                                    status: "voided"
                                                },
                                        });}}
                                    >
                                        {/* TOTAL CANCELLED {" ("} {dailySales ? dailySales.total_cancelled : ''} {")"} */}

                                        <Row>
                                            <Col className="text-left"><span className="">Total Cancelled</span></Col>
                                            <Col  ><span className=" right">{dailySales ? dailySales.total_cancelled : ''}</span></Col>
                                        </Row>
                                    </button>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>

                <hr/>
                <div className="tab-content noscroll mx-2">
                    <Row className="summ-header m-0 p-0">
                        <span className="cash-breakdown-subheader d-flex justify-content-center pt-2 pb-2 green">CASH SALES AND DEPOSIT COMPARISON</span>
                    </Row>
                    <Row className="p-3 justify-content-center">
                        <Col xs={5}>
                            <Row className=" d-flex mb-1 justify-content-center p-3 mx-2">
                                <Row className=" justify-content-center">
                                    <Row>
                                        <span className="cash-breakdown-subheader green text-center">
                                            ACTUAL TOTAL CASH
                                        </span>
                                    </Row>
                                    <Col xs={12} className='p-2 m-1'>
                                        <Row className="mt-1">
                                            <Col className="text-start " xs={6}><span className="print-data text-start ">Cash Received</span></Col>
                                            <Col className="text-end" xs={6}><span className="cash-breakdown-subheader  text-end">{dailySales ? numberFormat(parseFloat(dailySales.actual_cash_sales) + parseFloat(dailySales.total_expense) ) : ''}</span></Col>
                                        </Row>
                                        <Row className="mt-1">
                                            <Col className="text-start " xs={6}><span className="print-data text-start ">Expense</span></Col>
                                            <Col className="text-end " xs={6}><span className="print-data text-end ">-{dailySales ? numberFormat(dailySales.total_expense) : '0.00'}</span></Col>
                                        </Row>
                                        <hr/>
                                        <Row className="mt-1">
                                            <Col className="text-start " xs={8}><span className="print-data text-start ">Cash to be Deposited</span></Col>
                                            <Col className="text-end " xs={4}><span className="print-data text-end ">{dailySales ? numberFormat(dailySales.actual_cash_sales)  : ''}</span></Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Row>
                            <Row className="d-flex mb-1 justify-content-center p-3 mx-2">
                                <Row className=" justify-content-center">
                                    <Row>
                                        <span className="cash-breakdown-subheader green text-center">
                                            SYSTEM TOTAL CASH
                                        </span>
                                    </Row>
                                    <Col xs={12} className='p-2 m-1'>
                                        <Row className="mt-1">
                                            <Col className="text-start " xs={6}><span className="print-data text-start">Cash Received</span></Col>
                                            <Col className="text-end" xs={6}><span className="cash-breakdown-subheader text-end">{dailySales ? numberFormat(parseFloat(dailySales.system_cash_sales) ) : ''}</span></Col>
                                        </Row>
                                        <Row className="mt-1">
                                            <Col className="text-start " xs={6}><span className="print-data text-start">Expenses</span></Col>
                                            <Col className="text-end " xs={6}><span className="print-data text-end ">-{dailySales ? numberFormat(dailySales.total_expense) : '0.00'}</span></Col>
                                        </Row>
                                        <hr/>
                                        <Row className="mt-1">
                                            <Col className="text-start " xs={8}><span className="print-data text-start">Cash To Be Deposited</span></Col>
                                            <Col className="text-end " xs={4}><span className="print-data text-end ">{dailySales ? numberFormat(parseFloat(dailySales.system_cash_sales) - parseFloat(dailySales.total_expense)): ''}</span></Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Row>
                        </Col>
                        <Col xs={2}>
                            <div className="vl mt-3"></div>
                        </Col>
                        <Col xs={5}>
                            <Row className="justify-content-center">
                                <Col className='me-5'>
                                    <Row className="ms-2 mt-3">
                                        <button
                                            type="button"
                                            className={showCashBreakDown ? "button-primary-full me-3" : "button-primary-clear-full me-3"}
                                            onClick={(e) => setShowCashBreakDown(!showCashBreakDown)}
                                        >
                                            <Row>
                                                <Col className="text-left"><span>Actual Cash Deposit Total</span></Col>
                                                <Col><span className=" right">{dailySales ? numberFormat(dailySales.actual_total) : "0.00" }</span></Col>
                                            </Row>
                                        </button>
                                    </Row>
                                    <Row className="ms-2 mt-3">
                                        <Col><span className="cash-breakdown-subheader">System Cash Deposit Total</span></Col>
                                        <Col><span className="cash-breakdown-subheader right">{dailySales ? numberFormat(parseFloat(dailySales.system_cash_sales) - parseFloat(dailySales.total_expense)) : ''}</span></Col>
                                    </Row>
                                    <hr/>
                                    <Row className="ms-2">
                                        {
                                            salesSummary ? 
                                                (parseFloat(salesSummary.cash_sales_overage)) > 0 ? 
                                                    <>
                                                    <Col xs={6}><span className="cash-breakdown-subheader">VARIANCE</span></Col>
                                                    <Col xs={3} className="text-end"><span className="cash-breakdown-subheader align-end">OVER</span></Col>
                                                    <Col xs={3}><span className="cash-breakdown-subheader right total-sales-cont">{salesSummary ? numberFormat(salesSummary.cash_sales_overage) : ''}</span></Col>
                                                    </>
                                                : 
                                                    <>
                                                    <Col xs={6}><span className="cash-breakdown-subheader">VARIANCE</span></Col>
                                                    <Col xs={3} className="text-end"><span className="cash-breakdown-subheader">SHORT</span></Col>
                                                    <Col xs={3}><span className="cash-breakdown-subheader right total-sales-cont-red">{salesSummary ? numberFormat(salesSummary.cash_sales_overage) : ''}</span></Col>
                                                    </>
                                            : ""
                                        }
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <hr/>

                {
                    <div className={showCashBreakDown ? "" : "display-none"}>
                        <div className="cash-breakdown-container mt-1 p-4 noscroll">
                            <Row>
                                <Col>
                                    <h3 className="report-subheader green">CASH BREAKDOWN</h3>
                                </Col>
                            </Row>
                            <hr/>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col>
                                            <h4 className="cash-breakdown-subheader bold">DEPOSIT</h4>
                                        </Col>
                                        <Col>
                                            <h4 className="cash-breakdown-subheader right"> TOTAL: {totalCashDeposit} </h4>
                                        </Col>
                                    </Row>
                                    <div className="tab-content">
                                        <div className="below">
                                            <Table
                                                tableHeaders={[
                                                    "DENOMINATION",
                                                    "QUANTITY",
                                                    "AMOUNT",
                                                ]}
                                                headerSelector={[
                                                    "denomination",
                                                    "quantity",
                                                    "amount",
                                                ]}
                                                tableData={cashDeposit}
                                                showLoader={showLoader}
                                            />
                                        </div>
                                        <div className="mb-2" />
                                    </div>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <h4 className="cash-breakdown-subheader bold">CHANGE FUNDS</h4>
                                        </Col>
                                        <Col>
                                            <h4 className="cash-breakdown-subheader right">TOTAL: {totalCashFunds} </h4>
                                        </Col>
                                    </Row>
                                    <div className="noscroll tab-content">
                                        <div className="below">
                                            <Table
                                                tableHeaders={[
                                                    "DENOMINATION",
                                                    "QUANTITY",
                                                    "AMOUNT",
                                                ]}
                                                headerSelector={[
                                                    "denomination",
                                                    "quantity",
                                                    "amount",
                                                ]}
                                                tableData={cashChangeFunds}
                                                showLoader={showLoader}
                                            />
                                        </div>
                                        <div className="mb-2" />
                                    </div>
                                </Col>
                            </Row>
                            <div className="d-flex mx-2 mt-2 mb-2 justify-content-evenly">
                                <Col xs={6}>
                                    <Row className="text-end">
                                        <div className="d-flex my-2 justify-content-end ">
                                            <Col className="print-data">
                                                Total Cash Deposit: {totalCashDeposit}
                                            </Col>
                                        </div>
                                    </Row>
                                </Col>
                                <Col xs={6}>
                                    <Row className="text-end">
                                        <div className="d-flex my-2 justify-content-end ">
                                            <Col className="print-data">
                                                Total Change Funds: {totalCashFunds}
                                            </Col>
                                        </div>
                                    </Row>
                                    <Row className="text-end">
                                        <div className="d-flex my-2 justify-content-end ">
                                            <Col className="print-data">
                                                Initial Cash in Drawer: {salesSummary.initial_cash_in_drawer ? numberFormat(salesSummary.initial_cash_in_drawer) : "0.00" }
                                            </Col>
                                        </div>
                                    </Row>
                                </Col>
                            </div>
                        </div>
                        <hr/>
                    </div>
                }

                <div className="tab-content noscroll mt-4">
                    {/* <Row className="m-0">
                        <h3 className="cash-breakdown-subheader d-flex justify-content-center green pt-1">SUMMARY</h3>
                    </Row> */}
                    <div className="me-2 mt-3 ms-2 mt-1 mb-3 noscroll">
                        <Row>
                            <Col className=' p-2  me-2 mx-3'>
                                <Row className="summ-header m-0 p-0">
                                    <span className="cash-breakdown-subheader d-flex justify-content-center pt-2 pb-2 green">INVENTORY SALES COMPARISON</span>
                                </Row>
                                <Row className="ms-2 mt-2 p-2 me-3">
                                    <Col xs={7}><span className="cash-breakdown-subheader justify-content-center green lighter">Actual Inventory Sales</span></Col>
                                    <Col xs={5} className='d-flex justify-content-end'><span className="cash-breakdown-subheader">{salesSummary ? numberFormat(salesSummary.actual_inventory_sales) : ''}</span></Col>
                                </Row>
                                <Row className="ms-2 p-2 me-3">
                                    <Col xs={8}><span className="cash-breakdown-subheader justify-content-center green lighter">System Inventory Sales</span></Col>
                                    <Col xs={4} className='d-flex justify-content-end'><span className="cash-breakdown-subheader">-{salesSummary ? numberFormat(salesSummary.system_inventory_sales) : ''}</span></Col>
                                </Row>
                                <hr/>
                                <Row className="ms-2 mb-2 p-2 me-3">
                                    {
                                        salesSummary ? 
                                            (parseFloat(salesSummary.overage_inventory_sales)) >= 0 ? 
                                                <>
                                                <Col xs={6}><span className="cash-breakdown-subheader">VARIANCE</span></Col>
                                                <Col xs={4} className="text-end"><span className="cash-breakdown-subheader">OVER</span></Col>
                                                <Col xs={2}><span className="cash-breakdown-subheader right total-sales-cont">{salesSummary ? numberFormat(salesSummary.overage_inventory_sales) : ''}</span></Col>
                                                </>
                                            : 
                                                <>
                                                <Col xs={6}><span className="cash-breakdown-subheader">VARIANCE</span></Col>
                                                <Col xs={4} className="text-end"><span className="cash-breakdown-subheader">SHORT</span></Col>
                                                <Col xs={2}><span className="cash-breakdown-subheader right total-sales-cont-red">{salesSummary ? numberFormat(salesSummary.overage_inventory_sales) : ''}</span></Col>
                                                </>
                                        : ""
                                    }
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
                <hr/>
                <div className="cash-breakdown-container p-4">
                    <Row>
                        <Col xs={8}>
                            <h3 className="report-subheader green">INVENTORY SALES</h3>
                        </Col>
                        <Col xs={2}>
                            <button
                                type="button"
                                className={showInitial ? "button-primary-full me-3" : "button-primary-clear-full me-3"}
                                onClick={(e) => setShowInitial(!showInitial)}
                            >
                                Initial
                            </button>
                        </Col>
                        <Col xs={2}>
                            <button
                                type="button"
                                className={showEnd ? "button-primary-full me-3" : "button-primary-clear-full me-3"}
                                onClick={(e) => setShowEnd(!showEnd)}
                            >
                                End
                            </button>
                        </Col>
                    </Row>
                    <hr/>
                    <Row className="row-baseline">
                        <Col xs={6} className='noscroll'>
                            <Row>
                                <Col>
                                    <h6 className="cash-breakdown-subheader bold">Actual</h6>
                                </Col>
                                <Col>
                                    <h6 className="cash-breakdown-subheader right"> TOTAL: {numberFormat(actualTotalSales)} </h6>
                                </Col>
                            </Row>
                            <div className="tab-content">
                                <div className="below">
                                    {/* table */}
                                    <Table
                                    tableHeaders={[
                                        "ITEM",
                                        "USAGE",
                                        "PRICE",
                                        "AMOUNT",
                                    ]}
                                    headerSelector={[
                                        "name",
                                        "usage",
                                        "price",
                                        "amount",
                                    ]}
                                        tableData={actualInventorySales}
                                        showLoader={showLoader}
                                    />
                                </div>
                                <div className="mb-2" />
                            </div>
                        </Col>
                        <Col xs={6}>
                            <Row>
                                <Col>
                                    <h6 className="cash-breakdown-subheader bold">System</h6>
                                </Col>
                                <Col>
                                    <h6 className="cash-breakdown-subheader right"> TOTAL: {numberFormat(systemTotalSales)} </h6>
                                </Col>
                            </Row>
                            <div className="tab-content noscroll">
                                <div className="below">
                                    {/* table */}
                                    <Table
                                        tableHeaders={[
                                        "ITEM",
                                        "USAGE",
                                        "PRICE",
                                        "AMOUNT",
                                        ]}
                                        headerSelector={[
                                            "name",
                                            "usage",
                                            "price",
                                            "amount",
                                        ]}
                                        tableData={systemInventorySales}
                                        showLoader={showLoader}
                                    />
                                </div>
                                <div className="mb-2" />
                            </div>
                        </Col>
                    </Row>
                </div>

                {
                    <div className={showInitial ? "" : "display-none"}>
                        <hr/>
                        <div className="cash-breakdown-container p-4 pt-0">
                            <Row>
                                <Col className='noscroll'>
                                    <h6 className="cash-breakdown-subheader bold">INITIAL</h6>
                                    <div className="tab-content noscroll">
                                        <div className="below">
                                            {/* table */}
                                            <Table
                                                tableHeaders={[
                                                    "ITEM",
                                                    "BEGINNING",
                                                    "DELIVERED",
                                                    "TOTAL",
                                                ]}
                                                headerSelector={[
                                                    "name",
                                                    "beginning",
                                                    "delivered",
                                                    "total",
                                                ]}
                                                tableData={initialInventory}
                                                showLoader={showLoader}
                                            />
                                        </div>
                                        <div className="mb-2" />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                }
                {
                    <div className={showEnd ? "" : "display-none"}>
                        <hr/>
                        <div className="cash-breakdown-container p-4 pt-0">
                            <Row className="mt-3">
                                <Col className='noscroll'>
                                    <h6 className="cash-breakdown-subheader bold">END</h6>
                                    <div className="tab-content noscroll">
                                        <div className="below">
                                            <Table
                                                tableHeaders={[
                                                    "ITEM",
                                                    "ACTUAL END",
                                                    "SYSTEM END",
                                                    "ACTUAL USAGE",
                                                    "SYSTEM USAGE",
                                                    "VARIANCE"
                                                ]}
                                                headerSelector={[
                                                    "name",
                                                    "actual_end",
                                                    "system_end",
                                                    "actual_usage",
                                                    "system_usage",
                                                    "variance"
                                                ]}
                                                tableData={endInventory}
                                                showLoader={showLoader}
                                            />
                                        </div>
                                        <div className="mb-2" />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                }

                <hr/>
                <Row className="mt-4">  
                    <Col xs={2}>
                        <h3 className="report-subheader green">EXPENSES REPORT</h3>
                    </Col>
                    <Col xs={2}>
                        <button
                            type="button"
                            className={"button-primary-full me-3"}
                            onClick={(e) => handleShowExpenseModal()}
                        >
                            Attachment
                        </button>
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <h3 className="report-subheader"> TOTAL: {expenses[0]?.grand_total ? numberFormat(expenses[0]?.grand_total) : "0.00" } </h3>
                    </Col>
                </Row>
                <div className="tab-content mt-2">
                    <div className="below">
                        <Table
                            tableHeaders={[
                                "ITEM",
                                "QUANTITY",
                                "UNIT",
                                "UNIT PRICE",
                                "AMOUNT",
                                "TIME ADDED",
                                "APPROVED BY",
                            ]}
                            headerSelector={[
                                "name",
                                "qty",
                                "unit",
                                "price",
                                "total",
                                "added_on",
                                "approved_by_name",
                            ]}
                            tableData={expensesItems}
                            showLoader={showLoader}
                        />
                    </div>
                    <div className="mb-2" />
                </div>
                <hr/>

                <Row className="mt-4">
                    <Col>
                        <h3 className="report-subheader green">WASTAGE REPORT</h3>
                    </Col>
                </Row>
                <div className="tab-content">
                    <div className="below">
                        <Table
                            tableHeaders={[
                                "ITEM",
                                "QUANTITY",
                                "UNIT",
                                "REASON"
                            ]}
                            headerSelector={[
                                "name",
                                "qty",
                                "unit",
                                "reason",
                            ]}
                            tableData={wastageItems}
                            showLoader={showLoader}
                        />
                    </div>
                    <div className="mb-2" />
                </div>
            

                {/* Print and Close Button */}
                <div className="d-flex justify-content-end pt-2 mb-3">
                    <button
                        name="action"
                        className="button-primary justify-content-center align-items-center print-btn"
                        onClick={handlePrint}
                    >
                        Print
                    </button>
                    {/* <ReactToPrint
                        trigger={() => 
                            <button name="action" className="button-primary justify-content-center align-items-center print-btn d-flex justify-content-end"
                            >Print</button>}
                        content={() => componentRef.current}
                    /> */}
                    <button
                        name="action"
                        className="ms-2 button-primary justify-content-center align-items-center close-btn"
                        onClick={() => navigate("/dailysales")}
                    >
                        Close
                    </button>
                </div>
            </div>

            
            <Modal show={showViewModal} onHide={()=>handleCloseViewModal()} size="md" centered>
                <Modal.Body className='pt-5'>
                    {
                        (image !== "" && image !== undefined) ? (
                        <>
                            <div className='row justify-content-center'>
                                <div className='col-sm-12 align-center' style={{textAlignLast:"center"}}><img src={`data:image/png;base64,${image}`} width={400} height={400} alt="no image"/></div>
                            </div>
                        </>
                        ) : (
                            <>
                                <div className="no-data-cont mb-4">
                                    <div className='mt-5 mb-2' style={{textAlignLast:"center"}} alt="no data found"><img src={NoDataImg} width={400} height={400}/></div>
                                    <span>Uh Oh! No Attachment found.</span>
                                </div>
                            </>
                        )
                    }
                    <div className='d-flex justify-content-center my-4'>
                        <button
                            className="button-secondary"
                            onClick={()=>handleCloseViewModal()}
                        >
                            Close
                        </button>
                    </div>
                
                </Modal.Body>
            </Modal>

            <Modal show={showExpenseModal} onHide={()=>handleCloseExpenseModal()} size="md" centered>
                <Modal.Body className='pt-5'>
                    {attachment ? (
                        <>
                        {attachment.map((data) => {
                            if(data.base_64 !== "") {
                                return(
                                    <>
                                        <div className='mt-5 mb-2' style={{textAlignLast:"center"}}><img src={`data:image/png;base64,${data.base_64}`} width={400} height={400}/></div>
                                        {/* <div className='d-flex justify-content-center my-4'>
                                            <button
                                                className="button-primary"
                                                onClick={()=>handleShowViewModal(data.base_64)}
                                            >
                                                View
                                            </button>
                                        </div> */}
                                    </>
                                )
                            } else {
                                return(
                                    <>
                                        <div className="no-data-cont">
                                            <div className='mt-5 mb-2' style={{textAlignLast:"center"}} alt="no data found"><img src={NoDataImg} width={400} height={400}/></div>
                                            <span>Uh Oh! No data found.</span>
                                        </div>
                                    </>
                                )
                            }
                        })}
                        </>
                    ) : (
                        <div className="no-data-cont">
                            <div className='mt-5 mb-2' style={{textAlignLast:"center"}} alt="no data found"><img src={NoDataImg} width={400} height={400}/></div>
                            <span>Uh Oh! No data found.</span>
                        </div>
                    )}
                    <div className='d-flex justify-content-center my-4'>
                        <button
                            className="button-secondary"
                            onClick={()=>handleCloseExpenseModal()}
                        >
                            Close
                        </button>
                    </div>
                
                </Modal.Body>
            </Modal>
        </div>
    );
}
