import React, { useState } from "react";
import { Col, Form, Row, Tab, Tabs} from "react-bootstrap";
import toast from "react-hot-toast";
import Select from "react-select";
import { useNavigate, useLocation } from "react-router-dom";
import Delete from "../../../Components/Modals/DeleteModal";
import Navbar from "../../../Components/Navbar/Navbar";
import Table from "../../../Components/TableTemplate/Table";
import AdjustmentTable from "./../../Inventory/Adjustment/AdjustmentTable";
import {
    dateFormat,
    formatDateNoTime,
    formatDate,
    numberFormat,
    refreshPage,
    toastStyle,
    getTodayDate,
    getType,
    TokenExpiry
} from "../../../Helpers/Utils/Common";
import DatePicker from "react-datepicker";
import Moment from "moment";
import MarkModal from './../StoreDeposit/MarkModal';

import { getAllFranchisee } from "../../../Helpers/apiCalls/franchiseeApi";
import { searchExpenses, deleteExpenses } from "../../../Helpers/apiCalls/Reports/DailyExpensesApi";
import { searchDailySales } from "../../../Helpers/apiCalls/Reports/DailySalesApi";
import { getAllBranches } from "../../../Helpers/apiCalls/Manage/Branches";
import { updateDailySalesStatus } from './../../../Helpers/apiCalls/Reports/DailySalesApi';


export default function DailySales({undeposited}) {
    let navigate = useNavigate();
    var dateToday = getTodayDate();
    const accountType = getType();
    const [inactive, setInactive] = useState(true);
    const today = Moment().format("MM/DD/YYYY");
    const date = new Date();
    date.setDate(date.getDate() - 7);
    const [filterConfig, setFilterConfig] = useState({
        date_from: date,
        date_to: dateToday,
        status: "submitted",
        is_deposited: undeposited ? "N" : ""
    })
    const [branches, setBranches] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [expenses, setExpenses] = useState([]);
    const [totalQuantity, setTotalQuantity] = useState([]);
    const [averagePrice, setAveragePrice] = useState([]);
    const [totalAmount, setTotalAmount] = useState([]);
    const [filteredExpenses, setFilteredExpenses] = useState([]);

     /* delete modal handler */
     const [showDeleteModal, setShowDeleteModal] = useState(false);
     const handleShowDeleteModal = () => setShowDeleteModal(true);
     const handleCloseDeleteModal = () => setShowDeleteModal(false); 
     const [selectedRow, setSelectedRow] = useState([]);
    const [checkedItems, setCheckedItems] = useState([]);
    const [selectedTab, setSelectedTab] = useState("submitted");
     
    const inventoryVariance = [
        {value: '0', name: 'No Variance'},
        {value: '1', name: 'Over'},
        {value: '2', name: 'Short'},
    ];

    const cashVariance = [
        {name: 'No Variance', value: '0'},
        {name: 'Over', value:'1'},
        {name: 'Short', value:'2'},
    ];
    
     /* posted Modal */
     const [showMarkModal, setShowMarkModal] = useState(false);
     const handleShowMarkModal = () => (
        checkedItems.length != 0
            ? setShowMarkModal(true)
            : toast.error("Please select payment to mark as reviewed", {
                  style: toastStyle(),
              })
        );
     const handleCloseMarkModal = () => setShowMarkModal(false);

    // SEARCH USER
    function handleOnSearch(e) {
        const { name, value } = e.target;
        setFilterConfig((prev) => {
            return { ...prev, [name]: value };
        });
    }

    const handleTabSelect = (key) => {
        setCheckedItems([])
        if(key === "submitted") {
            setSelectedTab(key);
        } else {
            setSelectedTab("");
        }
        
        setFilterConfig((prev) => {
            return {
                ...prev,
                tab: key,
                status: key,
                date_from: date,
                date_to: dateToday,
                inventory_variance_discrepancy: "",
                cash_variance_discrepancy: "",
                is_deposited: "",
            };
        });
    };

    function handleSelectChange(e, row) {
        setSelectedRow(row);

        if (e.target.value === "view") {
            // navigate("/dailysales/view/" + row.id, 
            //     {
            //         state: {
            //             date: row.date,
            //             branch_id: row.branch_id,
            //             date_from: filterConfig.date_from,
            //             date_to: filterConfig.date_to
            //         },
            //     }
            // )
            {
                window.open('/dailysales/view/' + row.branch_id + "/" + row.date ,'_blank')
            };
        } else if (e.target.value === "deduction") {
            navigate("/deductions/" + row.branch_id + "/" + row.branch_name + "/" + row.date, {
                state: { 
                    source: "sales",
                    date: row.date,
                    daily_sale_id: row.id,
                    cash_variance: row.cash_sales_overage,
                    inventory_variance: row.overage_inventory_sales,
                 },
            });
        } else if (e.target.value === "delete") {
            handleShowDeleteModal();
        }
    }


    function ActionBtn(row, type) {
        return (
            <Form.Select
                name="action"
                className="PO-select-action"
                onChange={(e) => handleSelectChange(e, row)}
            >
                <option value="" hidden selected>
                    Select
                </option>
                { accountType === "admin" ? (
                    <option value="view" className="color-options">
                        View
                    </option>
                    ): null
                }
                { accountType === "admin" ? (
                    <option value="deduction" className="color-options">
                        Employee deductions
                    </option>
                    ): null
                }
                {/* { accountType === "admin" ? (
                    <option value="delete" className="color-red">
                        Delete
                    </option>
                    ): null
                } */}
            </Form.Select>
        );
    }

    function ViewBtn(row) {
        return (
            <button
                name="action"
                className="btn btn-sm view-btn-table"
                id={row.id}
                onClick={() => handlePrint(row)}
                // value="payment-pi"
            >
                View
            </button>
        );
    }

    function handlePrint(row) {
        // navigate("/dailysales/view/" + row.id, 
        //         {
        //             state: {
        //                 date: row.date,
        //                 branch_id: row.branch_id,
        //                 date_from: filterConfig.date_from,
        //                 date_to: filterConfig.date_to
        //             },
        //         }
        //     )
        {
            window.open('/dailysales/view/' + row.branch_id + "/" + row.date, '_blank')
        };
    }

    async function fetchBranches() {
        setShowLoader(true);

        const response = await getAllBranches();
        if (response.error) {
        } else {
            var allBranches = response.data.data.data.map((data) => {
                var branch = data;
                branch.label = data.name
                branch.value = data.id
                return branch;
            });
            setBranches([{label: "All Branches", value:""}, ...allBranches]);
        }
        setShowLoader(false);
    }

    function searchFilter(e) {
        let filteredArr = [];
        let term = e.target.value.toLowerCase();
        if(expenses) {
            expenses.forEach(function(expense) {
                // console.log(expense.branch_name)
                if((expense.branch_name).toLowerCase().includes(term)) {
                    filteredArr.push(expense);
                }
            })
        }
        if(term == "") {
            setFilteredExpenses(expenses);
        } else {
            setFilteredExpenses(filteredArr);
        }
    }

    async function fetchData() {
        setShowLoader(true);
        setExpenses([])
        setFilteredExpenses([])

        const response = await searchDailySales(filterConfig);

        if (response.error) {
        } else {
            var allBills = response.data.data.map((bill) => {
                var info = bill;
                info.total_variance = numberFormat(parseFloat(bill.cash_sales_overage) + parseFloat(bill.overage_inventory_sales));
                info.date = bill.date !== "0000-00-00" ? Moment(bill.date).format("MMM DD, YYYY") : "";
                info.cash_sales_overage = numberFormat(bill.cash_sales_overage)
                info.overage_inventory_sales = numberFormat(bill.overage_inventory_sales)
                info.total_sales = numberFormat(bill.total_sales)
                return info;
            });
            // console.log(allBills);
            setFilteredExpenses(allBills);
            setExpenses(allBills);
        }
        setShowLoader(false);
    }

    async function handleDeleteExpense() {
        const response = await deleteExpenses(selectedRow.id);
        if (response.data) {
            toast.success("Expense Deleted Successfully!", {
                style: toastStyle(),
            });
            setTimeout(() => refreshPage(), 1000);
        } else {
            toast.error("Error Deleting Expense", {
                style: toastStyle(),
            });
        }
    }

    console.log(checkedItems)

    async function handleMarkAsReviewed() {
        console.log(checkedItems)
        const response = await updateDailySalesStatus(checkedItems);
        console.log(response)
        if (response.data) {
            toast.success("Successfully marked as reviewed!", {
                style: toastStyle(),
            });
            setTimeout(() => refreshPage(), 1000);
        } else if (response.error) {
            TokenExpiry(response);
            toast.error(
                "Something went wrong",
                { style: toastStyle() }
            );
            setTimeout(() => refreshPage(), 1000);
        }
    }

    function handleOnCheck(row) {
        setCheckedItems(row.selectedRows);
    }


    function DepositedBtn(row) {
        return (
            <span >
                {row.is_deposited !== "0" ?  
                    <span >✅ </span> : 
                    <span >❌ </span> 
                }
            </span>
        );
    }

    function InventoryVariance(row) {
        return (
            <span >
                {row.inventory_variance === "1" ?  
                    <span >🔴 With Discrepancy</span> : 
                    <span >🟢 No Discrepancy</span> 
                }
            </span>
        );
    }

    function CashVariance(row) {
        return (
            <span >
                {row.cash_variance === "1" ?  
                    <span >🔴 With Discrepancy</span> : 
                    <span >🟢 No Discrepancy</span> 
                }
            </span>
        );
    }

    function TotalVarianceBtn(row) {
        return (
            <span
                className={parseFloat(row.total_variance) > 0 ? "ml-auto ps-label green" : parseFloat(row.total_variance) < 0 ? "ml-auto ps-label red" : "ml-auto ps-label black"}
            >
                {row.total_variance}
            </span>
        );
    }

    function InvVarianceBtn(row) {
        return (
            <span
            className={parseFloat(row.overage_inventory_sales) > 0 ? "ml-auto ps-label green" : parseFloat(row.overage_inventory_sales) < 0 ? "ml-auto ps-label red" : "ml-auto ps-label black"}
            >
                {row.overage_inventory_sales}
            </span>
        );
    }

    function CashVarianceBtn(row) {
        return (
            <span
            className={parseFloat(row.cash_sales_overage) > 0 ? "ml-auto ps-label green" : parseFloat(row.cash_sales_overage) < 0 ? "ml-auto ps-label red" : "ml-auto ps-label black"}
            >
                {row.cash_sales_overage}
            </span>
        );
    }

    React.useEffect(() => {
        fetchBranches();
    }, []);

    React.useEffect(() => {
        fetchData();
    }, [filterConfig]);

    return (
        <div>
            <div className="page">
                <Navbar
                    onCollapse={(inactive) => {
                        setInactive(inactive);
                    }}
                    active={"DS REPORTS"}
                />
            </div>

            <div
                className={`manager-container ${
                    inactive ? "inactive" : "active"
                }`}
            >
                {/* headers */}
                <Row className="mb-4">
                    <Col xs={6}>
                        <h1 className="page-title"> DAILY SALES REPORT </h1>
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <input
                            type="text"
                            name="term"
                            id="term"
                            placeholder="Search branch name"
                            className="search-bar"
                            onChange={(e) => searchFilter(e)}
                        ></input>
                    </Col>
                </Row>

                {
                    accountType !== "purchasing_staff" && (
                        <>
                            <Tabs
                                activeKey={filterConfig.shop}
                                // defaultActiveKey={filterConfig.shop}
                                id="PO-tabs"
                                className="manager-tabs"
                                onSelect={handleTabSelect}
                            >
                                <Tab eventKey="submitted" title="Submitted">
                                    <div className="tab-content">
                                        {/* filters */}
                                        <div className="my-2 px-2 PO-filters d-flex">
                                            <span className="me-2 align-middle mt-2 ps-label">
                                                Filter By:
                                            </span>
                                            <Select
                                                className="dropsearch-filter px-0 py-0 me-2"
                                                classNamePrefix="react-select"
                                                placeholder="Select Branch"
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    backgroundColor: state.isSelected ? 'white' : '#59c8e3',
                                                    borderRadius: "7px",
                                                    border: "0px",
                                                    minHeight: "20px",
                                                    maxHeight: "35px",
                                                    }),
                                                    input: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        color: state.isSelected? "white": "white",
                                                        
                                                    }),
                                                    dropdownIndicator: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        color: "white"
                                                        
                                                    }),
                                                    singleValue: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        color: "white"
                                                        
                                                    }),
                                                    placeholder: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        color: "white"
                                                        
                                                    }),
                                                    
                                                }}
                                                // value={branch}
                                                options={branches}
                                                onChange={(e) => { setFilterConfig((prev) => {
                                                        return { ...prev, "branch_id": e.value };
                                                    });}}
                                            />

                                            <Form.Select
                                                name="inventory_variance_discrepancy"
                                                className="date-filter me-2"
                                                onChange={(e) => handleOnSearch(e)}
                                                value={filterConfig.inventory_variance_discrepancy}
                                            >
                                                <option value="" selected>
                                                    Inventory Variance
                                                </option>
                                                {
                                                    inventoryVariance.map((varr) => (
                                                        <option value={varr.value}>{varr.name}</option>
                                                    ))
                                                }
                                            </Form.Select>

                                            <Form.Select
                                                name="cash_variance_discrepancy"
                                                className="date-filter me-2"
                                                onChange={(e) => handleOnSearch(e)}
                                                value={filterConfig.cash_variance_discrepancy}
                                            >
                                                <option value="" selected>
                                                    Cash Variance
                                                </option>
                                                {
                                                    cashVariance.map((cash) => (
                                                        <option value={cash.value}>{cash.name}</option>
                                                    ))
                                                }
                                            </Form.Select>
                                            
                                            <Form.Select
                                                name="total_variance"
                                                className="date-filter me-2"
                                                onChange={(e) => handleOnSearch(e)}
                                                value={filterConfig.total_variance}
                                            >
                                                <option value="" selected>
                                                    Total Variance
                                                </option>
                                                <option value="0"> No Variance </option>
                                                <option value="1"> Over </option>
                                                <option value="2"> Short </option>
                                            </Form.Select>

                                            <Form.Select
                                                name="is_deposited"
                                                className="date-filter me-2"
                                                onChange={(e) => handleOnSearch(e)}
                                                value={filterConfig.is_deposited}
                                            >
                                                <option value="" selected disabled>
                                                    Is Deposited
                                                </option>
                                                <option value=""> All </option>
                                                <option value="Y"> Deposited </option>
                                                <option value="N"> Not Deposited </option>
                                            </Form.Select>
                                        </div>
                                        <div className="pl-5 PO-filters d-flex">
                                            <span className="me-2 align-middle mt-2 ps-label">
                                                From:
                                            </span>
                                            <DatePicker
                                                selected={filterConfig.date_from}
                                                name="date_from"
                                                placeholderText={Moment(date).format("MM/DD/YYYY")}
                                                onChange={(date) => {
                                                    setFilterConfig((prev) => {
                                                        return { ...prev, date_from: date };
                                                    });
                                                }}
                                                fixedHeight
                                                className="PI-date-btn me-3 form-control"
                                                showYearDropdown
                                                dateFormatCalendar="MMMM"
                                                yearDropdownItemNumber={20}
                                                scrollableYearDropdown
                                            />

                                            <span className="me-2 align-middle mt-2 ps-label">
                                                To:
                                            </span>
                                            <DatePicker
                                                selected={filterConfig.date_to}
                                                name="date_to"
                                                placeholderText={today}
                                                onChange={(date) => {
                                                    setFilterConfig((prev) => {
                                                        return { ...prev, date_to: date };
                                                    });
                                                }}
                                                fixedHeight
                                                className="PI-date-btn me-3 form-control"
                                                showYearDropdown
                                                dateFormatCalendar="MMMM"
                                                yearDropdownItemNumber={20}
                                                scrollableYearDropdown
                                            />
                                        </div>
                                        <div className="below">
                                            {/* table */}
                                            <AdjustmentTable
                                                tableHeaders={[
                                                    "-",
                                                    "DATE",
                                                    "BRANCH",
                                                    "TOTAL SALES",
                                                    "TOTAL VARIANCE",
                                                    // "TAG",
                                                    "INV VARIANCE",
                                                    "CASH VARIANCE",
                                                    "DEPOSITED",
                                                    "CANCELLED",
                                                    "CASHIER",
                                                    "PREPARED BY",
                                                    "ACTIONS",
                                                ]}
                                                headerSelector={[
                                                    "-",
                                                    "date",
                                                    "branch_name",
                                                    "total_sales",
                                                    "total_variance",
                                                    // "total_varice_tag",
                                                    "overage_inventory_sales",
                                                    "cash_sales_overage",
                                                    "is_deposited",
                                                    "total_cancelled",
                                                    "cashier_name",
                                                    "preparer_name",
                                                ]}
                                                tableData={filteredExpenses}
                                                ActionBtn={(row) => ActionBtn(row)}
                                                ViewBtn={(row) => ViewBtn(row)}
                                                DepositedBtn={(row)  => DepositedBtn(row)} 
                                                TotalVarianceBtn={(row) => TotalVarianceBtn(row)}
                                                InvVarianceBtn={(row) => InvVarianceBtn(row)}
                                                CashVarianceBtn={(row) => CashVarianceBtn(row)}
                                                // branchStatus={(row) => InventoryVariance(row)}
                                                // cashVariance={(row) => CashVariance(row)}
                                                handleOnCheck={(row) => handleOnCheck(row)}
                                                showLoader={showLoader}
                                            />
                                        </div>
                                        <div className="mb-2" />
                                    </div>
                                </Tab>
                                <Tab eventKey="reviewed" title="reviewed">
                                    <div className="tab-content">
                                        {/* filters */}
                                        <div className="my-2 px-2 PO-filters d-flex">
                                            <span className="me-2 align-middle mt-2 ps-label">
                                                Filter By:
                                            </span>
                                            <Select
                                                className="dropsearch-filter px-0 py-0 me-2"
                                                classNamePrefix="react-select"
                                                placeholder="Select Branch"
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    backgroundColor: state.isSelected ? 'white' : '#59c8e3',
                                                    borderRadius: "7px",
                                                    border: "0px",
                                                    minHeight: "20px",
                                                    maxHeight: "35px",
                                                    }),
                                                    input: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        color: state.isSelected? "white": "white",
                                                        
                                                    }),
                                                    dropdownIndicator: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        color: "white"
                                                        
                                                    }),
                                                    singleValue: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        color: "white"
                                                        
                                                    }),
                                                    placeholder: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        color: "white"
                                                        
                                                    }),
                                                    
                                                }}
                                                // value={branch}
                                                options={branches}
                                                onChange={(e) => { setFilterConfig((prev) => {
                                                        return { ...prev, "branch_id": e.value };
                                                    });}}
                                            />

                                            <Form.Select
                                                name="inventory_variance_discrepancy"
                                                className="date-filter me-2"
                                                onChange={(e) => handleOnSearch(e)}
                                                value={filterConfig.inventory_variance_discrepancy}
                                            >
                                                <option value="" selected>
                                                    Inventory Variance
                                                </option>
                                                {
                                                    inventoryVariance.map((varr) => (
                                                        <option value={varr.value}>{varr.name}</option>
                                                    ))
                                                }
                                            </Form.Select>

                                            <Form.Select
                                                name="cash_variance_discrepancy"
                                                className="date-filter me-2"
                                                onChange={(e) => handleOnSearch(e)}
                                                value={filterConfig.cash_variance_discrepancy}
                                            >
                                                <option value="" selected>
                                                    Cash Variance
                                                </option>
                                                {
                                                    cashVariance.map((cash) => (
                                                        <option value={cash.value}>{cash.name}</option>
                                                    ))
                                                }
                                            </Form.Select>

                                            
                                            <Form.Select
                                                name="total_variance"
                                                className="date-filter me-2"
                                                onChange={(e) => handleOnSearch(e)}
                                                value={filterConfig.total_variance}
                                            >
                                                <option value="" selected>
                                                    Total Variance
                                                </option>
                                                <option value="0"> No Variance </option>
                                                <option value="1"> Over </option>
                                                <option value="2"> Short </option>
                                            </Form.Select>

                                            <Form.Select
                                                name="is_deposited"
                                                className="date-filter me-2"
                                                onChange={(e) => handleOnSearch(e)}
                                                value={filterConfig.is_deposited}
                                            >
                                                <option value="" selected disabled>
                                                    Is Deposited
                                                </option>
                                                <option value=""> All </option>
                                                <option value="Y"> Deposited </option>
                                                <option value="N"> Not Deposited </option>
                                            </Form.Select>
                                        </div>

                                        <div className="pl-5 PO-filters d-flex">
                                            <span className="me-2 align-middle mt-2 ps-label">
                                                From:
                                            </span>
                                            <DatePicker
                                                selected={filterConfig.date_from}
                                                name="date_from"
                                                placeholderText={Moment(date).format("MM/DD/YYYY")}
                                                onChange={(date) => {
                                                    setFilterConfig((prev) => {
                                                        return { ...prev, date_from: date };
                                                    });
                                                }}
                                                fixedHeight
                                                className="PI-date-btn me-3 form-control"
                                                showYearDropdown
                                                dateFormatCalendar="MMMM"
                                                yearDropdownItemNumber={20}
                                                scrollableYearDropdown
                                            />

                                            <span className="me-2 align-middle mt-2 ps-label">
                                                To:
                                            </span>
                                            <DatePicker
                                                selected={filterConfig.date_to}
                                                name="date_to"
                                                placeholderText={today}
                                                onChange={(date) => {
                                                    setFilterConfig((prev) => {
                                                        return { ...prev, date_to: date };
                                                    });
                                                }}
                                                fixedHeight
                                                className="PI-date-btn me-3 form-control"
                                                showYearDropdown
                                                dateFormatCalendar="MMMM"
                                                yearDropdownItemNumber={20}
                                                scrollableYearDropdown
                                            />
                                        </div>
                                        <div className="below">
                                            {/* table */}
                                            <Table
                                                tableHeaders={[
                                                    "-",
                                                    "DATE",
                                                    "BRANCH",
                                                    "TOTAL SALES",
                                                    "TOTAL VARIANCE",
                                                    // "TAG",
                                                    "INV VARIANCE",
                                                    "CASH VARIANCE",
                                                    "DEPOSITED",
                                                    "CANCELLED",
                                                    "CASHIER",
                                                    "PREPARED BY",
                                                    "ACTIONS",
                                                ]}
                                                headerSelector={[
                                                    "-",
                                                    "date",
                                                    "branch_name",
                                                    "total_sales",
                                                    "total_variance",
                                                    // "total_varice_tag",
                                                    "overage_inventory_sales",
                                                    "cash_sales_overage",
                                                    "is_deposited",
                                                    "total_cancelled",
                                                    "cashier_name",
                                                    "preparer_name",
                                                ]}
                                                tableData={filteredExpenses}
                                                ActionBtn={(row) => ActionBtn(row)}
                                                ViewBtn={(row) => ViewBtn(row)}
                                                DepositedBtn={(row)  => DepositedBtn(row)} 
                                                InvVarianceBtn={(row) => InvVarianceBtn(row)}
                                                CashVarianceBtn={(row) => CashVarianceBtn(row)}
                                                TotalVarianceBtn={(row) => TotalVarianceBtn(row)}
                                                // branchStatus={(row) => InventoryVariance(row)}
                                                // cashVariance={(row) => CashVariance(row)}
                                                showLoader={showLoader}
                                            />
                                        </div>
                                        <div className="mb-2" />
                                    </div>
                                </Tab>
                            </Tabs>
                        </>
                    )
                }
                {
                    accountType === "purchasing_staff" && (
                        <>
                    <Tabs
                        activeKey={filterConfig.shop}
                        // defaultActiveKey={filterConfig.shop}
                        id="PO-tabs"
                        className="manager-tabs"
                        onSelect={handleTabSelect}
                    >
                        <Tab eventKey="submitted" title="Submitted">
                            <div className="tab-content">
                                {/* filters */}
                                <div className="my-2 px-2 PO-filters d-flex">
                                    <span className="me-2 align-middle mt-2 ps-label">
                                        Filter By:
                                    </span>
                                    <Select
                                        className="dropsearch-filter px-0 py-0 me-2"
                                        classNamePrefix="react-select"
                                        placeholder="Select Branch"
                                        styles={{
                                            control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            backgroundColor: state.isSelected ? 'white' : '#59c8e3',
                                            borderRadius: "7px",
                                            border: "0px",
                                            minHeight: "20px",
                                            maxHeight: "35px",
                                            }),
                                            input: (baseStyles, state) => ({
                                                ...baseStyles,
                                                color: state.isSelected? "white": "white",
                                                
                                            }),
                                            dropdownIndicator: (baseStyles, state) => ({
                                                ...baseStyles,
                                                color: "white"
                                                
                                            }),
                                            singleValue: (baseStyles, state) => ({
                                                ...baseStyles,
                                                color: "white"
                                                
                                            }),
                                            placeholder: (baseStyles, state) => ({
                                                ...baseStyles,
                                                color: "white"
                                                
                                            }),
                                            
                                        }}
                                        // value={branch}
                                        options={branches}
                                        onChange={(e) => { setFilterConfig((prev) => {
                                                return { ...prev, "branch_id": e.value };
                                            });}}
                                    />

                                    <Form.Select
                                        name="inventory_variance_discrepancy"
                                        className="date-filter me-2"
                                        onChange={(e) => handleOnSearch(e)}
                                        value={filterConfig.inventory_variance_discrepancy}
                                    >
                                        <option value="" selected>
                                            Inventory Variance
                                        </option>
                                        {
                                            inventoryVariance.map((varr) => (
                                                <option value={varr.value}>{varr.name}</option>
                                            ))
                                        }
                                    </Form.Select>

                                    <Form.Select
                                        name="cash_variance_discrepancy"
                                        className="date-filter me-2"
                                        onChange={(e) => handleOnSearch(e)}
                                        value={filterConfig.cash_variance_discrepancy}
                                    >
                                        <option value="" selected>
                                            Cash Variance
                                        </option>
                                        {
                                            cashVariance.map((cash) => (
                                                <option value={cash.value}>{cash.name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                    
                                    <Form.Select
                                        name="total_variance"
                                        className="date-filter me-2"
                                        onChange={(e) => handleOnSearch(e)}
                                        value={filterConfig.total_variance}
                                    >
                                        <option value="" selected>
                                            Total Variance
                                        </option>
                                        <option value="0"> No Variance </option>
                                        <option value="1"> Over </option>
                                        <option value="2"> Short </option>
                                    </Form.Select>

                                    <Form.Select
                                        name="is_deposited"
                                        className="date-filter me-2"
                                        onChange={(e) => handleOnSearch(e)}
                                        value={filterConfig.is_deposited}
                                    >
                                        <option value="" selected disabled>
                                            Is Deposited
                                        </option>
                                        <option value=""> All </option>
                                        <option value="Y"> Deposited </option>
                                        <option value="N"> Not Deposited </option>
                                    </Form.Select>
                                </div>
                                <div className="pl-5 PO-filters d-flex">
                                    <span className="me-2 align-middle mt-2 ps-label">
                                        From:
                                    </span>
                                    <DatePicker
                                        selected={filterConfig.date_from}
                                        name="date_from"
                                        placeholderText={Moment(date).format("MM/DD/YYYY")}
                                        onChange={(date) => {
                                            setFilterConfig((prev) => {
                                                return { ...prev, date_from: date };
                                            });
                                        }}
                                        fixedHeight
                                        className="PI-date-btn me-3 form-control"
                                        showYearDropdown
                                        dateFormatCalendar="MMMM"
                                        yearDropdownItemNumber={20}
                                        scrollableYearDropdown
                                    />

                                    <span className="me-2 align-middle mt-2 ps-label">
                                        To:
                                    </span>
                                    <DatePicker
                                        selected={filterConfig.date_to}
                                        name="date_to"
                                        placeholderText={today}
                                        onChange={(date) => {
                                            setFilterConfig((prev) => {
                                                return { ...prev, date_to: date };
                                            });
                                        }}
                                        fixedHeight
                                        className="PI-date-btn me-3 form-control"
                                        showYearDropdown
                                        dateFormatCalendar="MMMM"
                                        yearDropdownItemNumber={20}
                                        scrollableYearDropdown
                                    />
                                </div>
                                <div className="below">
                                    {/* table */}
                                    <Table
                                        tableHeaders={[
                                            // "-",
                                            "DATE",
                                            "BRANCH",
                                            // "TOTAL SALES",
                                            // "TOTAL VARIANCE",
                                            // "INV VARIANCE",
                                            // "CASH VARIANCE",
                                            "DEPOSITED",
                                            "CANCELLED",
                                            "CASHIER",
                                            "PREPARED BY",
                                            // "ACTIONS",
                                        ]}
                                        headerSelector={[
                                            // "-",
                                            "date",
                                            "branch_name",
                                            // "total_sales",
                                            // "total_variance",
                                            // "overage_inventory_sales",
                                            // "cash_sales_overage",
                                            "is_deposited",
                                            "total_cancelled",
                                            "cashier_name",
                                            "preparer_name",
                                        ]}
                                        tableData={filteredExpenses}
                                        ActionBtn={(row) => ActionBtn(row)}
                                        ViewBtn={(row) => ViewBtn(row)}
                                        DepositedBtn={(row)  => DepositedBtn(row)} 
                                        TotalVarianceBtn={(row) => TotalVarianceBtn(row)}
                                        InvVarianceBtn={(row) => InvVarianceBtn(row)}
                                        CashVarianceBtn={(row) => CashVarianceBtn(row)}
                                        // branchStatus={(row) => InventoryVariance(row)}
                                        // cashVariance={(row) => CashVariance(row)}
                                        handleOnCheck={(row) => handleOnCheck(row)}
                                        showLoader={showLoader}
                                    />
                                </div>
                                <div className="mb-2" />
                            </div>
                        </Tab>
                        <Tab eventKey="reviewed" title="reviewed">
                            <div className="tab-content">
                                {/* filters */}
                                <div className="my-2 px-2 PO-filters d-flex">
                                    <span className="me-2 align-middle mt-2 ps-label">
                                        Filter By:
                                    </span>
                                    <Select
                                        className="dropsearch-filter px-0 py-0 me-2"
                                        classNamePrefix="react-select"
                                        placeholder="Select Branch"
                                        styles={{
                                            control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            backgroundColor: state.isSelected ? 'white' : '#59c8e3',
                                            borderRadius: "7px",
                                            border: "0px",
                                            minHeight: "20px",
                                            maxHeight: "35px",
                                            }),
                                            input: (baseStyles, state) => ({
                                                ...baseStyles,
                                                color: state.isSelected? "white": "white",
                                                
                                            }),
                                            dropdownIndicator: (baseStyles, state) => ({
                                                ...baseStyles,
                                                color: "white"
                                                
                                            }),
                                            singleValue: (baseStyles, state) => ({
                                                ...baseStyles,
                                                color: "white"
                                                
                                            }),
                                            placeholder: (baseStyles, state) => ({
                                                ...baseStyles,
                                                color: "white"
                                                
                                            }),
                                            
                                        }}
                                        // value={branch}
                                        options={branches}
                                        onChange={(e) => { setFilterConfig((prev) => {
                                                return { ...prev, "branch_id": e.value };
                                            });}}
                                    />

                                    <Form.Select
                                        name="inventory_variance_discrepancy"
                                        className="date-filter me-2"
                                        onChange={(e) => handleOnSearch(e)}
                                        value={filterConfig.inventory_variance_discrepancy}
                                    >
                                        <option value="" selected>
                                            Inventory Variance
                                        </option>
                                        {
                                            inventoryVariance.map((varr) => (
                                                <option value={varr.value}>{varr.name}</option>
                                            ))
                                        }
                                    </Form.Select>

                                    <Form.Select
                                        name="cash_variance_discrepancy"
                                        className="date-filter me-2"
                                        onChange={(e) => handleOnSearch(e)}
                                        value={filterConfig.cash_variance_discrepancy}
                                    >
                                        <option value="" selected>
                                            Cash Variance
                                        </option>
                                        {
                                            cashVariance.map((cash) => (
                                                <option value={cash.value}>{cash.name}</option>
                                            ))
                                        }
                                    </Form.Select>

                                    
                                    <Form.Select
                                        name="total_variance"
                                        className="date-filter me-2"
                                        onChange={(e) => handleOnSearch(e)}
                                        value={filterConfig.total_variance}
                                    >
                                        <option value="" selected>
                                            Total Variance
                                        </option>
                                        <option value="0"> No Variance </option>
                                        <option value="1"> Over </option>
                                        <option value="2"> Short </option>
                                    </Form.Select>

                                    <Form.Select
                                        name="is_deposited"
                                        className="date-filter me-2"
                                        onChange={(e) => handleOnSearch(e)}
                                        value={filterConfig.is_deposited}
                                    >
                                        <option value="" selected disabled>
                                            Is Deposited
                                        </option>
                                        <option value=""> All </option>
                                        <option value="Y"> Deposited </option>
                                        <option value="N"> Not Deposited </option>
                                    </Form.Select>
                                </div>

                                <div className="pl-5 PO-filters d-flex">
                                    <span className="me-2 align-middle mt-2 ps-label">
                                        From:
                                    </span>
                                    <DatePicker
                                        selected={filterConfig.date_from}
                                        name="date_from"
                                        placeholderText={Moment(date).format("MM/DD/YYYY")}
                                        onChange={(date) => {
                                            setFilterConfig((prev) => {
                                                return { ...prev, date_from: date };
                                            });
                                        }}
                                        fixedHeight
                                        className="PI-date-btn me-3 form-control"
                                        showYearDropdown
                                        dateFormatCalendar="MMMM"
                                        yearDropdownItemNumber={20}
                                        scrollableYearDropdown
                                    />

                                    <span className="me-2 align-middle mt-2 ps-label">
                                        To:
                                    </span>
                                    <DatePicker
                                        selected={filterConfig.date_to}
                                        name="date_to"
                                        placeholderText={today}
                                        onChange={(date) => {
                                            setFilterConfig((prev) => {
                                                return { ...prev, date_to: date };
                                            });
                                        }}
                                        fixedHeight
                                        className="PI-date-btn me-3 form-control"
                                        showYearDropdown
                                        dateFormatCalendar="MMMM"
                                        yearDropdownItemNumber={20}
                                        scrollableYearDropdown
                                    />
                                </div>
                                <div className="below">
                                    {/* table */}
                                    <Table
                                        tableHeaders={[
                                            // "-",
                                            "DATE",
                                            "BRANCH",
                                            // "TOTAL SALES",
                                            // "TOTAL VARIANCE",
                                            // "INV VARIANCE",
                                            // "CASH VARIANCE",
                                            "DEPOSITED",
                                            "CANCELLED",
                                            "CASHIER",
                                            "PREPARED BY",
                                            // "ACTIONS",
                                        ]}
                                        headerSelector={[
                                            // "-",
                                            "date",
                                            "branch_name",
                                            // "total_sales",
                                            // "total_variance",
                                            // "overage_inventory_sales",
                                            // "cash_sales_overage",
                                            "is_deposited",
                                            "total_cancelled",
                                            "cashier_name",
                                            "preparer_name",
                                        ]}
                                        tableData={filteredExpenses}
                                        ActionBtn={(row) => ActionBtn(row)}
                                        ViewBtn={(row) => ViewBtn(row)}
                                        DepositedBtn={(row)  => DepositedBtn(row)} 
                                        InvVarianceBtn={(row) => InvVarianceBtn(row)}
                                        CashVarianceBtn={(row) => CashVarianceBtn(row)}
                                        TotalVarianceBtn={(row) => TotalVarianceBtn(row)}
                                        // branchStatus={(row) => InventoryVariance(row)}
                                        // cashVariance={(row) => CashVariance(row)}
                                        showLoader={showLoader}
                                    />
                                </div>
                                <div className="mb-2" />
                            </div>
                        </Tab>
                    </Tabs>
                    </>
                    )
                }
                {selectedTab === "submitted" && (accountType === "admin" || accountType === "purchasing_officer") ? (
                    <>
                        <div className="d-flex justify-content-end pt-3 mb-3 mr-3">
                            <button
                                type="button"
                                className="button-primary mr-3 "
                                onClick={() => handleShowMarkModal()}
                            >
                                Mark as Reviewed
                            </button>
                        </div>
                    </>
                ) : (
                    <></>
                )}
            </div>

            {/* modals */}
            <Delete
                show={showDeleteModal}
                onHide={handleCloseDeleteModal}
                text="expeses"
                onDelete={handleDeleteExpense}
            />
            <MarkModal
                show={showMarkModal}
                hide={handleCloseMarkModal}
                type="mark these DSR as reviewed?"
                ids={checkedItems}
                handler={handleMarkAsReviewed}
            />
        </div>
    );
}
