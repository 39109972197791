import React, { useState } from "react";
import { Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import toast from "react-hot-toast";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import Delete from "../../../Components/Modals/DeleteModal";
import Navbar from "../../../Components/Navbar/Navbar";
import Table from "../../../Components/TableTemplate/Table";
import AdjustmentTable from "./../../Inventory/Adjustment/AdjustmentTable";
import {
    dateFormat,
    formatDateNoTime,
    formatDate,
    numberFormat,
    refreshPage,
    toastStyle,
    getTodayDate,
    getType,
    TokenExpiry
} from "../../../Helpers/Utils/Common";
import DatePicker from "react-datepicker";
import Moment from "moment";

import { getAllFranchisee } from "../../../Helpers/apiCalls/franchiseeApi";
import { getAllFoodpandaReport, deleteFoodpandaReport } from "../../../Helpers/apiCalls/Reports/FoodpandaReportApi";
import { getAllBranches } from "../../../Helpers/apiCalls/Manage/Branches";
import MarkModal from './../../Reports/StoreDeposit/MarkModal';
import { updateFoodPandaReportStatus } from './../../../Helpers/apiCalls/Reports/FoodpandaReportApi';

export default function FoodpandaReport() {
    let navigate = useNavigate();
    var dateToday = getTodayDate();
    const accountType = getType();
    const [inactive, setInactive] = useState(true);
    const [filterConfig, setFilterConfig] = useState({
        by_branch: 1,
        status: "for_checking",
    })
    const [branches, setBranches] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [allData, setAllData] = useState([]);
    const [totalQuantity, setTotalQuantity] = useState([]);
    const [averagePrice, setAveragePrice] = useState([]);
    const [totalAmount, setTotalAmount] = useState([]);
    const [selectedRow, setSelectedRow] = useState([]);
    const [selectedTab, setSelectedTab] = useState("for_checking");
    const [checkedItems, setCheckedItems] = useState([]);

    /* delete modal handler */
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const handleShowDeleteModal = () => setShowDeleteModal(true);
    const handleCloseDeleteModal = () => setShowDeleteModal(false);
    const [showMarkModal, setShowMarkModal] = useState(false);
     const handleShowMarkModal = () => (
        checkedItems.length != 0
            ? setShowMarkModal(true)
            : toast.error("Please select report to mark as reviewed", {
                  style: toastStyle(),
              })
        );
     const handleCloseMarkModal = () => setShowMarkModal(false);
    const today = Moment().format("MM/DD/YYYY");
    const ago = new Date();
    ago.setDate(ago.getDate() - 7);

    const dummy = [
        {
            id: "23",
            branch_name: "SM CITY CEBU",
            date: "December 2022",
            remarks: "asdfasdf",
        },
    ]

    // SEARCH USER
    function handleOnSearch(e) {
        const { name, value } = e.target;
        setFilterConfig((prev) => {
            return { ...prev, [name]: value };
        });
    }

    function handleSelectChange(e, row) {
        setSelectedRow(row);

        if (e.target.value === "view") {
            // navigate("/foodpandareport/view/" + row.branch_id, {
            //     state: { 
            //         date: filterConfig.date === undefined ? today : filterConfig.date,
            //         branch_name: row.branch_name }
            // })
            var dateparam = filterConfig.date === undefined ? Moment(today).format("MM-DD-YYYY") : Moment(filterConfig.date).format("MM-DD-YYYY");
            {
                window.open('/foodpandareport/view/' + row.branch_id + "/" + row.branch_name + "/" + dateparam ,'_blank')
            };
        } else if (e.target.value === "delete") {
            handleShowDeleteModal();
        }
    }

    function ActionBtn(row, type) {
        return (
            <Form.Select
                name="action"
                className="PO-select-action"
                onChange={(e) => handleSelectChange(e, row)}
            >
                <option value="" hidden selected>
                    Select
                </option>
                { (accountType === "admin" || accountType === "purchasing_staff") ? (
                    <option value="view" className="color-options">
                        View
                    </option>
                    ): null
                }
                {/* { accountType === "admin" ? (
                    <option value="delete" className="color-options">
                        Delete
                    </option>
                    ): null
                } */}
            </Form.Select>
        );
    }

    function ViewBtn(row) {
        return (
            <button
                name="action"
                className="btn btn-sm view-btn-table"
                id={row.id}
                onClick={() => handleView(row)}
                // value="payment-pi"
            >
                View
            </button>
        );
    }
    function handleView(row) {
        console.log(row)
        // navigate("/foodpandareport/view/" + row.branch_id, {
        //     state: { 
        //         date: filterConfig.date === undefined ? today : filterConfig.date,
        //         branch_name: row.branch_name }
        // })
        var dateparam = filterConfig.date === undefined ? Moment(today).format("MM-DD-YYYY") : Moment(filterConfig.date).format("MM-DD-YYYY");
        {
            window.open('/foodpandareport/view/' + row.branch_id + "/" + row.branch_name + "/" + dateparam ,'_blank')
        };
    }

    const handleTabSelect = (key) => {
        setCheckedItems([])
        if(key === "for_checking") {
            setSelectedTab(key);
        } else {
            setSelectedTab("");
        }
        
        setFilterConfig((prev) => {
            return {
                ...prev,
                tab: key,
                status: key,
                date: "",
                branch_id: "",
                branch_name: "",
            };
        });
    };

    function handleOnCheck(row) {
        setCheckedItems(row.selectedRows);
    }

    async function handleMarkAsReviewed() {
        console.log(checkedItems)
        const response = await updateFoodPandaReportStatus(checkedItems);
        console.log(response)
        if (response.data) {
            toast.success("Successfully marked as reviewed!", {
                style: toastStyle(),
            });
            setTimeout(() => refreshPage(), 1000);
        } else if (response.error) {
            TokenExpiry(response);
            toast.error(
                "Something went wrong",
                { style: toastStyle() }
            );
            setTimeout(() => refreshPage(), 1000);
        }
    }

    async function fetchBranches() {
        setShowLoader(true);

        const response = await getAllBranches();
        if (response.error) {
        } else {
            var allBranches = response.data.data.data
            .filter((data) => {
                return data.is_franchise === "0"
            })
            .map((data) => {
                var branch = data;
                branch.label = data.name
                branch.value = data.id
                return branch;
            });
            setBranches([{label: "All Branches", value:""}, ...allBranches]);
        }
        setShowLoader(false);
    }

    async function fetchData() {
        setShowLoader(true);
        setAllData([])

        const response = await getAllFoodpandaReport(filterConfig);
        console.log(response)

        if (response.error) {
        } else {

            var allBills = response.data.data.map((bill) => {
                var info = bill;
                info.sales_date = Moment(bill.added_on).format("MM-DD-YYYY")
                info.total_gross = numberFormat(bill.gross_value)
                info.total_revenue = numberFormat(bill.sales_revenue)
                info.total_paid = numberFormat(bill.total_paid_amount)
                return info;
            });
            console.log(allBills)
            setAllData(allBills)
        }
        setShowLoader(false);
    }

    async function handleDeleteData() {
        const response = await deleteFoodpandaReport(selectedRow.id);
        if (response.data) {
            toast.success("Report Deleted Successfully!", {
                style: toastStyle(),
            });
            setTimeout(() => refreshPage(), 1000);
        } else {
            toast.error("Error Deleting Report", {
                style: toastStyle(),
            });
        }
    }

    React.useEffect(() => {
        fetchBranches();
    }, []);

    React.useEffect(() => {
        fetchData();
    }, [filterConfig]);

    return (
        <div>
            <div className="page">
                <Navbar
                    onCollapse={(inactive) => {
                        setInactive(inactive);
                    }}
                    active={"STORE REPORTS"}
                />
            </div>

            <div
                className={`manager-container ${
                    inactive ? "inactive" : "active"
                }`}
            >
                {/* headers */}
                <Row className="mb-4">
                    <Col xs={6}>
                        <h1 className="page-title"> FOODPANDA REPORT </h1>
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <input
                            type="text"
                            name="branch_name"
                            placeholder="Search branch name"
                            className="search-bar"
                            onChange={handleOnSearch}
                        ></input>
                    </Col>
                </Row>

                <Tabs
                    activeKey={filterConfig.shop}
                    className="manager-tabs"
                    onSelect={handleTabSelect}
                >
                    <Tab eventKey="for_checking" title="For Checking">
                        <div className="tab-content">
                            {/* filters */}
                            <div className="my-2 px-2 PO-filters d-flex">
                                <span className="me-4 align-middle mt-2 ps-label">
                                    Filter By:
                                </span>
                                <Select
                                    className="dropsearch-filter px-0 py-0 me-2"
                                    classNamePrefix="react-select"
                                    placeholder="Select Branch"
                                    styles={{
                                        control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        backgroundColor: state.isSelected ? 'white' : '#59c8e3',
                                        borderRadius: "7px",
                                        border: "0px",
                                        minHeight: "20px",
                                        maxHeight: "35px",
                                        }),
                                        input: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: state.isSelected? "white": "white",
                                            
                                        }),
                                        dropdownIndicator: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        singleValue: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        placeholder: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        
                                    }}
                                    options={branches}
                                    onChange={(e) => { setFilterConfig((prev) => {
                                            return { ...prev, "branch_id": e.value };
                                        });}}
                                />

                                <span className="me-4 align-middle mt-2 ps-label">
                                    Date From:
                                </span>
                                <DatePicker
                                    selected={filterConfig.date_from}
                                    name="date"
                                    placeholderText={Moment(ago).format("MM/DD/YYYY")}
                                    onChange={(date) => {
                                        setFilterConfig((prev) => {
                                            return { ...prev, date_from: date };
                                        });
                                    }}
                                    fixedHeight
                                    className="PI-date-btn me-3 form-control"
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    yearDropdownItemNumber={20}
                                    scrollableYearDropdown
                                />
                                <span className="me-4 align-middle mt-2 ps-label">
                                    To:
                                </span>
                                <DatePicker
                                    selected={filterConfig.date_to}
                                    name="date"
                                    placeholderText={today}
                                    onChange={(date) => {
                                        setFilterConfig((prev) => {
                                            return { ...prev, date_to: date };
                                        });
                                    }}
                                    fixedHeight
                                    className="PI-date-btn me-3 form-control"
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    yearDropdownItemNumber={20}
                                    scrollableYearDropdown
                                />
                            </div>

                            <div className="my-2 px-2 PO-filters d-flex justify-content-center">
                            </div>

                            <div className="below">
                                {/* table */}
                                <AdjustmentTable
                                    tableHeaders={[
                                        "-",
                                        "SALES DATE",
                                        "BRANCH NAME",
                                        "TOTAL GROSS",
                                        "TOTAL REVENUE",
                                        "TOTAL PAID",
                                        "ACTIONS",
                                    ]}
                                    headerSelector={[
                                        "-",
                                        "sales_date",
                                        "branch_name",
                                        "total_gross",
                                        "total_revenue",
                                        "total_paid",
                                    ]}
                                    tableData={allData}
                                    ActionBtn={(row) => ActionBtn(row)}
                                    ViewBtn={(row) => ViewBtn(row)}
                                    handleOnCheck={(row) => handleOnCheck(row)}
                                    showLoader={showLoader}
                                />
                            </div>
                            <div className="mb-2" />
                        </div>
                    </Tab>
                    <Tab eventKey="reviewed" title="reviewed">
                        <div className="tab-content">
                            {/* filters */}
                            <div className="my-2 px-2 PO-filters d-flex">
                                <span className="me-4 align-middle mt-2 ps-label">
                                    Filter By:
                                </span>
                                <Select
                                    className="dropsearch-filter px-0 py-0 me-2"
                                    classNamePrefix="react-select"
                                    placeholder="Select Branch"
                                    styles={{
                                        control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        backgroundColor: state.isSelected ? 'white' : '#59c8e3',
                                        borderRadius: "7px",
                                        border: "0px",
                                        minHeight: "20px",
                                        maxHeight: "35px",
                                        }),
                                        input: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: state.isSelected? "white": "white",
                                            
                                        }),
                                        dropdownIndicator: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        singleValue: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        placeholder: (baseStyles, state) => ({
                                            ...baseStyles,
                                            color: "white"
                                            
                                        }),
                                        
                                    }}
                                    options={branches}
                                    onChange={(e) => { setFilterConfig((prev) => {
                                            return { ...prev, "branch_id": e.value };
                                        });}}
                                />
                                
                                <span className="me-4 align-middle mt-2 ps-label">
                                    Date From:
                                </span>
                                <DatePicker
                                    selected={filterConfig.date_from}
                                    name="date"
                                    placeholderText={Moment(ago).format("MM/DD/YYYY")}
                                    onChange={(date) => {
                                        setFilterConfig((prev) => {
                                            return { ...prev, date_from: date };
                                        });
                                    }}
                                    fixedHeight
                                    className="PI-date-btn me-3 form-control"
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    yearDropdownItemNumber={20}
                                    scrollableYearDropdown
                                />
                                <span className="me-4 align-middle mt-2 ps-label">
                                    To:
                                </span>
                                <DatePicker
                                    selected={filterConfig.date_to}
                                    name="date"
                                    placeholderText={today}
                                    onChange={(date) => {
                                        setFilterConfig((prev) => {
                                            return { ...prev, date_to: date };
                                        });
                                    }}
                                    fixedHeight
                                    className="PI-date-btn me-3 form-control"
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    yearDropdownItemNumber={20}
                                    scrollableYearDropdown
                                />
                            </div>

                            <div className="my-2 px-2 PO-filters d-flex justify-content-center">
                            </div>

                            <div className="below">
                                {/* table */}
                                <Table
                                    tableHeaders={[
                                        "-",
                                        "SALES DATE",
                                        "BRANCH NAME",
                                        "TOTAL GROSS",
                                        "TOTAL REVENUE",
                                        "TOTAL PAID",
                                        "ACTIONS",
                                    ]}
                                    headerSelector={[
                                        "-",
                                        "sales_date",
                                        "branch_name",
                                        "total_gross",
                                        "total_revenue",
                                        "total_paid",
                                    ]}
                                    // tableData={allData}
                                    ActionBtn={(row) => ActionBtn(row)}
                                    ViewBtn={(row) => ViewBtn(row)}
                                    showLoader={showLoader}
                                />
                            </div>
                            <div className="mb-2" />
                        </div>
                    </Tab>

                </Tabs>
                {/* {selectedTab === "for_checking" && (accountType === "admin" || accountType === "purchasing_staff") ? (
                    <>
                        <div className="d-flex justify-content-end pt-3 mb-3 mr-3">
                            <button
                                type="button"
                                className="button-primary mr-3 "
                                onClick={() => handleShowMarkModal()}
                            >
                                Mark as Reviewed
                            </button>
                        </div>
                    </>
                ) : (
                    <></>
                )} */}

                

            </div>

            {/* modals */}
            <Delete
                show={showDeleteModal}
                onHide={handleCloseDeleteModal}
                text="report"
                onDelete={handleDeleteData}
            />
            
            <MarkModal
                show={showMarkModal}
                hide={handleCloseMarkModal}
                type="mark these report as reviewed?"
                ids={checkedItems}
                handler={handleMarkAsReviewed}
            />
        </div>
    );
}
