import React, { useState, useEffect, Fragment } from 'react'
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import Select from "react-select";
import ReactLoading from "react-loading";
import {
    formatDateNoTime,
    getTodayDate,
    getTodayDateISO,
    getTodayDateNoTime,
    numberFormat,
    refreshPage,
    toastStyle,
    TokenExpiry,
} from "../../../Helpers/Utils/Common";
import Navbar from "../../../Components/Navbar/Navbar";
import InputError from "../../../Components/InputError/InputError";
import Employees from "../Employees.css"
//api
import { getAllEmployees } from "../../../Helpers/apiCalls/employeesApi";
import { validatePayroll } from './../../../Helpers/Validation/Employees/PayrollValidation';
import { getPayroll, createPayroll, updatePayroll, computePayroll } from './../../../Helpers/apiCalls/Employees/PayrollApi';


function FormPayroll({ add, edit }) {
    //variables
    const {id} = useParams()
    let navigate = useNavigate();
    const {state} = useLocation();
    console.log("state",state)
    const [inactive, setInactive] = useState(true);
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);
    const [isSaveClicked, setIsSaveClicked] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [employees, setEmployees] = useState([]);
    const today = getTodayDateISO();
    const [cashAdvances, setCashAdvances] = useState([]);
    const [shortages, setShortages] = useState([]);
    const [computation, setComputation] = useState([]);
    const [addDetails, setAddDetails] = useState({
        employee_id: state ? state.selectedEmployee?.value : "",
        employee_id_value: state ? state.selectedEmployee : "",
        disbursement_date: today,
        date_from: state ? state.date_from : "",
        date_to: state ? state.date_to : "",
        rate: "",
        wastage: "",
        remarks: "",
        holiday_description: "",
        disbursement_type: "atm",
        total_working_hours: 0,
        philhealth: 0,
        sss: 0,
        sss_loan: 0,
        pagibig: 0,
        pagibig_loan: 0,
        shortage: 0,
        cash_advance: 0,
        other_allowance: 0,
        communication_allowance: 0,
        other_allowances: 0,
        transportation_allowance: 0,
        holidays: 0,
        thirteenth_month: 0,
        bonus: 0,
        service_incentive: 0,
        basic_pay: 0,
        total_deduction: 0,
        total_additions: 0,
        grand_total: 0,
    });
    const [isError, setIsError] = useState({
        employee_id: false,
        disbursement_date: false,
        date_from: false,
        date_to: false,
        rate: false,
        total_working_hours: false,
        basic_pay: false,
    });

    //functions
    const handleAddChange = (e, type) => {
        if(type === "select") {
            setAddDetails((prevState) => ({
                ...prevState,
                [e.for]: e.value,
                [e.select_value]: {
                    for: e.for,
                    label: e.label,
                    value: e.value,
                }
            }));
        } else {
            setIsChanged(true);
            const { name, value } = e.target;
            console.log(e)
            setAddDetails((prevState) => ({
                ...prevState,
                [name]: value,
            }));
            setTimeout(() => setIsChanged(false), 1);
        }
    }
    console.log("addDetails", addDetails)

    
    function handleShortageChange(e, row) {
        setIsChanged(true);
        var temp = [...shortages];
        const { name, value } = e.target;
        console.log(name, value)
        
        if(value === "") {
            toast.error("Please enter cash advance amount or put 0", {
                style: toastStyle(),
            });
            temp[row][name] = "";
            setShortages(temp);
            setTimeout(() => setIsChanged(false), 1);
        } else {
            if(parseFloat(temp[row]["total_balance"]) >= parseFloat(value)) {
                temp[row][name] = value;
                setShortages(temp);
                setTimeout(() => setIsChanged(false), 1);
            } else {
                toast.error("Cash advance amount should not exceed " + temp[row]["total_balance"], {
                    style: toastStyle(),
                });
                temp[row][name] = temp[row]["total_balance"];
                setShortages(temp);
                setTimeout(() => setIsChanged(false), 1);
            }
        }
    }
    console.log("shortages", shortages)
    
    function handleCashAdvanceChange(e, row) {
        setIsChanged(true);
        var temp = [...cashAdvances];
        const { name, value } = e.target;
        console.log(name, value)
        
        if(value === "") {
            toast.error("Please enter cash advance amount or put 0", {
                style: toastStyle(),
            });
            temp[row][name] = "";
            setCashAdvances(temp);
            setTimeout(() => setIsChanged(false), 1);
        } else {
            if(parseFloat(temp[row]["balance"]) >= parseFloat(value)) {
                temp[row][name] = value;
                setCashAdvances(temp);
                setTimeout(() => setIsChanged(false), 1);
            } else {
                toast.error("Cash advance amount should not exceed " + temp[row]["balance"], {
                    style: toastStyle(),
                });
                temp[row][name] = temp[row]["balance"];
                setCashAdvances(temp);
                setTimeout(() => setIsChanged(false), 1);
            }
        }
    }
    console.log("cashAdvances", cashAdvances)

    const handleSubmit = () => {
        if (add) handleCreatePayroll();
        else if (edit) handleUpdatePayroll();
    };

    // const handleSave = () => {
    //     if (add) handleCreatePayroll("save");
    //     else if (edit) handleUpdatePayroll("save");
    // };

    //API
    async function fetchEmployees() {

        const response = await getAllEmployees();
        if (response.error) {
            setEmployees([])
        } else {
            let result = response.data.data.map((data) => {
                var employee = data;
                employee.for = "employee_id"
                employee.select_value = "employee_id_value"
                employee.label = data.first_name + " " + data.last_name;
                employee.value = data.id;
                return employee;
            });
            setEmployees(result);
        }
    }
    console.log(employees)

    async function handleCreatePayroll(type) {
        if (isSubmitClicked) {
            return;
        }
        if (validatePayroll(addDetails, setIsError)) {
            setIsSubmitClicked(true)
            const response = await createPayroll(addDetails, cashAdvances, shortages);
            console.log("response", response)
            if (response.data) {
                if(response.data.response === "Payroll generated.") {
                    toast.success("Successfully created payroll!", {
                        style: toastStyle(),
                    });
                    setTimeout(() => {
                        navigate("/payroll")
                        // navigate("/payroll/view/" + response.data.id)
                    }, 1000);
                } else {
                    toast.error("Something went wrong", {
                        style: toastStyle(),
                    });
                    setTimeout(() => {
                        navigate(-1)
                    }, 1000);
                }
            } else {
                toast.error(response.error.response.data.messages.error, {
                    style: toastStyle(),
                });
                setTimeout(() => {
                    setIsSubmitClicked(false)
                }, 1000);
            }
        } else {
            toast.error("Please fill in all fields", {
                style: toastStyle(),
            });
        }
    }

    async function handleUpdatePayroll(type) {
        if (isSubmitClicked) {
            return;
        }
        if (validatePayroll(addDetails, setIsError)) {
            const response = await updatePayroll(addDetails, cashAdvances, shortages);
            console.log("response", response)
            if (response.data) {
                if(response.data.status === "success") {
                    toast.success("Successfully updated payroll!", {
                        style: toastStyle(),
                    });
                    setTimeout(() => {
                        navigate(-1)
                    }, 1000);
                } else {
                    toast.error("Something went wrong", {
                        style: toastStyle(),
                    });
                    setTimeout(() => {
                        navigate(-1)
                    }, 1000);
                }
            }
        } else {
            toast.error("Please fill in all fields", {
                style: toastStyle(),
            });
        }
    }

    async function fetchComputation() {
        // setAddDetails([]);
        setIsChanged(true);

        const response = await computePayroll({
            employee_id: state.selectedEmployee.value,
            date_from: state.date_from,
            date_to: state.date_to,
        });
        console.log(response);

        if (response.error) {
            toast.error(response.error.response.data.messages.error, {
                style: toastStyle(),
            });
            setTimeout(() => {
                navigate(-1)
            }, 1000);
        } else {
            var details = response.data.map((data) => {
                var info = data;
                return info;
            });
            setComputation(details[0])
            var shortage = details[0].deductions[0].shortages.map((data) => {
                var info = data
                    data.amount = data.total_balance
                return info
            })
            setShortages(shortage)
            var cashadvance = details[0].deductions[0].cash_advances.map((data) => {
                var info = data
                    data.amount = data.balance
                return info
            })
            setCashAdvances(cashadvance)
            setAddDetails((prevState) => ({
                ...prevState,
                ["rate"]: details[0].rate,
                ["total_working_hours"]: details[0].total_working_hours,
                ["basic_pay"]: details[0].basic_pay,
                ["philhealth"]: details[0].deductions[0].philhealth,
                ["sss"]: details[0].deductions[0].sss,
                ["pagibig"]: details[0].deductions[0].pagibig,
                ["sss_loan"]: details[0].deductions[0].sss_loan_balance,
                ["pagibig_loan"]: details[0].deductions[0].pagibig_loan_balance,
                ["transportation_allowance"]: details[0].additions.transportation_allowance,
                ["other_allowances"]: details[0].additions.other_allowances,
            }));
        }
        setTimeout(() => setIsChanged(false), 1);
    }   
    console.log(computation);

    async function fetchData() {
        setAddDetails([]);

        const response = await getPayroll(id);
        console.log(response);

        if (response.error) {
            // TokenExpiry(response);
        } else {
            var data = response.data.data[0];
            data.total_additions = data.total_addition;
            data.employee_id_value = {
                label: data.employee_name,
                value: data.employee_id,
                for: "employee_id",
                select_value: "employee_id_value",
            }
            setAddDetails(data)
            // setEmployeeDetails(data[0].employees)
        }
    }


    useEffect(() => {
        if(add) {
            fetchEmployees();
            fetchComputation()
        }
        if(edit) {
            fetchData()
        }
    }, []);

    useEffect(() => {
        var tempOtherFees = addDetails.other_fees
            ? parseFloat(addDetails.other_fees)
            : 0;
            
        var tempShortage = shortages
        .map((shortage) => parseFloat(shortage.amount !== "" ? shortage.amount : 0))
        .reduce((a, b) => a + b, 0);
        
        var tempCashAdvance = cashAdvances
        .map((ca) => parseFloat(ca.amount !== "" ? ca.amount : 0))
        .reduce((a, b) => a + b, 0);

        var tempTotalDeduction = parseFloat(addDetails.philhealth) + 
                                parseFloat(addDetails.sss) + parseFloat(addDetails.pagibig) + 
                                tempShortage + 
                                tempCashAdvance +
                                parseFloat(addDetails.sss_loan) +
                                parseFloat(addDetails.pagibig_loan);
        var tempTotalAddition = parseFloat(addDetails.transportation_allowance) + 
                                parseFloat(addDetails.other_allowances) + 
                                parseFloat(addDetails.holidays) +
                                parseFloat(addDetails.thirteenth_month) +
                                parseFloat(addDetails.bonus) +
                                parseFloat(addDetails.service_incentive);

        var _subtotal = addDetails.amount
        ? parseFloat(addDetails.amount)
        : 0;
        var _grandTotal = (parseFloat(addDetails.basic_pay) + tempTotalAddition) - tempTotalDeduction;
        setAddDetails((prev) => {
            return {
                ...prev,
                subtotal: _subtotal.toFixed(2),
                grand_total: _grandTotal.toFixed(2),
                total_deduction: tempTotalDeduction.toFixed(2),
                total_additions: tempTotalAddition.toFixed(2),
            };
        });
    }, [
        isChanged
    ]);

    return (
        <div>
            <div className="page">
                <Navbar
                    onCollapse={(inactive) => {
                        setInactive(inactive);
                    }}
                    active={"EMPLOYEES"}
                />
            </div>

            <div className={`container ${inactive ? "inactive" : "active"}`}>
                {/* header */}
                <div className="d-flex justify-content-between align-items-center my-3 pb-4">
                    <h1 className="page-title mb-0">
                        {add ? "ADD " : "EDIT "} PAYROLL
                    </h1>
                </div>

                {/* content */}
                <div className="edit-form">
                    {/* FRANCHISEE SALES INVOICE DETAILS */}
                    <Fragment>
                        <Row className="pt-3 mb-2">
                            <Col xs={4}>
                                <span className="edit-label">
                                    EMPLOYEE NAME
                                    <label className="badge-required">{` *`}</label>
                                </span>
                            </Col>
                            <Col xs={4}>
                                <span className="edit-label">
                                    DATE FROM
                                    <label className="badge-required">{` *`}</label>
                                </span>
                            </Col>
                            <Col xs={4}>
                                <span className="edit-label">
                                    DATE TO
                                    <label className="badge-required">{` *`}</label>
                                </span>
                            </Col>
                        </Row>
                        <Row> 
                            <Col xs={4}>
                                <Form.Control
                                    type="text"
                                    name="employee_name"
                                    className="nc-modal-custom-text"
                                    value={addDetails.employee_id_value.label}
                                    // onChange={(e) => handleAddChange(e)} 
                                    disabled
                                />
                                {/* <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    placeholder="Select Employee..."
                                    value={addDetails.employee_id_value}
                                    options={employees}
                                    // onChange={(e) => handleAddChange(e, "select")}
                                    disabled
                                /> */}
                                <InputError
                                    isValid={isError.employee_id}
                                    message={"Employee is required"}
                                />
                            </Col>
                            <Col xs={4}>
                                <Form.Control
                                    type="date"
                                    name="date_from"
                                    className="nc-modal-custom-text"
                                    value={addDetails.date_from}
                                    // onChange={(e) => handleAddChange(e)}
                                    disabled
                                />
                                <InputError
                                    isValid={isError.date_from}
                                    message={"Month is required"}
                                />
                            </Col>
                            <Col xs={4}>
                                <Form.Control
                                    type="date"
                                    name="date_to"
                                    className="nc-modal-custom-text"
                                    value={addDetails.date_to}
                                    // onChange={(e) => handleAddChange(e)}
                                    disabled
                                />
                                <InputError
                                    isValid={isError.date_to}
                                    message={"Month is required"}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-4 mb-2">
                            <Col >
                                <span className="edit-label">
                                    DISBURSEMENT DATE
                                    <label className="badge-required">{` *`}</label>
                                </span>
                            </Col>
                            {/* <Col>
                                <span className="edit-label">
                                    Department
                                    <label className="badge-required">{` *`}</label>
                                </span>
                            </Col> */}
                            <Col>
                                <span className="edit-label">
                                    disbursement Type 
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Control
                                    type="date"
                                    name="disbursement_date"
                                    className="nc-modal-custom-text"
                                    value={addDetails.disbursement_date}
                                    onChange={(e) => handleAddChange(e)}
                                />
                                <InputError
                                    isValid={isError.disbursement_date}
                                    message={"Date is required"}
                                />
                            </Col>
                            {/* <Col>
                                <Form.Control
                                    type="text"
                                    name="department"
                                    className="nc-modal-custom-text"
                                    value={addDetails.department}
                                    onChange={(e) => handleAddChange(e)} 
                                />
                                <InputError
                                    isValid={isError.department}
                                    message={"department is required"}
                                />
                            </Col> */}
                            <Col>
                                <Form.Select
                                    type="select"
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    placeholder="Select Type..."
                                    name="disbursement_type"
                                    value={addDetails.disbursement_type}
                                    onChange={(e) => handleAddChange(e)}
                                >
                                    <option disabled selected value="">
                                        Select
                                    </option>
                                    <option value="atm" selected>
                                        ATM
                                    </option>
                                    <option value="palawan">
                                        Palawan
                                    </option>
                                    <option value="cash">
                                        Cash
                                    </option>
                                    <option value="others">
                                        Others
                                    </option>
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row className="mt-4 mb-2">
                            <Col xs={3}>
                                <span className="edit-label">
                                    Rate
                                    <label className="badge-required">{` *`}</label>
                                </span>
                            </Col>
                            <Col xs={3}>
                                <Form.Control
                                    type="text"
                                    name="rate"
                                    className="nc-modal-custom-text text-end"
                                    value={numberFormat(addDetails.rate)}
                                    // onChange={(e) => handleAddChange(e)} 
                                    disabled
                                />
                                {/* <InputError
                                    isValid={isError.rate}
                                    message={"Rate is required"}
                                /> */}
                            </Col>
                        </Row>
                        <Row className="mt-4 mb-2">
                            <Col xs={3}>
                                <span className="edit-label">
                                    Total Working Hours
                                    <label className="badge-required">{` *`}</label>
                                </span>
                            </Col>
                            <Col xs={3}>
                                <Form.Control
                                    type="text"
                                    name="total_working_hours"
                                    className="nc-modal-custom-text text-end"
                                    value={numberFormat(addDetails.total_working_hours)}
                                    // onChange={(e) => handleAddChange(e)} 
                                    disabled
                                />
                                {/* <InputError
                                    isValid={isError.total_working_hours}
                                    message={"Total working days is required"}
                                /> */}
                            </Col>
                        </Row>
                        <Row className="mt-4 mb-2">
                            <Col xs={3}>
                                <span className="edit-label">
                                    Basic Pay
                                    <label className="badge-required">{` *`}</label>
                                </span>
                            </Col>
                            <Col xs={3}>
                                <Form.Control
                                    type="text"
                                    name="basic_pay"
                                    className="nc-modal-custom-text text-end"
                                    value={numberFormat(addDetails.basic_pay)}
                                    // onChange={(e) => handleAddChange(e)} 
                                    disabled
                                />
                                {/* <InputError
                                    isValid={isError.basic_pay}
                                    message={"Basic pay is required"}
                                /> */}
                            </Col>
                        </Row>
                        <Row className="mt-4 mb-2">
                            <Col xs={3}>
                            </Col>
                            <Col xs={3}>
                            </Col>
                        </Row>

                        <Row className="mt-5 mb-2">
                            <Col>
                                <span className="edit-label">
                                    DEDUCTIONS
                                </span>
                            </Col>
                            <hr/>
                        </Row>
                        <Row className="border-gray">
                            <Row className="mt-4 mb-2">
                                <Col>
                                    <span className="edit-label">
                                        CONTRIBUTIONS
                                    </span>
                                </Col>
                                <hr/>
                            </Row>
                            <Row className="mt-1 mb-2">
                                <Col xs={3}>
                                    <span className="edit-label">
                                        PhilHealth
                                    </span>
                                </Col>
                                <Col xs={3}>
                                    <Form.Control
                                        type="text"
                                        name="philhealth"
                                        className="nc-modal-custom-text text-end"
                                        value={addDetails.philhealth}
                                        onChange={(e) => handleAddChange(e)} 
                                    />
                                    <InputError
                                        isValid={isError.philhealth}
                                        message={"philhealth is required"}
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-4 mb-2">
                                <Col xs={3}>
                                    <span className="edit-label">
                                        SSS
                                    </span>
                                </Col>
                                <Col xs={3}>
                                    <Form.Control
                                        type="text"
                                        name="sss"
                                        className="nc-modal-custom-text text-end"
                                        value={addDetails.sss}
                                        onChange={(e) => handleAddChange(e)} 
                                    />
                                    <InputError
                                        isValid={isError.sss}
                                        message={"sss is required"}
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-4 mb-2">
                                <Col xs={3}>
                                    <span className="edit-label">
                                        pagibig
                                    </span>
                                </Col>
                                <Col xs={3}>
                                    <Form.Control
                                        type="text"
                                        name="pagibig"
                                        className="nc-modal-custom-text text-end"
                                        value={addDetails.pagibig}
                                        onChange={(e) => handleAddChange(e)} 
                                    />
                                    <InputError
                                        isValid={isError.pagibig}
                                        message={"pagibig is required"}
                                    />
                                </Col>
                            </Row>
                            {
                                shortages.length > 0 ? (
                                    shortages?.map((data, index) => {
                                        return (
                                            <Row className="mt-4 mb-2">
                                                <Col xs={3}>
                                                    <span className="edit-label">
                                                        Shortage
                                                    </span>
                                                </Col>
                                                <Col xs={3}>
                                                    <Form.Control
                                                        type="text"
                                                        name="amount"
                                                        className="nc-modal-custom-text text-end"
                                                        value={data.amount}
                                                        onChange={(e) => handleShortageChange(e, index)} 
                                                    />
                                                    {/* <InputError
                                                        isValid={isError.shortage}
                                                        message={"shortage is required"}
                                                    /> */}
                                                </Col>
                                                
                                                <Col xs={3}>
                                                    <span className="edit-label">
                                                        Source: {data.source === "cash_variance" ? "Cash Variance" : "inventory variance"}
                                                    </span>
                                                </Col>
                                                <Col xs={3}>
                                                    <span className="edit-label">
                                                        Date: {data.date}
                                                    </span>
                                                </Col>
                                            </Row>
                                        )
                                    })
                                ) : (
                                    <Row className="mt-4 mb-2 justify-contents-center">
                                        <Col xs={3}>
                                            <span className="edit-label">
                                                Shortage
                                            </span>
                                        </Col>
                                        <Col xs={3}>
                                            <span className="edit-label">No Shortage Found!</span>
                                        </Col>
                                    </Row>
                                )
                                
                            }
                            
                            {
                                cashAdvances.length > 0 ? (
                                    cashAdvances?.map((data, index) => {
                                        return (
                                            <Row className="mt-4 mb-2">
                                                <Col xs={3}>
                                                    <span className="edit-label">
                                                        Cash Advance
                                                    </span>
                                                </Col>
                                                <Col xs={3}>
                                                    <Form.Control
                                                        type="text"
                                                        name="amount"
                                                        className="nc-modal-custom-text text-end"
                                                        value={data.amount}
                                                        onChange={(e) => handleCashAdvanceChange(e, index)} 
                                                    />
                                                    {/* <InputError
                                                        isValid={isError.cash_advance}
                                                        message={"cash_advance is required"}
                                                    /> */}
                                                </Col>
                                                <Col xs={3}>
                                                    <span className="edit-label">
                                                        Date: {data.date}
                                                    </span>
                                                </Col>
                                            </Row>
                                        )
                                    })
                                ) : (
                                    <Row className="mt-4 mb-2 justify-contents-center">
                                        <Col xs={3}>
                                            <span className="edit-label">
                                            Cash Advance
                                            </span>
                                        </Col>
                                        <Col xs={3}>
                                            <span className="edit-label">No Cash Advance Found!</span>
                                        </Col>
                                    </Row>
                                )
                            }
                        </Row>
                        <Row className="border-gray mt-4">
                            <Row className="mt-4 mb-2">
                                <Col>
                                    <span className="edit-label">
                                        LOANS
                                    </span>
                                </Col>
                                <hr/>
                            </Row>
                            <Row className="mt-4 mb-2">
                                <Col xs={3}>
                                    <span className="edit-label">
                                        SSS LOAN
                                    </span>
                                </Col>
                                <Col xs={3}>
                                    <Form.Control
                                        type="text"
                                        name="sss_loan"
                                        className="nc-modal-custom-text text-end"
                                        value={addDetails.sss_loan}
                                        onChange={(e) => handleAddChange(e)} 
                                    />
                                    <InputError
                                        isValid={isError.sss_loan}
                                        message={"sss_loan is required"}
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-4 mb-2">
                                <Col xs={3}>
                                    <span className="edit-label">
                                        pagibig loan
                                    </span>
                                </Col>
                                <Col xs={3}>
                                    <Form.Control
                                        type="text"
                                        name="pagibig_loan"
                                        className="nc-modal-custom-text text-end"
                                        value={addDetails.pagibig_loan}
                                        onChange={(e) => handleAddChange(e)} 
                                    />
                                    <InputError
                                        isValid={isError.pagibig_loan}
                                        message={"pagibig_loan is required"}
                                    />
                                </Col>
                            </Row>
                        </Row>
                        <Row className="align-left py-1">
                            <Col xs={2} className="text-start">
                                <span className="edit-label color-gray">
                                    Total Deduction
                                </span>
                            </Col>
                            <Col xs={1} className="text-end">
                                <span className="edit-label align-middle">
                                    PHP
                                </span>
                            </Col>
                            <Col xs={3} className="text-end">
                                <span className="edit-label align-middle">
                                    {numberFormat(addDetails.total_deduction)} 
                                </span>
                            </Col>
                        </Row>

                        <Row className="mt-5 mb-2">
                            <Col>
                                <span className="edit-label">
                                    ADDITIONS
                                </span>
                            </Col>
                            <hr/>
                        </Row>
                        <Row className="border-gray">
                            <Row className="mt-4 mb-2">
                                <Col>
                                    <span className="edit-label">
                                        ALLOWANCES
                                    </span>
                                </Col>
                                <hr/>
                            </Row>
                            <Row className="mt-1 mb-2">
                                <Col xs={3}>
                                    <span className="edit-label">
                                    Transportation Allowance
                                    </span>
                                </Col>
                                <Col xs={3}>
                                    <Form.Control
                                        type="text"
                                        name="transportation_allowance"
                                        className="nc-modal-custom-text text-end"
                                        value={addDetails.transportation_allowance}
                                        onChange={(e) => handleAddChange(e)} 
                                    />
                                    <InputError
                                        isValid={isError.transportation_allowance}
                                        message={"transportation_allowance is required"}
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-4 mb-2">
                                <Col xs={3}>
                                    <span className="edit-label">
                                        OTHER ALLOWANCES
                                    </span>
                                </Col>
                                <Col xs={3}>
                                    <Form.Control
                                        type="text"
                                        name="other_allowances"
                                        className="nc-modal-custom-text text-end"
                                        value={addDetails.other_allowances}
                                        onChange={(e) => handleAddChange(e)} 
                                    />
                                    <InputError
                                        isValid={isError.other_allowances}
                                        message={"other_allowances is required"}
                                    />
                                </Col>
                            </Row>
                            {/* <Row className="mt-4 mb-2">
                                <Col xs={3}>
                                    <span className="edit-label">
                                        Communication Allowance
                                    </span>
                                </Col>
                                <Col xs={3}>
                                    <Form.Control
                                        type="text"
                                        name="communication_allowance"
                                        className="nc-modal-custom-text"
                                        value={addDetails.communication_allowance}
                                        onChange={(e) => handleAddChange(e)} 
                                    />
                                    <InputError
                                        isValid={isError.communication_allowance}
                                        message={"communication_allowance is required"}
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-4 mb-2">
                                <Col xs={3}>
                                    <span className="edit-label">
                                        FOOD ALLOWANCE
                                    </span>
                                </Col>
                                <Col xs={3}>
                                    <Form.Control
                                        type="text"
                                        name="food_allowance"
                                        className="nc-modal-custom-text"
                                        value={addDetails.food_allowance}
                                        onChange={(e) => handleAddChange(e)} 
                                    />
                                    <InputError
                                        isValid={isError.food_allowance}
                                        message={"food_allowance is required"}
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-4 mb-2">
                                <Col xs={3}>
                                    <span className="edit-label">
                                        HMO ALLOWANCE
                                    </span>
                                </Col>
                                <Col xs={3}>
                                    <Form.Control
                                        type="text"
                                        name="hmo_allowance"
                                        className="nc-modal-custom-text"
                                        value={addDetails.hmo_allowance}
                                        onChange={(e) => handleAddChange(e)} 
                                    />
                                    <InputError
                                        isValid={isError.hmo_allowance}
                                        message={"hmo_allowance is required"}
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-4 mb-2">
                                <Col xs={3}>
                                    <span className="edit-label">
                                        TECH ALLOWANCE
                                    </span>
                                </Col>
                                <Col xs={3}>
                                    <Form.Control
                                        type="text"
                                        name="tech_allowance"
                                        className="nc-modal-custom-text"
                                        value={addDetails.tech_allowance}
                                        onChange={(e) => handleAddChange(e)} 
                                    />
                                    <InputError
                                        isValid={isError.tech_allowance}
                                        message={"tech_allowance is required"}
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-4 mb-2">
                                <Col xs={3}>
                                    <span className="edit-label">
                                        OPS ALLOWANCE
                                    </span>
                                </Col>
                                <Col xs={3}>
                                    <Form.Control
                                        type="text"
                                        name="ops_allowance"
                                        className="nc-modal-custom-text"
                                        value={addDetails.ops_allowance}
                                        onChange={(e) => handleAddChange(e)} 
                                    />
                                    <InputError
                                        isValid={isError.ops_allowance}
                                        message={"ops_allowance is required"}
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-4 mb-2">
                                <Col xs={3}>
                                    <span className="edit-label">
                                        SPECIAL ALLOWANCE
                                    </span>
                                </Col>
                                <Col xs={3}>
                                    <Form.Control
                                        type="text"
                                        name="special_allowance"
                                        className="nc-modal-custom-text"
                                        value={addDetails.special_allowance}
                                        onChange={(e) => handleAddChange(e)} 
                                    />
                                    <InputError
                                        isValid={isError.special_allowance}
                                        message={"special_allowance is required"}
                                    />
                                </Col>
                            </Row> */}
                            <Row className="mt-4 mb-2">
                                <Col xs={3}>
                                    <span className="edit-label">
                                        HOLIDAYS
                                    </span>
                                </Col>
                                <Col xs={3}>
                                    <Form.Control
                                        type="text"
                                        name="holidays"
                                        className="nc-modal-custom-text text-end"
                                        value={addDetails.holidays}
                                        onChange={(e) => handleAddChange(e)} 
                                    />
                                    <InputError
                                        isValid={isError.holidays}
                                        message={"holidays is required"}
                                    />
                                </Col><Col xs={3}>
                                    <span className="edit-label">
                                        DESCRIPTION
                                    </span>
                                </Col>
                                <Col xs={3}>
                                    <Form.Control
                                        type="text"
                                        name="holiday_description"
                                        className="nc-modal-custom-text"
                                        value={addDetails.holiday_description}
                                        onChange={(e) => handleAddChange(e)} 
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-4 mb-2">
                                <Col xs={3}>
                                    <span className="edit-label">
                                        13th Month
                                    </span>
                                </Col>
                                <Col xs={3}>
                                    <Form.Control
                                        type="text"
                                        name="thirteenth_month"
                                        className="nc-modal-custom-text text-end"
                                        value={addDetails.thirteenth_month}
                                        onChange={(e) => handleAddChange(e)} 
                                    />
                                    <InputError
                                        isValid={isError.thirteenth_month}
                                        message={"thirteenth_month is required"}
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-4 mb-2">
                                <Col xs={3}>
                                    <span className="edit-label">
                                        BONUS
                                    </span>
                                </Col>
                                <Col xs={3}>
                                    <Form.Control
                                        type="text"
                                        name="bonus"
                                        className="nc-modal-custom-text text-end"
                                        value={addDetails.bonus}
                                        onChange={(e) => handleAddChange(e)} 
                                    />
                                    <InputError
                                        isValid={isError.bonus}
                                        message={"bonus is required"}
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-4 mb-2">
                                <Col xs={3}>
                                    <span className="edit-label">
                                    Service Incentive
                                    </span>
                                </Col>
                                <Col xs={3}>
                                    <Form.Control
                                        type="text"
                                        name="service_incentive"
                                        className="nc-modal-custom-text text-end"
                                        value={addDetails.service_incentive}
                                        onChange={(e) => handleAddChange(e)} 
                                    />
                                    <InputError
                                        isValid={isError.service_incentive}
                                        message={"service_incentive is required"}
                                    />
                                </Col>
                            </Row>
                        </Row>
                        <Row className="align-left py-1">
                            <Col xs={2} className="text-start">
                                <span className="edit-label color-gray">
                                    Total Addition
                                </span>
                            </Col>
                            <Col xs={1} className="text-end">
                                <span className="edit-label align-middle">
                                    PHP
                                </span>
                            </Col>
                            <Col xs={3} className="text-end">
                                <span className="edit-label align-middle">
                                    {numberFormat(addDetails.total_additions)} 
                                </span>
                            </Col>
                        </Row>
                        
                        <Row className="mt-4 mb-2">
                            <Col>
                                <span className="edit-label">
                                    Remarks
                                </span>
                            </Col>
                        </Row>
                        <Row className="mt-4 mb-2">
                            <Col>
                                <Form.Control
                                    type="text"
                                    name="remarks"
                                    className="nc-modal-custom-text"
                                    value={addDetails.remarks}
                                    onChange={(e) => handleAddChange(e)} 
                                />
                            </Col>
                        </Row>
                    </Fragment>
                    
                    <Row className="align-right py-1 mt-3">
                        <Col xs={2} className="text-end">
                            <span className="edit-label color-gray">
                                Basic Pay
                            </span>
                        </Col>
                        <Col xs={1} className="text-end">
                            <span className="edit-label align-middle">
                                PHP
                            </span>
                        </Col>
                        <Col xs={3} className="text-end">
                            <span className="edit-label align-middle">
                                {addDetails.basic_pay ? numberFormat(addDetails.basic_pay) : "0.00"} 
                            </span>
                        </Col>
                    </Row>
                    <Row className="align-right py-1">
                        <Col xs={2} className="text-end">
                            <span className="edit-label color-gray">
                                Total Deduction
                            </span>
                        </Col>
                        <Col xs={1} className="text-end">
                            <span className="edit-label align-middle">
                                PHP
                            </span>
                        </Col>
                        <Col xs={3} className="text-end">
                            <span className="edit-label align-middle">
                                -{addDetails.total_deduction ? numberFormat(addDetails.total_deduction) : "0.00"} 
                            </span>
                        </Col>
                    </Row>
                    <Row className="align-right py-1">
                        <Col xs={2} className="text-end">
                            <span className="edit-label color-gray">
                                Total Addition
                            </span>
                        </Col>
                        <Col xs={1} className="text-end">
                            <span className="edit-label align-middle">
                                PHP
                            </span>
                        </Col>
                        <Col xs={3} className="text-end">
                            <span className="edit-label align-middle">
                                +{addDetails.total_additions ? numberFormat(addDetails.total_additions) : "0.00"} 
                            </span>
                        </Col>
                    </Row>
                    <Row className="align-right py-3">
                        <Col xs={2} className="text-end">
                            <span className="edit-label color-gray grand-total-text">
                                Grand Total
                            </span>
                        </Col>
                        <Col xs={1} className="text-end">
                            <span className="edit-label align-middle grand-total-text">
                                PHP
                            </span>
                        </Col>
                        <Col xs={3} className="text-end">
                            <span className="edit-label align-middle grand-total-text">
                                {addDetails.grand_total ? numberFormat(addDetails.grand_total) : "0.00"} 
                            </span>
                        </Col>
                    </Row>

                    {/* FOOTER: CANCEL & SUBMIT BUTTONS */}
                    <div className="d-flex justify-content-end pt-5 pb-3">
                        <button
                            type="button"
                            className="button-secondary me-3"
                            onClick={() => navigate(-1)}
                        >
                            Cancel
                        </button>
                        {/* {isSaveClicked ? (
                            <div className="button-secondary d-flex justify-content-center">
                                <ReactLoading
                                    type="bubbles"
                                    color="#FFFFFF"
                                    height={50}
                                    width={50}
                                />
                            </div>
                        ) : (
                            <button
                                type="button"
                                className="button-primary me-3"
                                onClick={handleSave}
                            >
                                Save
                            </button>
                        )} */}
                        {isSubmitClicked ? (
                            <div className="button-primary d-flex justify-content-center">
                                <ReactLoading
                                    type="bubbles"
                                    color="#FFFFFF"
                                    height={50}
                                    width={50}
                                />
                            </div>
                        ) : (
                            <button
                                type="button"
                                className="button-primary"
                                onClick={handleSubmit}
                            >
                                Generate
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormPayroll